import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './condition.pug'

const DefaultData = () => ({
  name: '',
  config: {
    evaluate: {
      tagKey: '',
      operator: '',
      value: ''
    }
  }
})

// Nodo que permite dividir el flujo del workflow en dos caminos basado en una condición
export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node)
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    fieldSelectOptions() {
      return this.node.tags.map(item => (
        {label: item.name || item.key, value: `${item.type}.${item.key}`}
      )).sort((a, b) => (a.label.localeCompare(b.label)))
    },

    operatorTextOptions() {
      return [
        COMPARISON_OPERATORS.EQUAL,
        COMPARISON_OPERATORS.DISTINCT,
        COMPARISON_OPERATORS.CONTAINS,
        COMPARISON_OPERATORS.GREATER_THAN,
        COMPARISON_OPERATORS.LESS_THAN,
        COMPARISON_OPERATORS.STAYIN
      ].map(item => ({label: this.$t(`comparisonOperators.text.${item}`), value: item}))
    },

    operatorNumberOptions() {
      return [
        COMPARISON_OPERATORS.EQUAL,
        COMPARISON_OPERATORS.LESS_THAN,
        COMPARISON_OPERATORS.GREATER_THAN
      ].map(item => ({label: this.$t(`comparisonOperators.number.${item}`), value: item}))
    },

    operatorSelectOptions() {
      return this.isTagNumeric ? this.operatorNumberOptions : this.operatorTextOptions
    },

    isTagNumeric() {
      const field = this.data.config.evaluate.field

      if (!field) return false

      // el field tiene la forma "<tagType>.<tagKey>"
      const fieldParts = field.split('.')
      const tagType = fieldParts[0]
      const tagKey = fieldParts[1]

      const tag = this.node.tags.find(item => item.key === tagKey && item.type === tagType)
      return tag.fieldType === FIELD_TYPES.NUMERIC
    }
  },
  watch: {
    operatorSelectOptions() {
      this.data.config.evaluate.operator = this.operatorSelectOptions[0].value
    }
  },
  methods: {
    save() {
      validatePresence(this.data, [
        'name',
        'config.evaluate.field',
        'config.evaluate.operator',
        'config.evaluate.value'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.condition.${key}`, options)
    }
  }
})
