import template from './index.pug'

Vue.component('ns-input-currency', {
  template: template(),
  inheritAttrs: false,
  props: {
    value: [String, Number],
    // el size como prop viene heredado del ns-input (aunque está feo)
    size: [String],
    decimals: {
      type: Boolean,
      default: true
    },
    negative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // guarda el valor actual del input interno
      currentValue: ''
    }
  },
  computed: {
    // aunque mostremos "," hay que emitir con "." que es válido en programación
    valueToEmit() {
      if (!this.currentValue) return ''

      const value = this.currentValue.replace(',', '.')

      return value[value.length - 1] === ',' ? value.replace(',', '') : value
    },

    rootLayoutClass() {
      return [
        `ns-input--${this.size}`,
        {
          'is-disabled': this.$attrs.disabled
        }
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) { this.initializeValue(newVal) }
    },

    currentValue(newVal, oldVal) {
      this.currentValue = this.checkAndTransformValue(newVal, oldVal)
    }
  },
  methods: {
    checkAndTransformValue(value, oldVal = '') {
      let newValue = value.toString().replace('.', ',').trim()
      const withDecimals = '(([0-9]*)|([0-9]+[,.][0-9]*))?'
      const regexMatch = new RegExp(
        `^(${this.negative ? '-?' : ''}${this.decimals ? withDecimals : '[0-9]*'})?$`,
        'g'
      )

      if (!newValue.match(regexMatch)) newValue = oldVal

      return newValue
    },

    initializeValue(value) {
      // aseguramos que solo se ejecuta cuando se cambia el v-model desde fuera del componente
      if ((value || value === 0) && value !== this.valueToEmit) {
        this.currentValue = this.checkAndTransformValue(value)
      } else if (!value) {
        this.currentValue = ''
      }
    },

    handleBlur(event) {
      if (this.currentValue[this.currentValue.length - 1] === ',') {
        this.currentValue = this.currentValue.slice(0, -1)
      }

      this.$emit('blur', event)
      this.$emit('input', this.currentValue.replace(',', '.'))
    }
  }
})
