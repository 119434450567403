import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './pause.pug'

const DefaultData = () => ({
  name: '',
  config: {
    timevalue: '',
    timeunit: ''
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      callCenters: [],
      ctis: [],
      services: [],
      timeTypeSelectOptions: [
        {label: this.t('timeTypes.second'), value: 'second'},
        {label: this.t('timeTypes.minute'), value: 'minute'},
        {label: this.t('timeTypes.hour'), value: 'hour'},
        {label: this.t('timeTypes.day'), value: 'day'}
      ]
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
      }
    }
  },
  methods: {
    save() {
      validatePresence(this.data, [
        'name',
        'config.timeunit',
        'config.timevalue'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.pause.${key}`, options)
    }
  }
})
