import template from './notification-item.pug'
import typeNotification from './type-notification'
import ModalNotification from './_modal-notification'

export default Vue.extend({
  template: template(),
  components: {
    ModalNotification
  },
  props: {
    type: String,
    title: String,
    date: [String, Date],
    description: String,
    explanation: String,
    disableDelete: Boolean,
    formatDate: Boolean
  },
  data() {
    return {
      modalExplanation: false
    }
  },
  computed: {
    notification() {
      return typeNotification().find(({value}) => value === this.type) || {}
    },
    dateFormated() {
      if (!this.date) return ''
      if (this.formatDate) {
        return moment(this.date).format('DD/MM/YYYY')
      }
      return this.date
    }
  },
  methods: {
    deleteNotification() {
      this.$emit('delete')
    },
    viewNotification() {
      this.modalExplanation = true
    }
  }
})
