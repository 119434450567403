import {orderBy} from 'lodash'

const normalizeString = string => {
  if (string) {
    return string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }
    return ''
}

const addError = (errors, key, error) => {
  errors[key] ? errors[key].push(error) : errors[key] = [error] // eslint-disable-line
}

const simulateValidateBackend = (validations, payload) => {
  const errors = {}
  let parsedPayload = {}

  if (payload instanceof FormData) {
    /* eslint-disable */
    for (const [key, value] of payload.entries()) {
      // no soporta array de objetos
      parsedPayload[key.replace('[]', '')] = key.includes('[]') ? payload.getAll(key) : value
    }
    /* eslint-enable */
  } else {
    parsedPayload = payload
  }
  Object.keys(validations).forEach(field => {
    const validation = validations[field]
    const value = parsedPayload[field]

    // el campo es un array de objetos (nested)
    if ((typeof value === 'object') && validation.type === 'object') {
      const result = simulateValidateBackend(validation.objectValidations, value)

      if (result.status === 422) {
        Object.keys(result.body.errors).forEach(key => {
          addError(errors, `${field}.${key}`, result.body.errors[key][0])
        })
      }
    } else if ((value instanceof Array) && (typeof value[0] === 'object')) {
      value.forEach((item, index) => {
        const result = simulateValidateBackend(validation.objectValidations, item)

        if (result.status === 422) {
          Object.keys(result.body.errors).forEach(key => {
            addError(errors, `${field}[${index}].${key}`, result.body.errors[key][0])
          })
        }
      })
    } else if (validation.required && ((!value && value !== false) || value.length === 0)) {
      addError(errors, field, {error: 'blank'})
    } else if (validation.format && value && !value.match(validation.format)) {
      addError(errors, field, {error: 'format'})
    } else if (validation.maxLength && value && value.length > validation.maxLength) {
      addError(errors, field, {error: 'too_long'})
    } else if (validation.inclusion && value && validation.inclusion.indexOf(value) === -1) {
      addError(errors, field, {error: 'inclusion'})
    }
  })

  if (Object.keys(errors).length === 0) {
    return {
      body: payload,
      status: 200
    }
  }

  return {
    body: {errors},
    status: 422
  }
}

const simulateIndexBackend = (collection, params) => {
  let data = collection
  const filters = params.filter
  const order = params.order
  const pagination = params.page || {number: 1, size: 2}

  // Apply filters
  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      if ((!value && value !== 0) || (Array.isArray(value) && !value.length)) return

      if (key.includes('_gt') || key.includes('_lt')) {
        // Date filters code
      } else if (key === 'specific') {
        // Specific filter code
      } else if (Array.isArray(value)) {
        // Filter multiselect
        data = data.filter(item => {
          // Si el campo a filtrar también es un array de ids
          if (Array.isArray(item[key])) {
            return value.filter(val => item[key].includes(val)).length > 0
          }

          return value.includes(item[key])
        })
      } else if (typeof value === 'object') {
        // Sub filters
      } else {
        // Other fields filter code
        data = data.filter(
          item => normalizeString(item[key]).includes(normalizeString(value))
        )
      }
    })
  }

  // Apply order
  if (order) {
    data = orderBy(data, Object.keys(order), Object.values(order))
  }

  // Apply pagination
  const firstPosition = (pagination.number - 1) * pagination.size
  const pageDate = data.slice(firstPosition, firstPosition + pagination.size)

  return {
    data: pageDate,
    meta: {
      currentPage: pagination.number,
      totalPages: Math.ceil(data.length / pagination.size),
      totalElements: data.length,
      pageSize: pagination.size
    }
  }
}

// Parsea la url cambiando las $variables por sus valores en -record-
// campaigns/$campaignId/tags/$id => campaigns/record.campaignId/tags/record.id
const buildUrl = (resourceName, record) => (
  resourceName.replace(/\$\w+/g, match => (record[match.substring(1)]))
)

const generateShowMocks = (collection, resourceName) => (
  collection.map(record => ({
    method: 'GET',
    url: buildUrl(resourceName, record),
    body: {data: record},
    status: 200
  }))
)

const generateDeleteMocks = (collection, resourceName) => (
  collection.map(record => ({
    method: 'DELETE',
    url: buildUrl(resourceName, record),
    status: 200
  }))
)

// Genera los mocks de editar correctamente y los de error
const generateUpdateMocks = (collection, resourceName, successMock, errorMock) => (
  collection.map(record => ({
    method: 'PUT',
    url: buildUrl(resourceName, record),
    payload: successMock.payload,
    body: successMock.body || {...record, ...successMock.payload},
    status: 201
  })).concat(
    collection.map(record => ({
      method: 'PUT',
      url: buildUrl(resourceName, record),
      payload: errorMock.payload,
      body: errorMock.body,
      status: 422
    })),
    [
      {
        method: 'PUT',
        url: `${resourceName}/0`,
        payload: successMock.payload,
        status: 404
      }
    ]
  )
)

const   generateMethodMocks = options => {
  let mocks = []

  if (options.method === 'GET') {
    if (options.url.match(/\/\$id$/)) {
      // show
      mocks = options.records.map(record => ({
        method: 'GET',
        url: buildUrl(options.url, record),
        body: {data: record},
        status: 200
      }))
    } else {
      // index
      mocks = [{
        method: 'GET',
        url: options.url,
        body: request => simulateIndexBackend(options.records, request.params),
        status: 200
      }]
    }
  }

  if (options.method === 'POST') {
    mocks = [{
      method: options.method,
      url: options.url,
      validate: request => simulateValidateBackend(options.validations, request.body)
    }]
  }

  if (options.method === 'PATCH' || options.method === 'PUT') {
    mocks = options.records.map(record => (
      {
        method: options.method,
        url: buildUrl(options.url, record),
        validate: request => simulateValidateBackend(options.validations, request.body)
      }
    ))
  }

  if (options.method === 'DELETE') {
    mocks = options.records.map(record => ({
      method: 'DELETE',
      url: buildUrl(options.url, record),
      status: 200
    }))
  }

  if (options.notFoundUrl) {
    mocks.push({
      method: options.method,
      url: options.notFoundUrl,
      status: 404
    })
  }
  

  return mocks
}

export {
  simulateIndexBackend,
  simulateValidateBackend,
  generateShowMocks,
  generateDeleteMocks,
  generateUpdateMocks,
  generateMethodMocks
}
