
import ListIndex from '../phone-list-component/list-index'
import template from './client-portfolio-list-index.pug'

export default Vue.extend({
  template: template(),
  components: {
    ListIndex
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    campaignTags: {
      type: Array,
      default: () => ([])
    }
  }
})
