import RouteParent from 'js/router/route-parent'

import AdminsIndex from 'js/pages/users/admins/index.js'
import AdminsNew from 'js/pages/users/admins/new.js'
import AdminsEdit from 'js/pages/users/admins/edit.js'

import AgenciesIndex from 'js/pages/users/agencies/index.js'
import AgenciesNew from 'js/pages/users/agencies/new.js'
import AgenciesEdit from 'js/pages/users/agencies/edit.js'

import OperatorsIndex from 'js/pages/users/operators/index.js'
import OperatorsNew from 'js/pages/users/operators/new.js'
import OperatorsEdit from 'js/pages/users/operators/edit.js'

export default [
  {
    path: '/admins',
    component: RouteParent,
    meta: {
      auth: true,
      permit: ['MANAGE_ADMINS']
    },
    children: [
      {
        path: '',
        name: 'admins-index',
        component: AdminsIndex,
        meta: {
          auth: true,
          permit: ['MANAGE_ADMINS']
        }
      },
      {
        path: 'new',
        name: 'admins-new',
        component: AdminsNew,
        meta: {
          auth: true,
          permit: ['MANAGE_ADMINS']
        }
      },
      {
        path: ':id/edit',
        name: 'admins-edit',
        component: AdminsEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_ADMINS']
        }
      }
    ]
  },
  {
    path: '/agency-suppliers',
    component: RouteParent,
    meta: {
      auth: true,
      permit: ['MANAGE_AGENCIES']
    },
    children: [
      {
        path: '',
        name: 'agency-suppliers-index',
        component: AgenciesIndex,
        meta: {
          auth: true,
          permit: ['MANAGE_AGENCIES']
        }
      },
      {
        path: 'new',
        name: 'agency-suppliers-new',
        component: AgenciesNew,
        meta: {
          auth: true,
          permit: ['MANAGE_AGENCIES']
        }
      },
      {
        path: ':id/edit',
        name: 'agency-suppliers-edit',
        component: AgenciesEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_AGENCIES']
        }
      }
    ]
  },
  {
    path: '/operators',
    component: RouteParent,
    meta: {
      auth: true,
      permit: ['MANAGE_OPERATORS']
    },
    children: [
      {
        path: '',
        name: 'operators-index',
        component: OperatorsIndex,
        meta: {
          auth: true,
          permit: ['MANAGE_OPERATORS']
        }
      },
      {
        path: 'new',
        name: 'operators-new',
        component: OperatorsNew,
        meta: {
          auth: true,
          permit: ['MANAGE_OPERATORS']
        }
      },
      {
        path: ':id/edit',
        name: 'operators-edit',
        component: OperatorsEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_OPERATORS']
        }
      }
    ]
  }
]
