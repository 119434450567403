import {pick} from 'lodash'
import ProviderForm from './_form'
import template from './edit.pug'

export default Vue.extend({
  template: template(),
  components: {
    ProviderForm
  },
  data() {
    return {
      ready: false,
      data: {},
      errors: {}
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('providerLeads.edit.title'))
        this.loadData()
      }
    }
  },

  methods: {
    loadData() {
      this.ready = false

      API.providerLeads.show(this.$route.params.id).then(response => {
        this.data = pick(
          response, ['id', 'name', 'description']
        )

        this.$setBreadcrumbs([
          {
            label: this.$t('breadcrumbs.provider-leads.index'),
            route: {name: 'provider-leads-index'}
          },
          {label: this.$t('breadcrumbs.provider-leads.edit', {name: this.data.name}), route: {}}
        ])

        this.ready = true
      })
    },

    updateProvider(data) {
      API.providerLeads.update(this.$route.params.id, data).then(() => {
        this.$notifications.success(this.$t('providerLeads.edit.succesfullyUpdated'))
        this.$router.push({name: 'provider-leads-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
