import Message from './_message'

import template from './confirm-email.pug'

export default Vue.extend({
  template: template(),
  components: {Message},
  data() {
    return {
      invalid: false,
      expired: false
    }
  },
  computed: {
    confirmationToken() {
      return this.$route.query.token
    }
  },
  watch: {
    confirmationToken: {
      immediate: true,
      handler(newToken) {
        this.invalid = false
        this.expired = false

        if (!newToken) {
          this.$router.push({name: 'login'})
        } else {
          this.confirm()
        }
      }
    }
  },
  methods: {
    confirm() {
      this.$auth.confirmation(this.confirmationToken).then(() => {
        this.$notifications.success(
          this.$t('auth.confirmEmail.success.notification'),
          {duration: 0, close: true}
        )

        this.$auth.logout()
      }, response => {
        if (response.status === 404) {
          this.invalid = true
        } else {
          this.expired = true
        }
      })
    }
  }
})
