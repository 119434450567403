const localeRoot = 'analytics.charts.contactedConvertedBar'

export default {
  options: data => {
    if (!data) return null

    return {
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        }
      },
      xAxis: [
        {
          type: 'category',
          data: data.map(item => item.date),
          axisLabel: {
            rotate: 30,
            margin: 28.5,
            align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: Vue.i18n.t(`${localeRoot}.series.contactedCount`),
          type: 'bar',
          barWidth: '40%',
          barGap: 0,
          data: data.map(item => item.contactedCount)
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.convertedCount`),
          type: 'bar',
          barWidth: '40%',
          data: data.map(item => item.convertedCount)
        }
      ],
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'empty',
          brushSelect: false,
          showDetail: false
        }
      ],
      media: [
        {
          query: {
            maxWidth: 500
          },
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        top: '3%',
        icon: 'circle',
        left: 'middle'
      },
      color: GRAPH_COLORS
    }
  }
}
