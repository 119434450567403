import {pick, isEqual} from 'lodash'
import {EmailEditor} from 'vue-email-editor'
import {validations} from '../../../../../mocks/customers/factory.js'
import template from './editor.pug'

const DefaultValues = () => Object.freeze({
  integrations: {
    googleAdwords: false,
    googleCampaignManager: false,
    RTB: false,
    DMP: false,
    CRM: false,
    ERP: false,
    facebookLeadAds: false
  }
})
const Fields = Object.keys(DefaultValues())
const Validations = pick(validations, Fields)

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  components: {
    EmailEditor
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    templateSelected: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: {},
      ready: false,
      emailEditorReady: false,
      config: {
        facebookLeads: false
      }
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    }
  },
  methods: {
    showEditor() {
      this.emailEditorReady = true
    },
    closeEditor() {
      this.emailEditorReady = false
    },
    editorLoaded() {
      // Pass your template JSON here
      // eslint-disable-next-line eqeqeq
      if (this.data.design != 'without') {
        this.$refs.emailEditor.editor.loadDesign(this.data.design)
      }
    },
    editorReady() {
      // console.log('editorReady')
    },
    saveDesign() {
      // this.$refs.emailEditor.editor.saveDesign(
      //   design => {
      //     // console.log('saveDesign', design)
      //   }
      // )
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml(
        // eslint-disable-next-line no-shadow
        data => {
          this.data.design = data.design
          this.data.template = data.html
          this.emailEditorReady = false
        }
      )
    },
    initializeData() {
      this.data = this.value
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`emailMarketing.form.${key}`, options)
    }
  }
})
