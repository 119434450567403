import {isEqual} from 'lodash'
import SegmentationForm from './_segmentation-form.js'

import template from './_general-data-form.pug'

const Fields = ['name', 'description', 'startAt', 'endAt', 'segmentationId']

// Componente con el form del automation y un boton que muestra el formulario de añadir segmentación
// y oculta el formulario de automation
export default Vue.extend({
  template: template(),
  fields: Fields,
  components: {
    SegmentationForm
  },
  props: {
    canEditFields: {type: Boolean, default: true},
    campaign: {type: Object, default: () => ({})},
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ready: false,
      data: {},
      segmentations: [],
      showSegmentation: false,
      segmentationData: {},
      segmentationErrors: {rules: []},
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false,
        initParseDate(date) {
          return date ? moment(date, FORMAT_DATE).toDate() : null
        },
        reverseParseDate(date) {
          return moment(date, FORMAT_DATE_DATEPICKER).format(FORMAT_DATE)
        }
      }
    }
  },
  computed: {
    datetimeStartObjectConfig() {
      const today = moment()
      const startAt = moment(this.campaign.startAt, 'D/M/Y')
      const endAt = moment(this.data.endAt || this.campaign.endAt, 'D/M/Y')

      return {
        minDate: today < startAt ? startAt.toDate() : today.toDate(),
        maxDate: endAt.toDate(),
        ...this.dateConfig
      }
    },

    datetimeEndObjectConfig() {
      const today = moment()
      const startAt = moment(this.data.startAt || this.campaign.startAt, 'D/M/Y')
      const endAt = moment(this.campaign.endAt, 'D/M/Y')

      return {
        minDate: today < startAt ? startAt.toDate() : today.toDate(),
        maxDate: endAt.toDate(),
        ...this.dateConfig
      }
    },

    segmentationOptions() {
      return this.segmentations.map(segmentation => (
        {label: segmentation.name, value: segmentation.id}
      )).concat([{label: this.t('fields.segmentation.placeholder'), value: ''}])
    },

    segmentation() {
      return this.segmentations.find(
        segmentation => segmentation.id === this.data.segmentationId
      )
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data) && !isEqual({}, this.data)) return

        this.initializeData()
        this.getSegmentations()
      }
    }
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.value ? this.value[key] || '' : ''
      })

      this.data = data
    },

    getSegmentations() {
      API.segmentations.index(
        this.campaign.id,
        {order: {name: 'asc'}, page: {number: 1, size: 1000}},
        {skipLoading: true}
      ).then(response => {
        this.segmentations = []
        this.segmentations = response.data
      })
    },

    createSegmentation() {
      API.segmentations.create(this.campaign.id, this.segmentationData).then(() => {
        this.segmentationData = {}
        this.$notifications.success(this.t('segmentations.succesfullyCreated'))
        this.closeSegmentationForm()
        this.getSegmentations()
      }, errors => {
        this.segmentationErrors = errors
      })
    },

    closeSegmentationForm() {
      this.$emit('toogle-show-segmentation')
      this.segmentationErrors = {}
      this.segmentationData = {}
      this.showSegmentation = false
    },

    openSegmentationForm() {
      this.$emit('toogle-show-segmentation')
      this.segmentationErrors = {rules: [{}]}
      this.showSegmentation = true
    },

    scrollToSelectSegmentationId(open) {
      if (open) {
        // Se necesita añadir un timeout para darle tiempo al componente para que muestre
        setTimeout(() => { document.getElementById('segmentation_id').scrollIntoView() }, 1)
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.generalDataForm.${key}`, options)
    }
  }
})
