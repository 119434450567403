import FullTable from 'js/components/full-table/index.js'
import {cloneDeep, debounce} from 'lodash'
import template from './_form.pug'
import {months} from '../../../services/months'

const Fields = [
  'leadProposalOfferDetail'
]

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    demandData: {
      type: Object,
      default: () => ({})
    },
    offerData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {},
      auxTable: [],
      months: months(),
      currencyOptions: []
    }
  },
  computed: {
    modeForm() {
      return this.data.id ? 'edit' : 'new'
    },

    saveButtonText() {
      return this.t(`saveButton.${this.modeForm}`)
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadCurrencys()
  },
  methods: {
    initializeData() {
      this.auxTable = []
      this.data = {}
      const data = {}
      const aux = []

      Fields.forEach(key => {
        if (key === 'leadProposalOfferDetail') {
          data[key] = this.initData ? this.initData[key] : []
        } else {
          data[key] = this.initData ? this.initData[key] : ''
        }
      })

      if (this.initData) {
        this.initData.leadProposalOfferDetail.forEach(item => {
          aux.push({
            id: item.id,
            month: this.getMonthText(item.availableMonth),
            // Id del detalle de la oferta
            leadProposalOfferDetailId: item.leadProposalOfferId,
            // Cantidad de Leads ofertados por el proveedor
            leadAmountOffer: item.leadAmountOffer,
            // Costo de leads ofertados
            cplOffer: item.cplOffer,
            // Cantidad de Leads a comprar
            leadAmount: item.leadAmount,
            errorLead: '',
            errorAmount: ''
          })
        })
      } else {
        this.offerData.demandOverTimes.forEach(item => {
          aux.push({
            ...item,
            month: this.getMonthText(item.availableMonthOffer),
            // Id del detalle de la oferta
            leadProposalOfferDetailId: item.id,
            // Cantidad de Leads ofertados por el proveedor
            leadAmountOffer: item.leadAmountOffer,
            // Costo de leads ofertados
            cplOffer: item.cplOffer,
            // Cantidad de Leads a comprar
            leadAmount: '',
            errorLead: '',
            errorAmount: ''
          })
        })
      }
      this.auxTable = aux
      this.data = data
    },
    getMonthText(month) {
      return this.months.find(({value}) => value === month).label
    },

    loadCurrencys() {
      API.leadTrade.currencys({page: {number: 1, size: 1000}}).then(({data}) => {
        this.currencyOptions = data.map(currency => ({
          label: currency.name,
          value: currency.id
        }))
      })
    },

    isEmpty(value) {
      return value === '' || value === null || value === undefined
    },

    // eslint-disable-next-line func-names
    validateDetail: debounce(function (row) {
      API.offerPurchase.order.validateDetail(this.demandData.id, {
        leadProposalOfferDetailId: row.leadProposalOfferDetailId,
        leadAmountPurchaseOrder: row.leadAmount
      }, {}, {skipLoading: true}).then(data => {
          row.errorAmount = data?.errors?.leadAmountPurchaseOrder.length
      })
    }, 600),

    isValid() {
      let isValid = true
      const auxTableValidated = this.auxTable.map(item => {
        if (this.isEmpty(item.leadAmount)) {
          isValid = false
        }
        return {
          ...item,
          errorLead: this.isEmpty(item.leadAmount) ? 'no-lead' : ''
        }
      })
      this.auxTable = cloneDeep(auxTableValidated)
      return isValid
    },

    serializeLeadProposalOfferDetail() {
      this.data.leadProposalOfferDetail = []
      this.data.leadProposalOfferDetail = this.auxTable.map(item => ({
        id: item.id,
        leadProposalOfferDetailId: item.leadProposalOfferDetailId,
        leadAmount: Number(item.leadAmount)
      }))
    },

    t(key, options = {}) {
      return this.$t(`leadtrade.offerOrder.form.${key}`, options)
    },

    submit() {
      if (!this.isValid()) return
      this.serializeLeadProposalOfferDetail()
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$router.push({name: 'offer-order-index'})
    }
  }
})
