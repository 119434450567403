import CampaignsTable from './_table.js'
import template from './product-index.pug'

export default Vue.extend({
  template: template(),
  components: {
    CampaignsTable
  },
  data() {
    return {
      campaigns: [],
      meta: {},
      ready: false
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.loadProduct()
      }
    }
  },
  methods: {
    loadProduct() {
      this.ready = false
      API.products.show(this.$route.params.id).then(product => {
        this.$setPageTitle(this.$t('campaigns.product.title', {name: product.name}))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.products.index'), route: {name: 'products-index'}},
          {
            label: this.$t('breadcrumbs.products.show', {name: product.name}),
            route: {name: 'products-show', params: {id: product.id}}
          },
          {
            label: this.$t('breadcrumbs.products.campaigns', {name: product.name}),
            route: {}
          }
        ])

        this.ready = true
      })
    },

    loadCampaigns(queryOptions = {}) {
      queryOptions.filter = queryOptions.filter || {}
      queryOptions.filter.productId = [this.$route.params.id]

      API.campaigns.index(queryOptions).then(({data, meta}) => {
        this.campaigns = data
        this.meta = meta
      })
    }
  }
})
