import template from './new.pug'

export default Vue.extend({
  template: template(),
  props: {
    customers: {
      type: Object,
      required: true
    },
    createMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    const facebookCredentials = {
      client_id: VUE_FB_CLIENT_ID,
      client_secret: VUE_FB_CLIENT_SECRET
    }
    return {
      form: {
        customerId: null,
        facebookPage: null,
        facebookForm: null,
        userAccessTokenLong: null
      },
      form_default: {
        customerId: null,
        facebookPage: null,
        facebookForm: null,
        userAccessTokenLong: null
      },
      facebook: {
        login: null,
        pages: null,
        forms: null,
        info: null
      },
      objectEmpty: {
        id: null,
        name: null
      },
      credentials: facebookCredentials,
      errors: {}
    }
  },
  computed: {
    ButtonParams() {
      if (this.facebookIsConnect) {
        return {class: 'active', icon: 'close', text: this.t('disconnect'), type: 'negative'}
      }
      return {class: '', icon: 'facebook', text: this.t('connect'), type: 'primary'}
    },
    facebookIsConnect() {
      return this.facebook.login?.status === 'connected'
    },
    name() {
      return this.facebook.info?.name
    },
    inputError() {
      return {
        page: this.errors?.facebookPage?.name || null,
        forms: this.errors?.facebookForm?.name || null
      }
    },
    pagesOption() {
      if (!this.facebook.pages) return []
      return this.facebook.pages.data.map(item => ({
        value: item.id,
        label: item.name
      }))
    },
    formOption() {
      if (!this.facebook.forms) return []
      return this.facebook.forms.data.map(item => ({
        value: item.id,
        label: item.name
      }))
    },
    pageSelected() {
      return this.facebook.pages?.data?.find(({id}) => id === this.form.facebookPage) ||
      this.objectEmpty
    },
    formSelected() {
      return this.facebook.forms?.data?.find(({id}) => id === this.form.facebookForm) ||
      this.objectEmpty
    }
  },
  mounted() {
    window.fbAsyncInit = () => {
      FB.init({
        appId: this.credentials.client_id,
        xfbml: false,
        version: 'v18.0'
      })
      this.checkLoginStatus()
    };
    // eslint-disable-next-line func-names
    (function (d, s, id) {
      let js = ''
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) { return }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.facebook.${key}`, options)
    },
    savePage() {
      const data = {
        id: null,
        customerId: this.customers.id,
        facebookPage: this.pageSelected,
        facebookForm: this.formSelected,
        pageAccessToken: this.pageSelected?.access_token || null
      }
      if (this.createMode) {
        this.form = {...this.form_default}
        return this.$emit('create-event', data)
      }
      API.facebook.create(data).then(() => {
        FB.api(`/${this.pageSelected.id}/subscribed_apps`,
          'post',
          {access_token: this.pageSelected.access_token, subscribed_fields: ['leadgen']},
          response => {
            console.log('Successfully subscribed page', response)
          })
        this.$notifications.success(this.t('new.succesfullyCreated'))
        this.errors = {}
        this.form = {...this.form_default}
        this.$emit('load-data')
      }, errors => {
        this.errors = errors
      })
    },
    getForms() {
      const {id, access_token} = this.pageSelected
      FB.api(`/${id}/leadgen_forms`, {access_token}, response => {
        this.facebook.forms = null
        if (response && !response.error) {
            this.facebook.forms = response
        }
      })
    },
    getPages() {
      FB.api('/me/accounts', {
        access_token: this.form.userAccessTokenLong
      }, response => {
        this.facebook.pages = null
        if (response && !response.error) {
          this.facebook.pages = response
        }
      })
    },
    getInformation() {
      FB.api('/me', response => {
        this.facebook.info = null
        if (response && !response.error) {
          this.facebook.info = response
        }
      })
    },
    generateAccessTokenLong() {
      const host = 'https://graph.facebook.com/oauth/access_token'
      const params = {
        grant_type: 'fb_exchange_token',
        client_id: this.credentials.client_id,
        client_secret: this.credentials.client_secret,
        fb_exchange_token: this.facebook.login.authResponse.accessToken
      }
      const url = new URL(host)
      url.search = new URLSearchParams(params)
      fetch(url).then(response => response.json()
      .then(({access_token}) => {
        this.form.userAccessTokenLong = access_token
        this.getPages()
      }))
    },
    connectFacebook() {
      FB.login(response => {
        this.facebook.login = null
        if (response.authResponse) {
          this.facebook.login = response
          this.generateAccessTokenLong()
          this.getInformation()
        }
      },
      {
        // eslint-disable-next-line max-len
        scope: 'pages_show_list,pages_manage_metadata,pages_manage_ads,pages_read_engagement,leads_retrieval,business_management'
      })
    },
    disconnectFacebook() {
      this.facebook.login = null
      this.facebook.info = null
      this.facebook.pages = null
      this.facebook.forms = null
      FB.logout()
    },
    checkLoginStatus() {
      FB.getLoginStatus(response => {
        this.facebook.login = null
        if (response && !response.error) {
          if (response.status === 'connected') {
            this.facebook.login = response
            this.generateAccessTokenLong()
            this.getInformation()
          }
        }
      })
    }
  }
})
