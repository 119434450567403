import LeadsAccountsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsAccountsTable
  },
  data() {
    return {
      leads: [],
      customers: [],
      campaigns: [],
      meta: {}
    }
  },
  created() {
    this.loadCustomers()
    this.loadCampaigns()
    this.$setPageTitle('Logs Leads Proveedores')
    this.$setBreadcrumbs([
      {label: 'Leads', route: {}}
    ])
  },
  methods: {
    loadCustomers() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.customers.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.customers = data
        this.customers.unshift(allData)
      })
    },
    loadCampaigns() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000},
        simplequery:'1'
      }
      API.campaigns.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.campaigns = data
        this.campaigns.unshift(allData)
      })
    },
    loadLeadsDetails(queryOptions = {}) {
        API.providerLeads.getLogsError(this.$route.params.id, queryOptions).then(({data, meta}) => {
          this.leads = data.map(item => {
            item.copy = false
            return item
          })
          this.meta = meta
        })
    }
  }
})
