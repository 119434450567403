export default {
  errorsRootKey: 'analytics/reports/cut',
  resource: 'analytics/reports/cut',
  actions: ['index', 'show', 'create', 'update', 'destroy'],

  exportCutReport(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/reports/cut/export',
      {params, ...options}
    ).then(response => response.data)
  }
}
