import {mapGetters} from 'vuex'
import template from './_form.pug'

const Fields = [
  'name',
  'reportId',
  'format',
  'rangeDays',
  'reportSetting',
  'emailSetting',
  'schedulerSetting'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      emails: [],
      emailInput: '',
      msg: '',
      customers: [],
      formatOptions: [
        {value: 'pdf', label: 'PDF'},
        {value: 'excel', label: 'Excel'},
        {value: 'html', label: 'Html'}
      ],
      periodIdOptions: [
        {value: 'day', label: this.t('fields.periodOptions.day')},
        {value: 'week', label: this.t('fields.periodOptions.week')},
        {value: 'month', label: this.t('fields.periodOptions.month')},
        {value: 'year', label: this.t('fields.periodOptions.year')},
        {value: 'range', label: this.t('fields.periodOptions.range')}
      ],
      viewIdOptions: [
        {value: 'monthView', label: this.t('fields.viewOptions.monthView')},
        {value: 'dayView', label: this.t('fields.viewOptions.dayView')},
        {value: 'hourView', label: this.t('fields.viewOptions.hourView')},
        {value: 'sourceView', label: this.t('fields.viewOptions.sourceView')},
        {value: 'companyView', label: this.t('fields.viewOptions.companyView')},
        {value: 'ageView', label: this.t('fields.viewOptions.ageView')},
        {value: 'detailView', label: this.t('fields.viewOptions.detailView')}
      ],
      reportIdOptions: [],
      customerIdOptions: [],
      campaignIdOptions: [],
      providerLeadIdOptions: [],
      typeLeadIdOptions: [],
      frecuencyOptions: [
        {value: 'monthly', label: this.t('fields.frequencyOptions.monthly')},
        {value: 'weekly', label: this.t('fields.frequencyOptions.weekly')},
        {value: 'daily', label: this.t('fields.frequencyOptions.daily')},
        {value: 'hourly', label: this.t('fields.frequencyOptions.hourly')},
        {value: 'onlyOnce', label: this.t('fields.frequencyOptions.onlyOnce')}
      ],
      onlyOnceSetting: new Date(''),
      datetimeObjectConfig: {
        allowInput: true,
        enableTime: true,
        dateFormat: 'Y/m/d H:i',
        time_24hr: true,
        onChange: s => {
          // convert date to string and format date to YYYY-MM-DD HH:mm:
          const date = new Date(s)
          const year = date.getFullYear()
          const month = date.getMonth() + 1 > 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
          const day = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`
          const hour = date.getHours() > 10 ? date.getHours() : `0${date.getHours()}` 
          const min = date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`
          const dateStr = `${year}-${month}-${day} ${hour}:${min}`
          this.data.schedulerSetting.onlyOnceSetting.date = dateStr
        }
      },
      monthlySetting: '',
      weeklySetting: '',
      dailySetting: '',
      hourlySetting: '',
      minutesSetting: '',
      checkCustomerId: true,
      customerId: '',
      periodId: '',
      viewId: '',
      campaignId: [],
      providerLeadId: [],
      typeLeadId: [],
      period: ''
    }
  },
  computed: {
    ...mapGetters(['countries']),

    selectMonthDays() {
      const days = []
      for (let i = 1; i <= 31; i++) {
        days.push({value: `${i}`, label: `${i}`})
      }
      return days
    },

    selecteHours() {
      const hours = []
      // for con las horas pero en los numeros menores a 0 se le agrega un 0 delante
      for (let i = 0; i <= 23; i++) {
        if (i < 10) {
          hours.push({value: `0${ i}`, label: `0${ i}`})
        } else {
          hours.push({value: `${ i}`, label: `${ i}`})
        }
      }
      return hours
    },

    selectMinutes() {
      const minutes = []
      // for con los minutos pero en los numeros menores a 0 se le agrega un 0 delante
      for (let i = 0; i <= 59; i++) {
        if (i < 10) {
          minutes.push({value: `0${ i}`, label: `0${ i}`})
        } else {
          minutes.push({value: `${ i}`, label: `${ i}`})
        }
      }
      return minutes
    },
    selectWeekDays() {
      return [
        {value: 'Monday', label: 'Lunes'},
        {value: 'Tuesday', label: 'Martes'},
        {value: 'Wednesday', label: 'Miércoles'},
        {value: 'Thursday', label: 'Jueves'},
        {value: 'Friday', label: 'Viernes'},
        {value: 'Saturday', label: 'Sábado'},
        {value: 'Sunday', label: 'Domingo'}
      ]
    }

  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
        this.getReportListData()
        this.getProvidersLeads()
        this.getConstants()
        this.getCustomers()
      }
    },

    customerId(value) {
      this.data.reportSetting.filters[0].filterValue[0] = value
      this.getCampaignByCustomer(value)
    },

    campaignId(value) {
      this.data.reportSetting.filters[1].filterValue = value
    },

    providerLeadId(value) {
      this.data.reportSetting.filters[2].filterValue = value
    },

    typeLeadId(value) {
      this.data.reportSetting.filters[3].filterValue = value
    },

    periodId(value) {
      this.data.reportSetting.periodId= value
    },
    viewId(value) {
      this.data.reportSetting.viewId = value
    },

    monthlySetting(value) {
        this.data.schedulerSetting.monthlySetting.day = value
    },
    weeklySetting(value) {
        this.data.schedulerSetting.weeklySetting.day = value
    },
    minutesSetting(value) {
      if (this.data.schedulerSetting.frequency === 'hourly') {
        this.data.schedulerSetting.hourlySetting.mins = value
      }
      if (this.data.schedulerSetting.frequency === 'monthly') {
        this.data.schedulerSetting.monthlySetting.start = `${this.hourlySetting}:${value}`
      }
      if (this.data.schedulerSetting.frequency === 'weekly') {
        this.data.schedulerSetting.weeklySetting.start = `${this.hourlySetting}:${value}`
      }
      if (this.data.schedulerSetting.frequency === 'daily') {
        this.data.schedulerSetting.dailySetting.start = `${this.hourlySetting}:${value}`
      }
    },
    hourlySetting(value) {
      if (this.data.schedulerSetting.frequency === 'monthly') {
        this.data.schedulerSetting.monthlySetting.start = `${value}:${this.minutesSetting}`
      }
      if (this.data.schedulerSetting.frequency === 'weekly') {
        this.data.schedulerSetting.weeklySetting.start = `${value}:${this.minutesSetting}`
      }
      if (this.data.schedulerSetting.frequency === 'daily') {
        this.data.schedulerSetting.dailySetting.start = `${value}:${this.minutesSetting}`
      }
    }
  },
  created() {
  },
  methods: {
    resetFrecuencyValues() {
      this.data.schedulerSetting.monthlySetting = {
        start: '00:00',
        day: ''
      }
      this.data.schedulerSetting.weeklySetting = {
        start: '00:00',
        day: ''
      }
      this.data.schedulerSetting.dailySetting = {
        start: '00:00'
      }
      this.data.schedulerSetting.hourlySetting = {
        mins: '00'
      }
      this.data.schedulerSetting.onlyOnceSetting = {
        date: '1901-01-01 00:00'
      }

      // this.monthlySetting = ''
      // this.weeklySetting = ''
      // this.dailySetting = ''
      // this.hourlySetting = ''
      // this.minutesSetting = ''
      // this.onlyOnceSetting = new Date()
    },

    addEmailInput() {
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.emailInput)) {
        this.data.emailSetting.to.push({email: this.emailInput})
        this.emailInput = ''
        this.msg = ''
      } else {
        this.msg = this.t('emailError')
      }
    },

    removeEmailInput(index) {
      this.data.emailSetting.to.splice(index, 1)
    },

    getReportListData() {
      API.programationReport.getReportList({page: {number: 1, size: 1000}}).then(response => {
        this.reportIdOptions = response.data.map(item => ({value: item.id, label: item.name}))
      })
    },

    getCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(response => {
        this.customerIdOptions = response.data.map(item => ({value: item.id, label: item.name}))
      })
    },

    getProvidersLeads() {
      API.providerLeads.index({page: {number: 1, size: 1000}}).then(response => {
        this.providerLeadIdOptions = response.data.map(item => ({value: item.id, label: item.name}))
      })
    },

    getCampaignByCustomer(id) {
    /*  API.campaigns.index({customerId: id, page: {number: 1, size: 1000}}).then(response => {
        this.campaignIdOptions = response.data.map(item => ({value: item.id, label: item.name}))
      })*/

      let filter = {customerId: [id]}
    
      return API.campaigns.index({
        filter,
        page: {number: 1, size: 1000}
      }).then(response => {
        this.campaignIdOptions = response.data.map(campaign => ({
          label: campaign.name,
          value: campaign.id
        }))
      })

    },

    getConstants() {
      API.constants.index({page: {number: 1, size: 1000}}).then(response => {
        // eslint-disable-next-line max-len
        this.typeLeadIdOptions = response.data.typeLeads.map(item => ({value: item.id, label: item.name}))
      })
    },

    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })
      data.reportSetting = this.initData ? this.initData.reportSetting || {} : {}
      if (!data.reportSetting.filters) {
        data.reportSetting.filters = []
        data.reportSetting.filters.push({
          filterName: 'customerId',
          filterValue: []
        })
        data.reportSetting.filters.push({
          filterName: 'campaignId',
          filterValue: []
        })
        data.reportSetting.filters.push({
          filterName: 'providerLeadId',
          filterValue: []
        })
        data.reportSetting.filters.push({
          filterName: 'typeLeadId',
          filterValue: []
        })
        data.reportSetting.periodId=''
        data.reportSetting.viewId=''
        data.schedulerSetting = {}

        data.schedulerSetting.frequency = ''

        data.schedulerSetting.monthlySetting = {}
        data.schedulerSetting.weeklySetting = {}
        data.schedulerSetting.dailySetting = {}
        data.schedulerSetting.hourlySetting = {}
        data.schedulerSetting.onlyOnceSetting = {}

        data.emailSetting = {}
        data.emailSetting.to = []
        data.emailSetting.subject = ''
        data.emailSetting.message = ''

        this.data = data
        this.resetFrecuencyValues()
      }

      this.data = data
      // eslint-disable-next-line max-len
      this.customerId = this.data.reportSetting ? this.data.reportSetting.filters[0].filterValue[0] : ''
      if (this.$route.name === 'programation-report-edit') {
        this.campaignId = this.data.reportSetting.filters[1].filterValue
        this.providerLeadId = this.data.reportSetting.filters[2].filterValue
        this.typeLeadId = this.data.reportSetting.filters[3].filterValue
        this.periodId = this.data.reportSetting.periodId
        this.viewId = this.data.reportSetting.viewId
        this.checkCustomerId = true
        this.getCampaignByCustomer(this.customerId)

        if (this.data.schedulerSetting.frequency === 'monthly') {
          this.monthlySetting = this.data.schedulerSetting.monthlySetting.day
          this.hourlySetting = this.data.schedulerSetting.monthlySetting.start.split(':')[0]
          this.minutesSetting = this.data.schedulerSetting.monthlySetting.start.split(':')[1]
        }
        if (this.data.schedulerSetting.frequency === 'hourly') {
          this.minutesSetting = this.data.schedulerSetting.hourlySetting.mins
        }

        if (this.data.schedulerSetting.frequency === 'daily') {
          this.hourlySetting = this.data.schedulerSetting.dailySetting.start.split(':')[0]
          this.minutesSetting = this.data.schedulerSetting.dailySetting.start.split(':')[1]
        }

        if (this.data.schedulerSetting.frequency === 'weekly') {
          this.hourlySetting = this.data.schedulerSetting.weeklySetting.start.split(':')[0]
          this.minutesSetting = this.data.schedulerSetting.weeklySetting.start.split(':')[1]
          this.weeklySetting = this.data.schedulerSetting.weeklySetting.day
        }

        if (this.data.schedulerSetting.frequency === 'onlyOnce') {
          this.onlyOnceSetting = new Date(this.data.schedulerSetting.onlyOnceSetting.date)
        }
      }
    },

    t(key, options = {}) {
      return this.$t(`programationReport.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
