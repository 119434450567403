import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import validatePresence from '../_validate'
import template from './_validation-form.pug'

const DefaultData = () => ({
  url: '',
  activeWs: false,
  method: '',
  headers: {},
  queryParams: {},
  body: {},
  mapping: {},
  providers: []
})

// Nodo que permite añadir una petición http
export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    campaignTags: {
      type: Array,
      default: () => ([])
    },
    initData: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      validator: value => ['new', 'edit'].includes(value)
    }
  },
  data() {
    return {
      data: DefaultData(),
      dataHeaders: [],
      dataQuery: [],
      dataBody: [],
      dataMapping: [],
      errorsMapping: [],
      leadProviderOptions: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    title() {
      return this.t(`${this.mode}Title`)
    },

    methodSelectOptions() {
      return HTTP_REQUEST_METHODS.map(method => (
        {value: method, label: this.t(`fields.method.options.${method}`)}
      ))
    },

    valueSelectOptions() {
      const options = {}

      // agrupamos por tipo
      this.campaignTags.forEach(item => {
        if (!options[item.type]) options[item.type] = []

        options[item.type].push(
          {label: item.name || item.key, value: item.key}
        )
      })

      let finalOptions = []

      // cada tipo debe tener su grupo
      Object.keys(options).sort().forEach(type => {
        finalOptions.push({title: this.$t(`campaigns.show.tags.types.${type}`)})
        finalOptions = finalOptions.concat(this.$sortByLocale(options[type], 'label'))
      })

      return finalOptions
    },

    headerErrors() {
      return this.dataHeaders.filter(item => item.errors).length
    },

    queryErrors() {
      return this.dataQuery.filter(item => item.errors).length
    },

    bodyErrors() {
      return this.dataBody.filter(item => item.errors).length
    },

    mappingErrors() {
      return this.dataMapping.filter(item => item.errors).length
    }
  },
  created() {
    this.loadLeadProviders()
    if (this.initData) {
      this.data = cloneDeep({...DefaultData(), ...this.initData})
    } else {
      this.data.method = 'post'
    }
    // Convertimos a un array para poder manejar de forma mas facil en Vue los valores
    // De esta forma es más sencillo añadir o eliminar elementos
    this.transformObjectToArrayFields('Headers', this.data.headers)
    this.transformObjectToArrayFields('Query', this.data.queryParams)
    this.transformObjectToArrayFields('Body', this.data.body)
    this.transformObjectToArrayFields('Mapping', this.data.mapping)
  },
  methods: {
    loadLeadProviders() {
      this.leadProviderOptions = []
      API.providerLeads.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.leadProviderOptions = data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    },
    // Función que transforma el array de data en el objeto de config
    transformArrayFieldsToObject(array) {
      const object = {}

      array.forEach(item => {
        // el campo errors es interno y no se envía al backend
        if (item.name !== 'errors') object[item.name] = item.value
      })

      return object
    },

    // Función que transforma el objeto de config en el array de data
    transformObjectToArrayFields(arrayName, object) {
      Object.keys(object).forEach(key => {
        let manual = true
        if (object[key].match(/\$lead/) && key !== 'body') {
          manual = false
        } else if (object[key].match(/\$lead/) && key === 'body') {
          manual = true
        } else {
          manual = true
        }

        this[`data${arrayName}`].push({
          name: key,
          value: object[key],
          manualUpdate: manual,
          errors: null // guarda los errores si los tiene
        })
      })
    },

    save() {
      // transformamos los datos de los objetos de config
      this.data = {
        ...this.data,
        headers: this.transformArrayFieldsToObject(this.dataHeaders),
        queryParams: this.transformArrayFieldsToObject(this.dataQuery),
        body: this.transformArrayFieldsToObject(this.dataBody),
        mapping: this.transformArrayFieldsToObject(this.dataMapping)
      }

      validatePresence(this.data, [
        'url',
        'method'
      ])

      this.validateSections()

      if (
        !this.data.errors &&
        !this.headerErrors &&
        !this.queryErrors &&
        !this.bodyErrors &&
        !this.mappingErrors
      ) {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    // valida los parámetros de cada sección
    validateSections() {
      [
        this.dataHeaders,
        this.dataQuery,
        this.dataBody,
        this.dataMapping
      ].forEach(sectionFields => {
        sectionFields.forEach(field => {
          field.errors = null
          validatePresence(field, ['name', 'value'])

          // validamos unicidad de las claves
          const sameNameItems = sectionFields.filter(item => item.name === field.name).length
          if (sameNameItems > 1) field.errors = {name: this.$t('errors.unique')}
        })
      })
    },

    addElementTo(arrayName) {
      this[`data${arrayName}`].push({name: '', value: '', manualUpdate: true})
    },

    removeElementTo(index, arrayName) {
      this[`data${arrayName}`].splice(index, 1)
    },

    close() {
      this.$emit('close')
    },

    t(key, options = {}) {
      return this.$t(
        `campaigns.show.validations.tabs.validationComponent.httpRequest.${key}`, options
      )
    }
  }
})
