import RouteParentIndex from 'js/router/route-parent'

import RouteParent from 'js/pages/logs/root.js'

import LogsIndex from 'js/pages/logs/leads/index.js'
import AcceptedPolicy from '../../pages/logs/accepted-policy'
import DeletedLeads from '../../pages/logs/deleted-leads'

export default [
  {
    path: '/logs',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'log-lead',
            component: LogsIndex,
            meta: {
              auth: true,
            }
          },
          {
            path: 'log-deleted',
            name: 'log-deleted',
            component: DeletedLeads,
            meta: {
              auth: true,
            }
          },{
            path: 'log-accepted-policy',
            name: 'log-accepted-policy',
            component: AcceptedPolicy,
            meta: {
              auth: true,
            }
          }
        ]
      }
    ]
  }
]
