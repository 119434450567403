export default {
  errorsRootKey: 'email/linkmobility',
  resource: 'email/linkmobility',
  actions: ['index', 'create', 'show', 'update', 'destroy'],

  configByAccount(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `email/linkmobility/getSettingbyCustomer/${customerId}`,
      {params, ...options}
    ).then(response => response.data)
  },

  testEmailLinkMobility(data = {}, options = {}) {
    return Vue.http.post(
      'email/linkmobility/test',
      data
    ).then(response => response.data)
  }
}
