export default {
  usages(params = {}, options = {}) {
    return Vue.http.get(
      `usages`,
      {params},
      {options}
    ).then(response => response.data)
  },
  export(params = {}, options = {}) {
    return Vue.http.get(
      `usages/export`,
      {params},
      {options}
    ).then(response => response.data)
  },
  exportdetail(params = {}, options = {}) {
    return Vue.http.get(
      `usages/exportdetail`,
      {params},
      {options}
    ).then(response => response.data)
  },
}
