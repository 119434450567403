const localeRoot = 'analytics.charts.timeAtentionLead'

export default {
  options: data => {
    if (!data || data.length === 0) return null

    return {
      grid: {
        left: '-4%',
        right: '0%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: v => v.reduce(
          (acc, item) => `${acc}${item.seriesName}: ${item.value}%<br />`, `${v[0].name}<br />`
        )
      },
      xAxis: [
        {
          type: 'value'
        }
        ],
        yAxis: [
        {
          type: 'category',
          data: data.leads.map(item => item.key),
          axisLabel: {
            rotate: 0,
            margin: 90,
            align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      series: [
        {
          // name: Vue.i18n.t(`${localeRoot}.series.contactedCount`),
          name: Vue.i18n.t(`${localeRoot}.series.leadsCount`),
          type: 'bar',
          barWidth: '40%',
          barGap: 0,
          data: data.leads.map(item => item.valuePercentage)
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.contactability`),
          type: 'bar',
          barWidth: '40%',
          data: data.contactability.map(item => item.valuePercentage)
        }
      ],
      dataZoom: [
      ],
      media: [
        {
          query: {
            maxWidth: 500
          },
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        top: '3%',
        icon: 'circle',
        left: 'middle'
      },
      color: GRAPH_COLORS
    }
  }
}
