import VueResource from 'vue-resource'
import translateErrors from 'js/services/translate-errors'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'

Vue.use(VueResource)

Vue.http.options.root = API_HOST

/*
 * Parseamos los errors para devolver algo más usable
 * Sobreescribe el campo 'errors' de la respuesta y añade el campo 'tokenErrors'
 */
const parseErrors = (response, rootKey) => {
  const {errors, tokenErrors} = translateErrors(response.data.errors, rootKey)

  response.data.errors = errors
  response.data.tokenErrors = tokenErrors
}

// Mockeo de las peticiones para frontend. Solo se usa desde TEST
if (MOCKS) {
  // En vez de isEqual se utilizara esta función, ya que, el isEqual compara que dos objetos
  // sean exactamente iguales, pero el Header es un objeto que tiene una forma especifica y
  // no es exactamente igual a lo que devuleven los mocks.
  // En la función solo se revisa si los elementos que tiene el object son iguales en el header.
  const headerIsEqual = (object, header) => Object.keys(object).map(
    key => object[key] === header.get(key)
  ).every(Boolean)

  // El body puede ser un FormData, en ese caso lo convertimos a objeto normal
  // Esta función solo compara los campos del payload del mock
  const isPayloadEqual = (payload, body) => {
    if (body instanceof FormData) {
      const newBody = {}

      /* eslint-disable */
      for (const [key, value] of body.entries()) {
        newBody[key.replace('[]', '')] = key.includes('[]') ? body.getAll(key) : value
      }
      /* eslint-enable */

      body = newBody
    }

    return Object.keys(payload).map(key => isEqual(payload[key], body[key])).every(Boolean)
  }

  const isParamsEqual = (mockParams, requestParams) => (
    Object.keys(mockParams).map(key => (
      isEqual(mockParams[key], requestParams[key])
    )).every(Boolean)
  )

  const files = require.context('../../mocks', true, /mock\.js$/)

  window.mockRequests = []

  files.keys().forEach(key => {
    window.mockRequests = window.mockRequests.concat(files(key).default)
  })

  /*
   * Función que mockea las peticiones definidas en la variable 'window.mockRequests'
   * Estas peticiones tienen la estructura: { method: a, url: b, body: c, status: d}
   */
  const testMock = (request, next) => {
    let abort = false

    // Cuando el enlace es de authentication/me se le añade el token
    // para que se elija el mock del user adecuado
    if (request.url === 'authentication/me') {
      request.headers.set('authorization', localStorage.getItem('_rails-vue-session-token'))
    }
    if (window.mockRequests) {
      window.mockRequests.forEach(mockRequest => {
        if (
          !abort &&
          mockRequest.url.replace(/^\//, '') === request.url.replace(/^\//, '') &&
          mockRequest.method === request.method &&
          (!mockRequest.params || isParamsEqual(mockRequest.params, request.params)) &&
          (!mockRequest.payload || isPayloadEqual(mockRequest.payload, request.body)) &&
          (!mockRequest.headers || headerIsEqual(mockRequest.headers, request.headers))
        ) {
          abort = true

          let body = cloneDeep(mockRequest.body || {})
          let status = mockRequest.status

          if (mockRequest.validate) {
            const result = mockRequest.validate(request)
            body = Object.assign(result.body)
            if (mockRequest.method === 'POST' && !body.id) body.id = 97 // id de ejemplo
            status = result.status
          } else if (typeof mockRequest.body === 'function') {
            body = mockRequest.body(request)
          }

          console.info(request, body, status) // eslint-disable-line

          next(request.respondWith(body, {status}))
        }
      })
    }

    return abort
  }

  Vue.http.interceptors.push((request, next) => {
    testMock(request, next)
  })
}

Vue.http.interceptors.push((request, next) => {
  if (!request.skipLoading) Vue.store.commit('incrementLoading')

  // Añadimos el token a todas las peticiones que hagamos con vue-resource
  if (Vue.auth && Vue.auth.token() && !request.headers.get('Authorization')) {
    request.headers.set('Authorization', `Bearer ${Vue.auth.token()}`)
  }

  next(response => {
    // eslint-disable-next-line no-alert
    if (MOCKS && response.status === 0) alert(`Mock no implementado: ${response.url}`)

    if (!request.skipLoading) Vue.store.commit('decreaseLoading')

    if (response.status === 422 && response.data.errors && !request.skipParseErrors) {
      parseErrors(response, request.errorsRootKey)
    }

    if (request.skipErrors) return

    if (response.status === 403) {
      Vue.router.push({name: 'error-403'})
    } else if (response.status === 404) {
      Vue.router.push({name: 'error-404'})
    } else if (response.status === 500) {
      Vue.router.push({name: 'error-500'})
    } else if (response.status === 401) {
      Vue.auth.setToken('')
      Vue.router.push({name: 'login'})
    }
  })
})
