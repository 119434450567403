import cloneDeep from 'lodash/cloneDeep'

import template from './_cpl-providers-form.pug'

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      validator: value => ['new', 'edit'].includes(value)
    }
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {

    currencySelectOptions() {
      return [
        {
          label: 'euro',
          value: 'euro'
        },
        {
          label: 'dolar',
          value: 'dolar'
        }
      ]
    },

    title() {
      return this.t(`${this.mode}Title`)
    },

    saveButtonText() {
      return this.t(`${this.mode}Button`)
    },

    basicDateConfig() {
      return {
        dateFormat: 'd/m/Y',
        monthSelectorType: 'static',
        minDate: this.startDate,
        enableTime: false,
        initParseDate(date) {
          return date ? moment(date, FORMAT_DATE).toDate() : null
        },
        reverseParseDate(date) {
          return moment(date, FORMAT_DATE_DATEPICKER).format(FORMAT_DATE)
        }
      }
    },

    startDateConfig() {
      return {
        ...this.basicDateConfig,
        maxDate: this.data.endAt
      }
    },

    endDateConfig() {
      return {
        ...this.basicDateConfig,
        minDate: this.data.startAt
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.data = cloneDeep(this.initData)
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.$emit('submit', this.data)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.cpl.${key}`, options)
    }
  }
})
