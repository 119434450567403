import template from './date-range.pug'

const today = moment().toDate()
const yesterday = moment().subtract(1, 'days').toDate()
const weekFirstDay = moment().startOf('week').toDate()
// const monthFirstDay = moment().startOf('month').toDate()
const yearFirstDay = moment().startOf('year').toDate()
const lastSevenDay = moment().subtract(7, 'days').toDate()
const lastThirtyDay = moment().subtract(30, 'days').toDate()
const lastNinetyDay = moment().subtract(90, 'days').toDate()
const lastTwelveMonth = moment().subtract(12, 'months').toDate()

export default Vue.extend({
  template: template(),
  props: {
    from: {
      type: String
    },
    to: {
      type: String
    },
    periodDefault: {
      type: String,
      default: 'month'
    }
  },
  data() {
    return {
      dateRange: {
        startDate: lastSevenDay,
        endDate: today
      },
      period: 'month'
    }
  },
  computed: {
    localeData() {
      return {
        daysOfWeek: [
          this.$t('day.sunday').slice(0, 3),
          this.$t('day.monday').slice(0, 3),
          this.$t('day.tuesday').slice(0, 3),
          this.$t('day.wednesday').slice(0, 3),
          this.$t('day.thursday').slice(0, 3),
          this.$t('day.friday').slice(0, 3),
          this.$t('day.saturday').slice(0, 3)
        ],
        monthNames: [
          this.$t('month.january').slice(0, 3),
          this.$t('month.february').slice(0, 3),
          this.$t('month.march').slice(0, 3),
          this.$t('month.april').slice(0, 3),
          this.$t('month.may').slice(0, 3),
          this.$t('month.june').slice(0, 3),
          this.$t('month.july').slice(0, 3),
          this.$t('month.august').slice(0, 3),
          this.$t('month.september').slice(0, 3),
          this.$t('month.octuber').slice(0, 3),
          this.$t('month.november').slice(0, 3),
          this.$t('month.december').slice(0, 3)
        ]
      }
    },
    ranges() {
      return {
        [this.t('options.today')]: [today, today],
        /* [this.t('options.week')]: [weekFirstDay, today],
        [this.t('options.month')]: [monthFirstDay, today],
        [this.t('options.year')]: [yearFirstDay, today] */
        [this.t('options.yesterday')]: [yesterday, yesterday],
        [this.t('options.thisWeek')]: [weekFirstDay, today],
        [this.t('options.last7Days')]: [lastSevenDay, today],
        [this.t('options.last30Days')]: [lastThirtyDay, today],
        [this.t('options.last90Days')]: [lastNinetyDay, today],
        [this.t('options.last12Months')]: [lastTwelveMonth, today],
        [this.t('options.thisYear')]: [yearFirstDay, today]
      }
    },
    periodOptions() {
      return [
        {
          label: this.t('period.day'),
          value: 'day'
        },
        {
          label: this.t('period.week'),
          value: 'week'
        },
        {
          label: this.t('period.month'),
          value: 'month'
        },
        {
          label: this.t('period.year'),
          value: 'year'
        }
      ]
    }
  },
  mounted() {
    if (this.from && this.to) {
      this.dateRange = {
        startDate: moment(this.from, FORMAT_DATE).format(),
        endDate: moment(this.to, FORMAT_DATE).format()
      }
      this.period = this.periodDefault
    }
  },
  methods: {
    dateFormated({startDate, endDate}) {
      // eslint-disable-next-line max-len
      return `${startDate ? moment(startDate).format(FORMAT_DATE) : ''} - ${endDate ? moment(endDate).format(FORMAT_DATE) : ''}`
    },
    t(key, options = {}) {
      return this.$t(`date-range-picker.${key}`, options)
    },
    applyDate() {
      this.$emit('from', moment(this.dateRange.startDate).format(FORMAT_DATE))
      this.$emit('to', moment(this.dateRange.endDate).format(FORMAT_DATE))
      this.$emit('period', this.period)
      this.$emit('apply')
    },
    selectDate() {
      this.$nextTick(() => {
        console.log(this.$refs.dateRangePicker.$children[1].selectedRange)
      })
    }
  }
})
