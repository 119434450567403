
import pick from 'lodash/pick'

import AnalyticsChart from 'js/components/analytics/chart'
import statusCountFunnel from 'js/components/analytics/leads-report-funnel'
import template from './item-report-chart.pug'

export default Vue.extend({
    template: template(),
    components: {
        AnalyticsChart
    },
    props: {
        value: Boolean,
        data: Object
    },
    data() {
        return {
            campaigns: []
        }
    },
    computed: {
        funnelChartOptions() {
            if (!this.data) return null

            return statusCountFunnel.options(pick(this.data, [
              'visitsCount',
              'totalCount',
              'leadsCount',
              'contactedCount',
              'convertedCount',
              'registratedCount'
            ]), 'analytics.charts.generationFunnel')
          }
    },
    methods: {
        t(key, options = {}) {
            return this.$t(`analytics.charts.generationFunnel.${key}`, options)
        },
        cancel() {
            this.$emit('input', false)
        }
    }
})
