import notice from 'js/components/notice'

import template from './_email.pug'

export default Vue.extend({
  template: template(),
  components: {
    notice
  },
  data() {
    return {
      data: {
        email: '',
        password: ''
      },
      errors: {}
    }
  },
  methods: {
    updateEmail() {
      this.errors = {}

      if (this.$user.email === this.data.email) {
        this.errors = {email: this.$t('profile.form.fields.email.errors.unchanged')}
      } else {
        API.profile.updateEmail(this.data).then(() => {
          const oldEmail = this.$user.email
          Vue.store.commit('setCurrentUser', {...this.$user, unconfirmedEmail: this.data.email})
          this.data.email = ''
          this.data.password = ''

          this.$notifications.warning(
            this.t('succesfullyUpdated', {
              unconfirmedEmail: this.$user.unconfirmedEmail,
              email: oldEmail
            })
          )
        }, errors => {
          this.errors = errors
        })
      }
    },

    t(key, params) {
      return this.$t(`profile.emailSettings.${key}`, params)
    }
  }
})
