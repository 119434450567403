import {EmailEditor} from 'vue-email-editor'
import template from './_form.pug'

const Fields = [
  'name', 'description', 'subject', 'template', 'design'
]

export default Vue.extend({
  template: template(),
  components: {
    EmailEditor
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      emailEditorReady: false
    }
  },
  computed: {

  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data
    },

    showEditor() {
      this.emailEditorReady = true
    },
    closeEditor() {
      this.emailEditorReady = false
    },
    editorLoaded() {
      // Pass your template JSON here
      // eslint-disable-next-line eqeqeq
      if (this.data.design != 'without') {
        this.$refs.emailEditor.editor.loadDesign(this.data.design)
      }
    },
    editorReady() {
      // console.log('editorReady')
    },
    saveDesign() {
      // this.$refs.emailEditor.editor.saveDesign(
      //   design => {
      //     // console.log('saveDesign', design)
      //   }
      // )
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml(
        // eslint-disable-next-line no-shadow
        data => {
          this.data.design = data.design
          this.data.template = data.html
          this.emailEditorReady = false
        }
      )
    },

    t(key, options = {}) {
      return this.$t(`emailMarketing.formEdit.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
