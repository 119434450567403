import FullTable from 'js/components/full-table/index.js'
import providerIndicatorBars from 'js/components/analytics/provider-indicator-bars'
import AnalyticsChart from 'js/components/analytics/chart'
import template from './provider-table.pug'

export default Vue.extend({
  name: 'item-report-table',
  template: template(),
  components: {
    FullTable,
    AnalyticsChart
  },
  props: {
    items: {
        type: Array,
        required: true
    },
    showSubColumn: Boolean
  },
  data() {
    return {
      chart: {
        dialog: false,
        data: null
      },
      order: {
        orderBy: null,
        orderDir: null
      },
      data: [],
      header: {},
      dataFormated: [],
      pagesIds: [],
      currentPage: 1,
      showGraphics: false
    }
  },
  watch: {
    items() {
      this.setData()
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    activeGraphics() {
      if (this.showGraphics) {
        this.showGraphics = false
      } else {
        this.showGraphics = true
      }
    },
    nextPage() {
      this.currentPage += 1
      if (this.currentPage > this.pagesIds.length) this.currentPage = 1
    },
    providerIndicatorChartOptions(item) {
      const data = this.graphicsData.find(el => el.variable === item.variable)
      return providerIndicatorBars.options(data)
    },
    setData() {
      this.data = this.items

      const dataFinalModified = []

      this.graphicsData = [...this.data]

      this.data.forEach(el => {
        // eslint-disable-next-line no-shadow
        const formatDate = el.data.map(el => el.date)
        const arrayUnique = Array.from(new Set(formatDate))
        const key = el.variable
        this.header[key] = arrayUnique

        // eslint-disable-next-line no-shadow
        const formatValues = Array.from(new Set(el.data.map(el => el.value)))
        const data = el.data
        const arrayValues = []
        formatValues.forEach(elem => {
          // eslint-disable-next-line no-shadow
          arrayValues.push(data.filter(el => el.value === elem))
        })

        const arrayDataJoin = []

        // eslint-disable-next-line no-shadow
        arrayValues.forEach((el, i) => {
          const dateValues = {}
          dateValues.value = formatValues[i]
          el.forEach(element => {
            // eslint-disable-next-line no-unused-expressions
            dateValues[element.date] = {
              leadsWeightPercentage: element.leadsWeightPercentage,
              effectivenessPercentage: element.effectivenessPercentage
            }
          })
          arrayDataJoin.push(dateValues)
        })

        dataFinalModified.push(arrayDataJoin)
      })

      // eslint-disable-next-line array-callback-return
      this.data = this.data.map((el, i) => ({
        variable: el.variable,
        data: dataFinalModified[i],
        total: el.total,
        pageId: i + 1
      }))

      this.pagesIds = Array.from({length: this.data.length}, (_, i) => i + 1)
    },

    changeOrder(params) {
      const filter = Object.keys(params.order)[0]
      this.order.orderBy = filter
      this.order.orderDir = params.order[filter]
      this.applyOrder()
    },
    applyOrder() {
      const dir = this.order.orderDir === 'asc' ? 1 : -1
      if (this.order.orderBy && this.order.orderDir) {
        this.data = this.data.sort((a, b) => {
          const x = a[this.order.orderBy]
          const y = b[this.order.orderBy]
          if (x < y) return dir * -1
          if (x > y) return dir
          return 0
        })
      }
    },
    t(key, params = {}) {
      return this.$i18n.t(`analytics.charts.${key}`, params)
    }
  }
})
