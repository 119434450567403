const typeNotification = () => ([
  {
    label: Vue.i18n?.t('notifications.type.warning'),
    value: 'warning',
    color: '',
    icon: 'exclamation-triangle'
  },
  {
    label: Vue.i18n?.t('notifications.type.info'),
    value: 'info',
    color: 'icon--warning',
    icon: 'info'
  },
  {
    label: Vue.i18n?.t('notifications.type.up'),
    value: 'up',
    color: 'icon--green',
    icon: 'arrow-up'
  },
  {
    label: Vue.i18n?.t('notifications.type.down'),
    value: 'down',
    color: 'icon--red',
    icon: 'arrow-down'
  }
])
export default typeNotification
