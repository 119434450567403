import pick from 'lodash/pick'
import FullTable from 'js/components/full-table/index.js'
import AnalyticsUtils from 'js/services/analytics-utils'
import {mapGetters} from 'vuex'

import template from './_table.pug'
import { cloneDeep } from 'lodash'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    consumptions: {
      type: Array,
      required: true
    },
    customers: {
      type: Array,
      required: true
    },
    transactionTypes: {
      type: Array,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      filters: {
        period: '',
        accountId: '',
        customerId: '',
        transactionType: '',
      },
      error: {
        period: '',
      },
      firstLoad: true,
      consumptionsData: [],
      lastQueryOptions: null, // guarda los últimos filtros aplicados
    }
  },
  watch: {
    consumptions() {
      this.consumptionsData = cloneDeep(this.consumptions.map(consumption => ({
        ...consumption,
        expanded: false
      })))
    }
  },
  computed: {
    ...mapGetters(['periods']),

    totalConsumptions() {
      return this.consumptionsData.reduce((acc, consumption) => {
        const totalProduct = consumption.products.reduce((acc1, product) => (acc1 = acc1 + product.cost), 0)
        return acc = acc + totalProduct
      }, 0)
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },
    periodOptions() {
      return this.periods.map(period => ({
        label: period.name,
        value: period.id
      }))
    },
    customerOptions() {
      return this.customers
    },
    accountOptions() {
      return this.accounts
    },
    transactionTypeOptions() {
      return this.transactionTypes
    },
  },
  methods: {
    loadCustomers(event) {
      this.$emit('load-customer', event)
      this.filters.customerId = null
    },
    totalCostProduct(products) {
      return products.reduce((acc, product) => (acc = acc + product.cost), 0)
    },
    totalQuantityProduct(products) {
      return products.reduce((acc, product) => (acc = acc + product.quantity), 0)
    },

    getData(queryOptions) {
      this.error = {}
      if(this.firstLoad) return this.firstLoad = false
      if(!queryOptions?.filter?.period) {
        this.error.period = this.$t('errors.blank')
        return
      }
      this.$emit('load-data', queryOptions || this.lastQueryOptions)
      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    t(key, options = {}) {
      return this.$t(`consumption.${key}`, options)
    },

    exportCSV() {
      // Este solo se ve afectado por estos filtros
      const filters = {}
      filters.filter = pick(this.$refs.filterTable.filterData, [
        'period',
        'accountId',
        'customerId',
        'transactionType',
      ])

      API.consumption
        .export(filters)
        .then(({data}) => {
          AnalyticsUtils.downloadCsv({
            title: this.t('title'),
            url: data.file
          })
        })
    },
    exportDetailCSV() {
      // Este solo se ve afectado por estos filtros
      const filters = {}
      filters.filter = pick(this.$refs.filterTable.filterData, [
        'period',
        'accountId',
        'customerId',
        'transactionType',
      ])

      API.consumption
        .exportdetail(filters)
        .then(({data}) => {
          AnalyticsUtils.downloadCsv({
            title: this.t('title'),
            url: data.file
          })
        })
    }
  }
})
