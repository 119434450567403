import Home from 'js/pages/home/index.js'

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      auth: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]
