import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './duplicity-config.pug'
import BasicInfoForm from './duplicate-form.js'

export default Vue.extend({
  template: template(),
  components: {
    BasicInfoForm
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: {},
      keysSelected: {
        leadLandingPageKey: ['phone'],
        leadFacebookFormKey: ['phone'],
        leadproviderKey: ['phone'],
        leadBaseColdKey: ['phone'],
        leadInboundKey: ['phone']
      },
      errors: {}
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.duplicated.${key}`, options)
    },

    confirmSave() {
      openModalConfirmRemove(
        this.t('saveConfirmationModal.title'),
        '',
        this.t('saveConfirmationModal.okButton')
      ).then(() => {
        this.save()
      })
    },

    loadData() {
      API.campaigns.duplicity.index(this.campaignId).then(({data}) => {
        this.data = data
      })
    },
    
    save() {
      for (let j = 0; j < this.data[0].keys.length; j++) {
        if (!this.keysSelected.leadBaseColdKey.length) {
          this.data[0].keys[j].selected = false
        } else {
          for (let i = 0; i < this.keysSelected.leadBaseColdKey.length; i++) {
            const self = this
            if (self.data[0].keys[j].key === self.keysSelected.leadBaseColdKey[i]) {
              self.data[0].keys[j].selected = true
              break
            } else {
              self.data[0].keys[j].selected = false
            }
          }
        }
      }
     
      for (let j = 0; j < this.data[1].keys.length; j++) {
        if (!this.keysSelected.leadInboundKey.length) {
          this.data[1].keys[j].selected = false
        } else {
          for (let i = 0; i < this.keysSelected.leadInboundKey.length; i++) {
            const self = this
            if (self.data[1].keys[j].key === self.keysSelected.leadInboundKey[i]) {
              self.data[1].keys[j].selected = true
              break
            } else {
              self.data[1].keys[j].selected = false
            }
          }
        }
      }
      
      for (let j = 0; j < this.data[2].keys.length; j++) {
        if (!this.keysSelected.leadLandingPageKey.length) {
          this.data[2].keys[j].selected = false
        } else {
          for (let i = 0; i < this.keysSelected.leadLandingPageKey.length; i++) {
            const self = this
            if (self.data[2].keys[j].key === self.keysSelected.leadLandingPageKey[i]) {
              self.data[2].keys[j].selected = true
              break
            } else {
              self.data[2].keys[j].selected = false
            }
          }
        }
      }

      for (let j = 0; j < this.data[3].keys.length; j++) {
        if (!this.keysSelected.leadFacebookFormKey.length) {
          this.data[3].keys[j].selected = false
        } else {
          for (let i = 0; i < this.keysSelected.leadFacebookFormKey.length; i++) {
            const self = this
            if (self.data[3].keys[j].key === self.keysSelected.leadFacebookFormKey[i]) {
              self.data[3].keys[j].selected = true
              break
            } else {
              self.data[3].keys[j].selected = false
            }
          }
        }
      }

      for (let j = 0; j < this.data[4].keys.length; j++) {
        if (!this.keysSelected.leadproviderKey.length) {
          this.data[4].keys[j].selected = false
        } else {
          for (let i = 0; i < this.keysSelected.leadproviderKey.length; i++) {
            const self = this
            if (self.data[4].keys[j].key === self.keysSelected.leadproviderKey[i]) {
              self.data[4].keys[j].selected = true
              break
            } else {
              self.data[4].keys[j].selected = false
            }
          }
        }
      }

      const sendData = {
        types: Object.values(this.data)
      }

      API.campaigns.duplicity.create(this.$route.params.id, sendData).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.errors = {}
      }, errors => {
        this.errors = errors
      })
    }
  }
})
