import AudiosMenuVoiceForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    AudiosMenuVoiceForm
  },
  data() {
    return {
      lastPath: null,
      lastPathParams: {},
      errors: {},
      isEditMode: false // Indica que estamos en modo de creación
    }
  },
  beforeRouteEnter(_to, from, next) {
    next(vm => {
      if (['audios-menu-voice-index', 'audios-menu-voice-new', 'audios-menu-voice-edit'].includes(from?.name)) {
        vm.lastPathParams = from.params
        vm.lastPath = from.name
      }
    })
  },
  created() {
    this.$setPageTitle(this.$t('audiosMenuVoice.new.title'))
    this.$setBreadcrumbs([
      { label: this.$t('breadcrumbs.audiosMenuVoice.index'), route: { name: 'audios-menu-voice-index' } },
      { label: this.$t('breadcrumbs.audiosMenuVoice.new'), route: {} }
    ])
  },
  methods: {
    createAudiosMenuVoice(formData) {
      API.audiosMenuVoice.create(formData).then(() => {
        this.$notifications.success(this.$t('audiosMenuVoice.new.succesfullyCreated'))

        this.$router.push({
          name: this.lastPath || 'audios-menu-voice-index',
          params: this.lastPathParams
        })
      }).catch(errors => {
        this.errors = errors
      })
    }
  }
})
