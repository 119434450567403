import copyTextFromElementById from 'js/services/copy-text-from-html.js'
import template from './_modal-pixel-control.pug'

export default Vue.extend({
  template: template(),
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      idCopied: null,
      currentTab: 'pixel-control'
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.modalPixelControl.${key}`, options)
    },

    copyPixelControl(domId) {
      this.idCopied = domId
      if (copyTextFromElementById(domId)) {
        this.$notifications.success(this.t('succesfullyCopied'))
      } else {
        this.$notifications.error(this.t('errorOnCopy'))
      }
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
