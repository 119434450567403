import cloneDeep from 'lodash/cloneDeep'

import template from './_provider-form.pug'

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    sitesSelectOptions: {
      type: Array,
      default: () => ([])
    },
    providerLeadsOptions: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      validator: value => ['new', 'edit'].includes(value)
    }
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    title() {
      return this.t(`${this.mode}Title`)
    },

    saveButtonText() {
      return this.t(`${this.mode}Button`)
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.data = cloneDeep(this.initData)
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.$emit('submit', this.data)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.provider.${key}`, options)
    }
  }
})
