import RouteParent from 'js/router/route-parent'

import CampaignsIndex from 'js/pages/campaigns/index.js'
import CampaignsWizard from 'js/pages/campaigns/wizard.js'
import showCampaigns from 'js/router/modules/campaigns/show.js'

export default [
  {
    path: '/campaigns',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'campaigns-index',
        component: CampaignsIndex,
        meta: {
          auth: true,
          permit: ['LIST_CAMPAIGNS']
        }
      },
      {
        path: ':id/wizard',
        name: 'campaigns-wizard-continue',
        component: CampaignsWizard,
        meta: {
          auth: true,
          permit: ['CREATE_CAMPAIGNS']
        }
      },
      {
        path: 'wizard',
        name: 'campaigns-wizard',
        component: CampaignsWizard,
        meta: {
          auth: true,
          permit: ['CREATE_CAMPAIGNS']
        }
      },
      showCampaigns
    ]
  }
]
