const typeNotificationValues = () => ([
  {
    label: Vue.i18n?.t('notifications.type.all'),
    value: '',
    color: '',
    icon: 'exclamation-triangle'
  },
  {
    label: Vue.i18n?.t('notifications.type.warning'),
    value: '7EF62D5C-BECC-422F-8771-0C99F916B494',
    color: '',
    icon: 'exclamation-triangle'
  },
  {
    label: Vue.i18n?.t('notifications.type.info'),
    value: 'BF8BFCD8-B165-4001-9EF2-2A4192316D9D',
    color: 'icon--warning',
    icon: 'info'
  },
  {
    label: Vue.i18n?.t('notifications.type.up'),
    value: '6C23C19E-720C-4CF2-81F3-34E47197E852',
    color: 'icon--green',
    icon: 'arrow-up'
  },
  {
    label: Vue.i18n?.t('notifications.type.down'),
    value: '097E8FF9-ABC4-44F1-B242-39B5DE3C22CC',
    color: 'icon--red',
    icon: 'arrow-down'
  }
])
export default typeNotificationValues
