import RouteParent from 'js/router/route-parent'

import targetNumbersIndex from 'js/pages/target-numbers/index.js'
import targetNumbersNew from 'js/pages/target-numbers/new.js'
import targetNumbersEdit from 'js/pages/target-numbers/edit.js'

export default [
  {
    path: '/target-numbers',
    component: RouteParent,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'target-numbers-index',
        component: targetNumbersIndex,
        meta: {
          auth: true,

        }
      },
      {
        path: 'new',
        name: 'target-numbers-new',
        component: targetNumbersNew,
        meta: {
          auth: true,
        }
      },
      {
        path: ':id/edit',
        name: 'target-numbers-edit',
        component: targetNumbersEdit,
        meta: {
          auth: true,
        }
      },
    ]
  }
]
