const localeRoot = 'analytics.charts.providerIndicator'

export default {
  options: data => {
    if (!data || data.length === 0) return null

    return {
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: v => v.reduce(
          (acc, item) => {
            if (item.seriesName === 'Proveedor') {
              return `${acc}${item.seriesName}: ${item.value}<br />`
            }
            // eslint-disable-next-line max-len
            return `${acc}${item.seriesName}: ${item.value}%<br />`
          }, `${v[0].name}<br />`
          )
      },
      xAxis: [
        {
          type: 'category',
          data: data.data.map(item => item.date),
          axisLabel: {
            rotate: 30,
            margin: 28.5,
            align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
        ],
        yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          // name: Vue.i18n.t(`${localeRoot}.series.contactedCount`),
          name: Vue.i18n.t(`${localeRoot}.series.effectivenessPercentage`),
          type: 'bar',
          barWidth: '40%',
          barGap: 0,
          data: data.data.map(item => item.effectivenessPercentage)
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.weigthPercentage`),
          type: 'bar',
          barWidth: '40%',
          data: data.data.map(item => item.leadsWeightPercentage)
        },
        {
          name: 'Proveedor',
          type: 'bar',
          barWidth: '40%',
          data: data.data.map(item => item.value)
        }
      ],
      dataZoom: [
      ],
      media: [
        {
          query: {
            maxWidth: 500
          },
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        top: '3%',
        icon: 'circle',
        left: 'middle'
      },
      color: GRAPH_COLORS
    }
  }
}
