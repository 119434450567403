import template from './_charts-builder.pug'
import FilterWrapper from '../../components/charts-builder/filter-wrapper.js'
import SortToggler from '../../components/charts-builder/sort-toggler.js'

export default Vue.extend({
  template: template(),
  components: {
    FilterWrapper,
    SortToggler
  }
})
