import notice from 'js/components/notice'
import FullTable from 'js/components/full-table/index.js'

import template from './_notification.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    notice
  },
  data() {
    return {
      notificationTypes: [],
      meta: {
        currentPage: 1,
        pageSize: 1000,
        totalElements: 1000,
        totalPages: 1
      },
      errors: {}
    }
  },
  created() {
    this.getNotificationTypes()
  },
  methods: {
    getNotificationTypes() {
      this.notificationTypes = []
      API.notifications.types({page: {number: 1, size: 1000}}).then(({data}) => {
        this.notificationTypes = data
      })
    },
    change(notification) {
      const {active, sendEmail, id} = notification
      API.notifications.configuration(id, {active, sendEmail}).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
      }).catch(() => this.getNotificationTypes())
    },

    t(key, params) {
      return this.$t(`profile.notificationSettings.${key}`, params)
    }
  }
})
