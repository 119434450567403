import {serialize} from 'object-to-formdata'
import CustomerForm from './_form'
import template from './edit.pug'

export default Vue.extend({
  template: template(),
  components: {
    CustomerForm
  },
  data() {
    return {
      ready: false,
      data: {},
      errors: {}
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('customers-admin.edit.title'))
        this.loadData()
      }
    }
  },

  methods: {
    loadData() {
      this.ready = false

      API.customersAdmin.show(this.$route.params.id).then(response => {
        this.data = response
        this.ready = true

        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
          {label: this.$t('breadcrumbs.customers.edit', {name: this.data.name}), route: {}}
        ])
      })
    },

    updateCustomer(data) {
      const method = API.customersAdmin.update

      // TODO: mejorar el validador de backend para soportar FormData complejos
      if (!MOCKS) data = serialize(data, {indices: true})

      method(this.$route.params.id, data).then(() => {
        this.$notifications.success(this.$t('customers.edit.succesfullyUpdated'))
        this.$router.push({name: 'customers-admin-index'})
      }, errors => {
        this.errors = errors
      })
    },

    isLogoRemoved(data) {
      return this.data.logoUrl && data.logo === null
    }
  }
})
