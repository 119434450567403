export default {
  state: {
    roles: [],
    countries: [],
    customerActivities: [],
    triggerTypes: [],
    errorTypes: [],
    typeLeads: [],
    policyTypes: [],
    statusLeads: [],
    periods: [],
    allNodes: [],
    conversionEvents: []
  },
  getters: {
    roles: state => state.roles,
    countries: state => state.countries,
    customerActivities: state => state.customerActivities,
    triggerTypes: state => state.triggerTypes,
    errorTypes: state => state.errorTypes,
    typeLeads: state => state.typeLeads,
    policyTypes: state => state.policyTypes,
    statusLeads: state => state.statusLeads,
    periods: state => state.periods,
    conversionEvents: state => state.conversionEvents,
    findRoleById(_state, getters) {
      return id => getters.roles.find(role => role.id === id)
    },

    findRoleByKey(_state, getters) {
      return key => getters.roles.find(role => role.key === key)
    },
    allNodes: state => state.allNodes
  },
  mutations: {
    setRoles(state, roles) {
      state.roles = roles
    },

    setCountries(state, countries) {
      state.countries = countries
    },

    setCustomerActivities(state, customerActivities) {
      state.customerActivities = customerActivities
    },

    setTriggerTypes(state, triggerTypes) {
      state.triggerTypes = triggerTypes
    },
    setErrorTypes(state, errorTypes) {
      state.errorTypes = errorTypes
    },

    setStatusLeads(state, statusLeads) {
      state.statusLeads = statusLeads
    },

    setTypeLeads(state, typeLeads) {
      state.typeLeads = typeLeads
    },

    setPolicyTypes(state, policyTypes) {
      state.policyTypes = policyTypes
    },

    setPeriods(state, periods) {
      state.periods = periods
    },

    setAllNodes(state, allNodes) {
      state.allNodes = allNodes
    },

    setConversionEvents(state, conversionEvents) {
      state.conversionEvents = conversionEvents
    }
  },
  actions: {
    loadConstants({commit}) {
      return API.constants.index().then(data => {
        commit('setRoles', data.data.roles)
        commit('setCountries', data.data.countries)
        commit('setCustomerActivities', data.data.customerActivities)
        commit('setTriggerTypes', data.data.triggerTypes)
        commit('setErrorTypes', data.data.errorTypes)
        commit('setTypeLeads', data.data.typeLeads)
        commit('setPolicyTypes', data.data.policyTypes)
        commit('setPeriods', data.data.periods)
        commit('setStatusLeads', data.data.statusLeads)
        commit('setConversionEvents', data.data.conversionEvents)
        return data
      })
    }
  }
}
