import {HubConnectionBuilder} from '@microsoft/signalr'

const loadNotifications = () => {
  API.notifications.getNotificications({page: {number: 1, size: 1000}}).then(({data}) => {
    Vue.store.commit('setBackendNotification', data)
  })
}

const loadNotificationsIndividual = id => {
  API.notifications.getNotificationTypeId(id).then(({data}) => {
    Vue.store.commit('setBackendNotificationIndividual', data)
  })
}

const signalr = {
  start(token) {
    if (MOCKS) {
      setTimeout(() => {
        API.notifications.getNotificications({page: {number: 1, size: 1000}}).then(({data}) => {
          Vue.store.commit('setBackendNotification', data)
        })
        API.notifications.getConfigNotificicationUser().then(({data}) => {
          globalThis.notificationsTypes = data
        })
      }, 10000)
      return

    } else {

      API.notifications.getNotificications({page: {number: 1, size: 1000}}).then(({data}) => {
        Vue.store.commit('setBackendNotification', data)
      })

    }
    const connection = new HubConnectionBuilder()
      .withUrl(API_NOTIFICATION_HOST, {
        accessTokenFactory: () => token
      })
      .withAutomaticReconnect()
      .build()

    async function start() {
      try {

        await connection.start()
        API.notifications.getConfigNotificicationUser().then(({data}) => {
          let notificationsTypes = data
          notificationsTypes.forEach(async el => {
            await connection.invoke('JoinGroup', el.id)
          })
        })

        loadNotifications()
        console.info('ConnectionId updated')
      } catch (err) {
        console.error(err)
        setTimeout(start, 5000)
      }
    }

    connection.onclose(async () => {
      await start()
    })

    connection.on('newNotification', () => {
      loadNotifications()
    })

    connection.on('newNotificationIndividual', id => {
      loadNotificationsIndividual(id)
    })

    start()
  }
}

// eslint-disable-next-line import/prefer-default-export
export {signalr}
