import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './general-data.pug'
import BasicInfoForm from '../../wizard/basic-info.js'

export default Vue.extend({
  template: template(),
  components: {
    BasicInfoForm
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: {},
      startDate: this.campaign.startAt,
      errors: {}
    }
  },
  watch: {
    campaign: {
      handler: function handler(newValue) {
        this.data = newValue
      },
      immediate: true
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.generalData.${key}`, options)
    },

    confirmSave() {
      openModalConfirmRemove(
        this.t('saveConfirmationModal.title'),
        '',
        this.t('saveConfirmationModal.okButton')
      ).then(() => {
        this.save()
      })
    },

    save() {
      API.campaigns.update(this.$route.params.id, this.data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.errors = {}
      }, errors => {
        this.errors = errors
      })
    }
  }
})
