import DemandTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    DemandTable
  },
  data() {
    return {
      demands: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('offerAndDemand.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.offer-and-demand.index'), route: {}}
    ])
  },
  methods: {
    loadProducts(queryOptions = {}) {
      API.leadTrade.index(queryOptions).then(({data, meta}) => {
        this.demands = data
        this.meta = meta
      })
    }
  }
})
