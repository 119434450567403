import {openModalConfirmRemove} from 'js/services/modals.js'
import template from './_field-form.pug'

const Fields = [
  'name',
  'label', 'fieldType', 'fieldValidation', 'leadField', 'required', 'applyWebService', 'applyForm','requiredType']

const DefaultValues = () => Object.freeze({
  name: '',
  label: '',
  fieldType: '',
  fieldValidation: FIELD_VALIDATIONS.TEXT,
  leadField: null,
  required: false,
  applyWebService: false,
  applyForm: false,
  requiredType:''
})

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  template: template(),
  components: {
    openModalConfirmRemove
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {
      }
    }
  },
  computed: {
    fieldTypesSelectOptions() {
      return Object.keys(FIELD_TYPES).map(key => ({
        label: this.$t(`fieldTypes.${key}`),
        value: FIELD_TYPES[key]
      }))
    },

    fieldValidationSelectOptions() {
      const options = Object.keys(FIELD_VALIDATIONS).map(key => ({
        label: this.$t(`fieldValidations.${key}`),
        value: FIELD_VALIDATIONS[key]
      }))

      options.unshift({
        label: this.$t('fieldValidations.without'),
        value: ''
      })

      return options
    },

    leadFieldSelectOptions() {
      const options = Object.keys(LEAD_FIELDS).map(key => ({
        label: this.$t(`leadFields.${key}`),
        value: LEAD_FIELDS[key]
      }))

      return options
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (JSON.stringify(newValue) === JSON.stringify(this.data)) return

        const data = {}
        Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
        this.data = data
      }
    },

    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`forms.formField.${key}`, options)
    },

    confirmDelete() {
      openModalConfirmRemove(
        this.t('deleteConfirmationModal.title'),
        this.t('deleteConfirmationModal.body'),
        this.t('deleteConfirmationModal.okButton')
      ).then(() => {
        this.$emit('remove')
      })
    }
  }
})
