import template from './_provider_form_modal.pug'

const Fields = ['id', 'name', 'description']
export default Vue.extend({
  template: template(),
  components: {
  },
  data() {
    return {
      errors: {},
      data: {}
    }
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] : ''
      })

      this.data = data
    },

    t(key, options = {}) {
      return this.$t(`providerLeads.form.${key}`, options)
    },

    createProvider() {
      const data = this.data
      API.providerLeads.create(data).then(() => {
        this.$notifications.success(this.$t('providerLeads.new.succesfullyCreated'))
        this.$emit('load')
        this.cancel()
      }, errors => {
        this.errors = errors
      })
    }
  }
})
