
import template from './modal-copy.pug'

export default Vue.extend({
  template: template(),
  props: {
    monthOptions: {
      type: Array,
      default: () => ([])
    },
    detail: {
      type: Object,
      default: () => ({})
    },
    availableYearOffer: {
      type: [String, Number]
    }
  },
  data() {
    return {
      data: []
    }
  },
  created() {
    let monthInit = 0
    if (new Date().getFullYear() === Number(this.availableYearOffer)) {
      monthInit = new Date().getMonth()
    }
    const monthOptions = this.monthOptions.slice(monthInit)
    monthOptions.forEach(month => {
      this.data.push({
        ...this.detail,
        label: month.label,
        availableMonthOffer: month.value,
        active: true,
        availableYearOffer: this.availableYearOffer
      })
    })
  },
  methods: {
    t(key, options = {}) {
        return this.$t(`offerAndDemand.copy.${key}`, options)
    },
    cancel() {
        this.$emit('input', false)
    },
    submit() {
      const data = this.data
      .filter(detail => !!detail.active)
      .map(detail => ({
        leadAmount: detail.leadAmount,
        cpl: detail.cpl,
        availableMonthOffer: detail.availableMonthOffer,
        availableYearOffer: detail.availableYearOffer,
        days: detail.days
      }))
      this.$emit('add-details', data)
      this.cancel()
    }
  }
})
