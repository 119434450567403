import CustomersTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    CustomersTable
  },
  data() {
    return {
      customers: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('customers.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.customers.index'), route: {}}
    ])
  },
  methods: {
    loadCustomers(queryOptions = {}) {
      API.customersAdmin.index(queryOptions).then(({data, meta}) => {
        this.customers = data
        this.meta = meta
      })
    }
  }
})
