/* eslint-disable max-len */
import {mapGetters} from 'vuex'
import DateRange from 'js/components/analytics/date-range'
import AnalyticsChart from 'js/components/analytics/chart'
import dynamicChartPie from 'js/components/analytics/dynamic-chart-pie'
import dynamicChartBar from 'js/components/analytics/dynamic-chart-bar'
import effectivenessContactabilityBar from 'js/components/analytics/effectiveness-contactability-bar'
import categorizationByMonth from 'js/components/analytics/model-categories/categorization-by-month'
import attemptsByCategoryLine from 'js/components/analytics/model-categories/attempts-by-category-line'
import effectivenessByCategoryBar from 'js/components/analytics/model-categories/effectiveness-by-category-bar'
import providerTable from 'js/components/analytics/provider-table.js'
import timeAtentionLeadChart from 'js/components/analytics/lead-atention.js'
import tableProviderComparative from 'js/components/analytics/table-provider-comparative'
import tableModel from 'js/components/analytics/table-model'
import ModalAddBoard from './modal-add-board'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    tableProviderComparative,
    DateRange,
    AnalyticsChart,
    providerTable,
    timeAtentionLeadChart,
    tableModel,
    ModalAddBoard
  },
  data() {
    return {
      modalAddBoard: false,
      filter: {
        from: '',
        to: '',
        customerId: '',
        campaignId: [],
        providerLeadId: [],
        typeLead: [],
        period: 'day'
      },
      itemsProvidersIndicator: [],
      timeAtentionLeadData: [],
      providerLeadOptions: [],
      providercomparativeOptions: [],
      customerOptions: [],
      campaignOptions: [],
      modelCategories: [],
      chartOptions: [
        {
          title: this.t('effectivenessContactability.title'),
          selected: false,
          id: 0,
          chart: 0
        },
        {
          title: this.t('modelCategories.categorizationByMonth.title'),
          selected: false,
          id: 1,
          chart: 1
        },
        {
          title: this.t('modelCategories.attemptsByCategoryLine.title'),
          selected: false,
          id: 2,
          chart: 2
        },
        {
          title: this.t('modelCategories.effectivenessByCategoryBar.title'),
          selected: false,
          id: 3,
          chart: 3
        },
        {
          title: this.t('providerIndicator.title'),
          selected: false,
          id: 4,
          chart: 4
        },
        {
          title: this.t('timeAtentionLead.title'),
          selected: false,
          id: 5,
          chart: 5
        },
        {
          title: this.t('board.title'),
          selected: false,
          id: 6,
          chart: 6
        },
        {
          title: this.t('providercomparative.title'),
          selected: false,
          id: 7,
          chart: 7
        },
        {
          title: this.t('modelCategories.title'),
          selected: false,
          id: 8,
          chart: 8
        }
      ],
      boardData: {},
      chartLoaded: [],
      chartSelected: [],
      chartListOptions: []
    }
  },
  computed: {
    ...mapGetters(['typeLeads']),
    typeLeadsOptions: vm => vm.typeLeads.map(({id, name}) => ({value: id, label: name})),
    hasSelectedProvider() {
      return this.chartSelected.find(({title}) => title === this.t('providerIndicator.title'))?.selected === true
    },
    hasSelectedAtention() {
      return this.chartSelected.find(({title}) => title === this.t('timeAtentionLead.title'))?.selected === true
    },
    hasModelCategorySelected() {
      return this.chartSelected.find(({title}) => title === this.t('modelCategories.title'))?.selected === true
    },
    hasBoardSelected() {
      return this.chartSelected.find(({title}) => title === this.t('board.title'))?.selected === true
    },
    hasProviderComparativeSelected() {
      return this.chartSelected.find(({title}) => title === this.t('providercomparative.title'))?.selected === true
    }
  },
  created() {
    this.$setPageTitle(this.$t('analytics.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.analytics.index'), route: {}}
    ])
  },
  mounted() {
    this.loadFilterSelect()
    this.loadData()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`analytics.index.${key}`, options)
    },
    loadFilterSelect() {
      this.loadProviderLeads()
      this.loadCustomers()
      this.loadChartOptions()
      if (this.filter.campaignId.length && !this.campaignOptions.length) {
        this.loadCampaignsData()
      }
    },
    onEnd() {
      const auxList = []
      this.chartListOptions.forEach((item, index) => {
        auxList.push({
          id: index,
          title: item.title,
          selected: item.selected,
          chart: item.chart
        })
        // this.selectedChart(auxList)
      })
      this.chartOptions.forEach(item => {
        const chartFind = auxList.find(itemSelected => itemSelected.title === item.title)
        if (chartFind) {
          item.id = chartFind.id
        }
      })
      this.selectedChart(this.chartOptions)
    },
    loadChartListOptions() {
      const options = []
      this.chartLoaded.forEach(item => {
        const chartFind = this.chartSelected.find(itemSelected => itemSelected.title === item.title)
        if (chartFind) {
          options.push({...item, id: chartFind.id, chart: chartFind.chart, selected: chartFind.selected})
        }
      })
      this.chartListOptions = []
      this.chartListOptions = options.sort((a, b) => a.id - b.id)
    },
    loadData() {
      localStorage.setItem('filterAnalytics', JSON.stringify(this.filter))
      this.chartLoaded = []
      this.loadEffectivenessContactability()
      this.loadModelCategories()
      this.loadBoardData()
      this.loadTimeAtentionLead()
      this.loadProviderComparative()
      this.loadProviderIndicatorData()
    },
    loadChartOptions() {
      const localChartOptions = JSON.parse(localStorage.getItem('selectedCharts')) || []
      const chartOptionsTemp = JSON.parse(JSON.stringify(this.chartOptions))
      this.chartSelected = JSON.parse(JSON.stringify(localChartOptions))
      chartOptionsTemp.forEach(item => {
        const chartFind = this.chartSelected.find(itemSelected => itemSelected.title === item.title)
        if (chartFind) {
          item.selected = chartFind.selected
        }
      })
      this.chartOptions = JSON.parse(JSON.stringify(chartOptionsTemp))
      this.filter = JSON.parse(localStorage.getItem('filterAnalytics')) || this.filter
    },
    async selectedChart(selected) {
      if (!selected) return
      const chartSelected = selected.filter(item => item.selected === true)
      await localStorage.setItem('selectedCharts', JSON.stringify(chartSelected))
      this.loadChartOptions()
      this.modalAddBoard = false
      this.loadChartListOptions()
    },
    dynamicChartOptions({type, options}) {
      if (!options) return null
      if (type === 'pie') return dynamicChartPie.options(options)
      return dynamicChartBar.options(options)
    },
    loadProviderLeads() {
      this.providerLeadOptions = []
      API.providerLeads.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.providerLeadOptions = data.map(item => ({
          value: item.id,
          label: item.name
        }))
      })
    },
    loadCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(response => {
        this.customerOptions = response.data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
        this.customerOptions.unshift({
          label: this.t('targetAllOption'),
          value: 'selected-all-values-options'
        })
      })
    },
    loadCampaignsData() {
      let filter = {customerId: [this.filter.customerId]}
      if (this.filter.customerId === 'selected-all-values-options') {
        filter = {}
      }
      return API.campaigns.index({
        filter,
        page: {number: 1, size: 1000}
      }).then(response => {
        this.campaignOptions = response.data.map(campaign => ({
          label: campaign.name,
          value: campaign.id
        }))
      })
    },
    loadBoardData() {
      const params = {filter: {...this.filter}}
      this.boardData = {}
      API.analytics.boardData(params).then(({data}) => {
        this.boardData = data
      })
    },
    loadEffectivenessContactability() {
      const params = {filter: {...this.filter}}
      API.analytics.effectivenessContactability(params).then(({data}) => {
        this.chartLoaded.push({
          title: this.t('effectivenessContactability.title'),
          option: effectivenessContactabilityBar.options(data)
        })
        this.loadChartListOptions()
      })
    },
    loadModelCategories() {
      const params = {filter: {...this.filter}}
      API.analytics.modelCategories(params).then(({data}) => {
        this.chartLoaded.push({
          title: this.t('modelCategories.categorizationByMonth.title'),
          option: categorizationByMonth.options(data)
        })
        this.chartLoaded.push({
          title: this.t('modelCategories.attemptsByCategoryLine.title'),
          option: attemptsByCategoryLine.options(data)
        })
        this.chartLoaded.push({
          title: this.t('modelCategories.effectivenessByCategoryBar.title'),
          option: effectivenessByCategoryBar.options(data)
        })
        this.modelCategories = []
        this.modelCategories = data
        this.loadChartListOptions()
      })
    },
    loadProviderComparative() {
      const params = {filter: {...this.filter}}
      API.analytics.providercomparative(params).then(({data}) => {
        this.providercomparativeOptions = JSON.parse(JSON.stringify(data))
      })
    },
    loadProviderIndicatorData() {
      const params = {filter: {...this.filter}}
      API.analytics.providerIndicator(params).then(({data}) => {
        this.itemsProvidersIndicator = JSON.parse(JSON.stringify(data))
      })
    },
    loadTimeAtentionLead() {
      const params = {filter: {...this.filter}}
      API.analytics.timeAtentionLead(params).then(({data}) => {
        data.leads = data.leads.reverse()
        data.contactability = data.contactability.reverse()
        this.timeAtentionLeadData = JSON.parse(JSON.stringify(data))
      })
    }
  }
})
