import RouteParent from 'js/router/route-parent'

import LaiaAnalyticsIndex from 'js/pages/laia-analytics/index.js'

export default [
  {
    path: '/laia-analytics',
    component: RouteParent,
    meta: {
      auth: false
    },
    children: [
      {
        path: '',
        name: 'laia-analytics',
        component: LaiaAnalyticsIndex,
        meta: {
        // NOTA comentado para maquetación
        //   auth: true,
        //   permit: ['LAIA_ANALYTICS']
        }
      }
    ]
  }
]
