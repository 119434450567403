import {openModalConfirmRemove} from 'js/services/modals.js'
import orderBy from 'lodash/orderBy'
import TreatmentForm from './_treatment-form.js'
import leadOptions from './_treatment-helper'
import template from './treatment-index.pug'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')

export default Vue.extend({
  template: template(),
  components: {
    TreatmentForm
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      treatmentList: [],
      treatmentFormData: {},
      treatmentFormErrors: {},
      leadProviderOptions: [],
      showForm: false
    }
  },
  computed: {
    campaignId() {
      return this.campaign.id
    },

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    formMode() {
      return this.treatmentFormData.id ? 'edit' : 'new'
    }
  },
  created() {
    this.loadTreatment()
    this.loadLeadProviders()
    this.ready = true
  },
  methods: {
    getLeadOption(value) {
      return leadOptions.find(item => item.value === value)?.label
    },
    loadLeadProviders() {
      this.leadProviderOptions = []
      API.providerLeads.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.leadProviderOptions = data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    },
    getClassByStatus(status) {
      switch (status) {
        case true:
          return 'table-tag--success'
        case false:
          return 'table-tag--gray'
        default:
          return ''
      }
    },
    getIconByStatus(status) {
      switch (status) {
        case true:
          return 'check'
        case false:
          return 'close'
        default:
          return ''
      }
    },
    async loadTreatment() {
      this.treatmentList = []
      API.campaigns.treatment.index(
        this.campaignId,
        {order: {name: 'asc'}, page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.treatmentList = data
      })
    },

    changeOrder(field, dir) {
      this.treatmentList = orderBy(
        this.treatmentList,
        item => item[field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        dir
      )
    },

    openNewForm() {
      this.treatmentFormData = {
        campaignId: this.campaignId,
        leadForDaysActive: false,
        leadDeleteExpirateActive: false,
        coldLeadDays: '',
        hotLeadDays: '',
        providerLeadId: ''
      }
      this.showForm = true
    },

    openConfirmModal(data) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(data.id))
    },

    destroy(id) {
      API.campaigns.treatment.destroy(this.campaign.id, id).then(() => {
        this.loadTreatment()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    openEditForm(item) {
      this.treatmentFormData = {
        id: item.id,
        leadForDaysActive: item.leadForDaysActive,
        leadDeleteExpirateActive: item.leadDeleteExpirateActive,
        coldLeadDays: item.coldLeadDays,
        hotLeadDays: item.hotLeadDays,
        providerLeadId: item.providerLeadId,
        campaignId: item.campaignId
      }

      this.showForm = true
    },

    closeModal() {
      this.treatmentFormData = {}
      this.treatmentFormErrors = {}
      this.showForm = false
    },

    saveTreatment(data) {
      this.treatmentFormErrors = {}

      if (this.formMode === 'edit') {
        this.updateTreatment(data)
      } else {
        this.createTreatment(data)
      }
    },

    updateTreatment(data) {
      API.campaigns.treatment.update(data.campaignId, data.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.loadTreatment()
        this.closeModal()
      }, errors => {
        this.treatmentFormErrors = errors
      })
    },

    createTreatment(data) {
      API.campaigns.treatment.create(data.campaignId, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.loadTreatment()
        this.closeModal()
      }, errors => {
        this.treatmentFormErrors = errors
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.treatment.${key}`, options)
    }
  }
})
