import MatrixModelTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    MatrixModelTable
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      MatrixModel: [],
      meta: {}
    }
  },
  created() {
  },
  methods: {
    loadDdis(queryOptions = {}) {
      API.campaigns.matrixModule.index(this.campaign.id, queryOptions).then(({data, meta}) => {
        this.MatrixModel = data
        this.meta = meta
      })
    }
  }
})
