import CampaignsIndex from '../campaigns/index.js'

import template from './index.pug'

export default Vue.extend({
  name: 'campaigns-home',
  template: template(),
  components: {
    CampaignsIndex
  }
})
