import {pick} from 'lodash'
import ProductForm from './_form'
import template from './edit.pug'

export default Vue.extend({
  template: template(),
  components: {
    ProductForm
  },
  data() {
    return {
      ready: false,
      data: {},
      errors: {}
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('products.edit.title'))
        this.loadData()
      }
    }
  },

  methods: {
    loadData() {
      this.ready = false

      API.products.show(this.$route.params.id).then(response => {
        this.data = pick(
          response, ['id', 'name', 'customerId', 'description', 'campaignsCount']
        )

        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.products.index'), route: {name: 'products-index'}},
          {label: this.$t('breadcrumbs.products.edit', {name: this.data.name}), route: {}}
        ])

        this.ready = true
      })
    },

    updateProduct(data) {
      API.products.update(this.$route.params.id, data).then(() => {
        this.$notifications.success(this.$t('products.edit.succesfullyUpdated'))
        this.$router.push({name: 'products-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
