/* eslint-disable no-use-before-define */

import {VueTreeList, Tree} from 'vue-tree-list'
import {
  generatePermissionTree
} from '../../../../mocks/laia-accounts/factory-permissions'
import template from './permission.pug'

export default Vue.extend({
  template: template(),
  components: {
    VueTreeList
  },
  data() {
    return {
      ready: false,
      data: {},
      model: null,
      errors: {},
      optionNumbers: []
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.$setPageTitle(this.$t('laiaAccounts.permission.title'))
      this.loadData()
    }
  },

  methods: {
    t(key, options = {}) {
      return this.$t(`laiaAccounts.permission.${key}`, options)
    },
    permissionText(v) {
      return this.t(`list.${v}`)
    },

    submit() {
      const data = this.formatModel(this.model)
      API.laiaAccounts.setPermission(this.$route.params.id, data)
        .then(() => {
          this.$notifications.success(this.$t('laiaAccounts.permission.succesfullyUpdated'))
          this.$router.push({
            name: this.lastPath || 'accounts-laia-index',
            params: this.lastPathParams
          })
        },
        errors => {
          console.error(errors)
          this.$notifications.error(this.$t('laiaAccounts.permission.errorOnUpdated'))
        })
    },
    loadData() {
      this.model = null
      this.ready = false
      API.laiaAccounts.getPermission(this.$route.params.id).then(response => {
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.model = new Tree(this.formatTreeView(generatePermissionTree(response.data)))
        } else {
          this.model = new Tree(this.formatTreeView(generatePermissionTree()))
        }
        this.ready = true

        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.laiaAccounts.index'), route: {name: 'accounts-laia-index'}},
          {label: this.$t('breadcrumbs.laiaAccounts.permission', {name: this.data.name}), route: {}}
        ])
      })
    },

    checkChildrens({children, active}) {
      const mapChildrens = arr => (arr.map(fixChildrens))
      const fixChildrens = object => {
        object.active = active
        if (object.children && object.children.length > 0) {
          return {
            ...object,
            children: this.checkChildrens({...object, active})
          }
        }
        return object
      }
      if (children && children.length > 0) mapChildrens(children)
    },

    formatModel({children}) {
      const permissionActived = []
      const mapModel = arr => (arr ? arr.map(fixModel) : null)
      const fixModel = object => {
        if (object.active) {
          permissionActived.push(object.permission)
        }
        if (object.children && object.children.length > 0) {
          mapModel(object.children)
        }
      }

      mapModel(children)
      return permissionActived
    },

    formatTreeView(data) {
      const mapModel = arr => (arr ? arr.map(fixModel) : null)
      const fixModel = object => {
        object.addTreeNodeDisabled = true
        object.addLeafNodeDisabled = true
        object.editNodeDisabled = true
        object.delNodeDisabled = true
        object.dragDisabled = true
        object.id = Math.random()
        if (object.children && object.children.length > 0) {
          return {
            ...object,
            children: mapModel(object.children)
          }
        }
        delete object.children
        return object
      }
      return mapModel(data)
    }

  }
})
