export default {
  errorsRootKey: 'invoice/invoice',
  resource: 'invoice/invoice',
  actions: ['index', 'create', 'show', 'update', 'destroy'],
  generatePdf(id, params = {}, options = {}) {
    return Vue.http.get(
      `invoice/generate-invoice/${id}`,
      {params, ...options}
    ).then(response => response.data)
  },
  getEmails(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `invoice/${customerId}/emails`,
      {params, ...options}
    ).then(response => response.data)
  },
  sendInvoice(customerId, id, data) {
    return Vue.http.post(
      `invoice/${customerId}/send-invoice-email/${id}`,
      data
    ).then(response => response.data)
  },
  getCustomers(params = {}, options = {}) {
    return Vue.http.get(
      'invoice/customers',
      {params, ...options}
    ).then(response => response.data)
  },
  getCustomersByInvoiceConfiguration(params = {}, options = {}) {
    return Vue.http.get(
      'invoice/customersByInvoiceConfiguration',
      {params, ...options}
    ).then(response => response.data)
  },
  getFamily(params = {}, options = {}) {
    return Vue.http.get(
      'invoice/family',
      {params, ...options}
    ).then(response => response.data)
  },
  getWayToPay(params = {}, options = {}) {
    return Vue.http.get(
      'invoice/way-to-pay',
      {params, ...options}
    ).then(response => response.data)
  },
  getPaymentVia(params = {}, options = {}) {
    return Vue.http.get(
      'invoice/payment-via',
      {params, ...options}
    ).then(response => response.data)
  },
  getLeadsOnCall(customerId, data) {
    return Vue.http.post(
      `invoice/${customerId}/leads-on-call`, data
    ).then(response => response.data)
  },
  getLimitDebit(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `invoice/${customerId}/limit-debts`,
      {params, ...options}
    ).then(response => response.data)
  },
  generateInvoiceReport(data) {
    return Vue.http.post(
      'invoice/generateInvoiceReport', data
    ).then(
      response => response.data,
      response => { throw response.data.errors }
    )
  },
  payment(id, data) {
    return Vue.http.put(
      `invoice/${id}/payment`, data
    ).then(response => response.data)
  }
}
