import {mapGetters} from 'vuex'
import {isEmpty} from 'lodash'
import template from './_form.pug'
import phoneNumberProviders from './form/phoneNumberProviders'
import calltrackingProviders from './form/calltrackingProviders'

const Fields = {
  name: '',
  legalName: '',
  address: '',
  postalCode: '',
  country: '',
  city: '',
  province: '',
  phone: '',
  email: '',
  logo: '',
  phoneNumberProviders: [],
  calltrackingProviders: [],
  biInsightUrl: ''
}

export default Vue.extend({
  template: template(),
  components: {
    phoneNumberProviders,
    calltrackingProviders
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      removeAvatar: false,
      whitelist: ['jpg', 'png', 'jpeg'],
      calltrackingProvidersErrors: [],
      phoneNumberProvidersErrors: []
    }
  },
  computed: {
    ...mapGetters(['countries']),

    countryOptions() {
      return this.countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    },

    showInitials() {
      return (!this.initData?.logoUrl && !this.data.logo) || this.removeAvatar
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
  },
  methods: {
    validateProviders() {
      this.calltrackingProvidersErrors = []
      this.phoneNumberProvidersErrors = []
      this.data.phoneNumberProviders.forEach(element => {
        const errors = {}
        if (!element.providerAccountId && element.active) {
          errors.providerAccountId = this.t('fields.phoneNumberProviders.errors.blank')
        }
        this.phoneNumberProvidersErrors.push(errors)
      })
      this.data.calltrackingProviders.forEach(element => {
        const errors = {}
        if (!element.providerAccountId && element.active) {
          errors.providerAccountId = this.t('fields.calltrackingProviders.errors.blank')
        }
        this.calltrackingProvidersErrors.push(errors)
      })
      return !this.phoneNumberProvidersErrors.find(errors => (!isEmpty(errors))) &&
        !this.calltrackingProvidersErrors.find(errors => (!isEmpty(errors)))
    },
    initializeData() {
      const data = JSON.parse(JSON.stringify(this.initData || Fields))
      this.data = data
    },
    t(key, options = {}) {
      return this.$t(`laiaAccounts.form.${key}`, options)
    },

    submit() {
      const model = {
        ...this.data,
        phoneNumberProviders: this.data.phoneNumberProviders
          .filter(({active}) => active),
        calltrackingProviders: this.data.calltrackingProviders
          .filter(({active}) => active)
      }
      if (this.validateProviders(model)) {
      const data = new FormData()
      Object.keys(model).forEach(key => {
        if (Array.isArray(this.data[key])) {
          model[key].forEach(element => {
            Object.entries(element).forEach(([sk, sv]) => {
              data.append(`${key}[${sk}]`, sv)
            })
          })
        } else {
          data.append(key, model[key])
        }
      })
      this.$emit('submit', data)
     }
    },

    cancel() {
      this.$emit('cancel')
    },

    logoChanged(file) {
      this.removeAvatar = !file
    },

    errorUpload(errors = []) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`fields.logo.errors.${error}`)
        )
      }
    },

    openLogoInput() {
      this.$refs.logoInput.$el.children[0].click()
    }
  }
})
