export default {
  errorsRootKey: 'schedulereports',
  resource: 'schedulereports',
  actions: ['index', 'show', 'create', 'update', 'destroy'],

  getReportList(params = {}, options = {}) {
    return Vue.http.get(
      'schedulereports/reportlist',
      {params, ...options}
    ).then(response => response.data)
  },

  disable(schedulerId, params = {}, options = {}) {
    return Vue.http.post(
      `schedulereports/${schedulerId}/disable`,
      {params, ...options}
    ).then(response => response.data)
  },

  enable(schedulerId, params = {}, options = {}) {
    return Vue.http.post(
      `schedulereports/${schedulerId}/enable`,
      {params, ...options}
    ).then(response => response.data)
  }
}
