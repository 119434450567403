import router from 'js/router/index.js'

function notRequireLogin(to) {
  return to.meta.auth === false && Vue.auth.token()
}

function requireLogin(to) {
  return to.meta.auth === true && !Vue.auth.token()
}

function isAdminLaia(to) {
  return (to.name === 'analytics-bi-index' && Vue.store.getters.currentUser?.role === 'admin-laia')
}

function hasCurrentUser() {
  return Vue.store.getters.hasCurrentUser
}

function needLoadCurrentUser() {
  return Vue.auth.token() && !hasCurrentUser()
}

async function loadCurrentUser() {
  if (needLoadCurrentUser()) {
    await Vue.store.dispatch('loadCurrentUser')
    await Vue.store.dispatch('loadConstants')
    await Vue.store.dispatch('loadCurrentAccount', Vue.store.getters.currentUser.accountId)
    await Vue.store.dispatch('rechargeRolesCurrentUser')
  }
}

// Comprobación de permisos de cada ruta
// Añadir a una ruta dentro del meta un key 'permit' con el array de los permisos
const checkPermission = route => {
  if (!route.meta || !route.meta.permit) return true

  return route.meta.permit.every(
    element => Vue.store.getters.currentUser.permissions.includes(element)
  )
}

async function checkAuthBeforeEnter(to, from, next) {
  if (notRequireLogin(to)) {
    next('home')
  } else if (requireLogin(to)) {
    next('login')
  } else {
    try {
      await loadCurrentUser()
    } catch (e) {
      console.error(e) // eslint-disable-line
      Vue.auth.logout()
      return
    }
    if (isAdminLaia(to)) {
      router.push({name: 'accounts-laia-index'})
    } else {
      next(checkPermission(to) ? true : {name: 'error-403'})
    }
  }
}

export default checkAuthBeforeEnter
