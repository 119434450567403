import template from './_modal-duplicate.pug'


export default Vue.extend({
  template: template(),
  props: {
    automationId: {
      type: String,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
    }
  },
  data() {
    return {
      data: {
        name: '',
        startAt: '',
        endAt: '',
      },
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      errors: {}
    }
  },
  computed: {
    fromDateConfig() {
      return {
        minDate: new Date(),
        maxDate: this.data.endAt ? this.data.endAt : null,
        ...this.dateConfig
      }
    },
    toDateConfig() {
      return {
        minDate: this.data.startAt ? this.data.startAt : null,
        ...this.dateConfig
      }
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    isValid() {
      this.errors = {}
      if(!this.data.name) {
        this.errors.name = this.$t('errors.blank')
      }
      if(!this.data.startAt) {
        this.errors.startAt = this.$t('errors.blank')
      }
      if(!this.data.endAt) {
        this.errors.endAt = this.$t('errors.blank')
      }
      if(Object.keys(this.errors).length) {
        return false
      }
      return true
    },
    duplicate() {
      if(!this.isValid()) return
      const payload = {
        name: this.data.name,
        startAt: moment(this.data.startAt).format(FORMAT_DATE),
        endAt: moment(this.data.endAt).format(FORMAT_DATE),
      }
      API.automations.duplicate(this.campaignId, this.automationId, payload).then((response) => {
        this.$emit('load')
        this.close()
      })
    },
    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.duplicate.${key}`, options)
    },
  }
})
