import {VueTreeList, Tree} from 'vue-tree-list'
import template from './summary.pug'

export default Vue.extend({
  template: template(),
  components: {
    VueTreeList
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      summary: null
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.summary.${key}`, options)
    },
    loadData() {
      const options = {
        addTreeNodeDisabled: true,
        addLeafNodeDisabled: true,
        editNodeDisabled: true,
        delNodeDisabled: true,
        dragDisabled: true
      }
      API.campaigns.validation.summary(this.campaignId).then(({data}) => {
        const formatChild = item => ({
          ...options,
          name: item.title,
          active: item.active,
          id: Math.random(),
          children: []
        })
        const summary = data.map(item => ({
          ...options,
          name: item.group,
          active: null,
          title: true,
          id: Math.random(),
          children: item.childrens.map(formatChild)
        }))
        this.summary = new Tree(summary)
      })
    }
  }
})
