import cloneDeep from 'lodash/cloneDeep'
import template from './_providers.pug'
import ProviderForm from './_providers-form.js'

// Formulario que permite añadir un listado de Call Centers cada uno de ellos con su CTI asociado.
export default Vue.extend({
  template: template(),
  components: {
    ProviderForm
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    customerId: {
      type: String,
      default: null
    },
    sitesSelectOptions: {
      type: Array,
      default: () => []
    },
    initErrors: {
      type: [Array, String],
      default: () => []
    }
  },
  data() {
    return {
      data: [],
      errors: [],
      // Flag para mostrar si es necesario mostrar un error indicando que es obligatorio añadir
      // al menos un call center con CTI asociado
      requiredError: false,
      providerLeadsOptions: []
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (JSON.stringify(newValue) === JSON.stringify(this.data)) return

        this.data = cloneDeep(newValue)
      }
    },

    initErrors: {
      deep: true,
      immediate: true,
      handler() {
        // gestionamos el error: "callCenters: required"
        if (this.initErrors instanceof Array) {
          this.errors = cloneDeep(this.initErrors)
          this.requiredError = false
        } else {
          this.errors = []
          this.requiredError = true
        }
      }
    },

    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler() {
        this.$emit('input', [...this.data])
      }
    }
  },
  created() {
    this.loadProviderLeads()
  },
  methods: {
    loadProviderLeads() {
      API.providerLeads.index(
        {order: {name: 'asc'}, page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.providerLeadsOptions = data.map(providerLeads => ({
          label: providerLeads.name,
          value: providerLeads.id
        }))
      })
    },

    addNewCallCenter() {
      this.data.push(ProviderForm.options.defaultValues())
    },

    removeCallCenter(index) {
      this.data.splice(index, 1)
      this.errors.splice(index, 1)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.form.providers.${key}`, options)
    }
  }
})
