import AutomationForm from './_form.js'

import template from './show.pug'

export default Vue.extend({
  template: template(),
  components: {
    AutomationForm
  },
  props: {
    campaign: {type: Object, default: () => ({})}
  },
  data() {
    return {
      data: {},
      errors: {},
      editing: false,
      ready: false
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.ready = false

      API.automations.show(
        this.$route.params.id,
        this.$route.params.automationId
      ).then(response => {
        this.data = response
        this.ready = true
        this.$insertBreadcrumb({label: response.name, route: {}})
        //console.log(this.data)
      })
    },

    save(data) {
      this.errors = {}

      API.automations.update(
        this.$route.params.id, this.$route.params.automationId, data
      ).then(() => {
        this.$notifications.success(this.$t('campaigns.show.automations.form.succesfullyUpdated'))
        this.editing = false
        this.errors = {}
        // para poder editar el workflow tiene que estar en estado stopped
        this.data = {status: 'stopped', id: this.$route.params.automationId, ...data}
      }, errors => {
        this.errors = errors
      })
    },

    cancel() {
      this.editing = false
      this.errors = {}
    }
  }
})
