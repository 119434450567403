import template from './_form.pug'

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      disableAccordion: false

    }
  },
  computed: {

  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  methods: {
    initializeData() {
      this.data = this.initData
    },

    t(key, options = {}) {
      return this.$t(`callCenters.show.ctiConfigs.callCenterConfig.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    },

    deleteSite(index) {
      this.data.services.splice(index, 1)
    },

    addSite() {
      this.data.services.push({serviceId: '', loadId: ''})
    }
  }
})
