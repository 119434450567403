import '@nosolosoftware/vue-components/icon'
import '@nosolosoftware/vue-components/breadcrumbs'
import '@nosolosoftware/vue-components/button'
import '@nosolosoftware/vue-components/checkbox'
import '@nosolosoftware/vue-components/radio'
import '@nosolosoftware/vue-components/bubble'
import '@nosolosoftware/vue-components/select'
import '@nosolosoftware/vue-components/input'
import '@nosolosoftware/vue-components/input-file'
import '@nosolosoftware/vue-components/input-tags'
import '@nosolosoftware/vue-components/accordion'
import '@nosolosoftware/vue-components/menu'
import '@nosolosoftware/vue-components/sidepanel'
import '@nosolosoftware/vue-components/field'
import '@nosolosoftware/vue-components/table'
import '@nosolosoftware/vue-components/table-th'
import '@nosolosoftware/vue-components/pagination'
import '@nosolosoftware/vue-components/table-pagination'
import '@nosolosoftware/vue-components/table-td-icons'
import '@nosolosoftware/vue-components/layout-sidebar'
import '@nosolosoftware/vue-components/notifications'
import '@nosolosoftware/vue-components/loading'
import '@nosolosoftware/vue-components/progress'
import '@nosolosoftware/vue-components/tabs'
import '@nosolosoftware/vue-components/tabs-item'
import '@nosolosoftware/vue-components/datepicker'
import '@nosolosoftware/vue-components/dual-listbox'
import '@nosolosoftware/vue-components/badge'
import '@nosolosoftware/vue-components/modal'

// Componentes propios
import 'js/components/laia-input'
import 'js/components/ns-input-password'
import 'js/components/ns-input-currency'
import 'js/components/pagination-default'

import 'js/vue-resource-config'

// Mixins globales
import 'js/mixins/global-getters'

// Range datepicker
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

// Filters
import 'js/filters/index'

import PortalVue from 'portal-vue'
import VueTimepicker from 'vue2-timepicker'
import draggable from 'vuedraggable'

import flatpickr from 'flatpickr'
import {Spanish} from 'flatpickr/dist/l10n/es.js'
import 'moment/locale/es'

import VuejsDialog from 'vuejs-dialog'

import BlankLayout from 'js/layouts/blank'
import AuthLayout from 'js/layouts/auth'
import SidebarMenuLayout from 'js/layouts/sidebar-menu'

import NotificationsService from 'js/services/notifications'
import LengthsService from 'js/services/lengths'
import ConstantsService from 'js/services/constants'
import AuthService from 'js/services/auth'
import PermissionAccount from 'js/services/permission-account.js'

import router from 'js/router/index.js'
import store from 'js/vuex/store.js'
import i18n from 'js/i18n.js'

// phone number input
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

// Con la nueva version de vuejs, si queremos añadir propiedades propias, debemos de especificar
// como se heredan entre componentes
Vue.config.optionMergeStrategies.fields = (parent, child) => (parent || []).concat(child)
Vue.config.optionMergeStrategies.defaultValues = (_, child) => child

require('scss/main.scss')

Vue.use(AuthService)

// Se lee los parametros de la URL para determinar si LAIA es cargado
// desde un iframe
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const embedded = urlParams.get('embedded')
const token = urlParams.get('token')
if (embedded && token) {
  Vue.auth.setToken(token)
  store.commit('setEbedded', embedded)
}

/* --- Vue-Resource --- */
Vue.router = router
Vue.store = store
Vue.i18n = i18n

Vue.use(NotificationsService)

Vue.use(ConstantsService)
Vue.use(LengthsService)
Vue.use(PortalVue)
Vue.use(PermissionAccount)
Vue.use(VuejsDialog, {
  okText: 'OK',
  cancelText: 'Cancelar'
})

Vue.component('date-range-picker', DateRangePicker)
Vue.component('draggable', draggable)
Vue.component('vue-timepicker', VueTimepicker)
Vue.component('vue-phone-number-input', VuePhoneNumberInput)
flatpickr.localize(Spanish)
moment.locale('es')

// Create and mount the root instance.
// Make sure to inject the router with the router option to make the
// whole app router-aware.
new Vue({
  router,
  store,
  i18n,
  components: {
    BlankLayout,
    AuthLayout,
    SidebarMenuLayout
  },
  created() {
  }
}).$mount('#app')
