import template from './index.pug'

Vue.set(Vue.options.components, 'old-ns-input', Vue.options.components['ns-input'])

// Se sobreescribe el ns-input para que por defecto lleve el filtro de eliminar espacios en blanco
Vue.component('ns-input', {
  template: template(),
  inheritAttrs: false,
  props: {
    valueFilters: {
      type: String
    },
    representationFilters: {
      type: String
    },
    filters: {
      type: String,
      default: 'removeSpaces'
    }
  },
  computed: {
    myValueFilters() {
      if (this.valueFilters && this.filters) {
        return `${this.valueFilters}, ${this.filters}`
      }

      return this.valueFilters || this.filters
    },

    myRepresentationFilters() {
      if (this.representationFilters && this.filters) {
        return `${this.representationFilters}, ${this.filters}`
      }

      return this.representationFilters || this.filters
    }
  }
})
