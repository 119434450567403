export default {
  state: {
    pageTitle: null,
    breadcrumbs: []
  },
  getters: {
    pageTitle(state) {
      return state.pageTitle
    },

    breadcrumbs(state) {
      return state.breadcrumbs
    }
  },
  mutations: {
    setPageTitle(state, pageTitle) {
      state.pageTitle = pageTitle
    },

    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    },

    resetBreadcrumbs(state) {
      state.breadcrumbs = []
    },

    insertBreadcrumb(state, breadcrumb) {
      state.breadcrumbs.push(breadcrumb)
    },

    popBreadcrumbs(state) {
      state.breadcrumbs.pop()
    }
  }
}
