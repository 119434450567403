// Permite desde los componentes, es decir, desde los .pug acceder a las contantes definidas aqui
/* eslint-disable object-shorthand */
const constants = Object.freeze({
  FORMAT_DATE: FORMAT_DATE,
  ROLES: ROLES
})
/* eslint-enable object-shorthand */

export default {
  install(Vue) {
    Vue.constants = constants
    Vue.prototype.$constants = Vue.constants
  }
}
