import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemoveWithPrompt, openModalAlert} from 'js/services/modals.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    staticPhones: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      showFilters: true,
      filters: {name: '', ddiNumber: '', campaignId: '', policyType: ''},
      lastQueryOptions: null, // guarda los últimos filtros aplicados,
      campaigns: []
    }
  },
  computed: {
    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },

    campaignOptions() {
      return this.campaigns
    }
  },
  created() {
    this.getCampaings()
    this.ready = true
  },

  methods: {
    getCampaings() {
      return API.campaigns.index({
        page: {number: 1, size: 1000},
        simplequery:'1'
      }).then(response => {
        this.campaigns = response.data.map(campaign => ({
          label: campaign.name,
          value: campaign.id
        }))
      })
    },

    openConfirmModal(staticPhone) {
      openModalConfirmRemoveWithPrompt(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton'),
        this.t('confirmationModal.remove.promptHelp')
      ).then(({data}) => {
        if (data !== 'yes') {
          openModalAlert(
            this.t('confirmationModal.cancel.title'),
            this.t('confirmationModal.cancel.body'),
            this.t('confirmationModal.cancel.okButton')
          )
        } else {
          this.destroy(staticPhone)
        }
      })
    },

    t(key, options = {}) {
      return this.$t(`staticPhone.index.${key}`, options)
    },

    destroy(object) {
      API.callTrackingStaticNumbers.destroy(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    }

  }
})
