import {cloneDeep, pick} from 'lodash'
import InfoForm from './form/info.js'
import template from './_form.pug'

const Fields = [
  InfoForm.options.fields
].flat()

export default Vue.extend({
  template: template(),
  components: {
    InfoForm
  },
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      infoData: {},
      infoErrors: {},
      sitesData: {},
      sitesErrors: [],
      integrationsData: {},
      integrationsErrors: {},
      removeAvatar: false
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    },

    errors: {
      deep: true,
      immediate: true,
      handler() {
        this.infoErrors = pick(this.errors, InfoForm.options.fields)
      }
    }
  },
  methods: {
    initializeData() {
      this.infoData = cloneDeep(InfoForm.options.defaultValues())
      const data = {...this.infoData, ...this.sitesData, ...this.integrationsData}

      Fields.forEach(key => {
        data[key] = this.initData[key] || data[key]
      })

      this.data = data
      this.infoData = pick(data, InfoForm.options.fields)
    },

    t(key, options = {}) {
      return this.$t(`customers.form.${key}`, options)
    },

    submit() {
      this.data = {...this.infoData, ...this.sitesData, ...this.integrationsData}
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$router.push({name: 'customers-admin-index'})
    }
  }
})
