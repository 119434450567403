import CallCenterForm from './_form'
import template from './edit.pug'

export default Vue.extend({
  template: template(),
  components: {
    CallCenterForm
  },
  data() {
    return {
      ready: false,
      data: {},
      errors: {}
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('ddis.edit.title'))
        this.loadData()
      }
    }
  },

  methods: {
    loadData() {
      this.ready = false
      API.ddis.show(this.$route.params.id).then(response => {
        this.data = response
        this.ready = true

        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.ddis.index'), route: {name: 'ddis-index'}},
          {label: this.$t('breadcrumbs.ddis.edit', {name: this.data.name}), route: {}}
        ])
      })
    },

    updateCallCenter(data) {
      API.ddis.update(this.$route.params.id, data).then(() => {
        this.$notifications.success(this.$t('ddis.edit.succesfullyUpdated'))
        this.$router.push({name: 'ddis-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
