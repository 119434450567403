import pick from 'lodash/pick'

const collection = [
  {
    // regla que se activa si el id del producto es mayor que 2
    id: 'condition1',
    name: 'Id Producto', // será el nombre de la Cdimension o MatomoVar
    fieldType: FIELD_TYPES.NUMERIC,
    sourceLocation: CDIMENSIONS.SOURCE_LOCATION.URL,
    sourceKey: 'product_id',
    comparisonOperator: COMPARISON_OPERATORS.GREATER_THAN,
    comparisonValue: '2',
    campaignId: 'campaign1',
    formId: 1
  },
  {
    // regla que se activa si el elemento 'status' de la página tiene un valor OK
    id: 'condition2',
    name: 'Enviado',
    fieldType: FIELD_TYPES.TEXT,
    sourceLocation: CDIMENSIONS.SOURCE_LOCATION.DATA_LAYER,
    sourceKey: 'send',
    comparisonOperator: COMPARISON_OPERATORS.EQUAL,
    comparisonValue: 'OK',
    campaignId: 'campaign1',
    formId: 1
  },
  {
    // regla que se activa si la cookie 'accept_terms' tiene el valor 1
    id: 'condition3',
    name: 'Terminos aceptados',
    fieldType: FIELD_TYPES.TEXT,
    sourceLocation: CDIMENSIONS.SOURCE_LOCATION.COOKIE,
    sourceKey: 'accept_terms',
    comparisonOperator: COMPARISON_OPERATORS.EQUAL,
    comparisonValue: 1,
    campaignId: 'campaign1',
    formId: 1
  }
]

// el show incluye todos los campos
const showConditions = collection

// seleccionamos solo aquellos campos del index
const indexConditions = collection.map(item => pick(item, [
  'id',
  'name',
  'fieldType',
  'sourceLocation',
  'sourceKey',
  'comparisonOperator',
  'comparisonValue',
  'formId'
]))

const validations = {
  name: {type: 'string', required: true, maxLenth: 128},
  fieldType: {type: 'string', required: true},
  sourceLocation: {type: 'string', required: true},
  sourceKey: {type: 'string', required: true, maxLenth: 254},
  comparisonOperator: {type: 'string', required: true},
  comparisonValue: {required: true, maxLenth: 128}
}

export {showConditions, indexConditions, validations}
