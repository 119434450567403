import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './webhook.pug'

const DefaultData = () => ({
  config: {
    webHookId: ''
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      webHooks: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    webHookSelectOptions() {
      return this.webHooks.map(webhook => ({
        label: webhook.name,
        value: webhook.id
      }))
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
        this.getWebHooks()
      }
    }
  },
  methods: {
    getWebHooks() {
      return API.callTrackingWebhook.index().then(({data}) => {
        this.webHooks = this.$sortByLocale(data, 'name')
      })
    },

    save() {
      validatePresence(this.data, [
        'config.webHookId'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.webhook.${key}`, options)
    }
  }
})
