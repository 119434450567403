import LeadsDetailsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsDetailsTable
  },
  data() {
    return {
      leads: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('customers.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
      {label: this.$t('customers.leads-details.leadscount'), route: {}}
    ])
  },
  methods: {
    loadLeadsDetails(queryOptions = {}) {
      API.leadsDetails.leadsCount(this.$route.params.id, queryOptions).then(({data, meta}) => {
        this.leads = data
        this.meta = meta
      })
    }
  }
})
