import RouteParent from 'js/router/route-parent'

import trackingNumbersIndex from 'js/pages/tracking-numbers/index.js'
import trackingNumbersEdit from 'js/pages/tracking-numbers/edit.js'

export default [
  {
    path: '/tracking-numbers',
    component: RouteParent,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'tracking-numbers-index',
        component: trackingNumbersIndex,
        meta: {
          auth: true,

        }
      },
      {
        path: ':id/edit',
        name: 'tracking-numbers-edit',
        component: trackingNumbersEdit,
        meta: {
          auth: true,
        }
      },
    ]
  }
]
