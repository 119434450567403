import camelCase from 'lodash/camelCase'
import template from './show.pug'

const noDataImg = require('assets/images/content/empty-report.svg')

export default Vue.extend({
  template: template(),
  data() {
    return {
      noDataImg,
      data: {},
      campaigns: [],
      ready: false,
      currentTab: this.$route.name.split('-')[2]
    }
  },
  computed: {
    viewTracking() {
      return Vue.account.has('customer_tracking')
    },
    viewTransformation() {
      return Vue.account.has('customer_transformation')
    },
    analyticsHaveInfo() {
      return this.data.status === CUSTOMER_STATUSES.ACTIVE
    },

    isTabInfo() {
      return this.currentTab === 'info'
    },

    tabsOptions() {
      const tabs = ['info', 'transform', 'tracking']

      return tabs.map(item => ({
        value: item,
        label: this.t(`${camelCase(item)}.tabTitle`)
      }))
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.loadAllData()
        this.currentTab = this.$route.name.split('-')[2]
      }
    }
  },
  methods: {
    async loadAllData() {
      this.ready = false
      await this.loadCustomerData()
      await this.loadCampaignsData()

      this.$setPageTitle(this.data.name)
      this.$setBreadcrumbs([
        {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
        {label: this.$t('breadcrumbs.customers.show', {name: this.data.name}), route: {}}
      ])

      this.ready = true
    },

    loadCampaignsData() {
      return API.campaigns.index({filter: {customerId: [this.$route.params.id]}}).then(response => {
        this.campaigns = response.data

        this.data.investment = this.campaigns.reduce(
          (count, campaign) => (count + (campaign.investment || 0)), 0
        )
      })
    },

    loadCustomerData() {
      return API.customers.show(this.$route.params.id).then(response => {
        this.data = response
      })
    },

    t(key, options = {}) {
      return this.$t(`customers.show.${key}`, options)
    },

    getRouteCurrentTab(tab) {
      return {name: `customers-show-${tab}`, params: {id: this.data.id}}
    },

    setCurrentTab(tab) {
      this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
