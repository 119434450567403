export default {
  install(Vue) {
    Vue.account = {
      has(key) {
        return Vue.store.getters.checkAccountPermission(key)
      }
    }

    Vue.prototype.$account = Vue.account
  }
}
