
import FullTable from 'js/components/full-table/index.js'
import AnalyticsUtils from 'js/services/analytics-utils'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    leads: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      showFilters: true,
      filters: {phone: '', from: '', to: '', idLead: ''},   
      initFilters: {phone: '', from: '', to: '', idLead: ''},    
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    fromDateConfig() {
      return {
        maxDate: this.filters.to ? this.filters.to : null,
        ...this.dateConfig
      }
    },
    toDateConfig() {
      return {
        minDate: this.filters.from ? this.filters.from : null,
        ...this.dateConfig
      }
    },

    defaultOrder() {
      return {currentField: 'id', currentDir: 'asc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  methods: {
    cleanFilters() {
      this.filters = JSON.parse(JSON.stringify(this.initFilters)) 
      this.lastQueryOptions =null;     
    },
    getFilters() {
      const filter = {
        ...this.filters,
        to: this.filters.to ? moment(this.filters.to).format('DD-MM-YYYY') : null,
        from: this.filters.from ? moment(this.filters.from).format('DD-MM-YYYY') : null,
        idLead: this.filters.idLead?this.filters.idLead:null,
        phone: this.filters.phone?this.filters.phone:null
      }
      Object.entries(filter).forEach(([key, value]) => {
        if (!value) {
          delete filter[key]
        }
      })
      return filter
    },
    getData(queryOptions) {
      const filter = this.getFilters()        
      queryOptions.filter =filter;
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', {filter, ...queryOptions})

      if (queryOptions) this.lastQueryOptions = queryOptions
    },
    t(key, options = {}) {
      return this.$t(`logs.log-deleted.${key}`, options)
    },
    exportLeads() {
      setTimeout(
        () => {
          this.loadLeadsCsv()
        },
        MOCKS ? 500 : 0
      )
    },
    loadLeadsCsv() {
      const filter = this.getFilters()
      console.log(filter);
      API.logs.exportDeletedLogs(filter)
        .then(({data}) => {
          AnalyticsUtils.downloadCsv({
            title: this.t('exportCsv.title'),
            to: filter.to,
            from: filter.from,
            url: data.file
          })
        })
    }
  }
})
