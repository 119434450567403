import Vuex from 'vuex'
import notifications from './modules/notifications'
import currentUser from './modules/current-user'
import currentAccount from './modules/current-account'
import constants from './modules/constants'
import breadcrumbs from './modules/breadcrumbs'
import comparisonOperators from './modules/comparison-operators'
import fieldTypes from './modules/field-types'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: [
    notifications,
    currentUser,
    currentAccount,
    constants,
    breadcrumbs,
    comparisonOperators,
    fieldTypes
  ],
  state: {
    loading: 0
  },
  mutations: {
    incrementLoading(state) {
      state.loading += 1
    },
    decreaseLoading(state) {
      state.loading -= 1
    }
  }
})
