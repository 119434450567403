import OfferForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    OfferForm
  },
  props: {
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ready: false,
      errors: {},
      offerData: null
    }
  },
  created() {
    this.offerData = null
    this.ready = false
    API.offerPurchase.show(this.$route.params.offerid).then(response => {
      this.offerData = response
      this.$setPageTitle(this.$t('leadtrade.offerOrder.new.title'))
      this.$setBreadcrumbs([
        {
          label: this.$t('breadcrumbs.offer-and-demand.index'),
          route: {name: 'offer-and-demand-index'}
        },
        {
          label: this.$t('breadcrumbs.offer.index'),
          route: {name: 'offer-index'}
        },
        {label: this.$t('breadcrumbs.offer-order.new'), route: {}}
      ])
      this.ready = true
    })
  },
  methods: {
    createOfferOrder(data) {
      API.offerPurchase.order.create(data).then(() => {
        this.$notifications.success(this.$t('leadtrade.offerOrder.new.succesfullyCreated'))
        this.$router.push({name: 'offer-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
