import {mapGetters} from 'vuex'
import template from './_form.pug'

const Fields = [
  'number', 'description', 'country_code', 'customerId', 'verticality', 'serviceDestinationId'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      customers: [],
      phoneNumberConfig: {
        size: 'lg',
        noExample: true,
        error: false,
        colorError: '#EE666A',
        defaultCountry: 'ES',
        updateData: {},
        translations: {
          countrySelectorLabel: 'Código de país',
          countrySelectorError: '',
          phoneNumberLabel: 'Numero de teléfono',
          example: ''
        },
        noFlags: true
      }
    }
  },
  computed: {
    ...mapGetters(['countries']),

    countryOptions() {
      return this.countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    },
    customerOptions() {
      return this.customers.map(customer => ({
        label: customer.name,
        value: customer.id
      }))
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
        this.getCustomers()
      }
    }
  },
  created() {

  },
  methods: {

    getCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customers = data
      })
    },

    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data
    },

    t(key, options = {}) {
      return this.$t(`ddis.form.${key}`, options)
    },

    submit() {
      // set void if data.number is null

      if (this.data.number === null || this.data.number === '') {
        this.data.number = ''
        this.data.country_code = ''
        this.phoneNumberConfig.error = true
      } else {
        this.data.number = this.phoneNumberConfig.updateData.e164
        this.data.country_code = this.phoneNumberConfig.updateData.countryCallingCode
        this.phoneNumberConfig.error = false
      }

      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    },

    errorUpload(errors = []) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`fields.logo.errors.${error}`)
        )
      }
    }
  }
})
