import template from './auth.pug'

export default Vue.extend({
  name: 'google-ads-lead-form-auth-index',
  template: template(),
  mounted() {
    const customerId = this.$route.query?.state
    if (customerId) {
      const {scope, code} = this.$route.query
      this.$router.push({
        name: 'customers-ads',
        params: {id: customerId},
        query: {scope, code}
      })
    } else {
      window.close()
    }
  }
})
