import template from './_form.pug'

const Fields = [
  'number', 'description', 'country_code', 'assignedTrackingNumbers'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      selectedOptions: [],
      options: [],
      phoneNumberConfig: {
        size: 'lg',
        noExample: true,
        error: false,
        colorError: '#EE666A',
        updateData: {},
        translations: {
          countrySelectorLabel: 'Código de país',
          countrySelectorError: '',
          phoneNumberLabel: 'Teléfono',
          example: ''
        },
        noFlags: true
      }
    }
  },
  computed: {

  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadTrackingNumbers()
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data

      if (this.data.assignedTrackingNumbers) {
        this.selectedOptions = this.data.assignedTrackingNumbers
      }
    },

    unselectedFilter(options, text) {
      return options.filter(item => item.label.includes(text))
    },

    loadTrackingNumbers() {
      if (this.initData) {
        API.trackingNumbers.availableToTargetNumber(this.initData.id, {
          page: {number: 1, size: 1000}
        }).then(({data}) => {
          this.options = data.map(trackingNumber => ({
            label: `${trackingNumber.number} (${trackingNumber.description})`,
            value: trackingNumber
          }))
          this.trackingNumbers = data
        })
      } else {
        API.trackingNumbers.availableToTargetNumber('00000000-0000-0000-0000-000000000000', {
          page: {number: 1, size: 1000}
        }).then(({data}) => {
          this.options = data.map(trackingNumber => ({
            label: `${trackingNumber.number} (${trackingNumber.description})`,
            value: trackingNumber
          }))
          this.trackingNumbers = data
        })
      }
    },

    t(key, options = {}) {
      return this.$t(`targetNumbers.form.${key}`, options)
    },

    submit() {
      this.data.assignedTrackingNumbers = this.selectedOptions
      // set void if data.number is null
      if (this.data.number === null || this.data.number === '') {
        this.data.number = ''
        this.data.country_code = ''
        this.phoneNumberConfig.error = true
      } else {
        this.data.number = this.phoneNumberConfig.updateData.e164
        this.data.country_code = this.phoneNumberConfig.updateData.countryCallingCode
        this.phoneNumberConfig.error = false
      }

      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    },

    errorUpload(errors = []) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`fields.logo.errors.${error}`)
        )
      }
    }
  }
})
