import cloneDeep from 'lodash/cloneDeep'

import template from './_list-form.pug'

export default Vue.extend({
  template: template(),
  props: {
    clientPortfolio: Boolean,
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      validator: value => ['new', 'edit'].includes(value)
    },
    translateKey: String
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    title() {
      return this.tk(`${this.mode}Title`)
    },

    saveButtonText() {
      return this.tk(`${this.mode}Button`)
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.data = cloneDeep(this.initData)
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.$emit('submit', this.data)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.phoneListComponent.${key}`, options)
    },
    tk(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.${this.translateKey}.${key}`, options)
    }
  }
})
