import RouteParent from 'js/router/route-parent'

import CustomersIndex from 'js/pages/customers-admin/index.js'
import CustomersNew from 'js/pages/customers-admin/new.js'
import CustomersEdit from 'js/pages/customers-admin/edit.js'

export default [
  {
    path: '/customers-admin',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'customers-admin-index',
        component: CustomersIndex,
        meta: {
          auth: true
        }
      },
      {
        path: 'new',
        name: 'customers-admin-new',
        component: CustomersNew,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit',
        name: 'customers-admin-edit',
        component: CustomersEdit,
        meta: {
          auth: true
        }
      }
    ]
  }
]
