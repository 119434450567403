import {set, get} from 'lodash'

export default (data, keys) => {
  const errors = {}

  keys.forEach(key => {
    if (!get(data, key)) set(errors, key, Vue.i18n.t('errors.blank'))
  })

  data.errors = Object.keys(errors).length ? errors : null
}
