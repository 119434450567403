import {isEmpty} from 'lodash'
import template from './notification.pug'

export default Vue.extend({
  template: template(),
  data() {
    return {
      ready: false,
      disabledUsers: true,
      data: {},
      errors: {},
      userOptions: [],
      rolesOptions: [],
      configTypesOptions: [],
      errorsMapping: [],
      externalUsers: [],
      isMappingErrorsEmpty: true
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.$setPageTitle(this.$t('laiaAccounts.notification.title'))
      this.loadData()
    }
  },

  methods: {
    t(key, options = {}) {
      return this.$t(`laiaAccounts.notification.${key}`, options)
    },
    loadRoles() {
      API.constants.index().then(({data}) => {
        this.rolesOptions = data.roles
        .map((item => ({value: item.id, label: `${item.key}`})))
      })
    },
    loadConfigTypes() {
      API.notifications.configTypes({page: {number: 1, size: 1000}}).then(({data}) => {
        this.configTypesOptions = data
        .map((item => ({value: item.id, label: `${item.name}`})))
      })
    },
    loadUsers() {
      this.userOptions = []
      API.laiaAccounts.users(this.$route.params.id).then(response => {
        this.userOptions = response.data
          .map((item => ({value: item.id, label: `${item.firstName} ${item.lastName}`})))
      })
    },

    loadUsersByRoles(init, id, index) {
      if (!init) {
        this.data.configuration.platform[index].users = []
      }
      API.users.indexByRole(id, {page: {number: 1, size: 1000}}).then(response => {
        this.disabledUsers = false
        const optionsValues = response.data
        .map((item => ({value: item.id, label: `${item.firstName} ${item.lastName}`})))
        this.$set(this.userOptions, index, optionsValues)
      }).catch(() => {
        this.disabledUsers = true
      })
    },

    submit() {
      this.checkMapping()
      console.log(this.errorsMapping[2][0])
      if (!this.isMappingErrorsEmpty) return
      API.laiaAccounts.setNotificationSettings(this.$route.params.id, this.data)
        .then(() => {
          this.$notifications.success(this.$t('laiaAccounts.notification.succesfullyUpdated'))
          this.$router.push({
            name: this.lastPath || 'accounts-laia-index',
            params: this.lastPathParams
          })
        },
        errors => {
          console.error(errors)
          this.$notifications.error(this.$t('laiaAccounts.notification.errorOnUpdated'))
        })
    },
    deleteMappingElement(index) {
      this.externalUsers.splice(index, 1)
    },
    addMappingElement() {
      this.externalUsers.push('')
    },
    checkMapping() {
      this.errorsMapping = []
      const usedEmail = []
      this.externalUsers.forEach(element => {
        const errors = []
        if (!element) {
          // errors.email = this.$t('errors.blank')
          errors.push(this.$t('errors.blank'))
        // eslint-disable-next-line max-len
        } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(element)) {
          errors.push(this.$t('errors.format'))
        }

        // Comprobamos que no se repita el email
        if (usedEmail.includes(element)) {
          errors.push(this.$t('errors.taken'))
          // errors = this.$t('errors.taken')
        }
        usedEmail.push(element)
        this.errorsMapping.push(errors)
      })
      this.isMappingErrorsEmpty = !this.errorsMapping.find(errors => (!isEmpty(errors)))
    },
    loadData() {
      this.data = {}
      this.externalUsers = []
      this.ready = false
      API.laiaAccounts.getNotificationSettings(this.$route.params.id).then(response => {
        this.data = response.data
        this.externalUsers = response.data.configuration.external.emails
        this.loadRoles()
        this.loadConfigTypes()
        this.disabledUsers = false
        response.data.configuration.platform
        // eslint-disable-next-line max-len
        .forEach((el, index) => this.loadUsersByRoles(true, response.data.configuration.platform[index].roleId, index))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.laiaAccounts.index'), route: {name: 'accounts-laia-index'}},
          {
            label: this.$t('breadcrumbs.laiaAccounts.logNotification'),
            route: {name: 'accounts-laia-logs-notification'}
          },
          {
            label: this.$t('breadcrumbs.laiaAccounts.notification',
            {name: this.data.name}),
            route: {}
          }
        ])
        this.ready = true
      })
    }
  }
})
