import RouteParent from 'js/router/route-parent'

import tipificationReportIndex from 'js/pages/tipification-report/index.js'

export default [
  {
    path: '/tipification-report',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'tipification-report-index',
        component: tipificationReportIndex,
        meta: {
          auth: true

        }
      }
    ]
  }
]
