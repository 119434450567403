import {cloneDeep, merge, set} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './http-request.pug'

const DefaultData = () => ({
  name: '',
  config: {
    url: '',
    method: '',
    headers: {},
    queryParams: {},
    body: {},
    responseMapping: {}
  }
})

// Nodo que permite añadir una petición http
export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      // Arrays con los key y values de los objetos 'node.config.headers', 'node.config.body'
      // 'node.config.queryParams' y 'node.config.responseMapping', cada elemento del array
      // tiene un objeto con los siguientes elementos:
      // key => valor de un key del objeto
      // value => valor del value de la key del objeto
      // manualUpdate => valor que comprueba si el value tiene el texto $lead
      dataHeaders: [],
      dataQuery: [],
      dataBody: [],
      dataMapping: [],
      errorsMapping: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    methodSelectOptions() {
      return HTTP_REQUEST_METHODS.map(method => (
        {value: method, label: this.t(`fields.method.options.${method}`)}
      ))
    },

    valueSelectOptions() {
      const options = {}

      // agrupamos por tipo
      this.node.tags.forEach(item => {
        if (!options[item.type]) options[item.type] = []

        options[item.type].push(
          {label: item.name || item.key, value: `$lead.${item.type}.${item.key}`}
        )
      })

      let finalOptions = []

      // cada tipo debe tener su grupo
      Object.keys(options).sort().forEach(type => {
        finalOptions.push({title: this.$t(`campaigns.show.tags.types.${type}`)})
        finalOptions = finalOptions.concat(this.$sortByLocale(options[type], 'label'))
      })

      return finalOptions
    },

    headerErrors() {
      return this.dataHeaders.filter(item => item.errors).length
    },

    queryErrors() {
      return this.dataQuery.filter(item => item.errors).length
    },

    bodyErrors() {
      return this.dataBody.filter(item => item.errors).length
    },

    mappingErrors() {
      return this.dataMapping.filter(item => item.errors).length
    }
  },
  created() {
    if (this.data.config.method === '') {
      this.data.config.method = 'post'
    }
    // Convertimos a un array para poder manejar de forma mas facil en Vue los valores
    // De esta forma es más sencillo añadir o eliminar elementos
    this.transformObjectToArrayFields('Headers', this.data.config.headers)
    this.transformObjectToArrayFields('Query', this.data.config.queryParams)
    this.transformObjectToArrayFields('Body', this.data.config.body)
    this.transformObjectToArrayFields('Mapping', this.data.config.responseMapping)

    if (this.node.config) this.validateSections()
  },
  methods: {
    // Función que transforma el array de data en el objeto de config
    transformArrayFieldsToObject(array) {
      const object = {}

      array.forEach(item => {
        // el campo errors es interno y no se envía al backend
        if (item.name !== 'errors') object[item.name] = item.value
      })

      return object
    },

    // Función que transforma el objeto de config en el array de data
    transformObjectToArrayFields(arrayName, object) {    
      Object.keys(object).forEach(key => {
        var manual = true;
        if(object[key].match(/\$lead/) &&  key!='body')
        {
          manual = false;
        }
        else if(object[key].match(/\$lead/) &&  key==='body')
        {
          manual = true;
        }
        else{
          manual = true;
        }

        this[`data${arrayName}`].push({
          name: key,
          value: object[key],
          manualUpdate: manual,
          errors: null // guarda los errores si los tiene
        })
      })
    },

    save() {
      // transformamos los datos de los objetos de config
      this.data.config = {
        ...this.data.config,
        headers: this.transformArrayFieldsToObject(this.dataHeaders),
        queryParams: this.transformArrayFieldsToObject(this.dataQuery),
        body: this.transformArrayFieldsToObject(this.dataBody),
        responseMapping: this.transformArrayFieldsToObject(this.dataMapping)
      }

      validatePresence(this.data, [
        'name',
        'config.url',
        'config.method'
      ])

      this.validateSections()

      if (
        this.data.errors ||
        this.headerErrors ||
        this.queryErrors ||
        this.bodyErrors ||
        this.mappingErrors
      ) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        }).catch(() => {})
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    // valida los parámetros de cada sección
    validateSections() {
      let error = false;

      [this.dataHeaders, this.dataQuery, this.dataBody, this.dataMapping].forEach(sectionFields => {
        sectionFields.forEach(field => {
          field.errors = null
          validatePresence(field, ['name', 'value'])

          // validamos unicidad de las claves
          const sameNameItems = sectionFields.filter(item => item.name === field.name).length
          if (sameNameItems > 1) field.errors = {name: this.$t('errors.unique')}
          if (field.errors) error = true
        })
      })

      // añadimos un error para que se muestre en rojo el nodo en el diagrama
      if (error) set(this.data, 'errors.config.fields', true)
    },

    addElementTo(arrayName) {
      this[`data${arrayName}`].push({name: '', value: '', manualUpdate: true})
    },

    removeElementTo(index, arrayName) {
      this[`data${arrayName}`].splice(index, 1)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.httpRequest.${key}`, options)
    }
  }
})
