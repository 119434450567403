import RouteParent from 'js/router/route-parent';

import audiosMenuVoiceIndex  from 'js/pages/audios-menu-voice/index.js';
import audiosMenuVoiceNew    from 'js/pages/audios-menu-voice/new.js';
import audiosMenuVoiceEdit   from 'js/pages/audios-menu-voice/edit.js';

export default [
    {
      path: '/VoiceAudios',
      component: RouteParent,
      meta: {
        auth: true,
      },
      children: [
        {
          path: '',
          name: 'audios-menu-voice-index',
          component: audiosMenuVoiceIndex,
          meta: {
            auth: true,

          }
        },

        {
          path: 'new',
          name: 'audios-menu-voice-new',
          component: audiosMenuVoiceNew,
          meta: {
            auth: true,
          }
        },
        {
          path: ':id/edit',
          name: 'audios-menu-voice-edit',
          component: audiosMenuVoiceEdit,
          meta: {
            auth: true,
          }
        }
        
      ]
    }
];
