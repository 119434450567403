import FormOfferOrder from '../offer-order/_form-detail'
import template from './modal-offer-order-detail.pug'

export default Vue.extend({
  template: template(),
  components: {
    FormOfferOrder
  },
  props: {
    offerId: {
      type: [String, Number]
    }
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`leadtrade.offer.index.modal.${key}`, options)
    },
    cancel() {
      this.$emit('input', false)
    }
  }
})
