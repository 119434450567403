import template from './show.pug'
import ModalSaveField from './_modal-save-field'

export default Vue.extend({
  template: template(),
  components: {
    ModalSaveField
  },
  props: {
    campaign: {
      type: Object
    }
  },
  data() {
    return {
      ready: false,
      dataJourney: {},
      showModalSaveField: false,
      expand: {
        leadJourney: true,
        leadTracking: true,
        visits: true
      }
    }
  },
  computed: {},
  watch: {
    '$route.params.leadId': {
      immediate: true,
      handler() {
        this.loadJourney()
      }
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format(FORMAT_DATETIME_COMPLETE)
    },

    getClassByScore(score) {
      const classByScore = {
        0: 'table-tag--gray',
        1: 'table-tag--success',
        2: 'table-tag--yellow-light',
        3: 'table-tag--red'
      }
      return classByScore[score] || 'table-tag--gray'
    },
    
    getClassByStage(stage) {
      const classByStage = {
        'Lead Generation': 'text-brand',
        'Lead Qualification': 'text-warning-light',
        'Lead Conversion': 'text-success', 
        'Lead Nurturing':'text-info'       
      }
      return classByStage[stage] || 'text-success'
    },

    t(key, options = {}) {
      return this.$t(`leads.show.${key}`, options)
    },

    createField(data) {
      API.campaigns.leads.addField(this.campaign.id, this.$route.params.leadId, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.showModalSaveField = false
        this.loadJourney()
      })
    },

    loadJourney() {
      API.campaigns.leads.journey(
        this.campaign.id,
        this.$route.params.leadId
      ).then(({data}) => {
        this.dataJourney = data
        this.ready = true
      })
    }
  }
})
