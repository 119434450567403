/* eslint-disable max-len */
import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './waitFor.pug'

const DefaultData = () => ({
  name: '',
  config: {
    eventName: '',
    event: '',
    eventStepId: '',
    timevalue: '',
    timeunit: ''
  },
  branchs: [
    {value: '', nextStepId: ''},
    {value: '', nextStepId: ''}
  ]
})

// Nodo que permite dividir el flujo del workflow en dos caminos basado en una condición
export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      eventSelectOptions: [],
      eventSelected: null,
      timeUnitSelectOptions: [
        {label: this.t('timeunits.seconds'), value: 'second'},
        {label: this.t('timeunits.minutes'), value: 'minute'},
        {label: this.t('timeunits.hours'), value: 'hour'},
        {label: this.t('timeunits.days'), value: 'day'}
      ],
      allNodes: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    }
  },
  watch: {

    eventSelected() {
      this.data.config.event = this.eventSelected.event
      this.data.config.eventStepId = this.eventSelected.id
      this.data.config.eventName = this.eventSelectOptions.find(item => item.value === this.eventSelected).label
    }
  },
  mounted() {
    this.allNodes = this.$store.getters.allNodes

    this.allNodes.forEach(node => {
      if (node.stepType === 'sendEmail') {
        this.eventSelectOptions.push({label: `EMAIL - ${node.name} - ${this.t('emailEvents.emailDelivered')}`, value: {id: node.id, event: 'EMAILDELIVERED'}})
        this.eventSelectOptions.push({label: `EMAIL - ${node.name} - ${this.t('emailEvents.emailOpened')}`, value: {id: node.id, event: 'EMAILOPENED'}})
        this.eventSelectOptions.push({label: `EMAIL - ${node.name} - ${this.t('emailEvents.emailClicked')}`, value: {id: node.id, event: 'EMAILCLICKLINK'}})
        this.eventSelectOptions.push({label: `EMAIL - ${node.name} - ${this.t('emailEvents.emailFormReceived')}`, value: {id: node.id, event: 'EMAILFORMRECEIVED'}})
      }
      if (node.stepType === 'sendSms') {
        this.eventSelectOptions.push({label: `SMS - ${node.name} - ${this.t('smsEvents.smsDelivered')}`, value: {id: node.id, event: 'SMSDELIVERED'}})
        this.eventSelectOptions.push({label: `SMS - ${node.name} - ${this.t('smsEvents.smsClicked')}`, value: {id: node.id, event: 'SMSCLICKLINK'}})
        this.eventSelectOptions.push({label: `SMS - ${node.name} - ${this.t('smsEvents.smsFormReceived')}`, value: {id: node.id, event: 'SMSFORMRECEIVED'}})
      }
    })

    if (this.data.config.eventStepId && this.data.config.event) {
      this.eventSelected = this.eventSelectOptions.find(item => item.value.id === this.data.config.eventStepId && item.value.event === this.data.config.event).value
    }
  },
  methods: {
    save() {
      if (this.eventSelectOptions.length === 0) {
        this.$notifications.error(this.t('needAddEmailSmsNode'))
      } else {
        validatePresence(this.data, [
          'name',
          'config.event',
          'config.timeunit',
          'config.timevalue'
        ])

        if (this.data.errors) {
          openModalConfirm().then(() => {
            this.$emit('save', cloneDeep(this.data))
          })
        } else {
          this.$emit('save', cloneDeep(this.data))
        }
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.waitFor.${key}`, options)
    }
  }
})
