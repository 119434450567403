/* eslint-disable max-len */
import RouteParentCampaign from 'js/router/route-parent-campaign'

import CampaignsShow from 'js/pages/campaigns/show.js'

import Analytics from 'js/pages/campaigns/show/analytics.js'
import AnalyticsTransformation from 'js/pages/campaigns/show/analytics/transformation-tab.js'
import AnalyticsTracking from 'js/pages/campaigns/show/analytics/tracking-tab.js'
import AnalyticsAudience from 'js/pages/campaigns/show/analytics/audience-tab.js'
import dashContactCenterTab from 'js/pages/campaigns/show/analytics/dash-contact-center-tab.js'

import LeadsIndex from 'js/pages/campaigns/show/leads/index.js'
import LeadsShow from 'js/pages/campaigns/show/leads/show.js'
import LeadsAdd from 'js/pages/campaigns/show/leads/add.js'

import CampaignsAutomations from 'js/pages/campaigns/show/automations/index.js'
import AutomationNew from 'js/pages/campaigns/show/automations/new.js'
import AutomationShow from 'js/pages/campaigns/show/automations/show.js'
import AutomationLogs from 'js/pages/campaigns/show/automations/logs-automations/index.js'

import CampaignsConfig from 'js/pages/campaigns/show/config.js'
import CampaignsConfigCdimensions from 'js/pages/campaigns/show/config/cdimensions-index.js'
import CampaignsConfigCpl from 'js/pages/campaigns/show/config/provider/cpl-providers-index.js'

import AnalyticsModel from 'js/pages/campaigns/show/analytic-model.js'
import matrixModel from 'js/pages/campaigns/show/analytic-model/matrix-model/index.js'
import rulesModel from 'js/pages/campaigns/show/analytic-model/rules-model/index.js'

// eslint-disable-next-line max-len
import CampaignsConfigProviderSite from 'js/pages/campaigns/show/config/provider/provider-index.js'
import CampaignsConfigforms from 'js/pages/campaigns/show/config/forms-index.js'
import CampaignsConfigPixelControl from 'js/pages/campaigns/show/config/pixel-control.js'
import CampaignsConfigGeneralData from 'js/pages/campaigns/show/config/general-data.js'

import CampaignsValidationsSummary from 'js/pages/campaigns/show/validations/summary'
import CampaignsValidationsDuplicated from 'js/pages/campaigns/show/validations/duplicity-config.js'
import CampaignsValidations from 'js/pages/campaigns/show/validations.js'
import CampaignsValidationsRobinson from 'js/pages/campaigns/show/validations/validation-robinson/validation-robinson-index.js'
import CampaignsRobinsonList from 'js/pages/campaigns/show/validations/robinson-list/robinson-list-index.js'
import CampaignsValidationsBlackList from 'js/pages/campaigns/show/validations/validation-black-list/validation-black-list-index.js'
import CampaignsBlackList from 'js/pages/campaigns/show/validations/black-list/black-list-index.js'
import CampaignsValidationsDataTreatment from 'js/pages/campaigns/show/validations/treatment/treatment-index.js'
import CampaignsValidationsClientPortfolio from 'js/pages/campaigns/show/validations/validation-client-portfolio/validation-client-portfolio-index.js'
import CampaignsClientPortfolioList from 'js/pages/campaigns/show/validations/client-portfolio-list/client-portfolio-list-index.js'

export default {
  path: ':id',
  component: CampaignsShow,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      component: Analytics,
      children: [
        {
          path: '',
          name: 'campaigns-show-analytics-transformation',
          component: AnalyticsTransformation,
          meta: {
            auth: true,
            permit: ['SHOW_ANALYTICS']
          }
        },
        {
          path: 'tracking',
          name: 'campaigns-show-analytics-tracking',
          component: AnalyticsTracking,
          meta: {
            auth: true,
            permit: ['SHOW_ANALYTICS']
          }
        },
        {
          path: 'audience',
          name: 'campaigns-show-analytics-audience',
          component: AnalyticsAudience,
          meta: {
            auth: true,
            permit: ['SHOW_ANALYTICS']
          }
        },
        {
          path: 'dash-contact',
          name: 'campaigns-show-analytics-dash-contact-center',
          component: dashContactCenterTab,
          meta: {
            auth: true,
            permit: ['SHOW_ANALYTICS']
          }
        }
      ]
    },
    {
      path: 'add-leads',
      name: 'campaigns-show-leads-add',
      component: LeadsAdd,
      meta: {
        auth: true,
        permit: ['MANAGE_LEADS']
      }
    },
    {
      path: 'leads',
      component: RouteParentCampaign,
      children: [
        {
          path: '',
          name: 'campaigns-show-leads',
          component: LeadsIndex,
          meta: {
            auth: true,
            permit: ['VIEW_LEADS']
          }
        },
        {
          path: ':leadId',
          name: 'campaigns-show-leads-show',
          component: LeadsShow,
          meta: {
            auth: true,
            permit: ['VIEW_LEADS']
          }
        }
      ]
    },
    {
      path: 'automations',
      component: RouteParentCampaign,
      meta: {
        auth: true,
        permit: ['VIEW_AUTOMATIONS']
      },
      children: [
        {
          path: '',
          name: 'campaigns-show-automations',
          component: CampaignsAutomations,
          meta: {
            auth: true,
            permit: ['VIEW_AUTOMATIONS']
          }
        },
        {
          path: 'new',
          name: 'campaigns-show-automations-new',
          component: AutomationNew,
          meta: {
            auth: true,
            permit: ['MANAGE_AUTOMATIONS']
          }
        },
        {
          path: ':automationId',
          name: 'campaigns-show-automations-show',
          component: AutomationShow,
          meta: {
            auth: true,
            permit: ['VIEW_AUTOMATIONS']
          }
        },
        {
          path: ':automationId/logs',
          name: 'campaigns-show-automations-logs',
          component: AutomationLogs,
          meta: {
            auth: true,
            permit: ['VIEW_AUTOMATIONS']
          }
        }
      ]
    },
    {
      path: 'config',
      component: CampaignsConfig,
      children: [
        {
          path: '',
          name: 'campaigns-show-config-cdimensions',
          component: CampaignsConfigCdimensions,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'forms',
          name: 'campaigns-show-config-forms',
          component: CampaignsConfigforms,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'pixel-control',
          name: 'campaigns-show-config-pixelControl',
          component: CampaignsConfigPixelControl,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'general-data',
          name: 'campaigns-show-config-generalData',
          component: CampaignsConfigGeneralData,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'provider',
          name: 'campaigns-show-config-provider',
          component: CampaignsConfigProviderSite,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'provider/:providerId/cpl',
          name: 'campaigns-show-config-provider-cpl',
          component: CampaignsConfigCpl,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        }
      ]
    },
    {
      path: 'validations',
      component: CampaignsValidations,
      children: [
        {
          path: '',
          name: 'campaigns-show-validations-summary',
          component: CampaignsValidationsSummary,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'duplicated',
          name: 'campaigns-show-validations-duplicated',
          component: CampaignsValidationsDuplicated,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'validation-robinson',
          name: 'campaigns-show-validations-validationRobinson',
          component: CampaignsValidationsRobinson,
          meta: {
            auth: true,
            permit: ['MANAGE_VALIDATIONS']
          }
        },
        {
          path: 'robinson-list',
          name: 'campaigns-show-validations-listRobinson',
          component: CampaignsRobinsonList,
          meta: {
            auth: true,
            permit: ['MANAGE_VALIDATIONS']
          }
        },
        {
          path: 'validation-black-list',
          name: 'campaigns-show-validations-validationBlackList',
          component: CampaignsValidationsBlackList,
          meta: {
            auth: true,
            permit: ['MANAGE_VALIDATIONS']
          }
        },
        {
          path: 'black-list',
          name: 'campaigns-show-validations-blackList',
          component: CampaignsBlackList,
          meta: {
            auth: true,
            permit: ['MANAGE_VALIDATIONS']
          }
        },
        {
          path: 'client-portfolio',
          name: 'campaigns-show-validations-validationClientPortfolio',
          component: CampaignsValidationsClientPortfolio,
          meta: {
            auth: true,
            permit: ['MANAGE_VALIDATIONS']
          }
        },
        {
          path: 'client-portfolio-list',
          name: 'campaigns-show-validations-clientPortfolioList',
          component: CampaignsClientPortfolioList,
          meta: {
            auth: true,
            permit: ['MANAGE_VALIDATIONS']
          }
        },
        {
          path: 'treatment',
          name: 'campaigns-show-validations-treatment',
          component: CampaignsValidationsDataTreatment,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        }
      ]
    },
    {
      path: 'analytics-model',
      component: AnalyticsModel,
      children: [
        {
          path: '',
          name: 'campaigns-show-analytics-model-matrix-model',
          component: matrixModel,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        },
        {
          path: 'rules-model',
          name: 'campaigns-show-analytics-model-rules-model',
          component: rulesModel,
          meta: {
            auth: true,
            permit: ['MANAGE_CONFIG']
          }
        }
      ]
    }
  ]
}
