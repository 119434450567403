export default {
  errorsRootKey: 'users.fields',
  resource: 'users',
  actions: ['show', 'update', 'create', 'destroy'],

  masiveInvite(data, options) {
    const finalOptions = {...options, skipParseErrors: true}
    return Vue.http.post('invitations/massive', {data}, finalOptions)
  },

  indexByRole(roleId, params = {}, options = {}) {
    return Vue.http.get(
      `users/by-role/${roleId}`,
      {params, ...options}
    ).then(response => response.data) // el data es: {data: x, meta: y}
  },

  indexAssignToCampaign(params = {}, options = {}) {
    return Vue.http.get(
      'users/assign-to-campaign',
      {params, ...options}
    ).then(response => response.data) // el data es: {data: x, meta: y}
  }
}
