import camelCase from 'lodash/camelCase'

const files = require.context('./api', false, /\.js$/)

// Obtiene el nombre de un archivo js
const basename = (path, extension = true) => {
  const name = path.split(/[\\/]/).pop()

  if (!extension) {
    return name.split('.').shift()
  }

  return name
}  

const getActionMethod = (action, service) => {
  switch (action) {
    case 'show':
      return (id, options) => Vue.http.get(
          `${service.resource}/${id}`,
          options
        ).then(
          response => response.data.data
        )

    case 'index':
      return (params = {}, options = {}) => Vue.http.get(
          service.resource,
          {params, ...options}
        ).then(
          response => response.data // el data es: {data: x, meta: y}
        )

    case 'create':
      return (data = {}, options = {}) => Vue.http.post(
          service.resource,
          data,
          {errorsRootKey: service.errorsRootKey, ...options}
        ).then(
          response => response.data,
          response => { throw response.data.errors }
        )

    case 'update':
      return (id, data = {}, options = {}) => Vue.http.put(
          `${service.resource}/${id}`,
          data,
          {errorsRootKey: service.errorsRootKey, ...options}
        ).then(
          response => response.data,
          response => { throw response.data.errors }
        )

    case 'patch':
      return (id, data = {}, options = {}) => Vue.http.patch(
          `${service.resource}/${id}`,
          data,
          {errorsRootKey: service.errorsRootKey, ...options}
        ).then(
          response => response.data,
          response => { throw response.data.errors }
        )

    case 'destroy':
      return (id, options) => Vue.http.delete(
          `${service.resource}/${id}`,
          options
        )

    default:
      return null
  }
}

const processApiService = service => {
  if (!service?.actions) return service

  service.actions.forEach(action => {
    service[action] = getActionMethod(action, service)
  })

  return service
}

const services = {}

files.keys().forEach(key => {
  const filename = camelCase(basename(key, false))

  services[filename] = processApiService(files(key).default)
})

// Exporta los servicios de forma automatica en funcion del nombre de los archivos de forma que si
// se crea el archivo "./api/consultation-type.js" se exportara como "consultationType"
export default services
