export default {
  errorsRootKey: 'pool-numbers',
  resource: 'calltrackings/pool-numbers',
  actions: ['index', 'show', 'create', 'update', 'destroy'],
  trackingNumber: {
    get(id, params = {}, options = {}) {
      return Vue.http.get(
        `CallTrackings/pool-numbers/${id}/TrackingNumbers`,
        {params, ...options}
      ).then(response => response.data)
    },
    executeAdvancedAction(id, data, options = {}) {
      return Vue.http.put(
        `CallTrackings/pool-numbers/${id}/TrackingNumbers`,
        data,
        {errorsRootKey: 'TrackingNumbers', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    executeFastAction(id, action, data, options = {}) {
      return Vue.http.post(
        `CallTrackings/pool-numbers/${id}/TrackingNumbers/execute/${action}`,
        data,
        {errorsRootKey: 'TrackingNumbers', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  }
}
