import {cloneDeep} from 'lodash'
import LaiaColorPicker from 'js/components/laia-color-picker'
import Fields from './_fields.js'

import template from './_form-form.pug'

export default Vue.extend({
  template: template(),
  components: {
    LaiaColorPicker,
    Fields
  },
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ready: false,
      showPrivacy: false,
      data: {
        fieldsData: [],
        fieldsError: [],
        callTracking: {},
        formPolicy: {}
      }
    }
  },
  computed: {
    maskPhoneOptions() {
      return [
        {value: '### ### ###', label: '### ### ###'},
        {value: '(###) ### ###', label: '(###) ### ###'}
      ]
    }
  },
  watch: {
    showPrivacy(newValue) {
      if (!newValue) {
        this.data.formPolicy.label = null
        this.data.formPolicy.link = null
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      // formPolicy puede ser un objeto o un booleano
      this.showPrivacy = !!this.initData.formPolicy

      const data = cloneDeep(this.initData)
      if (!data.callTracking) data.callTracking = {}
      if (!data.formPolicy) data.formPolicy = {}

      this.data = data
      this.fieldsData = this.data.fields
      this.ready = true
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.data.fields = this.fieldsData
      this.$emit('submit', this.data)
    },

    t(key, options = {}) {
      return this.$t(`forms.form.${key}`, options)
    },

    onlyPositiveInt(oldVal, newVal) {
      return newVal.trim().match(/^[0-9]*$/) ? newVal : oldVal
    }
  }
})
