import cloneDeep from 'lodash/cloneDeep'
import template from './_form.pug'

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      validator: value => ['new', 'edit'].includes(value)
    }
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {

    saveButtonText() {
      return this.t(`form.saveButton.${this.mode}`)
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.loadData()
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {

    loadData() {
      this.data = cloneDeep(this.initData)
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.$emit('submit', this.data)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.analyticsModel.tabs.matrixModel.${key}`, options)
    }
  }
})
