export default {
  errorsRootKey: 'customers',
  resource: 'customers',
  actions: ['index', 'show', 'create', 'update', 'patch', 'destroy'],
  campaigns: {
    index(customerId, params = {}, options = {}) {
      return Vue.http.get(
        `customers/${customerId}/campaigns`,
        {params},
        {options}
      ).then(response => response.data)
    },
    sites(customerId, params = {}, options = {}) {
      return Vue.http.get(
        `customers/${customerId}/campaignSites`,
        {params},
        {options}
      ).then(response => response.data)
    }
  },
  provider(params = {}, options = {}) {
    const url = params.campaignId ?
        `campaigns/${params.campaignId}/providers` :
        `customers/${params.customerId}/providers`

      delete params.campaignId
      delete params.customerId

    return Vue.http.get(
      url,
      {params},
      {options}
    ).then(response => response.data)
  },
  aibe: {
    campaing(instance, params = {}, options = {}) {
      return Vue.http.get(
        `aibe/${instance}/campaigns`,
        {params},
        {options}
      ).then(response => response.data)
    },
    wizard(data, options = {}) {
      return Vue.http.post(
        'aibe/wizard',
        data,
        {errorsRootKey: 'wizard', ...options}
      ).then(response => response.data)
    }
  }
}
