import FullTable from 'js/components/full-table/index.js'
import template from './table-model.pug'

export default Vue.extend({
  name: 'table-model',
  template: template(),
  components: {
    FullTable
  },
  props: {
    items: {
        type: Array,
        required: true
    }
  },
  data() {
    return {
      order: {
        orderBy: null,
        orderDir: null
      },
      data: []
    }
  },
  watch: {
    items() {
      this.setData()
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.data = []
      const aux = category => ({
        category,
        leads: 0,
        unitSales: 0,
        daySales: 0,
        contactability: 0,
        nTurns: 0
      })
      const category = ['a', 'b', 'c', 'd']
      const data = category.map(c => this.items.reduce((acc, curr) => {
          acc.leads += curr[`${c}LeadsCount`]
          acc.unitSales += (curr[`${c}SalesCount`])
          acc.daySales += curr[`${c}SalesSameDayCount`]
          acc.contactability += curr[`${c}ContactsCount`]
          acc.nTurns += curr[`${c}nTurnsCount`]
          return acc
        }, aux(c)))
      data.forEach(c => {
        c.unitSales = (c.unitSales * 100) / c.leads
        c.daySales = (c.daySales * 100) / c.leads
        c.contactability = (c.contactability * 100) / c.leads
      })
      this.data = data
    },
    changeOrder(params) {
      const filter = Object.keys(params.order)[0]
      this.order.orderBy = filter
      this.order.orderDir = params.order[filter]
      this.applyOrder()
    },
    applyOrder() {
      const dir = this.order.orderDir === 'asc' ? 1 : -1
      if (this.order.orderBy && this.order.orderDir) {
        this.data = this.data.sort((a, b) => {
          const x = a[this.order.orderBy]
          const y = b[this.order.orderBy]
          if (x < y) return dir * -1
          if (x > y) return dir
          return 0
        })
      }
    },
    t(key, params = {}) {
      return this.$i18n.t(`analytics.index.modelCategories.${key}`, params)
    }
  }
})
