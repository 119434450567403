import OrderTable from './_table.js'
import template from './index.pug'

import {generateMonthStructureForOffer} from '../demand-table/helpers'

export default Vue.extend({
  template: template(),
  components: {
    OrderTable
  },
  props: {
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      offers: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('leadtrade.order.index.title'))
    this.$setBreadcrumbs([
      {
        label: this.$t('breadcrumbs.offer-and-demand.index'),
        route: {name: 'offer-and-demand-index'}
      },
      {label: this.$t('breadcrumbs.order.index'), route: {}}
    ])
  },
  methods: {
    loadOffers(queryOptions = {}) {
      API.offerPurchase.index(queryOptions).then(({data, meta}) => {
        this.offers = generateMonthStructureForOffer(data).offers
        this.meta = meta
      })
    }
  }
})
