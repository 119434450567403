export default {
  state: {
    fieldTypes: []
  },
  getters: {
    fieldTypes: state => state.fieldTypes,

    findFieldTypeById(_state, getters) {
      return id => getters.fieldTypes.find(
        fieldType => fieldType.id === id
      )
    },

    findFieldTypeByKey(_state, getters) {
      return key => getters.fieldTypes.find(
        fieldType => fieldType.key === key
      )
    }
  },
  mutations: {
    setFieldTypes(state, fieldTypes) {
      state.fieldTypes = fieldTypes
    }
  },
  actions: {
    loadFieldTypes({commit}) {
      return API.fieldTypes.index().then(data => {
        commit('setFieldTypes', data.data)
        return data
      })
    }
  }
}
