import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './sub-workflow.pug'

const DefaultData = () => ({
  name: '',
  config: {
    automationId: ''
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      automations: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    automationSelectOptions() {
      return this.automations.map(automation => ({
        label: automation.name,
        value: automation.id
      }))
    },
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
        this.getAutomations()
      }
    }
  },
  methods: {
    getAutomations() {
      return API.automations.index(this.campaign.id).then(({data}) => {
        this.automations = this.$sortByLocale(data, 'name')
        console.log(this.automations)
      })
    },


    save() {
      validatePresence(this.data, [
        'name',
        'config.automationId'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.subWorkflow.${key}`, options)
    }
  }
})
