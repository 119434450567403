import FullTable from 'js/components/full-table/index.js';
import { openModalConfirmRemoveWithPrompt, openModalAlert } from 'js/services/modals.js';
import ModalPixelControl from './_modal-pixel-control.js';
import PlayAudios from './_play-audios.js';

import template from './_table.pug';

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    ModalPixelControl,
    PlayAudios,
  },
  props: {
    audiosMenuVoice: {
      type: Array,
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
      showFilters: true,
      filters: { name: '', audioFileName: '' },
      lastQueryOptions: null,
      pixelControlAudiosMenuVoice: null,
      selectedAudio: null,
      customers: [],
      currentTab: 'audio-url',
    };
  },
  computed: {
    showAudioPanel() {
      return !!this.selectedAudio;
    },
    canManage() {
      return this.$user.permissions.includes('MANAGE_CALL_CENTERS');
    },
    defaultOrder() {
      return { currentField: 'createdAt', currentDir: 'asc' };
    },
    filterButtonParams() {
      return this.showFilters ? { class: 'active', icon: 'angle-up' } : { class: '', icon: 'angle-down' };
    },
    canViewPixelControl() {
      return this.$user.can('VIEW_PIXEL_CONTROL');
    },
    customerOptions() {
      return this.customers.map((customer) => ({
        label: customer.name,
        value: customer.id,
      }));
    },
  },
  created() {
    this.getCustomers();
  },
  watch: {
    audiosMenuVoice: {
      immediate: true,
      handler() {
        this.ready = true;
      },
    },
  },
  methods: {
    getCustomers() {
      API.customers.index({ page: { number: 1, size: 1000 } }).then(({ data }) => {
        this.customers = data;
      });
    },
    playAudio(url) {
      const audio = new Audio(url);
      audio.play();
      audio.addEventListener('ended', () => {
      });
    },
    setCustomerName(id) {
      let customerData = this.customers.find((el) => el.id === id);
      return customerData ? customerData.name : '';
    },
    openConfirmModal(audiosMenuVoice) {
      openModalConfirmRemoveWithPrompt(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', { name: audiosMenuVoice.name }),
        this.t('confirmationModal.remove.okButton'),
        this.t('confirmationModal.remove.promptHelp')
      ).then(({ data }) => {
        if (data !== 'yes') {
          openModalAlert(
            this.t('confirmationModal.cancel.title'),
            this.t('confirmationModal.cancel.body'),
            this.t('confirmationModal.cancel.okButton')
          );
        } else {
          this.destroy(audiosMenuVoice);
        }
      });
    },
    t(key, options = {}) {
      return this.$t(`audiosMenuVoice.index.${key}`, options);
    },
    destroy(object) {
      API.audiosMenuVoice.destroy(object.id).then(() => {
        this.getData();
        this.$notifications.success(this.t('succesfullyDeleted'));
      });
    },
    getData(queryOptions) {
      this.$emit('load-data', queryOptions || this.lastQueryOptions);
      if (queryOptions) this.lastQueryOptions = queryOptions;
    },
    getClassByStatus(status) {
      switch (status) {
        case CUSTOMER_STATUSES.ACTIVE:
          return 'table-tag--success';
        case CUSTOMER_STATUSES.INACTIVE:
          return 'table-tag--gray';
        case CUSTOMER_STATUSES.NO_CAMPAIGNS:
          return 'table-tag--yellow-light';
        default:
          return '';
      }
    },
    getIconByStatus(status) {
      switch (status) {
        case CUSTOMER_STATUSES.ACTIVE:
          return 'check';
        case CUSTOMER_STATUSES.INACTIVE:
          return 'close';
        case CUSTOMER_STATUSES.NO_CAMPAIGNS:
          return 'ban';
        default:
          return '';
      }
    },
    noHaveCampaigns(status) {
      return status === CUSTOMER_STATUSES.NO_CAMPAIGNS;
    },
    showPixelControlIcon(status) {
      return [CUSTOMER_STATUSES.ACTIVE, CUSTOMER_STATUSES.INACTIVE, CUSTOMER_STATUSES.FINISHED].includes(status) && this.canViewPixelControl;
    },
    openPixelControlModal(row) {
      this.pixelControlAudiosMenuVoice = row;
    },
    showAudioIcon(status) {
      return [CUSTOMER_STATUSES.ACTIVE, CUSTOMER_STATUSES.INACTIVE, CUSTOMER_STATUSES.FINISHED].includes(status);
    },
    openAudioPanel(row) {
      this.selectedAudio = row;
    },
    closeAudioPanel() {
      this.selectedAudio = null;
    },
  },
});
