import UserIndex from '../_index.js'

import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    UserIndex
  }
})
