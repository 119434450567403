import cloneDeep from 'lodash/cloneDeep'
import template from './_fields.pug'
import FieldForm from './_field-form.js'

export default Vue.extend({
  template: template(),
  components: {
    FieldForm
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    initErrors: {
      type: [Array, String],
      default: () => []
    }
  },
  data() {
    return {
      data: [],
      errors: [],
      fieldRequiredError: false
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (JSON.stringify(newValue) === JSON.stringify(this.data)) return

        this.data = cloneDeep(newValue)
      }
    },

    initErrors: {
      deep: true,
      immediate: true,
      handler() {
        // gestionamos el error: "fields: required"
        if (this.initErrors instanceof Array) {
          this.errors = cloneDeep(this.initErrors)
          this.fieldRequiredError = false
        } else {
          this.errors = []
          this.fieldRequiredError = true
        }
      }
    },

    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler() {
        this.$emit('input', [...this.data])
      }
    }
  },
  methods: {
    addNewField() {
      this.data.push(FieldForm.options.defaultValues())
    },

    removeField(index) {
      this.data.splice(index, 1)
      this.errors.splice(index, 1)
    },

    t(key, options = {}) {
      return this.$t(`forms.formField.${key}`, options)
    }
  }
})
