import template from './new.pug'

export default Vue.extend({
  template: template(),
  props: {
    customers: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        customerId: null,
        page: null,
        auth_code: null
      },
      form_default: {
        customerId: null,
        page: null,
        auth_code: null
      },
      tiktok: {
        login: null,
        pages: null
      },
      objectEmpty: {
        pageId: null,
        pageName: null,
        accessToken: null,
        advertiserId: null
      },
      errors: {}
    }
  },
  computed: {
    ButtonParams() {
      if (this.tiktokisConnect) {
        return {class: 'active', icon: 'close', text: this.t('disconnect'), type: 'negative'}
      }
      return {class: '', icon: '', text: this.t('connect'), type: 'primary'}
    },
    tiktokisConnect() {
      return this.tiktok.login?.status === 'connected'
    },
    inputError() {
      return {
        page: this.errors?.tiktokSetting || null
      }
    },
    pagesOption() {
      if (!this.tiktok.pages) return []
      return this.tiktok.pages.map(item => ({
        value: item.advertiserId,
        label: item.pageName
      }))
    },
    pageSelected() {
      const pageSelected = this.tiktok.pages
        ?.find(({advertiserId}) => advertiserId === this.form.page)
      return pageSelected
    }
  },
  mounted() {
    this.checkLoginStatus()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.tiktok.${key}`, options)
    },
    savePage() {
      const data = {
        id: null,
        customerId: this.customers.id,
        tiktokSetting: this.pageSelected
      }
      API.tiktok.create(data).then(() => {
        this.$notifications.success(this.t('new.succesfullyCreated'))
        this.errors = {}
        this.form = {...this.form_default}
        this.$emit('load-data')
      }, errors => {       
        this.errors = errors
      })
    },
    getPages() {
      const {auth_code} = this.$route.query
      this.form.auth_code = auth_code
      if (MOCKS) {
        this.form.auth_code = 'auth_code'
      }
      API.tiktok.pages(this.form.auth_code).then(({data}) => {
        this.tiktok.pages = data
      })
    },
    connectTikTok() {
      const location = `${window.location.origin }/tiktok/auth`
      const state = this.customers.id
      // eslint-disable-next-line max-len
      const href = `https://business-api.tiktok.com/portal/auth?app_id=7284075320846581761&state=${state}&redirect_uri=${location}`
      const params = null
      const win = window.open(href, 'auth', params)
      win.focus()
    },
    disconnectTiktok() {
      this.tiktok.login = null
      this.tiktok.pages = null
    },
    checkLoginStatus() {
      const query = this.$route.query
      if (query?.auth_code && query?.code) {
        this.tiktok.login = {
          status: 'connected'
        }
        this.getPages()
      }
    }
  }
})
