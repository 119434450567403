export default {
  errorsRootKey: 'leads-file',

  import(id, data, options = {}) {
    return Vue.http.post(
      `leads-file/${id}/import`,
      data,
      {errorsRootKey: 'leads-file', ...options}
    ).then(
      response => response.data,      
    )
  },

  templates: {
    index(params = {}, options = {}) {
      return Vue.http.get(
        'leads-file/templates',
        {params, ...options}
      ).then(response => response.data)
    },

    show(id, options = {}) {
      return Vue.http.get(
        `leads-file/templates/${id}`,
        options
      ).then(
        response => response.data.data
      )
    },

    update(id, data, options = {}) {
      return Vue.http.put(
        `leads-file/templates/${id}`,
        data,
        {errorsRootKey: 'leads-file.templates', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    create(data, options = {}) {
      return Vue.http.post(
        'leads-file/templates',
        data,
        {errorsRootKey: 'leads-file.templates', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(id) {
      return Vue.http.delete(`leads-file/templates/${id}`)
    }
  }
}
