// Funcion para generar rango de fechas
const range = (start, stop, step) => Array
  .from({length: (stop - start) / step + 1}, (_, i) => (
    {value: start + (i * step), label: start + (i * step)}
  ))

const currentYear = (new Date()).getFullYear()

const lastTenYears = () => range(currentYear, currentYear + 10, 1)

const months = () => [
  {
    month: 'january',
    value: 1,
    label: Vue.i18n.t('month.january')
  },
  {
    month: 'february',
    value: 2,
    label: Vue.i18n.t('month.february')
  },
  {
    month: 'march',
    value: 3,
    label: Vue.i18n.t('month.march')
  },
  {
    month: 'april',
    value: 4,
    label: Vue.i18n.t('month.april')
  },
  {
    month: 'may',
    value: 5,
    label: Vue.i18n.t('month.may')
  },
  {
    month: 'june',
    value: 6,
    label: Vue.i18n.t('month.june')
  },
  {
    month: 'july',
    value: 7,
    label: Vue.i18n.t('month.july')
  },
  {
    month: 'august',
    value: 8,
    label: Vue.i18n.t('month.august')
  },
  {
    month: 'september',
    value: 9,
    label: Vue.i18n.t('month.september')
  },
  {
    month: 'octuber',
    value: 10,
    label: Vue.i18n.t('month.octuber')
  },
  {
    month: 'november',
    value: 11,
    label: Vue.i18n.t('month.november')
  },
  {
    month: 'december',
    value: 12,
    label: Vue.i18n.t('month.december')
  }
]

export {months, lastTenYears}
