export default {
  errorsRootKey: 'trackingSources',
  resource: 'calltrackings/tracking-numbers',
  actions: ['index', 'show', 'create', 'update', 'destroy'],
  available(params = {}, options = {}) {
    return Vue.http.get(
      'calltrackings/tracking-numbers/available',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  request(data, options = {}) {
    return Vue.http.post(
      'calltrackings/tracking-numbers/request',
      data,
      {errorsRootKey: 'request', ...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  },
  availableToTargetNumber(targetNumberId, params = {}, options = {}) {
    return Vue.http.get(
      `calltrackings/tracking-numbers/availableToTargetNumber/${targetNumberId}`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  availableToTrackingSource(params = {}, options = {}) {
    return Vue.http.get(
      'calltrackings/tracking-numbers/availableToTrackingSource',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  }
}
