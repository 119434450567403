import cloneDeep from 'lodash/cloneDeep'

import template from './_cdimension-form.pug'

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      validator: value => ['new', 'edit'].includes(value)
    }
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
    scopesSelectOptions() {
      return Object.keys(CDIMENSIONS.SCOPE).map(key => ({
        label: this.$t(`cdimensionsScopes.${key}`),
        value: CDIMENSIONS.SCOPE[key]
      }))
    },

    sourceLocationsSelectOptions() {
      return Object.keys(CDIMENSIONS.SOURCE_LOCATION).map(key => ({
        label: this.$t(`cdimensionsSourceLocations.${key}`),
        value: CDIMENSIONS.SOURCE_LOCATION[key]
      }))
    },

    fieldTypesSelectOptions() {
      return Object.keys(FIELD_TYPES).map(key => ({
        label: this.$t(`fieldTypes.${key}`),
        value: FIELD_TYPES[key]
      }))
    },

    leadFieldsSelectOptions() {
      const options = Object.keys(LEAD_FIELDS).map(key => ({
        label: this.$t(`leadFields.${key}`),
        value: LEAD_FIELDS[key]
      }))

      options.push({
        label: this.$t('leadFields.other'),
        value: ''
      })

      return options
    },

    title() {
      return this.t(`${this.mode}Title`)
    },

    saveButtonText() {
      return this.t(`${this.mode}Button`)
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.data = cloneDeep(this.initData)
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.$emit('submit', this.data)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.cdimensions.${key}`, options)
    }
  }
})
