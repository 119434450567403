import pick from 'lodash/pick'

import AnalyticsChart from 'js/components/analytics/chart'
import Filters from 'js/components/analytics/filters.js'
import statusCountFunnel from 'js/components/analytics/leads-report-funnel'
import salesByTargetPie from 'js/components/analytics/sales-by-target-pie'
import AnalyticsUtils from 'js/services/analytics-utils'
import ItemReportTable from 'js/components/analytics/item-report-table'

import template from './tracking-tab.pug'

export default Vue.extend({
  name: 'tracking-tab',
  template: template(),
  components: {
    Filters,
    ItemReportTable,
    AnalyticsChart
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      campaignData: null,
      itemGlobalData: null,
      sitesReportData: null,
      leadsReportByUtmMediumData: null, // Canal UTM
      leadsReportByUtmSourceData: null, // Proveedor UTM
      leadsReportByUtmCampaignData: null, // Campaña UTM
      leadsReportByCtaTypeData: null, // CtaType
      leadsReportByLandingData: null, // Landing
      leadsReportByProductIdData: null, // ProductId
      globalReport: null,
      filters: {},
      filtersInfo: {}
    }
  },
  computed: {
    targetTypeOptions() {
      const targetTypes = [
        'general', 'utmMedium', 'utmSource', 'utmCampaign', 'ctaType', 'product', 'landing'
      ]
      return targetTypes.filter(
        target => Vue.account.has(`campaign_tracking_${target}`)
      )
    },
    funnelChartOptions() {
      if (!this.campaignData) return null

      return statusCountFunnel.options(pick(this.campaignData, [
        'visitsCount', // total de visitas
        'leadsCount', // leads Netos
        'totalCount', // total de leads
        'utilsCount', // válidos para poder llamar (prospectos)
        'convertedCount',
        'registratedCount'
      ]), 'analytics.charts.generationFunnel')
    },

    // cuando se filtra por un canal, el resto de canales se agrupan
    groupedLeadsReportByUtmMediumData() {
      return this.groupedLeadsReportByTarget('UtmMedium')
    },

    // cuando se filtra por un source, el resto de canales se agrupan
    groupedLeadsReportByUtmSourceData() {
      return this.groupedLeadsReportByTarget('UtmSource')
    },

    isSource() {
      return this.filtersInfo.targetType === 'utmSource'
    },

    isUtmCampain() {
      return this.filtersInfo.targetType === 'utmCampaign'
    },

    isUtmMedium() {
      return this.filtersInfo.targetType === 'utmMedium'
    },

    investmentValue() {
      if (this.isGlobalStudyItem && this.campaignData != null) return this.campaignData.investment
      if (
        !this.isGlobalStudyItem && this.itemGlobalData != null
      ) return this.itemGlobalData.investment
      return this.filtersInfo.investment
    },

    groupedLeadsReportByUtmCampaignData() {
      return this.groupedLeadsReportByTarget('UtmCampaign')
    },

    groupedLeadsReportByCtaTypeData() {
      return this.groupedLeadsReportByTarget('CtaType')
    },

    groupedLeadsReportByLandingData() {
      return this.groupedLeadsReportByTarget('Landing')
    },

    groupedLeadsReportByProductIdData() {
      return this.groupedLeadsReportByTarget('ProductId')
    },

    salesByUtmMediumChartOptions() {
      return this.salesByTargetChartOptions('UtmMedium')
    },

    salesByUtmSourceChartOptions() {
      return this.salesByTargetChartOptions('UtmSource')
    },

    salesByUtmCampaignChartOptions() {
      return this.salesByTargetChartOptions('UtmCampaign')
    },

    salesByCtaTypeChartOptions() {
      return this.salesByTargetChartOptions('CtaType')
    },

    salesByLandingChartOptions() {
      return this.salesByTargetChartOptions('Landing')
    },

    salesByProductIdChartOptions() {
      return this.salesByTargetChartOptions('ProductId')
    },

    // inversión / ventas de cada canal
    cpaByUtmMediumChartOptions() {
      return this.cpaByTargetChartOptions('UtmMedium')
    },

    // inversión / ventas de cada utm source
    cpaByUtmSourceChartOptions() {
      return this.cpaByTargetChartOptions('UtmSource')
    },

    // inversión / ventas de cada utm source
    cpaByUtmCampaignChartOptions() {
      return this.cpaByTargetChartOptions('UtmCampaign')
    },

    // inversión / ventas de cada tipo de cta
    cpaByCtaTypeChartOptions() {
      return this.cpaByTargetChartOptions('CtaType')
    },

    // inversión / ventas de cada tipo de cta
    cpaByLandingChartOptions() {
      return this.cpaByTargetChartOptions('Landing')
    },

    // inversión / ventas de cada producto
    cpaByProductIdChartOptions() {
      return this.cpaByTargetChartOptions('ProductId')
    },

    isGlobalStudyItem() {
      return !this.filtersInfo.targetName
    },

    isGlobalReport() {
      return this.globalReport != null
    },

    // cuando aparece el segundo panel del item de estudio, pasamos de 4 a 3 columnas
    generalColumnsClass() {
      return this.isGlobalStudyItem ? 'col-3-lg' : 'col-4-lg'
    },

    targetType() {
      // Si el target seleccionado es producto, entonces retornamos productId
      return this.filtersInfo.targetType === 'product' ? 'productId' : this.filtersInfo.targetType
    },

    campaignHasData() {
      return AnalyticsUtils.checkObjectValues(this.campaignData, 'campaignId')
    },

    itemGlobalHasData() {
      return AnalyticsUtils.checkObjectValues(this.itemGlobalData, 'campaignId')
    }
  },
  methods: {
    applyFilters(filters, info) {
      // los ponemos a null para hacer que aparezca el cargando
      this.campaignData = null
      this.itemGlobalData = null
      this.globalReport = null
      this.sitesReportData = null
      this.leadsReportByUtmMediumData = null
      this.leadsReportByUtmSourceData = null
      this.leadsReportByCtaTypeData = null
      this.leadsReportByLandingData = null
      this.leadsReportByProductIdData = null

      this.filters = {...filters, campaignId: this.campaign.id}
      this.filtersInfo = info
      // TODO: eliminar cuando se terminen las pruebas
      setTimeout(() => {
        this.loadFunnelData()
        if (!this.isGlobalStudyItem) this.loadItemGlobalData()
        this.loadSiteVisits()

        if (this.filters[this.targetType] === 'selected-all-values-options') {
          this.loadGlobalReport()
        } else {
          // evitamos la petición cuando no se muestran las gráficas
          if (this.isGlobalStudyItem || this.filters.utmMedium) this.loadsalesByUtmMedium()
          if (this.isGlobalStudyItem || this.filters.utmSource) this.loadsalesByUtmSource()
          if (this.isGlobalStudyItem || this.filters.utmCampaign) this.loadsalesByUtmCampaign()
          if (this.isGlobalStudyItem || this.filters.ctaType) this.loadsalesByCtaType()
          if (this.isGlobalStudyItem || this.filters.landing) this.loadsalesByLanding()
          if (this.isGlobalStudyItem || this.filters.productId) this.loadsalesByProductId()
        }
      }, MOCKS ? 500 : 0)
    },

    loadFunnelData() {
      // Este solo se ve afectado por estos filtros
      const filters = pick(this.filters, ['from', 'to', 'campaignId'])

      API.analytics.statusCount(this.campaign.customerId, filters).then(({data}) => {
         this.campaignData = {...data}
      })
    },

    loadGlobalReport() {
      let filters = pick(this.filters, ['from', 'to', 'campaignId'])
      filters = {
        ...filters,
        typeItem: this.targetType
      }
      API.analytics.globalReport(this.campaign.customerId, filters).then(({data}) => {
        this.globalReport = data
      })
    },

    loadItemGlobalData() {
      // Este solo se ve afectado por estos filtros
      const filters = pick(this.filters, [
        'from', 'to', 'campaignId', 'utmMedium', 'utmSource', 'utmCampaign', 'ctaType',
        'productId', 'landing', 'agentId'
      ])

      API.analytics.statusCount(this.campaign.customerId, filters).then(({data}) => {
        this.itemGlobalData = data
      })
    },

    loadSiteVisits() {
      API.analytics.sitesReport(this.campaign.customerId, this.filters)
        .then(({data}) => { this.sitesReportData = data })
    },

    loadsalesByUtmMedium() {
      API.analytics.leadsReportByUtmMedium(this.campaign.customerId, this.filters)
        .then(({data}) => { this.leadsReportByUtmMediumData = data })
    },

    loadsalesByUtmSource() {
      API.analytics.leadsReportByUtmSource(this.campaign.customerId, this.filters)
        .then(({data}) => { this.leadsReportByUtmSourceData = data })
    },

    loadsalesByUtmCampaign() {
      API.analytics.leadsReportByUtmCampaign(this.campaign.customerId, this.filters)
        .then(({data}) => { this.leadsReportByUtmCampaignData = data })
    },

    loadsalesByCtaType() {
      API.analytics.leadsReportByCtaType(this.campaign.customerId, this.filters)
        .then(({data}) => { this.leadsReportByCtaTypeData = data })
    },

    loadsalesByLanding() {
      API.analytics.leadsReportByLanding(this.campaign.customerId, this.filters)
        .then(({data}) => { this.leadsReportByLandingData = data })
    },

    loadsalesByProductId() {
      // esta gráfica no se filtra por producto
      const params = {...this.filters, productId: undefined}

      API.analytics.salesByProduct(this.campaign.customerId, params)
      .then(({data}) => { this.leadsReportByProductIdData = data })
    },

    cpl: AnalyticsUtils.cpl,
    cpa: AnalyticsUtils.cpa,
    cr: AnalyticsUtils.cr,

    t(key, params = {}) {
      return this.$i18n.t(`analytics.charts.${key}`, params)
    },

    getTargetTitle(graphic, specificStudyItem = false) {
      return this.t(
        `${graphic}${!this.isGlobalStudyItem && specificStudyItem ? '.targetFilter' : ''}.title`,
        {target: this.filtersInfo.subtargetName || this.filtersInfo.targetName}
      )
    },

    // target: ['UtmMedium', 'UtmSource', 'UtmCampaign', 'CtaType', 'ProductId', 'Landing']
    groupedLeadsReportByTarget(target) {
      const data = this[`leadsReportBy${target}Data`]
      const filter = this.filters[target[0].toLowerCase() + target.slice(1)]

      if (!data) return []

      if (filter) {
        return AnalyticsUtils.groupOtherPie(data, target === 'ProductId' ? 'id' : 'key', filter)
      }

      return data
    },

    // target: ['UtmMedium', 'UtmSource', 'UtmCampaign', 'CtaType', 'ProductId', 'Landing']
    salesByTargetChartOptions(target) {
      const data = this[`groupedLeadsReportBy${target}Data`]

      if (!data) return null

      return salesByTargetPie.options(data, `analytics.charts.salesBy${target}Pie`)
    },

    // target: ['UtmMedium', 'UtmSource', 'UtmCampaign', 'CtaType', 'ProductId', 'Landing']
    cpaByTargetChartOptions(target) {
      let data = this[`groupedLeadsReportBy${target}Data`]
      if (!data) return null
      data = data.map(item => {
        let val = 0
        if (item.convertedCount === 0) {
          val = 0
        } else if (this.isGlobalStudyItem && this.campaignData != null) {
          val = (this.campaignData.investment || 0) / item.convertedCount
        } else if (!this.isGlobalStudyItem && this.itemGlobalData != null) {
          val = (this.itemGlobalData.investment || 0) / item.convertedCount
        } else {
          val = (this.filtersInfo.investment || 0) / item.convertedCount
        }
        return {...item, convertedCount: parseFloat(val.toFixed(2))}
      })
      data = data.filter(({convertedCount}) => convertedCount > 0)
      return salesByTargetPie.options(
        data,
        `analytics.charts.cpaBy${target}Pie`
      )
    }
  }
})
