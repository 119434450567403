import pick from 'lodash/pick'
import FullTable from 'js/components/full-table/index.js'
import AnalyticsUtils from 'js/services/analytics-utils'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    leads: {
      type: Array,
      required: true
    },
    customers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      showFilters: true,
      filters: {phone: '', from: '', to: '', customerId: ''},
      lastQueryOptions: null, // guarda los últimos filtros aplicados
      errorsMapping: {
        fromDate: '',
        toDate: '',
        customer: '',
        errores: 0
      },
      firstLoad: true,
      clear: false
    }
  },
  computed: {
    fromDateConfig() {
      return {
        ...this.dateConfig
      }
    },
    toDateConfig() {
      return {
        ...this.dateConfig
      }
    },

    defaultOrder() {
      return {currentField: 'fullname', currentDir: 'asc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },
    customersOptions() {
      return this.customers.map(customer => ({
        label: customer.name,
        value: customer.id
      }))
    },
    nsFieldFrom() {
      return this.errorsMapping.fromDate
    },
    nsFieldTo() {
      return this.errorsMapping.toDate
    }
  },
  methods: {
    getData(queryOptions) {
      if (this.firstLoad) {
        this.firstLoad = false
      } else {
        if (this.firstLoad === false &&
          this.clear === true &&
          this.$refs.filterTable.appliedFilters === false) {
          this.errorsMapping = {
            fromDate: '',
            toDate: '',
            customer: '',
            errores: 0
          }
          this.clear = false
          this.$parent.leads = []
        } else {
          this.errorsMapping = this.checkFilters(queryOptions)
          if ('filter' in queryOptions) {
            queryOptions = this.formatFilters(queryOptions)
          }
          if (this.errorsMapping.errores === 0) {
            this.$emit('load-data', queryOptions || this.lastQueryOptions)
            this.clear = true
          }
        }

        if (queryOptions) this.lastQueryOptions = queryOptions
      }
    },
    checkFilters(data) {
      const error = {}
      let errores = 0
      if ('filter' in data) {
        if ('from' in data.filter) {
          error.fromDate = ''
        } else {
          errores += 1
          error.fromDate = this.$t('errors.blank')
        }
        if ('to' in data.filter) {
          error.toDate = ''
        } else {
          errores += 1
          error.toDate = this.$t('errors.blank')
        }
      } else {
        errores = 2
        error.fromDate = this.$t('errors.blank')
        error.toDate = this.$t('errors.blank')
      }
      error.errores = errores
      return error
    },
    t(key, options = {}) {
      return this.$t(`customers.index.${key}`, options)
    },
    l(key, options = {}) {
      return this.$t(`customers.leads-details.${key}`, options)
    },
    exportLeads() {
      const filters = this.formatFilters(this.$refs.filterTable.queryData)
      this.filters = {...filters}
      setTimeout(
        () => {
          this.loadLeadsCsv()
        },
        MOCKS ? 500 : 0
      )
    },
    formatFilters(data) {
      const newData = pick(data, [
        'order',
        'page'
      ])
      if ('filter' in data) {
        newData.filter = {}
        if ('from' in data.filter) {
          newData.filter.from = moment(data.filter.from).format(FORMAT_DATE)
        }
        if ('to' in data.filter) {
          newData.filter.to = moment(data.filter.to).format(FORMAT_DATE)
        }
        if ('customerId' in data.filter) {
          if (data.filter.customerId !== '0') {
            newData.filter.customerId = data.filter.customerId
          }
        }
        if ('phone' in data.filter) {
          newData.filter.phone = data.filter.phone
        }
      }
      return newData
    },
    loadLeadsCsv() {
      // Este solo se ve afectado por estos filtros
      const filters = {}
      if ('filter' in this.filters) {
        filters.filter = pick(this.filters.filter, [
          'from',
          'to',
          'phone',
          'customerId'
        ])
      }
      API.accounts
        .leadsCvs(this.$user.accountId, filters)
        .then(({data}) => {
          AnalyticsUtils.downloadCsv({
            title: this.l('exportCsv.title'),
            to: filters.to,
            from: filters.from,
            url: data.file
          })
        })
    }
  }
})
