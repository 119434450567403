import {openModalConfirm} from 'js/services/modals'
import template from './_form.pug'

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {}
    }
  },
  computed: {
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {

  },
  methods: {

    testSMS() {
      openModalConfirm(
        this.$t('customers.link-mobility.modalConfirm.title'),
        this.$t('customers.link-mobility.modalConfirm.body'),
        this.$t('customers.link-mobility.modalConfirm.okButton')
      ).then(() => {       
        API.smsLinkMobility.testSMSLinkMobility(this.data).then(response => {
          if (response.data.response) {
            this.$notifications.success(this.$t('customers.link-mobility.edit.succesfullyTested'))
          } else {
            this.$notifications.warning(this.$t('customers.link-mobility.edit.warning'))
          }
        })
      })
    },

    initializeData() {
      this.data = this.initData
    },

    t(key, options = {}) {
      return this.$t(`customers.link-mobility.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
