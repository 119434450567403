import orderBy from 'lodash/orderBy'
import {openModalConfirmRemove} from 'js/services/modals.js'
import {cloneDeep} from 'lodash'
import ListForm from './_list-form.js'
import ListFileForm from './_list-file-form.js'
import FieldMappingModal from './_field-mapping-modal.js'
import template from './list-index.pug'
import FullTable from 'js/components/full-table/index.js'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    ListFileForm,
    ListForm,
    FieldMappingModal
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    campaignTags: {
      type: Array,
      default: () => ([])
    },
    clientPortfolio: Boolean,
    translateKey: String,
    phoneListService: String,
    fileService: String,
    fieldMappingService: String
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      listPhoneData: [],
      listPhoneFormData: {},
      listPhoneFormError: {},
      showForm: false,
      showFieldMapping: false,
      showRobinsonForm: false,
      fieldMappingData: {},
      lastQueryOptions: null, // guarda los últimos filtros aplicados
      meta: {}
    }
  },
  computed: {
    campaignId() {
      return this.campaign.id
    },

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    formMode() {
      return this.listPhoneFormData.id ? 'edit' : 'new'
    }
  },
  async mounted() {
    // this.loadList()
    this.loadFieldMappingData()
  },
  methods: {
    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados


      if (queryOptions) this.lastQueryOptions = queryOptions
      this.loadList(queryOptions || this.lastQueryOptions)
    },
    loadFieldMappingData() {
      this.fieldMappingData = {}
      API.campaigns[this.fieldMappingService].showList(this.campaign.id).then(dataList => {
        if (dataList) this.fieldMappingData = {...dataList}
      })
    },
    saveFieldMapping(data) {
      API.campaigns[this.fieldMappingService].createList(this.campaign.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.loadFieldMappingData()
        this.closeModal()
      })
    },
    loadList(queryOptions = {}) {
      console.log('queryOptions', queryOptions)
      API.campaigns[this.phoneListService].index(
        this.campaign.id,
        queryOptions
        // {order: {name: 'asc'}, page: {number: 1, size: 10}}
      ).then(({data, meta}) => {
        this.listPhoneData = cloneDeep(data)
        this.meta = {...meta}
        this.ready = true
      })
    },
    openFieldMappingModal() {
      this.showFieldMapping = true
    },

    changeOrder(field, dir) {
      this.listPhoneData = orderBy(
        this.listPhoneData,
        item => item[field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        dir
      )
    },

    openNewForm() {
      this.listPhoneFormData = {
        campaignId: this.campaignId
      }
      this.showForm = true
    },

    openNewFileForm() {
      this.showRobinsonForm = true
    },

    openEditForm(item) {
      this.listPhoneFormData = {
        id: item.id,
        phone: item.phone,
        type: item.type
      }

      this.showForm = true
    },

    destroy(id) {
      API.campaigns[this.phoneListService].destroy(this.campaign.id, id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    confirmDelete(data) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: data.phone}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(data.id))
    },

    closeModal() {
      this.listPhoneFormData = {}
      this.listPhoneFormError = {}
      this.showForm = false
      this.showRobinsonForm = false
      this.showFieldMapping = false
    },

    saveListRobinson(data) {
      this.listPhoneFormError = {}

      if (this.formMode === 'edit') {
        this.updateListRobinson(data)
      } else {
        this.createListRobinson(data)
      }
    },

    updateListRobinson(data) {
      API.campaigns[this.phoneListService].update(this.campaignId, data.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.getData()
        this.closeModal()
      }, errors => {
        this.listPhoneFormError = errors
      })
    },

    createListRobinson(data) {
      API.campaigns[this.phoneListService].create(this.campaignId, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.getData()
        this.closeModal()
      }, errors => {
        this.listPhoneFormError = errors
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.phoneListComponent.${key}`, options)
    },
    tk(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.${this.translateKey}.${key}`, options)
    }
  }
})
