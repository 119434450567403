import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import ModalPixelControl from './_modal-pixel-control.js'
import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    ModalPixelControl
  },
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      initFilters: {status: '', name: '', customerId: [], campaignDraftUserId: null},
      customers: [],
      customersSelectOptions: [],
      pixelControlCampaign: null,
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    canCreateCampaigns() {
      return this.$user.can('CREATE_CAMPAIGNS')
    },

    canViewCampaigns() {
      return this.$user.can('SHOW_ANALYTICS')
    },

    canViewPixelControl() {
      return this.$user.can('VIEW_PIXEL_CONTROL')
    },

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    statusOptions() {
      return [
        {label: this.t('filters.status.all'), value: ''},
        {label: this.t('filters.status.creation'), value: CAMPAIGN_STATUSES.CREATION},
        {label: this.t('filters.status.active'), value: CAMPAIGN_STATUSES.ACTIVE},
        {label: this.t('filters.status.inactive'), value: CAMPAIGN_STATUSES.INACTIVE},
        {label: this.t('filters.status.finished'), value: CAMPAIGN_STATUSES.FINISHED}
      ]
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },

    // invertimos el hash para buscar el key del valor de forma facil
    // esto lo utilizamos para montar la traducción con el key
    statusesKeys() {
      const keys = {}

      Object.keys(CAMPAIGN_STATUSES).forEach(key => {
        keys[CAMPAIGN_STATUSES[key]] = key
      })

      return keys
    }
  },
  created() {
    this.loadCustomers()
  },
  methods: {
    loadCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customers = data
        this.customersSelectOptions = data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
      })
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    t(key, options = {}) {
      return this.$t(`campaigns.index.${key}`, options)
    },

    getClassByStatus(status) {
      switch (status) {
        case CAMPAIGN_STATUSES.CREATION:
          return 'table-tag--yellow-light'
        case CAMPAIGN_STATUSES.ACTIVE:
          return 'table-tag--success'
        case CAMPAIGN_STATUSES.INACTIVE:
          return 'table-tag--gray'
        case CAMPAIGN_STATUSES.FINISHED:
          return 'table-tag--blue'
        default:
          return ''
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case CAMPAIGN_STATUSES.CREATION:
          return 'clock'
        case CAMPAIGN_STATUSES.ACTIVE:
          return 'check'
        case CAMPAIGN_STATUSES.INACTIVE:
          return 'close'
        case CAMPAIGN_STATUSES.FINISHED:
          return 'ban'
        default:
          return ''
      }
    },

    showMyCampaignIcon(campaign) {
      return campaign.status === CAMPAIGN_STATUSES.CREATION &&
        campaign.campaignDraftUserId === this.$user.id
    },

    showWizardIcon(campaign) {
      if (campaign.status !== CAMPAIGN_STATUSES.CREATION) return false

      return (campaign.campaignDraftUserId === this.$user.id && this.canCreateCampaigns) ||
        this.$user.role === ROLES.ADMIN ||
        this.$user.role === ROLES.SUPER_ADMIN
    },

    showDetailIcon(status) {
      return [
        CAMPAIGN_STATUSES.ACTIVE, CAMPAIGN_STATUSES.INACTIVE, CAMPAIGN_STATUSES.FINISHED
      ].includes(status) && this.canViewCampaigns
    },

    showAddLeadsIcon(status) {
      return [CAMPAIGN_STATUSES.ACTIVE, CAMPAIGN_STATUSES.INACTIVE].includes(status) &&
        this.$user.can('EDIT_CAMPAIGNS')
    },

    showPixelControlIcon(status) {
      return [
        CAMPAIGN_STATUSES.ACTIVE, CAMPAIGN_STATUSES.INACTIVE, CAMPAIGN_STATUSES.FINISHED
      ].includes(status) && this.canViewPixelControl
    },

    showDeleteIcon(status) {
      return [CAMPAIGN_STATUSES.INACTIVE, CAMPAIGN_STATUSES.CREATION].includes(status) &&
        this.$user.can('DELETE_CAMPAIGNS')
    },

    filterOnlyMyCampaigns(value, filters) {
      filters.campaignDraftUserId = value
      filters.status = value ? CAMPAIGN_STATUSES.CREATION : ''
    },

    confirmDelete(campaign) {
      openModalConfirmRemove(
        this.t('deleteConfirmationModal.title'),
        this.t('deleteConfirmationModal.body', {name: campaign.name}),
        this.t('deleteConfirmationModal.okButton')
      ).then(() => {
        this.destroyCampaign(campaign.id)
      })
    },

    destroyCampaign(campaignId) {
      API.campaigns.destroy(campaignId).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
