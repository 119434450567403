/* eslint-disable max-len */
export default {
  errorsRootKey: 'campaigns',
  resource: 'campaigns',
  actions: ['index', 'show', 'destroy', 'update', 'patch'],

  cdimensions: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/cdimensions`,
        {params, ...options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/cdimensions`,
        data,
        {errorsRootKey: 'cdimensions', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/cdimensions/${id}`,
        data,
        {errorsRootKey: 'cdimensions', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },

  validation: {
    changePriority(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/ws-set-priority`,
        data,
        {errorsRootKey: 'ws-set-priority', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    summary(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/validations-view`,
        {params, ...options}
      ).then(response => response.data)
    }
  },

  validationRobinson: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/robinson-list-ws`,
        {params, ...options}
      ).then(response => response.data)
    },
    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/robinson-list-ws`,
        data,
        {errorsRootKey: 'robinson-list-ws', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/robinson-list-ws/${id}`,
        data,
        {errorsRootKey: 'robinson-list-ws', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/robinson-list-ws/${id}`)
    },
    showList(campaignId, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/robinson-list-validation`,
        options
      ).then(
        response => response.data.data
      )
    },
    createList(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/robinson-list-validation`,
        data,
        {errorsRootKey: 'robinson-list-validation', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },
  validationBlackList: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/black-list-ws`,
        {params, ...options}
      ).then(response => response.data)
    },
    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/black-list-ws`,
        data,
        {errorsRootKey: 'black-list-ws', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/black-list-ws/${id}`,
        data,
        {errorsRootKey: 'black-list-ws', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/black-list-ws/${id}`)
    },
    showList(campaignId, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/black-list-validation`,
        options
      ).then(
        response => response.data.data
      )
    },
    createList(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/black-list-validation`,
        data,
        {errorsRootKey: 'black-list-validation', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },
  validationClientPortfolio: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/client-portfolio-config-list-ws`,
        {params, ...options}
      ).then(response => response.data)
    },
    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/client-portfolio-config-list-ws`,
        data,
        {errorsRootKey: 'client-portfolio-config-list-ws', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/client-portfolio-config-list-ws/${id}`,
        data,
        {errorsRootKey: 'client-portfolio-config-list-ws', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/client-portfolio-config-list-ws/${id}`)
    },
    showList(campaignId, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/client-portfolio-validation`,
        options
      ).then(
        response => response.data.data
      )
    },
    createList(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/client-portfolio-validation`,
        data,
        {errorsRootKey: 'client-portfolio-validation', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },

  listRobinson: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/robinson-list`,
        {params, ...options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/robinson-list`,
        data,
        {errorsRootKey: 'robinson-list', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/robinson-list/${id}`,
        data,
        {errorsRootKey: 'robinson-list', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/robinson-list/${id}`)
    }
  },

  clientPortfolioList: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/client-portfolio-list`,
        {params, ...options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/client-portfolio-list`,
        data,
        {errorsRootKey: 'client-portfolio-list', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/client-portfolio-list/${id}`,
        data,
        {errorsRootKey: 'client-portfolio-list', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/client-portfolio-list/${id}`)
    }
  },

  blackList: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/black-list`,
        {params, ...options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/black-list`,
        data,
        {errorsRootKey: 'black-list', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/black-list/${id}`,
        data,
        {errorsRootKey: 'black-list', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/black-list/${id}`)
    }
  },

  cpls: {
    index(campaignId, providerId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/providers/${providerId}/cpls`,
        {params, ...options}
      ).then(response => response.data)
    },

    create(campaignId, providerId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/providers/${providerId}/cpls`,
        data,
        {errorsRootKey: 'cpls', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, providerId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/providers/${providerId}/cpls/${id}`,
        data,
        {errorsRootKey: 'cpls', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, providerId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/providers/${providerId}/cpls/${id}`)
    }
  },

  providers: {
    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/providers`,
        data,
        {errorsRootKey: 'providers', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, providerId, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/providers/${providerId}`,
        data,
        {errorsRootKey: 'providers', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, providerId, siteId) {
      return Vue.http.delete(`campaigns/${campaignId}/providers/${providerId}/sites/${siteId}`)
    },

    staticNumbersAssigned(campaignId, providerId, siteId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/providerLeads/${providerId}/site/${siteId}/static-numbers/assigned`,
        {params},
        {options}
        )
      .then(response => response.data)
    },
    staticNumbersAssign(campaignId, providerId, siteId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/providerLeads/${providerId}/site/${siteId}/pool/assign`,
        data,
        {errorsRootKey: '', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    staticNumbersRemove(pooltype, campaignId, providerId, siteId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/providerLeads/${providerId}/site/${siteId}/pool/remove/${pooltype}`,
        data,
        {errorsRootKey: '', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },

  conditions: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/conditions`,
        {params},
        {options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/conditions`,
        data,
        {errorsRootKey: 'conditions', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/conditions/${id}`,
        data,
        {errorsRootKey: 'conditions', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/conditions/${id}`)
    }
  },

  forms: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/forms`,
        {params},
        {options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/forms`,
        data,
        {errorsRootKey: 'forms', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/forms/${id}`,
        data,
        {errorsRootKey: 'forms', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, id, options = {}) {
      return Vue.http.delete(`campaigns/${campaignId}/forms/${id}`, options)
    }
  },

  duplicity: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/duplicity-configuration`,
        {params},
        {options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/duplicity-configuration`,
        data,
        {errorsRootKey: 'forms', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },

  treatment: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/lead-treatment-configuration`,
        {params},
        {options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/lead-treatment-configuration`,
        data,
        {errorsRootKey: 'forms', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/lead-treatment-configuration/${id}`,
        data,
        {errorsRootKey: 'conditions', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, id) {
      return Vue.http.delete(`campaigns/${campaignId}/lead-treatment-configuration/${id}`)
    }
  },

  leads: {
    show(campaignId, id, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/leads/${id}`,
        options
      ).then(
        response => response.data.data
      )
    },

    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/leads`,
        {params},
        {options}
      ).then(response => response.data)
    },

    journey(campaignId, leadId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/leads/${leadId}/journey`,
        {params},
        {options}
      ).then(response => response.data)
    },

    addField(campaignId, leadId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/leads/${leadId}/addField`,
        data,
        {errorsRootKey: 'forms', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },

  emailProviders: {

    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/emailproviders`,
        {params},
        {options}
      ).then(response => response.data)
    },

    getSendersByProvider(CustomerId, NameProvider, options = {}) {
      return Vue.http.get(
        `email/${CustomerId}/${NameProvider}/senders`,
        options
      ).then(
        response => response.data.data
      )
    }

  },

  ctas: {
    index(params = {}, options = {}) {
      const url = params.campaignId ?
        `campaigns/${params.campaignId}/ctas` :
        `customers/${params.customerId}/ctas`

      delete params.campaignId
      delete params.customerId

      return Vue.http.get(
        url,
        {params, ...options}
      ).then(response => response.data)
    }
  },

  landings: {
    index(params = {}, options = {}) {
      const url = params.campaignId ?
        `campaigns/${params.campaignId}/landings` :
        `customers/${params.customerId}/landings`

      delete params.campaignId
      delete params.customerId

      return Vue.http.get(
        url,
        {params, ...options}
      ).then(response => response.data)
    }
  },

  tags: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/tags`,
        {params},
        {options}
      ).then(response => response.data)
    }
  },

  smsProviders: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/smsproviders`,
        {params},
        {options}
      ).then(response => response.data)
    }
  },
  matrixModule: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/leadModels`,
        {params},
        {options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/leadModels`,
        data,
        {errorsRootKey: 'leadModels', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    update(campaignId, id, data, options = {}) {
      return Vue.http.put(
        `campaigns/${campaignId}/leadModels/${id}`,
        data,
        {errorsRootKey: 'leadModels', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },

    destroy(campaignId, id, options = {}) {
      return Vue.http.delete(`campaigns/${campaignId}/leadModels/${id}`, options)
    },

    deleteMatrix(campaignId, options = {}) {
      return Vue.http.delete(`campaigns/${campaignId}/leadModels`, options)
    }
  },
  rulesModule: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/leadModelStructure`,
        {params},
        {options}
      ).then(response => response.data)
    },

    create(campaignId, data, options = {}) {
      return Vue.http.post(
        `campaigns/${campaignId}/leadModelStructure`,
        data,
        {errorsRootKey: 'leadModelStructure', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }

    // update(campaignId, id, data, options = {}) {
    //   return Vue.http.put(
    //     `campaigns/${campaignId}/leadModels/${id}`,
    //     data,
    //     {errorsRootKey: 'leadModels', ...options}
    //   ).then(
    //     response => response.data,
    //     response => {
    //       throw response.data.errors
    //     }
    //   )
    // },

    // destroy(campaignId, id, options = {}) {
    //   return Vue.http.delete(`campaigns/${campaignId}/leadModels/${id}`, options)
    // }
  },

  trackingNumbers: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/tracking-numbers`,
        {params},
        {options}
      ).then(response => response.data)
    },
    availableAutomation(campaignId, automationId, params = {}, options = {}) {
      return Vue.http.get(
        `campaigns/${campaignId}/tracking-numbers/${automationId}/availableAutomation`,
        {params},
        {options}
      ).then(response => response.data)
    }

  },

  embeddedreport: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(`campaigns/${campaignId}/embeddedreport`, {params}, {options})
      .then(response => response.data)
    }
  },

  callStatus: {
    index(campaignId, params = {}, options = {}) {
      return Vue.http.get(`campaigns/${campaignId}/callStatus`, {params}, {options})
      .then(response => response.data)
    }
  },
  agents: {
    index(params = {}, options = {}) {
      const url = params.campaignId ?
        `campaigns/${params.campaignId}/agents` :
        `customers/${params.customerId}/agents`

      delete params.campaignId
      delete params.customerId

      return Vue.http.get(
        url,
        {params, ...options}
      ).then(response => response.data)
    }
  },

  agentGroup: {
    index(params = {}, options = {}) {
      const url = params.campaignId ?
        'agents-group' :
        `customers/${params.customerId}/agents-group`

      if (!params.campaignId) {
        delete params.campaignId
      }
      delete params.customerId

      return Vue.http.get(
        url,
        {params, ...options}
      ).then(response => response.data)
    }
  }
}
