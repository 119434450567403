import FullTable from 'js/components/full-table/index.js'
import ItemReportChart from './item-report-chart'
import template from './item-report-table.pug'

export default Vue.extend({
  name: 'item-report-table',
  template: template(),
  components: {
    FullTable,
    ItemReportChart
  },
  props: {
    items: {
        type: Array,
        required: true
    },
    showSubColumn: Boolean
  },
  data() {
    return {
      chart: {
        dialog: false,
        data: null
      },
      order: {
        orderBy: null,
        orderDir: null
      },
      data: []
    }
  },
  watch: {
    items() {
      this.setData()
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    showChart(item) {
      this.chart.dialog = true
      this.chart.data = JSON.parse(JSON.stringify(item))
    },
    setData() {
      this.data = []
      this.data = this.items.map(item => ({
        ...item,
        visitRatio: Number(((item.totalCount * 100) / item.visitsCount).toFixed(1)),
        leadsRatio: Number(((item.convertedCount * 100) / item.leadsCount).toFixed(1))
      }))
    },
    changeOrder(params) {
      const filter = Object.keys(params.order)[0]
      this.order.orderBy = filter
      this.order.orderDir = params.order[filter]
      this.applyOrder()
    },
    applyOrder() {
      const dir = this.order.orderDir === 'asc' ? 1 : -1
      if (this.order.orderBy && this.order.orderDir) {
        this.data = this.data.sort((a, b) => {
          const x = a[this.order.orderBy]
          const y = b[this.order.orderBy]
          if (x < y) return dir * -1
          if (x > y) return dir
          return 0
        })
      }
    },
    t(key, params = {}) {
            return this.$i18n.t(`analytics.charts.${key}`, params)
        }
    }
})
