import template from './_modal-create-provider.pug'

// Componente para crear un provider
export default Vue.extend({
  template: template(),
  props: {
    errors: {
      type: Object
    }
  },
  data() {
    return {
      name: null
    }
  }
})
