export default {
  errorsRootKey: 'notifications/manuals',
  resource: 'notifications/manuals',
  actions: ['index', 'create', 'show', 'update', 'destroy'],
  enable(id, params = {}, options = {}) {
    return Vue.http.post(
      'notifications/manualSend', {id},
      {params, ...options}
    ).then(response => response.data)
  },
  pushManual(id, params = {}, options = {}) {
    return Vue.http.post(
      'notifications/manualSendPush', {id},
      {params, ...options}
    ).then(response => response.data)
  },
  types(params = {}, options = {}) {
    return Vue.http.get(
      'notifications/types',
      {params, ...options}
    ).then(response => response.data)
  },
  // getNotificationTypeId(id, params = {}, options = {}) {
  //   return Vue.http.get(
  //     'notifications', {id},
  //     {params, ...options}
  //   ).then(response => response.data)
  // },
  getNotificationTypeId(id, params = {}, options = {}) {
    return Vue.http.get(
      `notifications/${id}`,
      {params, ...options}
    ).then(response => response.data)
  },
  configTypes(params = {}, options = {}) {
    return Vue.http.get(
      'notifications/config-types',
      {params, ...options}
    ).then(response => response.data)
  },
  configuration(id, data, params = {}, options = {}) {
    return Vue.http.put(
      `notifications/configuration/${id}`, data,
      {params, ...options}
    ).then(response => response.data)
  },
  conectionIdUpdate(id, params = {}, options = {}) {
    return Vue.http.put(
      `notifications/${id}`, null,
      {params, ...options}
    ).then(response => response.data)
  },
  getNotificications(params = {}, options = {}) {
    return Vue.http.get(
      'notifications',
      {params, ...options}
    ).then(response => response.data)
  },
  getConfigNotificicationUser(params = {}, options = {}) {
    return Vue.http.get(
      'notifications/configuration-notification-user',
      {params, ...options}
    ).then(response => response.data)
  }
}
