import template from './index.pug'

// Muestra un aviso con un icono e información
export default Vue.extend({
  template: template(),
  props: {
    icon: {
      type: String,
      default: 'info'
    },
    text: {
      type: String,
      default: ''
    }
  }
})
