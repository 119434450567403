import RouteParent from 'js/router/route-parent'

import CallCentersCampaignsIndex from 'js/pages/campaigns/call-center-index.js'
import CallCentersCustomersIndex from 'js/pages/customers/call-center-index.js'
import CallCentersProductsIndex from 'js/pages/products/call-center-index.js'
import CallCentersIndex from 'js/pages/call-centers/index.js'
import CallCentersNew from 'js/pages/call-centers/new.js'
import CallCentersEdit from 'js/pages/call-centers/edit.js'
import CallCentersShow from 'js/pages/call-centers/show.js'
import callCenterConfigEdit from 'js/pages/call-centers/cti-configs/call-center-config/edit.js'

export default [
  {
    path: '/call-centers',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: ':id/campaigns',
        name: 'call-centers-campaigns',
        component: CallCentersCampaignsIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CALL_CENTERS', 'LIST_CAMPAIGNS']
        }
      },
      {
        path: ':id/customers',
        name: 'call-centers-customers',
        component: CallCentersCustomersIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CALL_CENTERS', 'VIEW_CUSTOMERS']
        }
      },
      {
        path: ':id/products',
        name: 'call-centers-products',
        component: CallCentersProductsIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CALL_CENTERS', 'VIEW_PRODUCTS']
        }
      },
      {
        path: '',
        name: 'call-centers-index',
        component: CallCentersIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CALL_CENTERS']
        }
      },
      {
        path: 'new',
        name: 'call-centers-new',
        component: CallCentersNew,
        meta: {
          auth: true,
          permit: ['MANAGE_CALL_CENTERS']
        }
      },
      {
        path: ':id/edit',
        name: 'call-centers-edit',
        component: CallCentersEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_CALL_CENTERS']
        }
      },
      {
        path: ':id',
        name: 'call-centers-show',
        component: CallCentersShow,
        meta: {
          auth: true,
          permit: ['VIEW_CALL_CENTERS']
        }
      },
      // ctis endpoints
      {
        path: ':id_call_center/cti-configs/:id',
        name: 'call-center-config',
        component: callCenterConfigEdit,
        meta: {
          auth: true,
          permit: ['VIEW_CALL_CENTERS']
        }
      }

    ]
  }
]
