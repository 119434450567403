export default {
  index(campaignId, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/tags`,
      {params, ...options}
    ).then(response => response.data)
  },
  all( params = {}, options = {}) {
    return Vue.http.get(
      'campaigns/tags',
      {params, ...options}
    ).then(response => response.data)
  },
  tagsByCampaign(campaignId, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/tags`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  }
}
