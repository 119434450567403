import template from './index.pug'

// Muestra un modal para un formulario con:
// - un título con un icono
// - un botón de cancelar
// - un botón de guardar
// - un slot para añadir el formulario
export default Vue.extend({
  template: template(),
  props: {
    icon: {
      type: String,
      default: 'info'
    },
    title: {
      type: String,
      default: ''
    },
    disabledSaveBtn: {
      type: Boolean,
      default: false
    }
  }
})
