import RouteParent from 'js/router/route-parent'

import emailMarketingIndex from 'js/pages/email-marketing/index.js'
import emailMarketingNew from 'js/pages/email-marketing/new.js'
import emailMarketingEdit from 'js/pages/email-marketing/edit.js'

export default [
  {
    path: '/email-marketing',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'email-marketing-index',
        component: emailMarketingIndex,
        meta: {
          auth: true
        }
      },
      {
        path: 'new',
        name: 'email-marketing-new',
        component: emailMarketingNew,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit',
        name: 'email-marketing-edit',
        component: emailMarketingEdit,
        meta: {
          auth: true
        }
      }
    ]
  }
]
