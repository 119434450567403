import RouteParent from 'js/router/route-parent'

import AccountsIndex from 'js/pages/accounts/index.js'

export default [
  {
    path: '/accounts',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'accounts-index',
        component: AccountsIndex,
        meta: {
          auth: true
        }
      }
    ]
  }
]
