import camelCase from 'lodash/camelCase'
import template from './config.pug'

export default Vue.extend({
  template: template(),
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentTab: this.$route.name.split('-').pop()
    }
  },
  computed: {
    tabsOptions() {
      return ['cdimensions', 'forms', 'pixelControl', 'generalData'].map(item => ({
        value: item,
        label: this.t(`tabs.${camelCase(item)}.tabTitle`)
      }))
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.config.${key}`, options)
    },

    getRouteCurrentTab(tab) {
      return {name: `campaigns-show-config-${tab}`, params: {id: this.campaign.id}}
    },

    setCurrentTab(tab) {
      return this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
