import {pick, cloneDeep, isEqual, isEmpty, sortBy} from 'lodash'
import {openModalConfirm} from 'js/services/modals.js'
import validateForm from '../../services/validate-form'
import BasicInfoForm from './wizard/basic-info.js'
import FormForm from './wizard/form.js'
import template from './wizard.pug'

// Wizard para la creación de una campaña
// Gestiona las validaciones en frontend de las distintas pestañas
// Puede guardar una versión Draft sin cumplir las validaciones
// Avisa al usuario si cambia de pestañas con datos sin validar y si abandona la pagina sin guardar
export default Vue.extend({
  template: template(),
  components: {
    BasicInfoForm,
    FormForm
  },
  data() {
    return {
      data: {},
      errors: {},
      assignedUser: null,
      usersSelectOptions: [],
      basicInfoData: {},
      basicInfoErrors: {},
      // Valores de WIZARD_STEP_STATE
      // COMPLETED => Todos los datos obligatorios están rellenos de forma correcta
      // EMPTY => No ha recogido ningún dato hasta el momento
      // WIP => No se han completado todos los campos obligatorios o no se ha validado alguno
      basicInfoState: '',
      formData: {messages: {success: '', error: ''}},
      formErrors: {},
      formState: '', // Valores de WIZARD_STEP_STATE
      ready: false,
      tabs: ['basicInfo', 'form'],
      currentTab: '',
      saved: false
    }
  },
  // Si el usuario abandona el wizard por el modo que sea se muestra un mensaje dando la opción
  // de guardar el wizard antes de abandonar la página
  // eslint-disable-next-line consistent-return
  beforeRouteLeave(to, _from, next) {
    // Evita el mensaje al abandonar la pagina por falta de permisos o al redirecionar tras guardar
    if (to.name === 'error-403' || to.name === 'error-500' || this.saved) {
      return next()
    }

    openModalConfirm(
      this.t('leaveWizardConfirmationModal.title'),
      this.t('leaveWizardConfirmationModal.body'),
      this.t('leaveWizardConfirmationModal.okButton'),
      {cancelText: this.t('leaveWizardConfirmationModal.cancelButton')}
    ).then(() => {
      this.saveDraft()
      next()
    }).catch(() => {
      next()
    })
  },
  computed: {
    getUserId() {
      return this.data.userId
    },
    assignedUserAvatarInitials() {
      return `${this.assignedUser?.firstName[0]} ${this.assignedUser?.lastName[0]}`
    },

    assignedUserFullName() {
      return `${this.assignedUser?.firstName} ${this.assignedUser?.lastName}`
    },

    indexOfCurrentTab() {
      return this.tabs.indexOf(this.currentTab)
    },

    previousButtonDisabled() {
      return this.indexOfCurrentTab === 0
    },

    nextButtonDisabled() {
      return this.indexOfCurrentTab === this.tabs.length - 1 || this.nameIsEmpty
    },

    createButtonDisabled() {
      const incomplete = this.tabs.filter(
        tab => this[`${tab}State`] !== WIZARD_STEP_STATE.COMPLETED
      )
      return incomplete.length > 1 || incomplete.length === 1 && incomplete[0] !== this.currentTab
    },

    nameIsEmpty() {
      return !this.basicInfoData.name
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        if (this.canCreateCampaign()) {
          this.initializeData()
        }
      }
    },
    getUserId() {
      this.loadAssignedUser()
    }
  },
  methods: {
    async initializeData() {
      if (this.canCreateCampaign()) {
        this.$setPageTitle(this.$t('campaigns.new.title'))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.campaigns.index'), route: {name: 'campaigns-index'}},
          {label: this.$t('breadcrumbs.campaigns.new'), route: {}}
        ])

        await this.loadUsers()

        if (this.$route.params.id) {
          this.initializeExistingCampaign()
        } else {
          this.initializeNewCampaign()
        }

        this.initializeTabs()
      }
    },

    initializeExistingCampaign() {
      API.campaignDrafts.show(this.$route.params.id).then(response => {
        this.data = response
        this.basicInfoData = pick(this.data, BasicInfoForm.options.fields)
        this.formData = this.data.defaultForm || cloneDeep(FormForm.options.defaultValues())
      })
    },

    initializeNewCampaign() {
      const data = {}
      this.basicInfoData = cloneDeep(BasicInfoForm.options.defaultValues())
      this.formData = cloneDeep(FormForm.options.defaultValues())
      this.data = data
    },

    initializeTabs() {
      this.validateTab('basicInfo', BasicInfoForm, false)
      this.validateTab('form', FormForm, false)

      // Selecciona la primera que no está completa o la ultima si estan todas completas
      this.currentTab = this.tabs.find(
        tab => this[`${tab}State`] !== WIZARD_STEP_STATE.COMPLETED
      ) || this.tabs[this.tabs.length - 1]

      this.ready = true
    },

    canCreateCampaign() {
      if (this.$user.isOperator && (this.data.userId && this.$user.id !== this.data.userId)) {
        this.$router.push({name: 'error-403'})
        return false
      }
      return true
    },

    loadAssignedUser() {
      if (this.data.userId) {
        API.users.show(this.data.userId).then(user => {
          this.assignedUser = user
        })
      } else {
        this.assignedUser = null
      }
    },

    async loadUsers() {
      return API.users.indexAssignToCampaign({page: {number: 1, size: 1000}}).then(response => {
        this.usersSelectOptions = sortBy(
          response.data.map(user => ({
            label: `${user.firstName} ${user.lastName}`.trim(),
            value: user.id
          })),
          ['label']
        )
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.form.${key}`, options)
    },

    getClassByStatus(status) {
      switch (status) {
        case WIZARD_STEP_STATE.EMPTY:
          return 'tab--gray'
        case WIZARD_STEP_STATE.WIP:
          return 'tab--yellow'
        case WIZARD_STEP_STATE.COMPLETED:
          return 'tab--blue'
        default:
          return ''
      }
    },

    // keepErrors por defecto = true, mantiene los errores, para mostrarlos en el formulario
    // Se usa con valor false en la carga inicial del formulario que necesita inicializar el estado
    // de la pestaña pero no queremos que se muestren los errores en el formulario
    validateTab(tabName, tabComponent, keepErrors = true) {
      const errors = validateForm(
        tabComponent.options.validations, this[`${tabName}Data`], 'campaigns'
      )

      if (errors) {
        if (keepErrors) this[`${tabName}Errors`] = errors

        const dataTab = this[`${tabName}Data`]
        const defaultValues = tabComponent.options.defaultValues()

        if (isEmpty(dataTab) || isEqual(dataTab, defaultValues)) {
          this[`${tabName}State`] = WIZARD_STEP_STATE.EMPTY
        } else {
          this[`${tabName}State`] = WIZARD_STEP_STATE.WIP
        }

        this.$nextTick(() => {
          const error = document.getElementsByClassName('error')[0]
          if (error) error.scrollIntoView({block: 'center'})
        })
      } else {
        this[`${tabName}Errors`] = {}
        this[`${tabName}State`] = WIZARD_STEP_STATE.COMPLETED
      }
    },

    saveDraft() {
      this.basicInfoErrors = {}
      this.formErrors = {}
      this.saveCampaign(false)
    },

    saveFinished() {
      if (this.isValidCurrentTab()) {
        this.saveCampaign(true)
      }
    },

    saveCampaign(finished) {
      this.data = {...this.data, ...this.basicInfoData}
      this.data.defaultForm = this.formData
      this.data.finished = finished

      const request = this.data.id ?
        API.campaignDrafts.update(this.data.id, this.data) :
        API.campaignDrafts.create(this.data)

      request.then(response => {
        this.$notifications.success(this.t('successfullyCreated'))
        this.data.id = response.data.id

        if (finished) {
          this.saved = true
          this.$router.push({name: 'campaigns-index'})
        } else if (this.data.userId !== this.$user.id) {
          // si se asigna a otro usuario, se cierra el formulario
          this.saved = true
          this.$router.push({name: 'campaigns-index'})
        }
      }, errors => {
        this.errors = errors

        // El nombre de la campaña se valida en backend (único)
        this.basicInfoErrors = pick(errors, BasicInfoForm.options.fields)

        if (!isEmpty(this.basicInfoErrors)) this.basicInfoState = WIZARD_STEP_STATE.WIP

        this.$notifications.error(this.t('errorCreated'))
        window.scrollTo(0, 0)
      })
    },

    previousStep() {
      this.changeTab(this.tabs[this.indexOfCurrentTab - 1])
    },

    nextStep() {
      this.changeTab(this.tabs[this.indexOfCurrentTab + 1])
    },

    changeTab(tabId) {
      if (this.isValidCurrentTab()) {
        this.currentTab = tabId
        return
      }

      openModalConfirm(
        this.t('changeStepConfirmationModal.title'),
        this.t('changeStepConfirmationModal.body'),
        this.t('changeStepConfirmationModal.okButton'),
        {cancelText: this.t('changeStepConfirmationModal.cancelButton')}
      ).then(() => {
        this.currentTab = tabId
      }).catch(() => {
        const currentTab = this.currentTab
        this.currentTab = tabId
        this.$nextTick(() => { this.currentTab = currentTab })
      })
    },

    // eslint-disable-next-line consistent-return
    isValidCurrentTab() {
      if (this.currentTab === 'basicInfo') {
        this.validateTab(this.currentTab, BasicInfoForm)
        return isEmpty(this.basicInfoErrors)
      } else if (this.currentTab === 'form') {
        this.validateTab(this.currentTab, FormForm)
        return isEmpty(this.formErrors)
      }
    }
  }
})
