import UserForm from '../_form.js'

import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    UserForm
  },
  data() {
    return {
      ready: false,
      data: {
        customerId: 0
      },
      errors: {},
      customerOptions: [],
      callCenterOptions: [],
      siteOptions: []
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('users.operators.form.new.title'))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.users.operators.index'), route: {name: 'operators-index'}},
          {label: this.$t('breadcrumbs.users.operators.new'), route: {}}
        ])

        this.loadCustomers()
        this.loadCallCenters()
        this.loadSites()
      }
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`users.operators.new.${key}`, options)
    },

    create(data) {
      this.errors = {}

      API.users.create(data).then(() => {
        this.$notifications.success(this.$t('users.form.succesfullySave'))
        this.$router.push({name: 'operators-index'})
      }, errors => {
        this.errors = errors
      })
    },

    loadSites() {
      API.sites.index(
        {page: {number: 1, size: 1000}, order: {name: 'asc'}}
      ).then(response => {
        this.siteOptions = response.data.map(site => ({
          label: site.description || site.url,
          value: site.id
        }))
      })
    },

    loadCallCenters() {
      API.callCenters.index(
        {page: {number: 1, size: 1000}, order: {name: 'asc'}}
      ).then(response => {
        this.callCenterOptions = response.data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
      })
    },

    loadCustomers() {
      API.customers.index(
        {page: {number: 1, size: 1000}, order: {name: 'asc'}}
      ).then(response => {
        this.customerOptions = response.data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
      })
    }
  }
})
