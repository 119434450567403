import pick from 'lodash/pick'
import FullTable from 'js/components/full-table/index.js'
import AnalyticsUtils from 'js/services/analytics-utils'
import {mapGetters} from 'vuex'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    leads: {
      type: Array,
      required: true
    },
    customers: {
      type: Array,
      required: true
    },
    campaigns: {
      type: Array,
      required: true
    },
    providers: {
      type: Array,
      required: true
    },
    utmSources: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalExport: false,
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      viewOptions: [
        {label: 'Consolidado Mes', value: 'monthView'},
        {label: 'Consolidado Día', value: 'dayView'},
        {label: 'Consolidado Hora', value: 'hourView'},
        {label: 'Fuente', value: 'sourceView'},
        {label: 'Compañía actual', value: 'companyView'},
        {label: 'Edad', value: 'ageView'},
        {label: 'Detalle', value: 'detailView'}
      ],
      exportOptions: [
        {label: 'Excel', value: 'excel'},
        {label: 'PDF', value: 'pdf'}
      ],
      showFilters: true,
      // eslint-disable-next-line max-len
      filters: {
        from: '',
        to: '',
        campaignId: [],
        providerLeadId: [],
        typeLead: [],
        source: [],
        statusLead: [],
        view: '',
        format: 'pdf'
      },
      lastQueryOptions: null, // guarda los últimos filtros aplicados
      errorsMapping: {
        fromDate: '',
        toDate: '',
        view: '',
        errores: 0
      },
      firstLoad: true,
      clear: false,
      textCopy: 'Copiar JSON'
    }
  },
  computed: {
    ...mapGetters(['errorTypes', 'statusLeads', 'typeLeads']),
    fromDateConfig() {
      return {
        ...this.dateConfig
      }
    },
    toDateConfig() {
      return {
        ...this.dateConfig
      }
    },

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },
    customersOptions() {
      return this.customers.map(customer => ({
        label: customer.name,
        value: customer.id
      }))
    },
    providersOptions() {
      return this.providers.map(item => ({
        label: item.name,
        value: item.id
      }))
    },
    utmSourcesOptions() {
      return this.utmSources.map(item => ({
        label: item.name,
        value: item.key
      }))
    },
    statusLeadsOptions() {
      return this.statusLeads.map(item => ({
        label: item.key,
        value: item.key
      }))
    },
    typeLeadsOptions() {
      return this.typeLeads.map(item => ({
        label: item.name,
        value: item.name
      }))
    },
    errorTypesOptions() {
      let errorTypesSelect = []

      errorTypesSelect = this.errorTypes.map(errorType => ({
        label: errorType.key,
        value: errorType.key
      }))

      errorTypesSelect.push({
        label: 'Todos',
        value: '0'
      })

      return errorTypesSelect.reverse()
    },
    campaignsOptions() {
      return this.campaigns.map(campaign => ({
        label: campaign.name,
        value: campaign.id
      }))
    },
    nsFieldFrom() {
      return this.errorsMapping.fromDate
    },
    nsFieldTo() {
      return this.errorsMapping.toDate
    },
    viewError() {
      return this.errorsMapping.view
    }
  },
  methods: {

    switchModal() {
      if (this.modalExport) {
        this.modalExport = false
      } else {
        this.modalExport = true
      }
    },

    getData(queryOptions) {
      if (this.firstLoad) {
        this.firstLoad = false
      } else {
        if (this.firstLoad === false &&
          this.clear === true &&
          this.$refs.filterTable.appliedFilters === false) {
          this.errorsMapping = {
            fromDate: '',
            toDate: '',
            view: '',
            errores: 0
          }
          this.clear = false
          this.$parent.leads = []
        } else {
          this.errorsMapping = this.checkFilters(queryOptions)
          if ('filter' in queryOptions) {
            queryOptions = this.formatFilters(queryOptions)
          }
          if (this.errorsMapping.errores === 0) {
            this.$emit('load-data', queryOptions || this.lastQueryOptions)
            this.clear = true
          }
        }

        if (queryOptions) this.lastQueryOptions = queryOptions
      }
    },
    checkFilters(data) {
      const error = {}
      let errores = 0
      if ('filter' in data) {
        if ('from' in data.filter) {
          error.fromDate = ''
        } else {
          errores += 1
          error.fromDate = this.$t('errors.blank')
        }
        if ('to' in data.filter) {
          error.toDate = ''
        } else {
          errores += 1
          error.toDate = this.$t('errors.blank')
        }
        if ('view' in data.filter) {
          error.view = ''
        } else {
          errores += 1
          error.view = this.$t('errors.blank')
        }
      } else {
        errores = 2
        error.fromDate = this.$t('errors.blank')
        error.toDate = this.$t('errors.blank')
        error.view = this.$t('errors.blank')
      }
      error.errores = errores
      return error
    },
    t(key, options = {}) {
      return this.$t(`customers.index.${key}`, options)
    },
    l(key, options = {}) {
      return this.$t(`operationalReport.${key}`, options)
    },
    exportLeads() {
      // eslint-disable-next-line max-len
      let queryOptions = this.$refs.filterTable.filterData.to && this.$refs.filterTable.filterData.to ? {filter: this.$refs.filterTable.filterData} : {filter: {}}
      // eslint-disable-next-line eqeqeq
      if (this.filters.format != '') {
        queryOptions.filter.format = this.filters.format
      }
      if ('filter' in queryOptions) {
        queryOptions = this.formatFilters(queryOptions)
        // eslint-disable-next-line eqeqeq
        if (this.filters.format != '') {
          queryOptions.filter.format = this.filters.format
        } else {
          queryOptions.filter.format = ''
        }
      }
      this.errorsMapping = this.checkFilters(queryOptions)
      if (this.errorsMapping.errores === 0) {
        API.operativeReport
          .exportOperativeReport(queryOptions)
          .then(({data}) => {
            AnalyticsUtils.downloadCsv({
              title: this.l('exportCsv.title'),
              to: queryOptions.to,
              from: queryOptions.from,
              url: data.file
            })
            this.modalExport = false
          })
      }
    },
    formatFilters(data) {
      const newData = pick(data, [
        'order',
        'page'
      ])
      if ('filter' in data) {
        newData.filter = {}
        if ('from' in data.filter) {
          newData.filter.from = moment(data.filter.from).format(FORMAT_DATE)
        }
        if ('to' in data.filter) {
          newData.filter.to = moment(data.filter.to).format(FORMAT_DATE)
        }
        if ('view' in data.filter) {
          if (data.filter.view !== '') {
            newData.filter.view = data.filter.view
          }
        }
        if ('campaignId' in data.filter) {
          if (data.filter.campaignId.length > 0) {
            newData.filter.campaignId = data.filter.campaignId
          }
        }
        if ('providerLeadId' in data.filter) {
          if (data.filter.providerLeadId.length > 0) {
            newData.filter.providerLeadId = data.filter.providerLeadId
          }
        }
        if ('typeLead' in data.filter) {
          if (data.filter.typeLead.length > 0) {
            newData.filter.typeLead = data.filter.typeLead
          }
        }
        if ('source' in data.filter) {
          if (data.filter.source.length > 0) {
            newData.filter.source = data.filter.source
          }
        }
        if ('statusLead' in data.filter) {
          if (data.filter.statusLead.length > 0) {
            newData.filter.statusLead = data.filter.statusLead
          }
        }
      }
      return newData
    }
  }
})
