import merge from 'lodash/merge'
import camelCase from 'lodash/camelCase'
import VueI18n from 'vue-i18n'

// Expresion regular que extrae el idioma, es decir, "en" de un string como "./auth/profile.en.json"
const EXTRACT_LANG_FROM_FILE = new RegExp('.([a-z]{2}).json$')

// 1 accede a lo que se encuentra entre () en la regex, es decir, el idioma
const extractLanguageFromPath = path => path.match(EXTRACT_LANG_FROM_FILE)[1]

const context = require.context('locales', true, /\.json$/)

const locales = {}

context.keys().forEach(filename => {
  const language = extractLanguageFromPath(filename)
  const translations = {}
  // Translations debe de tener como prefijo el lenguage al que corresponde esstas traducciones.
  // {en: {Aqui las traducciones}}
  translations[language] = {}
  let reference = translations[language]

  // Comprobamos si se encuentra anidado en un directorio
  // Si el filename se encuentra dentro de un directorio, al menos tendra dos / en el nombre
  if (filename.match(/\//g).length >= 2) {
    // Eliminamos el primer ./, y dividimos por el resto de /
    const prefixes = filename.replace(/\.\//, '').split('/')

    // Vamos anidando niveles a las traducciones. Si el archivo es ./auth/login.en.json, anidara
    // todos los directorios, es decir, en este caso añadir auth
    for (let i = 0; i < prefixes.length - 1; i++) {
      const prefix = prefixes[i]
      reference[prefix] = {}
      reference = reference[prefix]
    }

    // Añadimos tambien el nombre del archivo, sin el .en.json, y se pasa de kebab-case a camelCase
    const baseNameFile = prefixes[prefixes.length - 1]
    // Si el archivo es solo es.json o en.json no tenemos que hacer nada
    if (!/^[a-z]{2}\.json$/.test(baseNameFile)) {
      const nameFile = camelCase(baseNameFile.replace(/\.[a-z]{2}\.json/, ''))
      reference[nameFile] = {}
      reference = reference[nameFile]
    }
  } else {
    // Si no se encuentra dentro de un directorio, añadimos como clave el nombre del archivo.
    // Ejemplo: profile.en.json -> profile: {Aqui traducciones}
    const nameFile = camelCase(filename.replace(/\.?[a-z]{2}\.json/, ''))

    // Comprobamos si namefile existe, ya que para las en.json/es.json no necesitamos hacer lo
    // siguiente
    if (nameFile) {
      reference[nameFile] = {}
      reference = reference[nameFile]
    }
  }

  // Añadimos finalmente las traducciones que definimos en los json
  const contentFile = context(filename)
  Object.assign(reference, contentFile)

  // Vamos uniendo todas las traducciones en locales
  merge(locales, translations)
})

Vue.use(VueI18n)

export default new VueI18n({
  locale: DEFAULT_LANG,
  fallbackLocale: DEFAULT_LANG,
  // Congelamos el objecto para que vue no aplique reactividad a él
  messages: Object.freeze(locales)
})
