import pick from 'lodash/pick'
import {indexAccounts} from '../accounts/factory.js'

const collection = [
  {
    id: '1',
    type: 'autonomous',
    name: 'Acomp',
    socialReason: 'razón social',
    legalIdentifier: '30992020E',
    activityTypeId: '1',
    address: 'Calle Italía',
    country: 'ES',
    city: 'Granada',
    province: 'Granada',
    postalCode: '18002',
    email: 'acomp@email.com',
    phone: '656789900',
    status: CUSTOMER_STATUSES.ACTIVE,
    logoUrl: 'https://logoipsum.com/logo/logo-14.svg',
    productsCount: 2,
    conversionsCount: 2,
    campaignsCount: 2,
    callCentersCount: 1,
    accountId: indexAccounts[0].id,
    totalCount: 2400,
    integrations: {
      googleAdwords: true,
      googleCampaignManager: false,
      RTB: false,
      DMP: false,
      CRM: false,
      ERP: false,
      facebookLeadAds: true,
      SMSLaia: false,
      SMSLinkMobility: false,
      EmailLaia: true,
      EmailLinkMobility: false,
      GoogleLeadAds: true
    },
    sites: [
      {
        id: 'site1',
        url: 'www.google.com',
        description: 'Google',
        pixelControl: 'xxx',
        campaignIds: ['campaign1', 'campaign2']
      },
      {
        id: 'site2',
        url: 'www.nosolosoftware.es',
        description: 'NSS',
        pixelControl: 'xxx',
        campaignIds: []
      }
    ]
  },
  {
    id: '2',
    type: 'company',
    name: 'Infocomp',
    socialReason: 'razón social',
    legalIdentifier: '30992020E',
    activityTypeId: '1',
    address: 'Av Gran Capitan',
    country: 'ES',
    city: 'Granada',
    province: 'Granada',
    postalCode: '18002',
    email: 'infocomp@email.com',
    phone: '656789901',
    status: CUSTOMER_STATUSES.INACTIVE,
    logoUrl: 'https://logoipsum.com/logo/logo-16.svg',
    productsCount: 2,
    conversionsCount: 2,
    campaignsCount: 2,
    callCentersCount: 1,
    accountId: indexAccounts[0].id,
    totalCount: 1500,
    integrations: {
      googleAdwords: true,
      googleCampaignManager: false,
      RTB: false,
      DMP: true,
      CRM: true,
      ERP: true,
      SMSLaia: false,
      SMSLinkMobility: false,
      EmailLaia: false,
      EmailLinkMobility: false
    },
    sites: [
      {
        id: 'site3',
        url: 'www.nosolosoftware.es',
        description: 'NSS',
        pixelControl: 'xxx',
        campaignIds: ['campaign4']
      }
    ]
  },
  {
    id: '3',
    type: 'company',
    name: 'PTV',
    socialReason: 'razón social',
    legalIdentifier: '30992020E',
    activityTypeId: '1',
    address: '',
    country: 'ES',
    city: 'Granada',
    province: 'Granada',
    postalCode: '18002',
    email: 'ptv@email.com',
    phone: '656789902',
    status: CUSTOMER_STATUSES.NO_CAMPAIGNS,
    logoUrl: 'https://logoipsum.com/logo/logo-15.svg',
    productsCount: 2,
    conversionsCount: 2,
    campaignsCount: 2,
    callCentersCount: 1,
    accountId: indexAccounts[0].id,
    totalCount: 600,
    integrations: {
      googleAdwords: true,
      googleCampaignManager: false,
      RTB: false,
      DMP: true,
      CRM: true,
      ERP: true,
      SMSLaia: false,
      SMSLinkMobility: false,
      EmailLaia: false,
      EmailLinkMobility: false
    },
    sites: []
  }
]

// el show incluye todos los campos
const showCustomers = collection

// seleccionamos solo aquellos campos del index
const indexCustomers = collection.map(item => Object.assign(
  pick(item, [
    'id',
    'name',
    'address',
    'country',
    'email',
    'phone',
    'status',
    'logoUrl',
    'productsCount',
    'conversionsCount',
    'campaignsCount',
    'callCentersCount',
    'totalCount'
  ]),
  {
    // se añade para los tests
    callCenterId: ['callCenter1', 'callCenter2', 'callCenter3']
  }
))

const validations = {
  type: {type: 'string', required: true},
  socialReason: {type: 'string', required: true, unique: true, maxLength: 16},
  name: {type: 'string', required: true, unique: true, maxLength: 16},
  legalIdentifier: {type: 'string', required: true, maxLength: 10},
  activityType: {type: 'integer'},
  address: {type: 'string', maxLength: 254},
  postalCode: {type: 'string', maxLength: 10},
  country: {type: 'string', required: true},
  city: {type: 'string', maxLength: 32},
  province: {type: 'string', maxLength: 32},
  phone: {type: 'string', maxLength: 32},
  email: {
    type: 'string',
    format: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    maxLength: 254
  },
  integrations: {
    type: 'object',
    objectValidations: {
      googleAdwords: {type: 'boolean'},
      googleCampaignManager: {type: 'boolean'},
      RTB: {type: 'boolean'},
      DMP: {type: 'boolean'},
      CRM: {type: 'boolean'},
      ERP: {type: 'boolean'}
    }
  },
  sites: {
    type: 'objectArray',
    required: true,
    objectValidations: {
      url: {type: 'string', required: true, unique: true, maxLength: 256},
      description: {type: 'string', maxLength: 256}
    }
  }
}

export {showCustomers, indexCustomers, validations}
