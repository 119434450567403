import RouteParent from 'js/router/route-parent'

import ddisIndex from 'js/pages/ddis/index.js'
import ddisNew from 'js/pages/ddis/new.js'
import ddisEdit from 'js/pages/ddis/edit.js'

export default [
  {
    path: '/ddis',
    component: RouteParent,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'ddis-index',
        component: ddisIndex,
        meta: {
          auth: true,

        }
      },
      {
        path: 'new',
        name: 'ddis-new',
        component: ddisNew,
        meta: {
          auth: true,
        }
      },
      {
        path: ':id/edit',
        name: 'ddis-edit',
        component: ddisEdit,
        meta: {
          auth: true,
        }
      },
      // {
      //   path: ':id',
      //   name: 'ddis-show',
      //   component: CallCentersShow,
      //   meta: {
      //     auth: true,
      //   }
      // }
    ]
  }
]
