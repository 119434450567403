import template from './login.pug'

export default Vue.extend({
  template: template(),
  data() {
    return {
      data: {
        email: '',
        password: ''
      },
      error: false
    }
  },
  computed: {
    // TODO: cambiar ns-field para que acepte un boolean
    nsFieldErrors() {
      return this.error ? 'error' : ''
    }
  },
  methods: {
    async login() {
      this.errors = []

      this.$auth.login(this.data).then(response => {
        this.$auth.setToken(response.data.token)
        this.$router.push({name: 'analytics-bi-index'})
      }, response => {
        if (response.status === 422) {
          const emailError = response.data.tokenErrors.email

          // no queremos mostrar los errores de campo obligatorio, solo estos dos
          if (emailError && emailError.error === 'unconfirmed') {
            this.error = this.$t('auth.login.fields.email.errors.unconfirmed')
          } else {
            this.error = this.$t('auth.login.fields.password.errors.invalid')
          }
        }
      })
    }
  }
})
