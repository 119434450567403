import template from './_notification.pug'
import NotificationItem from '../../components/notification/notification-item'

export default Vue.extend({
  template: template(),
  components: {
    NotificationItem
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    notifications() {
      return this.$store.getters.backendNotifications
    },
    notificationsIndividual() {
      return this.$store.getters.backendNotificationsIndividual
    },
    showNotification: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  watch: {
    notificationsIndividual(newValue){
      if(newValue){
        this.$notifications.dynamic(newValue.description, newValue.type)
      }
    }
  },
  mounted() {
  },
  methods: {
    deleteNotification(i) {
      this.notifications.splice(i, 1)
    },
    t(key, options = {}) {
      return this.$t(`notifications.sidepanel.${key}`, options)
    }
  }
})
