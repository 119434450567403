import RouteParent from 'js/router/route-parent'

import ProgramationReportIndex from 'js/pages/programation-report/index.js'
import ProgramationReportNew from 'js/pages/programation-report/new.js'
import ProgramationReportEdit from 'js/pages/programation-report/edit.js'

import ProgramationReportCampaignsIndex from 'js/pages/campaigns/product-index.js'

export default [
  {
    path: '/programation-report',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'programation-report-index',
        component: ProgramationReportIndex,
        meta: {
          auth: true
        }
      },
      {
        path: 'new',
        name: 'programation-report-new',
        component: ProgramationReportNew,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit',
        name: 'programation-report-edit',
        component: ProgramationReportEdit,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/campaigns',
        name: 'programation-report-campaigns',
        component: ProgramationReportCampaignsIndex,
        meta: {
          auth: true
        }
      }
    ]
  }
]
