import {mapGetters} from 'vuex'
import modalTrackingNumber from './modal-tracking-number'
import template from './_form-edit.pug'

const Fields = [
  'name',
  'description',
  'ddiId',
  'campaignSiteId',
  'policyType',
  'lockTime',
  'levelMinOccupancy',
  'levelMaxOccupancy',
  'assignedTrackingNumbersCount',
  'campaign',
  'ddi'
]

export default Vue.extend({
  template: template(),
  components: {
    modalTrackingNumber
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      ddis: [],
      showTrackingNumberModal: false
    }
  },
  computed: {
    ...mapGetters(['policyTypes']),

    policyOptions() {
      return this.policyTypes.map(policy => ({
        label: policy.name,
        value: policy.key
      }))
    },
    ddiOptions() {
      return this.ddis.map(ddi => ({
        label: ddi.number,
        value: ddi.id
      }))
    }
  },
  created() {
    this.initializeData()
    this.getDdis()
  },
  methods: {
    openEditTrackingNumber() {
      this.$router.push({
        name: 'pool-edit-tracking-number',
        params: {
          id: this.initData.id
        },
        query: {
          pool: this.initData.name
        }
      })
    },
    getDdis() {
      API.ddis.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.ddis = data
      })
    },

    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data
    },

    t(key, options = {}) {
      return this.$t(`pool.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
