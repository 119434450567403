import {chunk} from 'lodash'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './show.pug'

export default Vue.extend({
  template: template(),
  data() {
    return {
      ready: false,
      data: {},
      campaigns: []
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_PRODUCTS')
    },

    canShowCampaigns() {
      return this.$user.permissions.includes('LIST_CAMPAIGNS')
    },

    countryFlagClass() {
      return this.data.customer.country ?
        `flag-icon-${this.data.customer.country.toLowerCase()}` : null
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('products.show.title'))
        this.loadData()
      }
    }
  },
  methods: {
    loadData() {
      this.ready = false

      API.products.show(this.$route.params.id).then(response => {
        this.data = response
        this.loadCustomer()
        this.loadCampaigns()

        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.products.index'), route: {name: 'products-index'}},
          {label: this.$t('breadcrumbs.products.show', {name: this.data.name}), route: {}}
        ])
      })
    },

    loadCustomer() {
      API.customers.show(this.data.customerId).then(response => {
        this.data.customer = response
        this.ready = true
      })
    },

    t(key, options = {}) {
      return this.$t(`products.show.${key}`, options)
    },

    loadCampaigns() {
      if (!this.data.campaignIds || this.data.campaignIds.length === 0) return

      const params = {
        id: this.data.campaignIds,
        order: {endAt: 'desc'},
        page: {number: 1, size: 6}
      }

      API.campaigns.index(params).then(response => {
        // Se reparten las campañas en grupos de 3 por temas de maquetación
        this.campaigns = chunk(response.data, 3)
      })
    },

    confirmDelete(product) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: product.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(product.id))
    },

    destroy(id) {
      API.products.destroy(id).then(() => {
        this.$notifications.success(this.t('succesfullyDeleted'))
        this.$router.push({name: 'products-index'})
      })
    }
  }
})
