import copyTextFromElementById from 'js/services/copy-text-from-html.js'
import template from './_modal-copy.pug'

export default Vue.extend({
  template: template(),
  props: {
    webhookData: {
      type: Object
    },
    keyData: {
      type: Object
    }
  },
  data() {
    return {
      idCopied: null,
      data: {},
      mode: 'webhook'
    }
  },
  created() {
    if (this.webhookData) {
      this.data = {
        ...this.webhookData,
        model: this.webhookData.webhook
      }
    } else {
      this.data = {
        ...this.keyData,
        model: this.keyData.key
      }
      this.mode = 'key'
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.ads.copy.${this.mode}.${key}`, options)
    },

    copyPixelControl(domId) {
      this.idCopied = domId
      if (copyTextFromElementById(domId)) {
        this.$notifications.success(this.t('succesfullyCopied'))
      } else {
        this.$notifications.error(this.t('errorOnCopy'))
      }
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
