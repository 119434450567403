import orderBy from 'lodash/orderBy'
import {openModalConfirmRemove} from 'js/services/modals.js'
import CplForm from './_cpl-providers-form.js'
import template from './cpl-providers-index.pug'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')

export default Vue.extend({
  template: template(),
  components: {
    CplForm
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      cpls: [],
      cplFormData: {},
      cplFormErrors: {},
      showForm: false
    }
  },
  computed: {
    campaignId() {
      return this.campaign.id
    },
    providerName() {
      return this.$route.query.name
    },

    defaultOrder() {
      return {currentField: 'cost', currentDir: 'asc'}
    },

    formMode() {
      return this.cplFormData.id ? 'edit' : 'new'
    }
  },
  async created() {
    await this.loadCpls()
    this.ready = true
  },
  methods: {
    openConfirmModal(data) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.deleteCpl(data))
    },

    async loadCpls() {     
      API.campaigns.cpls.index(this.campaignId, this.$route.query.providerId).then(({data}) => {
        this.cpls = data
      })
    },

    changeOrder(field, dir) {
      this.cpls = orderBy(
        this.cpls,
        item => item[field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        dir
      )
    },

    openNewForm() {
      this.cplFormData = {
        campaignId: this.campaignId
      }
      this.showForm = true
    },

    openEditForm(item) {
      this.cplFormData = {
        id: item.id,
        utmCampaign: item.utmCampaign,
        utmSource: item.utmSource,
        utmMedium: item.utmMedium,
        cost: item.cost,
        currency: item.currency,
        startDate: item.startDate,
        endDate: item.endDate
      }

      this.showForm = true
    },

    closeModal() {
      this.cplFormData = {}
      this.cplFormErrors = {}
      this.showForm = false
    },

    saveCpl(data) {
      this.cplFormErrors = {}

      if (this.formMode === 'edit') {
        this.updateCpl(data)
      } else {
        this.createCpl(data)
      }
    },

    updateCpl(data) {
      API.campaigns.cpls
      .update(this.campaignId,  this.$route.query.providerId, data.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.closeModal()
        this.loadCpls()
      }, errors => {
        this.cplFormErrors = errors
      })
    },

    createCpl(data) {
      API.campaigns.cpls.create(this.campaignId,  this.$route.query.providerId, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.closeModal()
        this.loadCpls()
      }, errors => {
        this.cplFormErrors = errors
      })
    },

    deleteCpl(data) {
      API.campaigns.cpls.destroy(this.campaignId,  this.$route.query.providerId, data.id).then(() => {
        this.$notifications.success(this.t('succesfullyDeleted'))
        this.closeModal()
        this.loadCpls()
      }, errors => {
        this.cplFormErrors = errors
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.cpl.${key}`, options)
    }
  }
})
