import pick from 'lodash/pick'
import FullTable from 'js/components/full-table/index.js'
import AnalyticsUtils from 'js/services/analytics-utils'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    leads: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      showFilters: true,
      filters: {phone: '', from: '', to: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    fromDateConfig() {
      return {
        ...this.dateConfig
      }
    },
    toDateConfig() {
      return {
        ...this.dateConfig
      }
    },

    defaultOrder() {
      return {currentField: 'fullname', currentDir: 'asc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  methods: {
    getData(queryOptions) {
      if ('filter' in queryOptions) {
        if ('from' in queryOptions.filter) {
           queryOptions.filter.from = moment(queryOptions.filter.from).format(FORMAT_DATE)
        }
        if ('to' in queryOptions.filter) {
           queryOptions.filter.to = moment(queryOptions.filter.to).format(FORMAT_DATE)
        }
      }
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    t(key, options = {}) {
      return this.$t(`customers.index.${key}`, options)
    },
    l(key, options = {}) {
      return this.$t(`customers.leads-details.${key}`, options)
    },
    exportLeads() {
      const filters = this.$refs.filterTable.queryData
      if ('filter' in filters) {
        if ('from' in filters.filter) {
          filters.filter.from = moment(filters.filter.from).format(FORMAT_DATE)
        }
        if ('to' in filters.filter) {
          filters.filter.to = moment(filters.filter.to).format(FORMAT_DATE)
        }
      }
      this.filters = {...filters}
      setTimeout(
        () => {
          this.loadLeadsCsv()
        },
        MOCKS ? 500 : 0
      )
    },
    loadLeadsCsv() {
      // Este solo se ve afectado por estos filtros
      const filters = {}
      if ('filter' in this.filters) {
        filters.filter = pick(this.filters.filter, [
          'from',
          'to',
          'phone'
        ])
      }
      API.leadsDetails
        .leadsCvs(this.$route.params.id, filters)
        .then(({data}) => {
          AnalyticsUtils.downloadCsv({
            title: this.l('exportCsv.title'),
            to: filters.to,
            from: filters.from,
            url: data.file
          })
        })
    }
  }
})
