export default {
  errorsRootKey: 'accounts',
  resource: 'accounts',
  actions: ['show'],
  leadsByAccount(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${customerId}/leads`,
      {params, ...options}
    ).then(response => response.data)
  },
  leadsCvs(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${customerId}/leads/export`,
      {params, ...options}
    ).then(response => response.data)
  },
  getLogsError(accountId, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${accountId}/leadsErrorLog`,
      {params, ...options}
    ).then(response => response.data)
  },
  exportLogsError(accountId, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${accountId}/leadsErrorLog/export`,
      {params, ...options}
    ).then(response => response.data)
  }
}
