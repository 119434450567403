import {cloneDeep, merge, set} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './update.pug'

const DefaultData = () => ({
  name: '',
  config: {
    fields: {}
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), cloneDeep(this.node)),
      // Array con los key y values del objeto 'node.config.fields'.
      // cada elemento del array tiene un objeto con los siguientes elementos:
      // key => valor de un key del objeto
      // value => valor del value de la key del objeto
      // newKey => indica si el campo a actualizar es nuevo (input) o uno existente (select)
      // manualUpdate => valor que comprueba si el value tiene el texto $lead
      dataFields: [],
      leadFieldValues: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    keySelectOptions() {
      return this.node.tags.filter(item => (
        ['workflow', 'commercial','form'].includes(item.type)
      )).map(item => (
        {label: item.name || item.key, value: `${item.type}.${item.key}`}
      )).sort((a, b) => (a.label.localeCompare(b.label)))
    },

    valueSelectOptions() {
      return this.node.tags.map(item => (
        {label: item.name || item.key, value: `$lead.${item.type}.${item.key}`}
      )).sort((a, b) => (a.label.localeCompare(b.label)))
    },

    fieldsErrors() {
      return this.dataFields.filter(item => item.errors).length
    }
  },
  created() {
    // Creamos el array de los fields, con el cual editaremos los mismos
    if (Object.keys(this.data.config.fields).length) {
      Object.keys(this.node.config.fields).forEach(field => {
        const isKeyInOptions = this.isKeyInOptions(field)

        this.dataFields.push({
          // al usuario no se le muestra el prefijo workflow
          key: isKeyInOptions ? field : field.replace('workflow.', ''),
          value: this.node.config.fields[field],
          newKey: !isKeyInOptions, // activa el select o el input (checkbox)
          manualUpdate: !this.node.config.fields[field].match(/\$lead/),
          errors: null
        })
      })
    } else {
      this.addField()
    }

    if (this.node.config) this.validateFields()
  },
  methods: {
    isKeyInOptions(key) {
      return this.keySelectOptions.map(option => (option.value)).filter(
        optionValue => (optionValue === key)
      ).length > 0
    },

    addField() {
      this.dataFields.push({key: '', value: '', manualUpdate: true, errors: null})
    },

    deleteField(index) {
      this.dataFields.splice(index, 1)
    },

    save() {
      // Convertimos el array de los fields en el objeto de datos que espera el backend
      this.data.config.fields = {}
      this.dataFields.forEach(field => {
        // Si es un nuevo campo, se añade el prefijo workflow ya que es ahí donde se guarda
        const key = field.newKey ? `workflow.${field.key}` : field.key
        this.data.config.fields[key] = field.value
      })

      validatePresence(this.data, [
        'name',
        'config.fields'
      ])

      this.validateFields()

      if (this.data.errors || this.fieldsErrors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    validateFields() {
      let error = false

      // validamos cada sección
      this.dataFields.forEach(field => {
        field.errors = null
        validatePresence(field, ['key', 'value'])

        // validamos unicidad de las claves
        const sameNameItems = this.dataFields.filter(item => item.name).length
        if (sameNameItems > 1) field.errors = {key: this.$t('errors.unique')}
        if (field.errors) error = true
      })

      // añadimos un error para que se muestre en rojo el nodo en el diagrama
      if (error) set(this.data, 'errors.config.fields', true)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.update.${key}`, options)
    }
  }
})
