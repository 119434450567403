import {mapGetters} from 'vuex'
import template from './modal-generate-pdf.pug'

export default Vue.extend({
  template: template(),
  props: {
    orderData: {
      type: Object
    }
  },
  data() {
    return {
      data: {
        segmentation: '',
        additionalDetails: '',
        activityTypeId: ''
      }
    }
  },
  computed: {
    ...mapGetters(['customerActivities']),
    activityTypeSelectOptions() {
      return this.customerActivities.map(activity => ({
        label: this.$t(`customerActivities.${activity.key}`),
        value: activity.id
      }))
    }
  },
  mounted() {
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`leadtrade.offerOrder.index.confirmationModal.pdf.${key}`, options)
    },

    generatePdf() {
      API.offerPurchase.order.pdf(this.orderData.id, this.data).then(() => {
        this.$emit('laod-data')
        this.$notifications.success(this.t('succesfully'))
        this.cancel()
      })
    },

    cancel() {
      this.$emit('input', false)
    }
  }
})
