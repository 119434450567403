export default {
  errorsRootKey: 'purchaseproposal',
  resource: 'leadtrade/purchaseproposal',
  actions: ['index', 'show'],
  create(data, params = {}, options = {}) {
    return Vue.http.post(
      'leadtrade/purchaseproposal', data,
      {params, ...options}
    ).then(response => response.data)
  },
  update(id, data, params = {}, options = {}) {
    return Vue.http.put(
      `leadtrade/purchaseproposal/${id}`, data,
      {params, ...options}
    ).then(response => response.data)
  },
  destroy(id, params = {}, options = {}) {
    return Vue.http.delete(
      `leadtrade/purchaseproposal/${id}`,
      {params, ...options}
    ).then(response => response.data)
  },
  changeStatus(data, params = {}, options = {}) {
    return Vue.http.put(
      'leadtrade/purchaseproposalstatus', data,
      {params, ...options}
    ).then(response => response.data)
  },
  order: {
    destroy(id, params = {}, options = {}) {
      return Vue.http.delete(
        `leadtrade/purchase-order/${id}`,
        {params, ...options}
      ).then(response => response.data)
    },
    create(data, params = {}, options = {}) {
      return Vue.http.post(
        'leadtrade/purchase-order', data,
        {params, ...options}
      ).then(response => response.data)
    },
    update(id, data, params = {}, options = {}) {
      return Vue.http.put(
        `leadtrade/purchase-order/${id}`, data,
        {params, ...options}
      ).then(response => response.data)
    },
    index(params = {}, options = {}) {
      return Vue.http.get(
        'leadtrade/purchase-order',
        {params, ...options}
      ).then(response => response.data)
    },
    byOffer(id, params = {}, options = {}) {
      return Vue.http.get(
        `leadtrade/purchase-order-by-offer/${id}`,
        {params, ...options}
      ).then(response => response.data)
    },
    show(id, params = {}, options = {}) {
      return Vue.http.get(
        `leadtrade/purchase-order/${id}`,
        {params, ...options}
      ).then(response => response.data)
    },
    pdf(id, data, params = {}, options = {}) {
      return Vue.http.post(
        `leadtrade/generate-purchase-order/${id}`,
        data,
        {params, ...options}
      ).then(response => response.data)
    },
    validateDetail(id, data, params = {}, options = {}) {
      return Vue.http.post(
        `leadtrade/purchase-order-exceeds-demand-volume/${id}`, data,
        {params, ...options}
      ).then(response => response.data)
    }
  }
}
