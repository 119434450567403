import {VueEditor} from 'vue2-editor'
import template from './_form.pug'
import typeNotification from '../../components/notification/type-notification'
import NotificationItem from '../../components/notification/notification-item'

const Fields = {
  title: '',
  date: moment().format('DD/MM/YYYY'),
  accountId: '',
  notificationId: '',
  description: '',
  explanation: ''
}

export default Vue.extend({
  template: template(),
  components: {
    VueEditor,
    NotificationItem
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      laiaAccounts: [],
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      typeNotification: typeNotification(),
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{header: 1}, {header: 2}], // custom button values
        [{list: 'ordered'}, {list: 'bullet'}],
        [{script: 'sub'}, {script: 'super'}], // superscript/subscript
        [{indent: '-1'}, {indent: '+1'}], // outdent/indent
        [{direction: 'rtl'}], // text direction

        [{size: ['small', false, 'large', 'huge']}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],

        [{color: []}, {background: []}], // dropdown with defaults from theme
        [{font: []}],
        [{align: []}],

        ['clean']
      ]
    }
  },
  computed: {
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadAccounts()
  },
  methods: {
    initializeData() {
      const data = JSON.parse(JSON.stringify(this.initData || Fields))
      this.data = data
    },
    loadAccounts(queryOptions = {}) {
      this.laiaAccounts = []
      API.laiaAccounts.index(queryOptions).then(({data}) => {
        this.laiaAccounts = data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    },
    t(key, options = {}) {
      return this.$t(`notifications.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
