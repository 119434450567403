import FullTable from 'js/components/full-table/index.js'
import template from './_form.pug'
import modalCampaign from './modal-campaign'
import modalCustomer from './modal-customer'
import modalCopy from './modal-copy'
import {months, lastTenYears} from '../../../services/months'

const Fields = [
  'customerId',
  'expiration',
  'campaignId',
  'leadCustomerTypologyId',
  'leadTypologyId',
  'demandOverTimes',
  'leadCurrencyId',
  'availableYearOffer'
]

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    modalCampaign,
    modalCustomer,
    modalCopy
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const detailDefault = () => ({
      leadAmount: '',
      cpl: '',
      availableMonthOffer: '',
      days: 22
    })

    return {
      data: {},
      formCampaign: false,
      formCustomer: false,
      formCopy: false,
      detail: detailDefault(),
      detailError: {},
      detailDefault: detailDefault(),
      months: months(),
      lastTenYearsOptions: lastTenYears(),
      customersOptions: [],
      customerTypologyOptions: [],
      campaignOptions: [],
      leadTypologyOptions: [],
      currencyOptioos: [],
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      }
    }
  },
  computed: {
    expirationDateConfig() {
      return {
        ...this.dateConfig,
        minDate: moment().format('DD/MM/YYYY')
      }
    },
    modeForm() {
      return this.data.id ? 'edit' : 'new'
    },

    isEditing() {
      return this.modeForm === 'edit'
    },

    saveButtonText() {
      return this.t(`saveButton.${this.modeForm}`)
    },

    monthOptions() {
      const newMonths = []
      // Se quita de la lista de meses los meses que ya estan en el detalle
      this.months.forEach(month => {
        if (
          !this.data.demandOverTimes
          .find(({availableMonthOffer}) => availableMonthOffer === month.value)
        ) {
          newMonths.push(month)
        }
      })
      return newMonths
    },
    getCampaignOptions() {
      return this.campaignOptions
        .filter(({customerId}) => customerId === this.data.customerId) || []
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadCustomers()
    this.loadCampaigns()
    this.loadCurrencys()
    this.loadCustomerTypologies()
    this.loadLeadTypologies()
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        if (key === 'demandOverTimes') {
          data[key] = this.initData ? this.initData[key] : []
        } else if (key === 'availableYearOffer') {
          data[key] = this.initData ? Number(
            this.initData.demandOverTimes[0].availableYearOffer
          ) : new Date().getFullYear()
        } else {
          data[key] = this.initData ? this.initData[key] : ''
        }
      })

      this.data = data
    },
    getMonthText(month) {
      return this.months.find(({value}) => value === month).label
    },
    showFormClone() {
      if (!this.validDetail()) return
      this.formCopy = true
    },
    addDetails(data) {
      if (!Array.isArray(this.data.demandOverTimes)) {
        this.data.demandOverTimes = []
      }
      data.forEach(detail => {
        this.data.demandOverTimes.push(detail)
      })
      this.detail = JSON.parse(JSON.stringify(this.detailDefault))
    },
    addDetail() {
      if (!this.validDetail()) return
      if (!Array.isArray(this.data.demandOverTimes)) {
        this.data.demandOverTimes = []
      }
      this.data.demandOverTimes.push(
        {...this.detail, availableYearOffer: this.data.availableYearOffer}
      )
      this.detail = JSON.parse(JSON.stringify(this.detailDefault))
    },

    validDetail() {
      this.detailError = {}
      Object.entries(this.detail).forEach(([key]) => {
        if (!this.detail[key]) {
          this.detailError = {
            ...this.detailError,
            [key]: this.$t('errors.blank')
          }
        }
        if (this.detail[key] < 0) {
          this.detailError = {
            ...this.detailError,
            [key]: this.$t('errors.min', {count: 0})
          }
        }
      })
      return Object.keys(this.detailError).length === 0
    },

    deleteDemand(month) {
      this.data.demandOverTimes = this.data.demandOverTimes
        .filter(({availableMonthOffer}) => availableMonthOffer !== month)
    },

    loadCustomers() {
      API.leadTrade.customers({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customersOptions = data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
      })
    },
    loadCampaigns() {
      API.leadTrade.campaigns({page: {number: 1, size: 1000}}).then(({data}) => {
        this.campaignOptions = data.map(campaign => ({
          customerId: campaign.customerId,
          label: campaign.name,
          value: campaign.id
        }))
      })
    },
    loadCurrencys() {
      API.leadTrade.currencys({page: {number: 1, size: 1000}}).then(({data}) => {
        this.currencyOptioos = data.map(currency => ({
          label: currency.name,
          value: currency.id
        }))
      })
    },
    loadCustomerTypologies() {
      API.leadTrade.customerTypology({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customerTypologyOptions = data.map(typ => ({
          label: typ.name,
          value: typ.id
        }))
      })
    },

    loadLeadTypologies() {
      API.leadTrade.leadTypology({page: {number: 1, size: 1000}}).then(({data}) => {
        this.leadTypologyOptions = data.map(typ => ({
          label: typ.name,
          value: typ.id
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`offerAndDemand.form.${key}`, options)
    },

    submit() {
      const data = {
        ...this.data,
        expiration: this.data.expiration ?
          moment(this.data.expiration, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
      }
      this.$emit('submit', data)
    },

    cancel() {
      this.$router.push({name: 'offer-and-demand-index'})
    }
  }
})
