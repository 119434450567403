import VueColor from 'vue-color'
import template from './index.pug'

// Componente para seleccionar un color en los formularios
// Para más opciones de configuración, el componente usa https://github.com/xiaokaike/vue-color
export default Vue.extend({
  template: template(),
  components: {
    'color-picker': VueColor.Chrome
  },
  props: {
    value: {type: String, default: '#427CF0'}
  },
  data() {
    return {
      pickerValue: {hex: this.value},
      displayPicker: false
    }
  },
  watch: {
    value() {
      this.pickerValue = {hex: this.value}
      this.inputValue = this.value
    },

    pickerValue: {
      // No se puede usar función arrow porque con -> bindea el contexto del padre y no a la
      // instancia de Vue, y al hacer this.$emit tenemos error porque no existe
      handler: function handler() {
        this.pickerValue = Object.assign(this.pickerValue)
        return this.$emit('input', this.pickerValue.hex)
      },
      immediate: true
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.documentClick)
  },
  methods: {
    updateFromPicker() {
      this.inputValue = this.pickerValue.hex
      this.$emit('input', this.pickerValue.hex)
    },

    togglePicker() {
      if (this.displayPicker) {
        this.hidePicker()
      } else {
        this.showPicker()
      }
    },

    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },

    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },

    // Cierra el selector de color al hacer click fuera del elemento
    documentClick(e) {
      const el = this.$refs.colorpicker
      const target = e.target

      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    }
  }
})
