import template from './assignment.pug'

export default Vue.extend({
  template: template(),
  data() {
    return {
      ready: false,
      data: {},
      errors: {},
      optionNumbers: [],
      selectedOptions: [],
      assignFast: false
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.$setPageTitle(this.$t('laiaAccounts.assignment.title'))
      this.loadData()
    }
  },

  methods: {
    t(key, options = {}) {
      return this.$t(`laiaAccounts.assignment.${key}`, options)
    },
    unselectedFilter(options, text) {
      return options.filter(item => (item.label.includes(text)))
    },
    loadListNumbers() {
      this.optionNumbers = []
      API.laiaAccounts.listNumbers(this.$route.params.id).then(response => {
        const optionNumbers = response.data
          .map((item => ({value: {...item}, label: `${item.number} ${item.providerName}`})))
        const selected = this.selectedOptions
        .map(item => ({value: {...item}, label: `${item.number} ${item.providerName}`}))

        this.optionNumbers = optionNumbers.concat(selected)
      })
    },

    submit() {
      API.laiaAccounts.assignTrackingNumber(this.$route.params.id, this.selectedOptions)
        .then(() => {
          this.$notifications.success(this.$t('laiaAccounts.assignment.succesfullyUpdated'))
          this.$router.push({
            name: this.lastPath || 'accounts-laia-index',
            params: this.lastPathParams
          })
        },
        errors => {
          console.error(errors)
          this.$notifications.error(this.$t('laiaAccounts.assignment.errorOnUpdated'))
        })
    },
    loadData() {
      this.selectedOptions = []
      this.ready = false
      API.laiaAccounts.getAssignTrackingNumber(this.$route.params.id).then(response => {
        this.selectedOptions = response.data
        this.ready = true
        this.loadListNumbers()
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.laiaAccounts.index'), route: {name: 'accounts-laia-index'}},
          {label: this.$t('breadcrumbs.laiaAccounts.assignment', {name: this.data.name}), route: {}}
        ])
      })
    }
  }
})
