import Message from './_message'
import template from './forgot-password.pug'

export default Vue.extend({
  template: template(),
  components: {Message},
  data() {
    return {
      data: {
        email: null
      },
      errors: {},
      success: false
    }
  },
  computed: {
    isSendDisabled() {
      return !this.data.email
    }
  },
  methods: {
    submit() {
      this.$auth.createPasswordToken(this.data).then(() => {
        this.success = true
      }, response => {
        if (response.status === 404) {
          this.errors = {email: this.$t('auth.forgotPassword.errors.email.not_found')}
        } else {
          this.errors = response.body.errors
        }
      })
    }
  }
})
