import {isEqual, isEmpty} from 'lodash'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './add.pug'

import ModalCreateProvider from './_modal-create-provider.js'
import ModalSaveTemplate from './_modal-save-template.js'

const defaultNoResultsImg = require('assets/images/content/empty-state.svg')

// Vista en la que se pueden subir un archivo con leads, tambien pueden crearse proveedores y
// editar/crear plantillas.
export default Vue.extend({
  template: template(),
  components: {
    ModalCreateProvider,
    ModalSaveTemplate
  },
  props: {
    campaign: {
      type: Object
    }
  },
  data() {
    return {
      inputsErrors: [],
      // Boleanos para mostrar modales y secciones
      ready: true,
      uploadingFile: false,
      uploadedFile: false,
      showModalSaveTemplate: false,
      showModalCreateProvider: false,
      noResultsImg: defaultNoResultsImg,
      // Datos basicos
      whitelist: ['xlsx', 'xls', 'csv'],
      file: null,
      errors: {},
      previousImportRequest: null,
      // Datos para los options
      providers: [],
      callCenters: [],
      ctis: [],
      services: [],
      templates: [],
      mappingElementLeadFields: [],
      // Datos de los inputs y selects
      providerId: null,
      callCenterId: null,
      ctiId: null,
      serviceId: null,
      templateId: null,
      mapping: [{}],
      // Errores
      errorsProvider: {},
      errorsMapping: [],
      isMappingErrorsEmpty: true,
      // Datos de los archivos
      fileId: null,
      fileHeaders: [],
      fileRows: [],
      previewHeaders: [],
      previewRows: [],
      isCold: false
    }
  },
  computed: {
    providerSelectOptions() {
      return [{label: this.t('fields.providerId.empty'), value: null}].concat(
        this.providers.map(provider => ({label: provider.name, value: provider.id}))
      )
    },
    callCenterSelectOptions() {
      return [{label: this.t('fields.callcenterPanel.empty'), value: null}].concat(
        this.callCenters.map(callCenter => ({label: callCenter.name, value: callCenter.id}))
      )
    },
    ctiSelectOptions() {
      return [{label: this.t('fields.callcenterPanel.empty'), value: null}].concat(
        this.ctis.map(cti => ({label: cti.name, value: cti.id}))
      )
    },

    serviceSelectOptions() {
      return [{label: this.t('fields.callcenterPanel.empty'), value: null}].concat(
        this.services.map(service => ({label: service.serviceId, value: service.serviceId}))
      )
    },
    templateSelectOptions() {
      return this.templates.map(templateEle => ({label: templateEle.name, value: templateEle.id}))
    },

    leadFieldSelectOptions() {
      return this.mappingElementLeadFields.map(leadField => (
        {label: this.$tLeadField(leadField), value: leadField}
      )).sort((a, b) => (a.label.localeCompare(b.label)))
    },
    ctiService() {
      return this.ctis.find(cti => (cti.id === this.ctiId))
    }
  },
  watch: {
    templateId(newValue) {
      if (newValue) {
        this.mapping = this.templates.find(templateEle => (templateEle.id === newValue)).mapping
      }
    },

    campaign: {
      immediate: true,
      handler() {
        if (!isEqual({}, this.data)) this.loadData()
      }
    },

    ctiService: {
      handler(newValue) {
        if (newValue) this.getCtiServices()
      }
    }
  },
  created() {
    this.$insertBreadcrumb({
      label: this.$t('breadcrumbs.leads.add'), route: {}
    })
  },
  methods: {
    abortAddFile() {
      if (this.previousImportRequest && !MOCKS) {
        this.previousImportRequest.abort()
      }
    },

    async loadData() {
      this.ready = false
      await this.loadProviders()
      await this.loadTemplates()
      await this.loadCallcenters()
      await this.loadCtis()
      this.loadMappingElementLeadFields()
      this.ready = true
    },

    loadProviders() {
      return API.providers.index(
        {order: {name: 'asc'}, campaignId: this.campaign.id, page: {number: 1, size: 1000}}
      ).then(response => {
        this.providers = []
        this.providers = response.data
      })
    },

    loadCallcenters() {
      return API.callCenters.index(
        {order: {name: 'asc'}, campaignId: this.campaign.id, page: {number: 1, size: 1000}}
      ).then(response => {
        this.callCenters = []
        this.callCenters = response.data
      })
    },
    loadCtis() {
      return API.ctis.index(
        {order: {name: 'asc'}, page: {number: 1, size: 1000}}
      ).then(response => {
        this.ctis = []
        this.ctis = response.data
      })
    },
    getCtiServices() {
      // console.log(this.data.config.callCenterId)
      // console.log(this.data.config.ctiId)

      return API.callCenters.ctiServices.index(this.callCenterId, this.ctiId).then(({data}) => {
        this.services = data
        // eslint-disable-next-line max-len
        // this.data.config.serviceId = this.data.config.serviceId ? this.data.config.serviceId : this.services && this.services[0] && this.services[0].serviceId
      })
    },

    loadTemplates() {
      return API.leadsFile.templates.index({campaignId: this.campaign.id}).then(response => {
        this.templates = []
        this.templates = response.data
      })
    },

    // TODO añadir los tipos
    loadMappingElementLeadFields() {
      this.mappingElementLeadFields = Object.values(LEAD_FIELDS)
    },

    changeFile() {
      this.uploadingFile = false
      this.uploadedFile = false
      this.fileHeaders = []
      this.fileRows = []
      this.previewHeaders = []
      this.previewRows = []
      this.fileId = null

      if (this.file) this.fileAdded()
    },

    async fileAdded() {
      this.errors.file = null
      this.uploadingFile = true
      this.abortAddFile()

      const data = new FormData()
      data.append('file', this.file)
      data.append('campaignId', this.campaign.id)

      // Añadimos esta espera para que cuando se ejecuta con MOCKS
      // se vea el loading
      if (MOCKS) await new Promise(res => setTimeout(res, 3000))

      await this.$http.post(
        'leads-file',
        data,
        {
          before: request => { this.previousImportRequest = request },
          skipLoading: true
        }
      ).then(response => {
        this.uploadedFile = true
        this.fileHeaders = response.data.data.headers
        this.fileRows = response.data.data.rows
        this.fileId = response.data.data.id
      })
    },

    createProvider(name) {
      API.providers.create({name, campaignId: this.campaign.id}).then(() => {
        this.$notifications.success(this.t('modals.createProvider.succesfullyCreated'))
        this.showModalCreateProvider = false
        this.loadProviders()
      }, error => {
        this.errorsProvider = error
      })
    },

    updateTemplate() {
      API.leadsFile.templates.update(
        this.templateId, {mapping: this.mapping, campaignId: this.campaign.id}
      ).then(() => {
        this.$notifications.success(this.t('modals.saveTemplate.succesfullyCreated'))
        this.showModalSaveTemplate = false
        this.loadTemplates()
      }, () => {
        this.$notifications.error(this.t('modals.saveTemplate.errorCreated'))
      })
    },

    createTemplate(name) {
      API.leadsFile.templates.create(
        {name, mapping: this.mapping, campaignId: this.campaign.id}
      ).then(() => {
        this.$notifications.success(this.t('modals.saveTemplate.succesfullyEdited'))
        this.showModalSaveTemplate = false
        this.loadTemplates()
      }, () => {
        this.$notifications.error(this.t('modals.saveTemplate.errorEdited'))
      })
    },
    checkInputs() {
      if (this.isCold) {
        if (!this.callCenterId) {
          this.inputsErrors.callCenterId = this.t('fields.mapping.leadField.errors.blank')
        }
        if (!this.serviceId) {
          this.inputsErrors.serviceId = this.t('fields.mapping.leadField.errors.blank')
        }
        if (!this.ctiId) {
          this.inputsErrors.ctiId = this.t('fields.mapping.leadField.errors.blank')
        }
      } else {
        this.inputsErrors = {}
      }
    },

    importLeads() {
      this.checkMapping()
      this.checkInputs()
      const error = Object.keys(this.inputsErrors).map(key => this.inputsErrors[key])

      if (this.isMappingErrorsEmpty && !error.length) {
        API.leadsFile.import(
          // eslint-disable-next-line max-len
          this.fileId, {mapping: this.mapping, campaignId: this.campaign.id, isCold: this.isCold, callCenterId: this.callCenterId, ctiId: this.ctiId, serviceId: this.serviceId, providerId:this.providerId}
        ).then(() => {
          this.$notifications.success(this.t('succesfullyImported'))
        },
        errors => {
          this.$notifications.error(`${this.t('errorImported')} [${errors.body.errors.id}]`)
          // this.errors = errors
        })
      } else {
        this.$notifications.error(`${this.t('errorImported')}`)
      }
    },

    openDeleteTemplateConfirmModal() {
      openModalConfirmRemove(
        this.t('removeConfirmationModal.title'),
        '',
        this.t('removeConfirmationModal.okButton')
      ).then(() => this.deleteTemplate())
    },

    deleteTemplate() {
      API.leadsFile.templates.destroy(this.templateId).then(() => {
        this.templateId = null
        this.$notifications.success(this.t('succesfullyDestroyTemplate'))
        this.mapping = [{leadField: null, column: null}]
        this.loadTemplates()
      }, () => {
        this.$notifications.error(this.t('errorDestroyTemplate'))
      })
    },

    saveTemplate() {
      this.checkMapping()

      if (this.isMappingErrorsEmpty) this.showModalSaveTemplate = true
    },

    // función que genera los errores del mapping de la plantilla
    checkMapping(preview = false) {
      this.errorsMapping = []
      const usedColumns = []
      const usedColumnNames = []

      this.mapping.forEach(mappingElement => {
        const errors = {}

        // Comprobamos que no se repita el nombre de columna
        if (usedColumnNames.includes(mappingElement.leadField)) {
          errors.leadField = this.t('fields.mapping.leadField.errors.taken')
        }
        // Comprobamos que tenga un nombre
        if (!mappingElement.leadField) {
          errors.leadField = this.t('fields.mapping.leadField.errors.blank')
        }
        // Comprobamos que el número no sea mayor que el del archivo (solo al previsualizar)
        if (preview && mappingElement.column > this.fileHeaders.length) {
          errors.column = this.t('fields.mapping.column.errors.invalid')
        }
        // Comprobamos que no se repita el numero de columna
        if (usedColumns.includes(mappingElement.column)) {
          errors.column = this.t('fields.mapping.column.errors.taken')
        }
        // Comprobamos que tenga numero de columna
        if (!mappingElement.column) {
          errors.column = this.t('fields.mapping.column.errors.blank')
        }

        usedColumns.push(mappingElement.column)
        usedColumnNames.push(mappingElement.leadField)
        this.errorsMapping.push(errors)
      })

      this.isMappingErrorsEmpty = !this.errorsMapping.find(errors => (!isEmpty(errors)))
    },

    cancelUploadFile() {
      this.abortAddFile()
      this.file = null
      this.uploadingFile = false
    },

    addMappingElement() {
      this.mapping.push({column: null, leadField: null})
    },

    deleteMappingElement(index) {
      this.mapping.splice(index, 1)
      this.errorsMapping.splice(index, 1)
    },

    addErrorFile(errors) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`fields.file.errors.${error}`)
        )
      }
    },

    preview() {
      this.checkMapping(true)

      // Si hay errores en mapping no hacemos nada
      if (!this.isMappingErrorsEmpty) return

      if (this.fileHeaders.length >= this.mapping.length) {
        this.previewHeaders = this.getPreviewFileHeaders()
        this.previewRows = this.getPreviewFileRows()
      } else {
        this.$notifications.error(this.t('errorPreview'))
      }
    },

    getPreviewFileHeaders() {
      return this.mapping.map(mapElement => (mapElement.leadField))
    },

    getPreviewFileRows() {
      return this.fileRows.map(fileRow => (
        this.mapping.map(rowElement => (fileRow[rowElement.column - 1]))
      ))
    },

    importLeadsFile() {
      API.leadsFile.templates.create(this.fileId, {
        mapping: this.mapping, campaignId: this.campaign.id, providerId: this.providerId
      }).then(() => {
        this.$notifications.success(this.t('succesfullyImported'))
        this.creatingTemplate = false
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.addLeads.form.${key}`, options)
    }
  }
})
