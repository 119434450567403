import RouteParent from 'js/router/route-parent'

import ConsumptionIndex from 'js/pages/consumption/index.js'

export default [
  {
    path: '/consumption',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'consumption-index',
        component: ConsumptionIndex,
        meta: {
          auth: true
        }
      }
    ]
  }
]
