import AnalyticsChart from 'js/components/analytics/chart'
import dynamicChartPie from 'js/components/analytics/dynamic-chart-pie'
import dynamicChartBar from 'js/components/analytics/dynamic-chart-bar'
import draggable from 'vuedraggable'
import template from './_charts-list.pug'

const noDataImg = require('assets/images/content/empty-report.svg')

export default Vue.extend({
  template: template(),
  components: {
    AnalyticsChart,
    draggable
  },
  data() {
    return {
        noDataImg,
        modal: false,
        filters: {},
        dynamicChartData: [],
        chartsList: [
          {
            id: 111,
            order: 0,
            name: 'Grafico A 111',
            params: null,
            type: 'pie'
          },
          {
            id: 222,
            order: 1,
            name: 'Grafico B 222',
            params: null,
            type: 'bar'
          },
          {
            id: 333,
            order: 2,
            name: 'Grafico C 333',
            params: null,
            type: 'pie'
          },
          {
            id: 444,
            order: 3,
            name: 'Grafico D 444',
            params: null,
            type: 'bar'
          }
        ]
    }
  },
  mounted() {
    this.loadDynamicChart()
  },
  methods: {
    openModal() {
        this.modal = !this.modal
    },
    onEnd() {
      this.dynamicChartData.forEach((item, i) => {
        item.order = i
      })
    },
    dynamicChartOptions({type, options}) {
        if (!options) return null
        if (type === 'pie') return dynamicChartPie.options(options)
        return dynamicChartBar.options(options)
    },

    async loadDynamicChart() {
      const dynamicChartData = []
      this.dynamicChartData = []
      await Promise.all(
        this.chartsList.map(async chart => {
          const customerId = 1
          const filters = {
            campaignId: 'campaign1',
            from: '22/08/2021',
            to: '29/08/2021'
          }
          return API.analytics.dynamicReport(customerId, filters)
          .then(({data}) => dynamicChartData.push({options: data, ...chart}))
        })
      )
      this.dynamicChartData = dynamicChartData.sort((a, b) => (a.order - b.order ? 1 : -1))
    },
    t(key, options = {}) {
        return this.$t(`laiaAnalytics.${key}`, options)
    }
  }
})