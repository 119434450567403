import template from './dash-contact-center-tab.pug'

export default Vue.extend({
  name: 'dash-contact-center-tab',
  template: template(),
  components: {
  },
  props: {
    campaign: {
      type: Object,
      required: true, 
    }
  },
  data() {
    return {
      embeddedReportUrl: ""
    }
  },
  computed: {
    
  },
  async created() {
    await this.loadEmbeddedReport()
  },
  methods: {
    async loadEmbeddedReport() {
      API.campaigns.embeddedreport.index(this.campaign.id).then(({data}) => {

        this.embeddedReportUrl = data.url

      })
    },
  }
})
