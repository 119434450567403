export default {
  errorsRootKey: 'googleads',
  resource: 'googleads',
  actions: ['index', 'show', 'create', 'update', 'destroy'],
  leadForms: {
    index(params = {}, options = {}) {
      return Vue.http.get(
        'googleads/leadforms',
        {params},
        {options}
      ).then(response => response.data)
    },
    create(googleCustomerId, data, options = {}) {
      return Vue.http.post(
        `googleads/${googleCustomerId}/leadforms`,
        data,
        {errorsRootKey: 'leadforms', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    destroy(id, options = {}) {
      return Vue.http.delete(
        `googleads/leadforms/${id}`,
        {options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    },
    getForms(googleCustomerId, laiacustomerId, params = {}, options = {}) {
      return Vue.http.get(
        `googleads/${laiacustomerId}/leadforms/${googleCustomerId}`,
        {params},
        {options}
      ).then(response => response.data)
    }
  },
  enable(id, options = {}) {
    return Vue.http.post(
      `googleads/${id}/enable`,
      {...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  },
  disable(id, options = {}) {
    return Vue.http.post(
      `googleads/${id}/disable`,
      {...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  },
  actionConversion(googleCustomerId, laiacustomerId, params = {}, options = {}) {
    return Vue.http.get(
      `googleads/${laiacustomerId}/actions/${googleCustomerId}`,
      {params},
      {options}
    ).then(response => response.data)
  },
  accountsGoogleAds(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `googleads/${customerId}/customers`,
      {params},
      {options}
    ).then(response => response.data)
  },
  getToken(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `googleads/${customerId}/token`,
      {params},
      {options}
    ).then(response => response.data)
  },
  getLeadToken(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `googleads/${customerId}/lead/token`,
      {params},
      {options}
    ).then(response => response.data)
  },
  setToken(customerId, data, options = {}) {
    return Vue.http.post(
      `googleads/${customerId}/token`,
      data,
      {errorsRootKey: 'token', ...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  },
  setLeadToken(customerId, data, options = {}) {
    return Vue.http.post(
      `googleads/${customerId}/lead/token`,
      data,
      {errorsRootKey: 'token', ...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  }
}
