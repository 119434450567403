import template from './_facebook_modal.pug'
import FacebookForm from '../../facebook/index'
import { cloneDeep } from 'lodash'

export default Vue.extend({
  template: template(),
  components: {
    FacebookForm
  },
  props: {
    initData: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      errors: {},
      data: {}
    }
  },
  created() {
    this.initializeData()
  },
  methods: {
    cancel() {
      this.$emit('input', false)
    },
    createEvent(event) {
      this.data = null
      this.data = event
    },
    initializeData() {
      this.data = cloneDeep(this.initData)
    },

    createProvider() {
      this.$emit('set-data', this.data)
      this.cancel()
    },

    t(key, options = {}) {
      return this.$t(`customers.aibe.form.facebook.${key}`, options)
    }
  }
})
