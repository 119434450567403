import NotificationsForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    NotificationsForm
  },
  data() {
    return {
      lastPath: null,
      lastPathParams: {},
      errors: {}
    }
  },
  beforeRouteEnter(_to, from, next) {
    // se añade el nombre de la ultima dirección, ya que, el boton cancelar debe redirigir
    // a la ultima dirección si esta es el listado de operadores o la creación de operadores
    next(vm => {
      if (['notifications-index', 'notifications-new', 'notifications-edit'].includes(from?.name)) {
        vm.lastPathParams = from.params
        vm.lastPath = from.name
      }
    })
  },
  created() {
    this.$setPageTitle(this.$t('notifications.new.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.notifications.index'), route: {name: 'notifications-index'}},
      {label: this.$t('breadcrumbs.notifications.new'), route: {}}
    ])
  },
  methods: {
    createNotification(data) {
      API.notifications.create(data).then(() => {
        this.$notifications.success(this.$t('notifications.new.succesfullyCreated'))

        this.$router.push({
          name: this.lastPath || 'notifications-index',
          params: this.lastPathParams
        })
      }, errors => {
        this.errors = errors
      })
    }
  }
})
