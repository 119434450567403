import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    demands: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      filters: {campaignName: '', customerName: ''},
      customersOptions: [],
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    defaultOrder() {
      return {currentField: 'createdAt', currentDir: 'desc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
    this.loadCustomers()
  },
  methods: {
    loadCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customersOptions = data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`offerAndDemand.index.${key}`, options)
    },

    confirmDelete(demand) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: demand.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(demand.id))
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    destroy(id) {
      API.leadTrade.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
