import leadAtentionBars from 'js/components/analytics/lead-atention-bars.js'
import AnalyticsChart from 'js/components/analytics/chart'
import template from './lead-atention.pug'

export default Vue.extend({
  name: 'item-report-table',
  template: template(),
  components: {
    AnalyticsChart
  },
  props: {
    items: {
        type: [Object, Array],
        required: true
    },
    showSubColumn: Boolean
  },
  data() {
    return {
      data: []
    }
  },
  watch: {
    items() {
      this.setData()
    }
  },
  mounted() {
    this.setData()
    this.timeAtentionLeadChartOptions()
  },
  methods: {
    timeAtentionLeadChartOptions() {
      return leadAtentionBars.options(this.data)
    },
    setData() {
      this.data = this.items
    },
    t(key, params = {}) {
      return this.$i18n.t(`analytics.charts.${key}`, params)
    }
  }
})
