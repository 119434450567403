import template from './tracking-number.pug'
import ModalTrackingNumber from './modal-tracking-number'

export default Vue.extend({
  template: template(),
  components: {
    ModalTrackingNumber
  },
  data() {
    const constants = {
      assign: 'assign',
      release: 'release',
      fast: 'fast',
      advanced: 'advanced'
    }
    return {
      constants,
      showTrackingNumberModal: false,
      data: {},
      ready: false,
      optionNumbers: [],
      selectedOptions: [],
      assignModelSelected: constants.fast,
      fast: {
        action: constants.assign,
        quantity: null
      }
    }
  },
  computed: {
    isFastAssignMode() {
      return this.assignModelSelected === 'fast'
    },
    isAdvancedAssignMode() {
      return this.assignModelSelected === 'advanced'
    },
    totalTrackingNumbers() {
      return Number(this.data.assignedTrackingNumbersCount) +
       Number(this.data.availableTrackingNumbersCount)
    },
    fastQuantityNumberError() {
      return {
        [this.constants.assign]: this.fastEndFree < 0 ?
          this.t('numerations.fast.errorAssign') : null,
        [this.constants.release]: this.fastEndAssigned[this.fast.action] < 0 ?
          this.t('numerations.fast.errorRelease') : null
      }
    },
    fastEndAssigned() {
      const assigned = Number(this.data.assignedTrackingNumbersCount || 0)
      const quantity = Number(this.fast.quantity || 0)
      return {
        [this.constants.assign]: quantity + assigned,
        [this.constants.release]: assigned - quantity
      }
    },
    fastEndFree() {
      const assigned = this.fastEndAssigned[this.fast.action]
      return this.totalTrackingNumbers - assigned
    },
    advancedEndFree() {
      return this.totalTrackingNumbers - this.selectedOptions.length
    },
    advancedEndAssigned() {
      return this.selectedOptions.length
    },
    btnActionDisabled() {
      // Se desabilita el boton de accion dinamicamente por el modo de asignacion
      return {
        // Fast mode
        [this.constants.fast]: this.fastQuantityNumberError[this.fast.action] !== null ||
          this.fast.quantity === null,

        // Advanced mode
        [this.constants.advanced]: false
      }
    },
    assignMode() {
      return {
        fast: {
          icon: 'switch',
          title: this.t('numerations.fastAssign'),
          btnChange: this.t('numerations.advancedAssign'),
          btnChangeIcon: 'list-check',
          btnAction: this.t('numerations.fast.btnAction'),
          btnActionIcon: 'send'
        },
        advanced: {
          icon: 'list-check',
          title: this.t('numerations.advancedAssign'),
          btnChange: this.t('numerations.fastAssign'),
          btnChangeIcon: 'switch',
          btnAction: this.t('numerations.advanced.btnAction'),
          btnActionIcon: 'switch-o'
        }
      }
    }
  },

  created() {
    this.$setPageTitle(this.$t('pool.trackingNumber.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.pool.index'), route: {name: 'pool-index'}},
      {
        label: this.$t('breadcrumbs.pool.edit', {name: this.$route.query?.pool}),
        route: {
          name: 'pool-edit',
          params: {id: this.$route.params.id}
        }
      },
      {label: this.$t('breadcrumbs.pool.trackingNumbers'), route: {}}
    ])
    this.loadData()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`pool.trackingNumber.${key}`, options)
    },
    changeAssignMode() {
      this.assignModelSelected = this.assignModelSelected === 'fast' ? 'advanced' : 'fast'
    },
    unselectedFilter(options, text) {
      return options.filter(item => (item.label.includes(text)))
    },
    openTrackingNumberModal() {
      this.showTrackingNumberModal = true
    },
    executeAction() {
      if (this.isFastAssignMode) {
        this.executeFastAction()
      } else {
        this.executeAdvancedAction()
      }
    },
    executeFastAction() {
      const payload = {
        id: this.$route.params.id,
        action: this.fast.action,
        data: {
          count: this.fast.quantity
        }
      }
      API.pool.trackingNumber.executeFastAction(
        payload.id,
        payload.action,
        payload.data
      ).then(() => {
        this.$notifications.success(this.$t('pool.trackingNumber.succesfullyCreated'))
        this.backToEditPool()
      }, errors => {
        console.log(errors)
      })
    },
    executeAdvancedAction() {
      const payload = {
        id: this.$route.params.id,
        data: {
          assignedTrackingNumbers: this.selectedOptions
        }
      }
      API.pool.trackingNumber.executeAdvancedAction(
        payload.id,
        payload.data
      ).then(() => {
        this.$notifications.success(this.$t('pool.trackingNumber.succesfullyCreated'))
        this.backToEditPool()
      }, errors => {
        console.log(errors)
      })
    },
    backToEditPool() {
      this.$router.push({
        name: 'pool-edit',
        params: {id: this.$route.params.id}
      })
    },
    loadData() {
      this.data = {}
      this.optionNumbers = []
      this.selectedOptions = []
      API.pool.trackingNumber.get(this.$route.params.id).then(({data}) => {
        this.data = data || {}
        const assigned = data.assignedTrackingNumbers
          .map((item => ({value: {...item}, label: `${item.number} ${item.description}`})))
        const available = data.availableTrackingNumbers
          .map((item => ({value: {...item}, label: `${item.number} ${item.description}`})))
        this.selectedOptions = data.assignedTrackingNumbers
        this.optionNumbers = assigned.concat(available)
        this.ready = true
      })
    }
  }
})
