import Vue from 'vue'

const localeRoot = 'analytics.charts.effectivenessContactability'

export default {
  options: data => {
    if (!data) return null
    if (data.length === 0) return null

    return {
      grid: {
        top: '13%',
        left: '4%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: v => v.reduce(
            (acc, item) => {
              if (item.seriesName === Vue.i18n.t(`${localeRoot}.series.leadsCount`)) {
                return `${acc}${item.seriesName}: ${item.value}<br />`
              }
              // eslint-disable-next-line max-len
              return `${acc}${item.seriesName}: ${parseFloat((Number(item.value) * 100) / v[0].value).toFixed(2)}%<br />`
            }, `${v[0].name}<br />`
          )
      },
      xAxis: [
        {
          type: 'category',
          data: data.map(item => item.date),
          axisLabel: {
            margin: 10,
            // align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: Vue.i18n.t(`${localeRoot}.series.leadsCount`),
          type: 'bar',
          stack: 'Ad',
          barGap: 0,
          data: data.map(item => item.leadsCount)
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.effectivenessPercentage`),
          type: 'line',
          data: data
          .map(item => parseFloat(item.effectivenessPercentage * item.leadsCount / 100).toFixed(2))
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.contactabilityPercentage`),
          type: 'line',
          data: data
          .map(
            item => parseFloat(item.contactabilityPercentage * item.leadsCount / 100).toFixed(2)
          )
        }
      ],
      media: [
        {
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        icon: 'circle',
        bottom: '3%'
      },
      color: GRAPH_COLORS
    }
  }
}
