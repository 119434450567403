export default {
    leadsCount(customerId, params = {}, options = {}) {
        return Vue.http.get(
          `customers/${customerId}/leads`,
          {params, ...options}
        ).then(response => response.data)
    },
    leadsCvs(customerId, params = {}, options = {}) {
      return Vue.http.get(
        `customers/${customerId}/leads/export`,
        {params, ...options}
      ).then(response => response.data)
    }
  }
