import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import {signalr} from '../../services/signalR'

const exclusiveAgencyRole = [
  'CREATE_OFFER',
  'EDIT_OFFER',
  'DELETE_OFFER'
]

const getUserPermissions = syntaxSugarUser => {
  let permissions = []

  // Permisos de superadmin (S)
  if (syntaxSugarUser.isSuperAdmin) {
    permissions = Object.values(PERMISSIONS)
    .filter(
      ele => ele !== 'MANAGE_LAIA_ADMIN' &&
      // Se ocultan los modulos de agencia
      !exclusiveAgencyRole.includes(ele)
    )
  }

  // Permisos de admin (A)
  if (syntaxSugarUser.isAdmin) {
    permissions = Object.values(PERMISSIONS)
    .filter(
      ele => ele !== 'MANAGE_ADMINS' &&
      ele !== 'MANAGE_LAIA_ADMIN' &&
      // Se ocultan los modulos de agencia
      !exclusiveAgencyRole.includes(ele)
    )
  }

  if (syntaxSugarUser.isAdminLaia) {
    permissions = [
      'MANAGE_LAIA_ADMIN'
    ]
  }

  // Permisos de operador (O), los cuales se dividen en 2 por recurso:
  // - VIEW_: puede acceder al listado y al show
  // - MANAGE_: puede realizar acciones sobre el recurso como editar, crear o eliminar
  //
  // Si fuese necesario concretar más, se usarían los de las acciones:
  //  - VIEW: SHOW y LIST
  //  - MANAGE: EDIT, CREATE y DELETE
  //
  // No mezclar ambas formas, si usmos por ejemplo SHOW, no mezclar con VIEW
  if (syntaxSugarUser.isOperator) {
    // Permisos de operador sin target (O)
    if (syntaxSugarUser.isOperatorWithNoTarget) {
      permissions = [
        'LIST_CAMPAIGNS', 'EDIT_CAMPAIGNS', 'DELETE_CAMPAIGNS',
        'SHOW_ANALYTICS',
        'VIEW_TAGS',
        'VIEW_LEADS', 'MANAGE_LEADS',
        'VIEW_AUTOMATIONS',
        'MANAGE_CONFIG',
        'VIEW_CUSTOMERS', 'EDIT_CUSTOMERS', 'DELETE_CUSTOMERS',
        'VIEW_CALL_CENTERS', 'MANAGE_CALL_CENTERS',
        'VIEW_PRODUCTS', 'MANAGE_PRODUCTS',
        'VIEW_PIXEL_CONTROL'
      ]

      if (syntaxSugarUser.allowManageWorkflows) permissions.push('MANAGE_AUTOMATIONS')
      if (syntaxSugarUser.allowCreateCampaigns) permissions.push('CREATE_CAMPAIGNS')
      if (syntaxSugarUser.allowCreateCustomers) permissions.push('CREATE_CUSTOMERS')
    }

    // Permisos de operador con target cliente (O-C)
    if (syntaxSugarUser.isOperatorWithClient) {
      permissions = [
        'LIST_CAMPAIGNS',
        'SHOW_ANALYTICS',
        'VIEW_TAGS',
        'VIEW_LEADS',
        'VIEW_AUTOMATIONS',
        'VIEW_CUSTOMERS',
        'VIEW_PRODUCTS'
      ]
    }

    // Permisos de operador con target Call Center (O-CC)
    if (syntaxSugarUser.isOperatorWithCallCenter) {
      permissions = [
        'LIST_CAMPAIGNS',
        'SHOW_ANALYTICS',
        'VIEW_CALL_CENTERS'
      ]
    }

    // Permisos de operador con target Site (O-U)
    if (syntaxSugarUser.isOperatorWithSite) {
      permissions = [
        'LIST_CAMPAIGNS',
        'SHOW_ANALYTICS',
        'VIEW_PIXEL_CONTROL'
      ]
    }
  }
   // Permisos de agencia
   if (syntaxSugarUser.isAgencySupplier) {
    permissions = [
      'VIEW_DEMAND',
      'VIEW_OFFER',
      'CREATE_OFFER',
      'EDIT_OFFER',
      'DELETE_OFFER',
      'VIEW_PURCHASE_ORDER'
    ]
  }

  // Permisos de Artemisa
  if (syntaxSugarUser.isArtemisaClient) {
    permissions = ['VIEW_AUTOMATIONS', 'MANAGE_AUTOMATIONS']
  }

  if (MOCKS) {
    permissions.forEach(permission => {
      if (!PERMISSIONS.includes(permission)) {
        // eslint-disable-next-line no-console
        console.error(`Permiso ${permission} no válido`)
      }
    })
  }

  return permissions
}

/**
 * Añade una propiedad del tipo isRole que devuelve true cuando el usuario tiene ese rol
 * */
const syntaxSugarRole = (user, embedded) => {
  const syntaxSugarUser = {...user}
  Object.values(ROLES).forEach(role => {
    Object.defineProperty(syntaxSugarUser, `is${upperFirst(camelCase(role))}`, {
      get: () => role === user.role
    })
  })

  Object.defineProperty(syntaxSugarUser, 'isArtemisaClient', {
    get: () => embedded === 'artemisa'
  })

  Object.defineProperty(syntaxSugarUser, 'isOperatorWithClient', {
    get: () => syntaxSugarUser.isOperator && syntaxSugarUser.targetType === 'customer'
  })

  Object.defineProperty(syntaxSugarUser, 'isOperatorWithCallCenter', {
    get: () => syntaxSugarUser.isOperator && syntaxSugarUser.targetType === 'call-center'
  })

  Object.defineProperty(syntaxSugarUser, 'isOperatorWithSite', {
    get: () => syntaxSugarUser.isOperator && syntaxSugarUser.targetType === 'site'
  })

  Object.defineProperty(syntaxSugarUser, 'isOperatorWithNoTarget', {
    get: () => syntaxSugarUser.isOperator && !syntaxSugarUser.targetId
  })

  const permissions = getUserPermissions(syntaxSugarUser)
  Object.defineProperty(syntaxSugarUser, 'permissions', {
    get: () => permissions
  })

  syntaxSugarUser.can = permission => {
    if (MOCKS && !PERMISSIONS.includes(permission)) {
      // eslint-disable-next-line no-console
      console.error(`Permiso ${permission} no válido (can)`)
    }

    return syntaxSugarUser.permissions.includes(permission)
  }

  return syntaxSugarUser
}

export default {
  state: {
    currentUser: null,
    currentPermission: null,
    embedded: null
  },
  getters: {
    currentUser: state => state.currentUser,
    checkAccountPermission: state => permission => state.currentPermission[permission],
    hasCurrentUser(state) {
      return !!state.currentUser
    },
    embeddedArtemisa: state => state.embedded === 'artemisa'
  },
  mutations: {
    setEbedded(state, client) {
      state.embedded = client
    },
    setCurrentUser(state, user) {
      if (user) user.fullName = `${user.firstName} ${user.lastName}`
      state.currentUser = user ? syntaxSugarRole(user) : user
    },
    setCurrentAccountPermission(state, permissions) {
      state.currentPermission = permissions
    }
  },
  actions: {
    loadCurrentUser({commit, dispatch}) {
      return API.profile.show({representation: 'basic'}).then(data => {
        commit('setCurrentUser', data)
        dispatch('loadCurrentAccountPermission', data)
        signalr.start(Vue.auth.token())
        return data
      })
    },
    loadCurrentAccountPermission({commit}, {accountId}) {
      return API.laiaAccounts.getPermission(accountId).then(data => {
        const permissions = data.data.reduce((acc, curr) => ({...acc, [curr]: true}), {})
        commit('setCurrentAccountPermission', permissions)
        return data
      })
    },
    rechargeRolesCurrentUser({commit, state}) {
      commit('setCurrentUser', state.currentUser)
    }
  }
}
