import {cloneDeep, isEqual} from 'lodash'
import FacebookModal from './_facebook_modal.js'
import ConfirmSourceModal from './_confirm_source_modal.js'
import FormModal from './_form_modal.js'
import template from './lead-source.pug'

const DefaultValues = () => Object.freeze({
  sourceLeads: {
    facebookLeadAds: false,
    webService: false,
    form: false,
    facebookLeadAdsSetting: {},
    formSetting: {}
  }
})

const Fields = Object.keys(DefaultValues())

const Validations = {}

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  components: {
    ConfirmSourceModal,
    FacebookModal,
    FormModal
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      sources: [
        {
          icon: 'contact',
          title: 'Landing Form',
          description: 'Seleccione si el origen es un formulario de una landing page',
          form: 'FormModal',
          active: false,
          configured: false,
          configOption: true,
          sourceType:"form"
        },
        {
          icon: 'facebook',
          title: 'Facebook lead Ads',
          description: 'Seleccione si el origen es un formulario en facebook',
          form: 'FacebookModal',
          active: false,
          configured: false,
          configOption: true,
          sourceType:"facebook"
        },
        {
          icon: 'web',
          title: 'Web service',
          description: 'Seleccione si el origen es por API',
          active: false,
          configured: true,
          configOption: false,
          sourceType:"webservice"
        },
        // {
        //   icon: 'user',
        //   title: 'Google Lead Form Ads',
        //   description: 'Seleccione si el origen es un formulario en google',
        //   active: false,
        //   configured: false
        // }
      ],
      data: {},
      ready: false,
      showFacebookModal: false,
      showFormModal: false,
      source: {
        option: null,
        modal: false,
        sourceType: ""
      }
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    }
  },
  methods: {
    initializeData() {
      const data = {}
      Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
      this.data = data
      this.ready = true
    },

    openModal(modal) {
      this[`show${modal}`] = true
    },

    configModal() {
      this.openModal(this.source.option)
    },

    cancelModal(){

      console.log(this.source);
      switch(this.source.sourceType)
      {
          case "form": {this.data.sourceLeads.form=false;this.sources[0].active=false;  break;} 
          case "facebook":  {this.data.sourceLeads.facebookLeadAds=false;this.sources[1].active =false;  break;}
          case "webservice":  {this.data.sourceLeads.webService=false; break; }        
      }
      this.source.active = false;
      this.source.option = null,
      this.source.modal= false;
      this.$emit('input', {...this.data})
      console.log(this.sources)
    }, 

    setFacebookData(event) {
      this.data.sourceLeads.facebookLeadAds = true
      this.data.sourceLeads.facebookLeadAdsSetting = cloneDeep({})
      this.data.sourceLeads.facebookLeadAdsSetting = event
      this.sources[1].configured = true
    },

    setFormData(event) {
      this.data.sourceLeads.form = true
      this.data.sourceLeads.formSetting = null
      this.data.sourceLeads.formSetting = event
      this.sources[0].configured = true
    },

    t(key, options = {}) {
      return this.$t(`customers.aibe.form.${key}`, options)
    },

    selectSource(source) {     
    
      switch(source.sourceType)
      {
          case "form":  this.data.sourceLeads.form=!source.active; break;
          case "facebook":  this.data.sourceLeads.facebookLeadAds=!source.active; break;
          case "webservice":  this.data.sourceLeads.webService=!source.active; break;         
      }
     
      source.active = !source.active
      this.source.sourceType = source.sourceType;
      if (source.active) {       
        this.source.option = source.form
        if (source.configOption) {
          this.source.modal = true
        }
      }

      this.$emit('input', {...this.data})
    },
    configSource(source) {
      this.source.option = source.form
      this.configModal()
    }
  }
})
