import moment from 'moment'
import {isUndefined} from 'lodash'

export default {
  cpl: (totalCount, investment) => (investment && totalCount ? (investment / totalCount)
  .toFixed(2) : 0),

  cpa: (convertedCount, investment) => (investment && convertedCount ? (investment / convertedCount)
  .toFixed(2) : 0),

  cr: (convertedCount, totalCount) => {
    const val = (convertedCount * 100) / (totalCount || 1)
    if (val === 0) return 0
    return `${val.toFixed(2)} %`
  },
  downloadCsv: ({title, to, from, url}) => {
    to = isUndefined(to) ? moment().format(FORMAT_DATE) : to
    from = isUndefined(from) ? moment().format(FORMAT_DATE) : from
    const fileName = `${title} ${from.replace(/\//g, '-')}_${to.replace(/\//g, '-')}`
    const a = document.createElement('a')
    // a.href = "data:text/csv;charset=utf-8, " + url;
    a.href = url
    a.setAttribute('download', fileName)
    a.setAttribute('target', '_blank')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  downloadPDF: ({title, to, from, url}) => {
    to = isUndefined(to) ? moment().format(FORMAT_DATE) : to
    from = isUndefined(from) ? moment().format(FORMAT_DATE) : from
    const fileName = `${title} ${from.replace(/\//g, '-')}_${to.replace(/\//g, '-')}`
    const a = document.createElement('a')
    // a.href = "data:text/csv;charset=utf-8, " + url;
    a.href = url
    a.setAttribute('download', fileName)
    a.setAttribute('target', '_blank')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  },
  checkObjectValues(object, omited) {
    if (!object) return false
    let hasValue = false
    Object.entries(object).forEach(([k, v]) => {
      if (k !== omited) {
        if (v > 0) {
          hasValue = true
        }
      }
    })
    return hasValue
  },
  getCrByCallCenterChart: (dayCallCentersData, filters) => {
    const callCenters = {} // {id: name}
    const chartData = [] // [{day: xxx, callCenters: {id1: xxx, }}, {day: ...}]

    // partimos de: [{date: x, callCenters: [...]}]
    dayCallCentersData.forEach(dayData => {
      const aux = {date: dayData.date, callCenters: {}}

      // si estamos filtrando por un callCenter, solo cogemos datos de este
      const dayCallCenters = filters.callCenterId ? dayData.callCenters.filter(
        item => item.id === filters.callCenterId
      ) : dayData.callCenters

      dayCallCenters.forEach(callCenter => {
        callCenters[callCenter.id] = callCenter.name

        // transformamos el array en un hash de CallCenters por id
        // de: {callCenters: [{id: xx1, ...}, {id: xx2, ...}]
        // a: {callCenters: {id1: {...}, id2: {...}}
        aux.callCenters[callCenter.id] = callCenter
      })

      chartData.push(aux)
    })

    return {callCenters, chartData}
  },

  // agrupa todos los valores excepto el indicado en una categoría Otros
  // se utiliza cuando se filtra por un elemento de los mostrados
  // - data: datos a agrupar
  // - idKey: nombre del campo que identifica al objecto (id o key)
  // - idNoGroup: id del objeto que no hay que agrupar
  groupOtherPie: (data, idKey, idNoGroup) => {
    if (!data || data.length === 0) return null
    const groupKeys = []
    const result = [
      {key: 'other', name: Vue.i18n.t('analytics.charts.other')}
    ]

    // Agrupamos todos los valores de tipo numérico
    Object.keys(data[0]).forEach(key => {
      if (typeof data[0][key] === 'number') {
        groupKeys.push(key)
        result[0][key] = 0
      }
    })

    data.forEach(item => {
      if (item[idKey] === idNoGroup) {
        result.push(item)
      } else {
        groupKeys.forEach(key => {
          result[0][key] += item[key]
        })
      }
    })

    return result
  }
}
