import template from './_modal-notification.pug'

export default Vue.extend({
  template: template(),
  props: {
    value: Boolean,
    title: String,
    text: String
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    close() {
      this.model = false
    }
  }
})
