import RouteParent from 'js/router/route-parent'

import AnalyticsIndex from 'js/pages/analytics/index.js'
import AnalyticsBiIndex from 'js/pages/analytics/bi-insight/index.js'

export default [
  {
    path: '/analytics',
    component: RouteParent,
    meta: {
      auth: false
    },
    children: [
      {
        path: '',
        name: 'analytics-index',
        component: AnalyticsIndex,
        meta: {
          auth: true,
          permit: ['SHOW_ANALYTICS']
        }
      },
      {
        path: '/bi-insight',
        name: 'analytics-bi-index',
        component: AnalyticsBiIndex,
        meta: {
          auth: true,
          permit: ['SHOW_ANALYTICS']
        }
      }
    ]
  }
]
