export default {
  index(campaignId, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/automations`,
      {params, ...options}
    ).then(response => response.data)
  },

  show(campaignId, id, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/automations/${id}`,
      {options}
    ).then(response => response.data.data)
  },

  create(campaignId, data, options = {}) {
    return Vue.http.post(
      `campaigns/${campaignId}/automations`,
      data,
      {errorsRootKey: 'automations', ...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  },

  update(campaignId, id, data, options = {}) {
    return Vue.http.put(
      `campaigns/${campaignId}/automations/${id}`,
      data,
      {errorsRootKey: 'automations', ...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  },

  destroy(campaignId, id, options = {}) {
    return Vue.http.delete(`campaigns/${campaignId}/automations/${id}`, options)
  },

  start(campaignId, id, options = {}) {
    return Vue.http.post(`campaigns/${campaignId}/automations/${id}/start`, options)
  },

  duplicate(campaignId, id, options = {}) {
    return Vue.http.post(`campaigns/${campaignId}/automations/${id}/duplicate`, options)
  },

  stop(campaignId, id, options = {}) {
    return Vue.http.post(`campaigns/${campaignId}/automations/${id}/stop`, options)
  },

  logs(campaignId, id, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/automations/${id}/logs`,
      {params, ...options}
    ).then(response => response.data)
  },
}
