import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import ModalOfferOrderDetail from './modal-offer-order-detail'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    ModalOfferOrderDetail
  },
  props: {
    offers: {
      type: Array,
      required: true
    },
    monthsUsed: {
      type: Object,
      required: true
    },
    meta: {
      type: Object,
      required: true
    },
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      offerId: null,
      showDetail: false,
      showFilters: true,
      filters: {agencyName: '', leadDemandId: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    canOffer() {
      return this.demandData.receiveOffers
    },

    defaultOrder() {
      return {currentField: 'createdAt', currentDir: 'desc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
    this.filters.leadDemandId = this.demandData.id
  },
  methods: {
    selectOffer(id) {
      this.offerId = id
      this.showDetail = true
    },
    t(key, options = {}) {
      return this.$t(`leadtrade.offer.index.${key}`, options)
    },

    confirmDelete(product) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: product.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(product.id))
    },

    confirmAccept(offer) {
      openModalConfirmRemove(
        this.t('confirmationModal.accept.title'),
        this.t('confirmationModal.accept.body', {name: offer.name}),
        this.t('confirmationModal.accept.okButton')
      ).then(() => this.changeStatus(offer, true))
    },
    confirmDenied(offer) {
      openModalConfirmRemove(
        this.t('confirmationModal.denied.title'),
        this.t('confirmationModal.denied.body', {name: offer.name}),
        this.t('confirmationModal.denied.okButton')
      ).then(() => this.changeStatus(offer))
    },

    changeStatus(offer, accept = false) {
      const data = {
        demandId: offer.leadDemandId,
        leadProposalOfferId: offer.id,
        // Status
        // 1 = Aceptado
        // 2 = Rechazado
        status: accept ? 1 : 2
      }
      API.offerPurchase.changeStatus(data).then(() => {
        this.getData()
        if (accept) {
          this.$notifications.success(this.t('succesfullyAccept'))
        } else {
          this.$notifications.success(this.t('succesfullyDenied'))
        }
      })
    },

    getData(queryOptions) {
      const params = queryOptions || this.lastQueryOptions

      const payload = {
        ...params,
        filter: {
          ...params.filter,
          leadDemandId: this.demandData.id
        }
      }
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', payload)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    destroy(id) {
      API.offerPurchase.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
