import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import {EmailEditor} from 'vue-email-editor'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './sendEmail.pug'

const DefaultData = () => ({
  name: '',
  config: {
    origin: '',
    emailTemplateId: '',
    subject: '',
    emailBody: '',
    sender: '',
    senderId: '',
    customerId: '',
    nameProvider: ''
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal,
    EmailEditor
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      ctis: [],
      emailEditorReady: false,
      templatesSelect: [],
      templates: [],
      emailProviderOptions: [],
      emailProviders: [],
      emailProviderId: '',
      emailSenderOptions: [],
      emailSenders: [],
      emailSenderId: '',
      activeSenders: false,
      selectedTemplate: false,
      originOptions: [
        {
          value: 'empty',
          label: 'Desde cero'
        },
        {
          value: 'template',
          label: 'Desde plantilla'
        }
      ]
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    ctiSelectOptions() {
      return this.ctis.map(cti => ({
        label: cti.name,
        value: cti.id
      }))
    },

    originType() {
      return this.data.config.origin
    },

    emailTemplateIdSelected() {
      return this.data.config.emailTemplateId
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
      }
    },

    originType: {
      handler(newValue) {
        if (newValue === 'template') {
          this.selectedTemplate = true
        }
      }
    },

    emailSenderId: {
      handler(newValue) {
        const emailSender = this.emailSenders.find(el => el.senderId === newValue)
        this.data.config.sender = emailSender.senderEmail
        this.data.config.senderId = emailSender.senderId
      }
    },

    emailTemplateIdSelected: {
      handler(newValue) {
        const templateData = this.templates.find(el => el.id === newValue)
        this.data.config.emailBody = templateData.template
        this.data.config.subject = templateData.subject
      }
    }
  },
  async created() {
    await this.getTemplates()
    await this.getEmailProviders()
    if (this.data.config.emailTemplateId && this.data.config.customerId) {
      this.selectedTemplate = true
      // eslint-disable-next-line max-len
      const emailProvider = this.emailProviders.find(el => el.nameProvider === this.data.config.nameProvider)
      this.emailProviderId = emailProvider.id
      await this.getEmailSenders(this.data.config.customerId, this.data.config.nameProvider)
      // eslint-disable-next-line max-len
      this.emailSenderId = this.emailSenders.find(el => el.senderId === this.data.config.senderId).senderId
    }
  },
  methods: {
    getSendersSelect() {
      const emailProvider = this.emailProviders.find(el => el.id === this.emailProviderId)
      this.data.config.nameProvider = emailProvider.nameProvider
      this.data.config.customerId = emailProvider.customerId
      this.getEmailSenders(this.data.config.customerId, this.data.config.nameProvider)
    },
    showEditor() {
      this.emailEditorReady = true
    },
    closeEditor() {
      this.emailEditorReady = false
    },
    editorLoaded() {
      // Pass your template JSON here
      // eslint-disable-next-line eqeqeq
      if (this.data.config.emailTemplateId !== '') {
        const design = this.templates.find(el => el.id === this.data.config.emailTemplateId).design
        this.$refs.emailEditor.editor.loadDesign(design)
      }
    },
    editorReady() {
      // console.log('editorReady')
    },
    saveDesign() {
      // this.$refs.emailEditor.editor.saveDesign(
      //   design => {
      //     // console.log('saveDesign', design)
      //   }
      // )
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml(
        // eslint-disable-next-line no-shadow
        data => {
          this.data.config.emailBody = data.html
          this.emailEditorReady = false
        }
      )
    },

    getTemplates() {
      return API.emailMarketing.index({page: {number: 1, size: 1000}}).then(({data}) => {
        // eslint-disable-next-line no-shadow, max-len
        this.templatesSelect = data.map(template => ({value: template.id, label: `${template.name} - ${template.description}`}))
        this.templates = data
      })
    },

    getEmailProviders() {
      // eslint-disable-next-line max-len
      return API.campaigns.emailProviders.index(this.campaign.id, {page: {number: 1, size: 1000}}).then(({data}) => {
        // eslint-disable-next-line max-len
        this.emailProviderOptions = data.map(provider => ({value: provider.id, label: `${provider.nameProvider}`}))
        this.emailProviders = data
      })
    },

    getEmailSenders(NameProvider, CustomerId) {
      return API.campaigns.emailProviders.getSendersByProvider(
          NameProvider,
          CustomerId,
          {page: {number: 1, size: 1000}}
        ).then(data => {
        // eslint-disable-next-line max-len
        this.emailSenderOptions = data.map(sender => ({value: sender.senderId, label: `${sender.senderEmail}`}))
        this.emailSenders = data
        this.activeSenders = true
      })
    },

    save() {
      validatePresence(this.data, [
        'name',
        'config.origin',
        'config.emailTemplateId',
        'config.subject',
        'config.emailBody'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.sendEmail.${key}`, options)
    }
  }
})
