import FullTable from 'js/components/full-table/index.js'
import ModalDuplicate from './_modal-duplicate'
import {openModalConfirmRemove} from 'js/services/modals.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    ModalDuplicate,
    FullTable
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      showDuplicateModal: false,
      automationIdSelected: null,
      filters: {status: '', name: '', segmentationId: ''},
      automations: [],
      meta: {},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_AUTOMATIONS')
    },

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },

    segmentationOptions() {
      // TODO: pendiente de implementar en futuros sprint
      return [
        {
          label: this.t('filters.segmentation.options.empty'),
          value: ''
        }
      ]
    },

    statusOptions() {
      // TODO: pendiente de implementar en futuros sprint
      return [
        {label: this.t('filters.status.options.active'), value: 'started'},
        {label: this.t('filters.status.options.inactive'), value: 'stopped'},
        {label: this.t('filters.status.options.scheduledrunning'), value: 'scheduledrunning'},
        {label: this.t('filters.status.options.scheduledstop'), value: 'scheduledstop'},
        {label: this.t('filters.status.options.empty'), value: null}
      ]
    }
  },
  methods: {
    duplicate(automationId) {
      this.automationIdSelected = automationId
      this.showDuplicateModal = true
    },
    getData(queryOptions) {
      API.automations.index(
        this.campaign.id, queryOptions || this.lastQueryOptions
      ).then(({data, meta}) => {
        this.automations = data
        this.meta = meta
      })

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.index.${key}`, options)
    },

    confirmDelete(automation) {
      openModalConfirmRemove(
        this.t('deleteConfirmationModal.title'),
        this.t('deleteConfirmationModal.body', {name: automation.name}),
        this.t('deleteConfirmationModal.okButton')
      ).then(() => {
        this.destroyAutomation(automation.id)
      })
    },

    destroyAutomation(automationId) {
      API.automations.destroy(this.campaign.id, automationId).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
