// Estas funciones agrupan los datos imposibles de ver en la etiqueta "OTROS"

const calAngleMin = data => {
  const sumTotal = data.reduce((acc, curr) => acc + curr.value, 0)
  const angleUnit = sumTotal / 360
  return angleUnit * 10
}

const sum = data => data.reduce((acc, curr) => acc + curr.value, 0)

const formatterLevel = data => {
  const others = {
    name: 'OTROS',
    value: 0,
    children: []
  }
  const items = []
  const angleMin = calAngleMin(data)
  const maxRange = sum(data) * 30 / 100
  data.forEach(level => {
    if (level.value < angleMin && others.value < maxRange) {
      others.children.push(level)
      others.value = sum(others.children)
    } else {
      items.push(level)
    }
  })
  if (others.value > 0) {
    items.push(others)
  }
  return items
}

const formatter = data => formatterLevel(data)
  .map(one => ({
    ...one,
    children: formatterLevel(one.children)
      .map(two => ({
        ...two,
        children: formatterLevel(two.children)
          .map(tree => ({
            ...tree,
            children: formatterLevel(tree.children)
          }))
      }))
  }))

export default {
  options: (data, group) => {
    if (!data) return null
    return {
      tooltip: {
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        }
      },

      series: {
          type: 'sunburst',
          radius: ['10%', '95%'],
          data: group ? formatter(data) : data,
          label: {
            fontSize: 11,
            minAngle: 3,
            formatter(params) {
              if (params.name.length > 7) {
                return `${params.name.substring(0, 7)}...`
              }
              return params.name
            }
          }
        }
    }
  }
}
