import pickBy from 'lodash/pickBy'
import template from './show.pug'

export default Vue.extend({
  template: template(),
  data() {
    return {
      ready: false,
      data: {},
      ctisOptions: {}
    }
  },
  computed: {
    canShowCampaigns() {
      return this.$user.permissions.includes('LIST_CAMPAIGNS')
    },

    canShowProducts() {
      return this.$user.permissions.includes('VIEW_PRODUCTS')
    },

    canShowCustomers() {
      return this.$user.permissions.includes('VIEW_CUSTOMERS')
    },

    countryFlagClass() {
      return this.data.country ? `flag-icon-${this.data.country.toLowerCase()}` : null
    },

    servicesIntegrated() {
      return Object.keys(
        pickBy(this.data.integrations, integration => (!!integration))
      ).map(key => ({
        label: this.t(`integrations.${key}.label`, {count: this.data.integrations[key]}),
        icon: this.t(`integrations.${key}.icon`)
      }))
    },

    servicesNotIntegrated() {
      return Object.keys(
        pickBy(this.data.integrations, integration => (!integration))
      ).map(key => ({
        label: this.t(`integrations.${key}.label`),
        icon: this.t(`integrations.${key}.icon`)
      }))
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      async handler() {
        this.$setPageTitle(this.$t('callCenters.show.title'))
        await this.loadData()
        await this.loadCtis()
        this.ready = true
      }
    }
  },
  methods: {
    loadCtis() {
      return API.ctis.index({page: {number: 1, size: 1000}}).then(({data}) => {
        data.forEach(cti => { this.ctisOptions[cti.id] = cti })
      })
    },

    loadData() {
      return API.callCenters.show(this.$route.params.id).then(response => {
        this.data = response

        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.callCenters.index'), route: {name: 'call-centers-index'}},
          {label: this.$t('breadcrumbs.callCenters.show', {name: this.data.name}), route: {}}
        ])
      })
    },

    pushCtiViews(ctiId) {
      // eslint-disable-next-line max-len
      this.$router.push({name: 'call-center-config', params: {id_call_center: this.data.id, id: ctiId}})
    },

    t(key, options = {}) {
      return this.$t(`callCenters.show.${key}`, options)
    },

    downloadPdf() {
      // Fuera de alcance
    }
  }
})
