import template from './_confirm_source_modal.pug'

export default Vue.extend({
  template: template(),
  methods: {
    cancel() {
      this.$emit('input', false) 
      this.$emit('cancelmodal')      
    },
    later() {
      this.$emit('later')
      this.$emit('input', false) 
    },
    config() {
      this.$emit('config')
      this.$emit('input', false) 
    },
    t(key, options = {}) {
      return this.$t(`customers.aibe.form.confirm.${key}`, options)
    }
  }
})
