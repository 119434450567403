import {isEqual, isEmpty} from 'lodash'
import i18n from 'js/i18n.js'
import LaiaColorPicker from 'js/components/laia-color-picker'
import {validations} from '../../../../../mocks/campaign-forms/factory.js'
import FormFields from '../show/config/_fields.js'
import template from './form.pug'

const DefaultValues = () => Object.freeze({
  title: '',
  color: '#ff0000',
  launcher: 'phone',
  submit: i18n.t('forms.form.fields.submit.defaultValue'),
  messages: {
    success: i18n.t('forms.form.fields.messages.success.defaultValue'),
    error: i18n.t('forms.form.fields.messages.error.defaultValue')
  },
  fields: [],
  callTracking: {},
  formPolicy: {}
})

const Fields = Object.keys(DefaultValues())

// Permite definir el formulario por defecto para usar en las landing pages de la campaña
export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations,
  template: template(),
  components: {
    LaiaColorPicker,
    FormFields
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ready: false,
      showPrivacy: false,
      data: {},
      fieldsData: [],
      fieldsError: []
    }
  },
  computed: {
    maskPhoneOptions() {
      return [
        {value: '### ### ###', label: '### ### ###'},
        {value: '(###) ### ###', label: '(###) ### ###'}
      ]
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },
    showPrivacy(newValue) {
      if (!newValue) {
        this.data.formPolicy.label = null
        this.data.formPolicy.link = null
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data) && !isEmpty(this.data)) return

        this.initializeData()
      }
    },

    fieldsData: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal) this.data.fields = this.fieldsData
      }
    }
  },
  methods: {
    initializeData() {
      // formPolicy puede ser un objeto o un booleano
      this.showPrivacy = !!this.value.formPolicy

      this.data = {...DefaultValues(), ...this.value}
      this.fieldsData = this.data.fields
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`forms.form.${key}`, options)
    },

    onlyPositiveInt(oldVal, newVal) {
      return newVal.trim().match(/^[0-9]*$/) ? newVal : (oldVal || '')
    }
  }
})
