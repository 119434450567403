/* eslint-disable no-return-assign */

const permissionKeys = [
    'laia',
    'campaign',
    'customer',
    'campaign_tracking',
    'campaign_tracking_utmCampaign',
    'campaign_tracking_general',
    'campaign_tracking_landing',
    'campaign_tracking_utmMedium',
    'campaign_tracking_product',
    'campaign_tracking_utmSource',
    'campaign_tracking_ctaType',
    'campaign_transformation',
    'campaign_transformation_agent',
    'campaign_transformation_callCenter',
    'campaign_transformation_general',
    'campaign_transformation_agentGroup',
    'campaign_transformation_product',
    'campaign_audience',
    'customer_tracking',
    'customer_tracking_campaign',
    'customer_tracking_utmCampaign',
    'customer_tracking_general',
    'customer_tracking_landing',
    'customer_tracking_utmMedium',
    'customer_tracking_product',
    'customer_tracking_utmSource',
    'customer_tracking_provider',
    'customer_transformation',
    'customer_transformation_agent',
    'customer_transformation_callCenter',
    'customer_transformation_campaign',
    'customer_transformation_general',
    'customer_transformation_agentGroup',
    'customer_transformation_product'
]

const targetKeys = [
    'general',
    'agent',
    'product',
    'callCenter',
    'utmMedium',
    'utmSource',
    'utmCampaign',
    'ctaType',
    'landing',
    'campaign',
    'agentGroup',
    'provider'
]

const permission = permissionKeys.reduce((acc, curr) => acc = {
    ...acc,
    [curr]: curr
}, {})

const target = targetKeys.reduce((acc, curr) => acc = {
    ...acc,
    [curr]: curr
}, {})

const campaignTrackingChildrens = [
    target.utmCampaign,
    target.general,
    target.landing,
    target.utmMedium,
    target.product,
    target.utmSource,
    target.ctaType
]

const campaignTransformationChildrens = [
    target.agent,
    target.callCenter,
    target.general,
    target.agentGroup,
    target.product
]

const customerTrackingChildrens = [
    target.campaign,
    target.utmCampaign,
    target.general,
    target.landing,
    target.utmMedium,
    target.product,
    target.utmSource,
    target.provider
]

const customerTransformationChildrens = [
    target.agent,
    target.callCenter,
    target.campaign,
    target.general,
    target.agentGroup,
    target.product
]

const generatePermissionTree = (permissionsActived = []) => {
    const active = permissionsActived.reduce((acc, curr) => acc = {
        ...acc,
        [curr]: true
    }, {})

    const generateSubTree = (prop, key) => {
        if (!prop) {
            const error = 'Item de estudio indefinido'
            alert(error)
            throw error
        }
        return {
            active: active[`${key}_${prop}`] || false,
            permission: `${key}_${prop}`,
            children: []
        }
    }
    return [
        {
            permission: permission.laia,
            active: active.laia || false,
            children: [
                {
                    permission: permission.campaign,
                    active: active.campaign || false,
                    children: [
                        {
                            active: active.campaign_tracking || false,
                            permission: permission.campaign_tracking,
                            children: campaignTrackingChildrens
                            .map(child => generateSubTree(child, permission.campaign_tracking))
                        },
                        {
                            active: active.campaign_transformation || false,
                            permission: permission.campaign_transformation,
                            children: campaignTransformationChildrens
                            .map(
                                child => generateSubTree(child, permission.campaign_transformation)
      )
                        },
                        {
                            active: active.campaign_audience || false,
                            permission: permission.campaign_audience,
                            children: []
                        }
                    ]
                },
                {
                    permission: permission.customer,
                    active: active.customer || false,
                    children: [
                        {
                            active: active.customer_tracking || false,
                            permission: permission.customer_tracking,
                            children: customerTrackingChildrens
                            .map(child => generateSubTree(child, permission.customer_tracking))
                        },
                        {
                            active: active.customer_transformation || false,
                            permission: permission.customer_transformation,
                            children: customerTransformationChildrens
                            .map(
                                child => generateSubTree(child, permission.customer_transformation)
    )
                        }
                    ]
                }
            ]
        }
    ]
}

export {generatePermissionTree, permissionKeys}
