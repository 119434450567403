// Devuelve la traducción de un error
// - rootKey: key raiz de la traducción
// - key: key del error en la traducción
// - error: se le pasa a la traducción por si necesita info extra (ejemplo max-length)
const translateError = (rootKey, key, error) => {
  const tokenError = error.error

  return (Vue.i18n.te(`${rootKey}.form.fields.${key}.errors.${tokenError}`)) ?
           Vue.i18n.t(`${rootKey}.form.fields.${key}.errors.${tokenError}`, error) :
           Vue.i18n.t(`errors.${tokenError}`, error)
}

// Función recursiva que va construyendo la estructura del objeto errors con las traducciones
const parseErrors = (originalErrors, rootKey, prevKey) => {
  const errors = {}

  Object.keys(originalErrors).forEach(errorKey => {
    // Ejemplo: field1.nested1[0].nested2 => field1
    const parts = errorKey.split('.')
    const fieldKey = parts[0]

    if (parts.length === 1) {
      errors[fieldKey] = translateError(
        rootKey,
        prevKey ? `${prevKey}.${errorKey}` : errorKey,
        originalErrors[errorKey][0] // solo parseamos el 1º
      )
    } else {
      const nextErrors = {}
      const nestedKey = parts.slice(1).join('.') // field1.nested1.nested2 => nested1.nested2
      nextErrors[nestedKey] = originalErrors[errorKey]

      // field1[0].nested1.nested2 => machearía ['field1', 0]
      const arrayParsed = /(\w+)\[(\d+)\]/.exec(fieldKey)

      if (arrayParsed) {
        // fieldArray[1].name...
        const key = arrayParsed[1]
        const index = arrayParsed[2]

        if (!errors[key]) errors[key] = []

        errors[key][index] = parseErrors(
          nextErrors,
          rootKey,
          prevKey ? `${prevKey}.${key}` : key
        )
      } else {
        // field.name...
        errors[fieldKey] = parseErrors(
          nextErrors,
          rootKey,
          prevKey ? `${prevKey}.${fieldKey}` : fieldKey
        )
      }
    }
  })

  return errors
}

/**
 * Funcion que se usa desde vue-resource-config y convierte los errores del back a una estructura
 * más usable desde el front a la vez que traduce los errores. Ejemplo:
 *
 * errors: {
 *   "attribute1": [
 *     {"error": "error1", "meta": "<extra-info>"},
 *     {"error": "error2"},
 *   ],
 *   "attribute2": [{"error": "error1"}],
 *   "nested_attributes1.attribute3": [{"error": "error3"}]
 *   "nested_attributes2[1].attribute4": [{"error": "error3"}]
 * }
 *
 * salida: {
 *   // devuelve el primer error de cada key
 *   tokenErrors: {
 *     "attribute1": {"error": "error1", "meta": "<extra-info>"},
 *     "attribute2": {"error": "error1"},
 *     "nested_attributes1.attribute3": {"error": "error3"}
 *     "nested_attributes2[1].attribute4": {"error": "error3"}
 *   },
 *
 *   // errores parsedos y traducidos
 *   errors: {
 *    attribute1: 'Error en campo ${meta}',
 *    attribute2: 'Campo en blanco',
 *    nested_attributes1: {
 *      attribute3: 'Error de inclusión'
 *    },
 *    nested_attributes2: [
 *      undefined,
 *      {attribute4: 'Error...'
 *    ]
 *   }
 * }
 */
export default (errors, rootKey) => {
  const tokenErrors = {}

  Object.keys(errors).forEach(errorKey => {
    tokenErrors[errorKey] = errors[errorKey][0] // solo cogemos el 1º
  })

  return {
    tokenErrors,
    errors: parseErrors(errors, rootKey)
  }
}
