import LeadsAccountsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsAccountsTable
  },
  data() {
    return {
      leads: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('logs.log-accepted-policy.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.log-accepted-policy.index'), route: {}}
    ])
  },
  methods: {
    loadLogs(queryOptions = {}) {
      this.leads = []
      this.meta = {}
      API.logs.acceptedPolicyLogs(queryOptions).then(({data, meta}) => {
        this.leads = data
        this.meta = meta
      })
    }
  }
})
