import {pick, cloneDeep, isEmpty, isEqual} from 'lodash'
import {openModalConfirm} from 'js/services/modals.js'
import validateForm from 'js/services/validate-form.js'
import {serialize} from 'object-to-formdata'

import template from './new.pug'
import InfoForm from './form/info.js'

const WIZARD_STEP_STATE = {
  COMPLETED: "'completed'",
  EMPTY: "'empty'",
  WIP: "'wip'"
}

export default Vue.extend({
  template: template(),
  components: {
    InfoForm
  },
  data() {
    return {
      data: {},
      errors: {},
      infoData: {},
      infoErrors: {},
      infoState: WIZARD_STEP_STATE.EMPTY,
      sitesData: {},
      sitesErrors: [],
      sitesState: WIZARD_STEP_STATE.EMPTY,
      integrationsData: {},
      integrationsErrors: {},
      integrationsState: WIZARD_STEP_STATE.EMPTY,
      tabs: ['info', 'sites', 'integrations'],
      currentTab: 'info',
      saved: false
    }
  },
  // Si el usuario abandona el wizard por el modo que sea se muestra un mensaje dando la opción
  // de guardar el wizard antes de abandonar la página
  // eslint-disable-next-line consistent-return
  beforeRouteLeave(to, _from, next) {
    // Evita el mensaje al abandonar la pagina por falta de permisos o al redirecionar tras guardar
    if (to.name === 'error-403' || to.name === 'error-500' || this.saved) {
      return next()
    }

    openModalConfirm(
      this.t('leaveWizardConfirmationModal.title'),
      this.t('leaveWizardConfirmationModal.body'),
      this.t('leaveWizardConfirmationModal.okButton'),
      {cancelText: this.t('leaveWizardConfirmationModal.cancelButton')}
    ).then(() => {
      next()
    }).catch(() => {
      next(false)
    })
  },
  computed: {
    indexOfCurrentTab() {
      return this.tabs.indexOf(this.currentTab)
    }
  },
  created() {
    this.$setPageTitle(this.$t('customers.new.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
      {label: this.$t('breadcrumbs.customers.new'), route: {}}
    ])

    this.initializeData()
  },

  methods: {
    initializeData() {
      const data = {}
      this.infoData = cloneDeep(InfoForm.options.defaultValues())
      this.data = data
    },

    cancel() {
      this.$router.push({name: 'customers-admin-index'})
    },

    t(key, options = {}) {
      return this.$t(`customers-admin.form.${key}`, options)
    },

    getClassByStatus(status) {
      switch (status) {
        case WIZARD_STEP_STATE.EMPTY:
          return 'tab--gray'
        case WIZARD_STEP_STATE.WIP:
          return 'tab--yellow'
        case WIZARD_STEP_STATE.COMPLETED:
          return 'tab--blue'
        default:
          return ''
      }
    },

    validateTab(tabName, tabComponent) {
      const errors = validateForm(
        tabComponent.options.validations, this[`${tabName}Data`], 'customers'
      )

      if (errors) {
        this[`${tabName}Errors`] = errors

        const dataTab = JSON.stringify(this[`${tabName}Data`])
        const defaultValues = JSON.stringify(tabComponent.options.defaultValues())

        if (isEmpty(dataTab) || isEqual(dataTab, defaultValues)) {
          this[`${tabName}State`] = WIZARD_STEP_STATE.EMPTY
        } else {
          this[`${tabName}State`] = WIZARD_STEP_STATE.WIP
        }
      } else {
        this[`${tabName}Errors`] = {}
        this[`${tabName}State`] = WIZARD_STEP_STATE.COMPLETED
      }
    },

    previousStep() {
      this.changeTab(this.tabs[this.indexOfCurrentTab - 1])
    },

    nextStep() {
      this.changeTab(this.tabs[this.indexOfCurrentTab + 1])
    },

    changeTab(tabId) {
      if (this.isValidCurrentTab()) {
        this.currentTab = tabId
        return
      }

      openModalConfirm(
        this.t('changeStepConfirmationModal.title'),
        this.t('changeStepConfirmationModal.body'),
        this.t('changeStepConfirmationModal.okButton'),
        {cancelText: this.t('changeStepConfirmationModal.cancelButton')}
      ).then(() => {
        this.currentTab = tabId
      }).catch(() => {
        const currentTab = this.currentTab
        this.currentTab = tabId
        this.$nextTick(() => {
          this.currentTab = currentTab
        })

        const error = document.getElementsByClassName('error')[0]
        if (error) error.scrollIntoView({block: 'center'})
      })
    },

    // eslint-disable-next-line consistent-return
    isValidCurrentTab() {
      if (this.currentTab === 'info') {
        this.validateTab(this.currentTab, InfoForm)
        return isEmpty(this.infoErrors)
      }
    },

    createCustomer() {
      this.data = {...this.data, ...this.infoData, ...this.sitesData, ...this.integrationsData}

      // TODO: mejorar el validador de backend para soportar FormData complejos
      const data = MOCKS ? this.data : serialize(this.data, {indices: true})

      API.customersAdmin.create(data).then(() => {
        this.saved = true
        this.$notifications.success(this.$t('customers.new.succesfullyCreated'))
        this.$router.push({name: 'customers-admin-index'})
      }, errors => {
        this.errors = errors
        this.infoErrors = pick(errors, InfoForm.options.fields)

        if (!isEmpty(this.infoErrors)) this.infoState = WIZARD_STEP_STATE.WIP

        this.$notifications.error(this.$t('customers.new.errorCreated'))
      })
    }
  }
})
