export default {
  options: (data, localeRoot) => {
    if (!data) return null

    return {
      tooltip: {
        trigger: 'item',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        // “<número de leads>: <porcentaje del Total>% del Total de leads de la campaña”.
        // ejemplo: 200: 20% del Total de leads de la campaña
        formatter: params => {
          const leadsCount = params.data.value
          // el primer nivel el el 100%
          const floatRepresentation = Vue.filter('floatRepresentation')
          const percent = floatRepresentation(
            (
              (leadsCount * 100) / Object.values(data)[0]
            ).toFixed(2)
          )

          // El primer nivel o nivel superior mostrará solo el número de leads
          return params.data.name === Object.keys(data)[0] ?
            leadsCount.toString() :
            Vue.i18n.t(`${localeRoot}.tooltip`, {leadsCount, percent})
        }
      },
      legend: {
        orient: 'vertical',
        left: '3%',
        icon: 'circle',
        itemHeight: 10,
        itemGap: 16,
        textStyle: {
          color: 'rgb(73, 80, 87)',
          fontSize: 13,
          fontFamily: 'Montserrat'
        },
        top: 'middle',
        formatter: key => `${data[key]}\n${Vue.i18n.t(`${localeRoot}.series.${key}`)}`
      },
      series: [
        {
          type: 'funnel',
          min: 0,
          max: data.totalCount,
          sort: 'none',
          left: 120, // lo alejamos un poco de la leyenda
          label: {
            show: false,
            position: 'inside',
            formatter: item => Vue.i18n.t(`${localeRoot}.series.${item.name}`)
          },
          itemStyle: {
            borderWidth: 0
          },
          emphasis: {
            label: {
              fontSize: 14
            }
          },
          data: Object.keys(data).map(key => ({
            value: data[key],
            name: key
          }))
        }
      ],
      color: GRAPH_COLORS
    }
  }
}
