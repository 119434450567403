import isEqual from 'lodash/isEqual'

import template from './_segmentation-form.pug'

export default Vue.extend({
  template: template(),
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    campaign: {
      type: Object,
      required: true
    },
    dataErrors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {
        name: '',
        rules: [
          {
            tagKey: '',
            comparisonOperator: '',
            value: ''
          }
        ]
      },
      errors: {},
      tags: []
    }
  },
  computed: {
    comparisonOperatorTextOptions() {
      return [
        COMPARISON_OPERATORS.EQUAL, COMPARISON_OPERATORS.DISTINCT, COMPARISON_OPERATORS.CONTAINS
      ].map(item => ({label: this.$t(`comparisonOperators.text.${item}`), value: item}))
    },

    comparisonOperatorNumberOptions() {
      return [
        COMPARISON_OPERATORS.EQUAL,
        COMPARISON_OPERATORS.LESS_THAN,
        COMPARISON_OPERATORS.GREATER_THAN
      ].map(item => ({label: this.$t(`comparisonOperators.number.${item}`), value: item}))
    },

    // Cuando hay errores en las reglas 'errors.rules' es un array
    // Cuando no se envían reglas 'errors.rules` es un string (blank)
    // Por tanto debemos comprobar que 'errors.rules` existe y no es un Array
    // para saber que rules se envio vacio
    isErrorRulesEmpty() {
      return this.errors.rules && !(this.errors.rules instanceof Array)
    },

    tagKeyOptions() {
      return this.tags.map(item => ({label: item.name, value: item.key}))
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data) && !isEqual({}, this.data)) return

        this.initializeData()
      }
    },

    dataErrors: {
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.errors) && !isEqual({}, this.errors)) return

        this.initializeErrors()
      }
    }
  },
  methods: {
    initializeData() {
      this.data = {
        name: this.value.name || '',
        rules: this.value.rules || [{}]
      }

      this.getTags()
    },

    initializeErrors() {
      this.errors = {
        name: this.dataErrors.name,
        rules: this.dataErrors.rules || []
      }

      this.getTags()
    },

    getTags() {
      API.tags.index(
        this.campaign.id, {order: {name: 'asc'}, page: {number: 1, size: 1000}}
      ).then(response => {
        this.tags = response.data
      })
    },

    t(key, options = {}) {
      return this.$t(
        `campaigns.show.automations.generalDataForm.segmentations.form.${key}`, options
      )
    },

    addRule() {
      this.data.rules.push({})
    },

    deleteRule(index) {
      this.data.rules.splice(index, 1)
      this.errors.rules.splice(index, 1)
    },

    getTagByKey(key) {
      return this.tags.find(
        tag => tag.key === key
      )
    },

    isTagNumeric(index) {
      return this.getTagByKey(this.data.rules[index].tagKey)?.fieldType === FIELD_TYPES.NUMERIC
    },

    getComparisonOperatorSelectOptions(index) {
      if (this.isTagNumeric(index)) return this.comparisonOperatorNumberOptions

      return this.comparisonOperatorTextOptions
    },

    // Modifica el valor del comparisonOperator por el del primero de la lista
    setDefaultComparisonOperator(i) {
      this.data.rules[i].comparisonOperator = this.getComparisonOperatorSelectOptions(i)[0].value
    },

    scrollToSelect(open, id) {
      if (open) {
        // Se necesita usar nextTick para esperar a que el componente se muestre
        Vue.nextTick(() => (document.getElementById(id).scrollIntoView()))
      }
    }
  }
})
