
import ValidationIndex from '../validation-component/validation-index'
import template from './validation-robinson-index.pug'

export default Vue.extend({
  template: template(),
  components: {
    ValidationIndex
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    campaignTags: {
      type: Array,
      default: () => ([])
    }
  }
})
