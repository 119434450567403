export default {
  options: data => {
    if (!data || data.length === 0) return null

    return {
      grid: {
        top: '13%',
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        }
      },
      xAxis: [
        {
          type: 'category',
          data: data.map(item => moment(item.date)
            .format(FORMAT_MONTH).replace(/^(.)/, match => match.toUpperCase())),
          axisLabel: {
            margin: 10,
            align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: 'A',
          type: 'bar',
          data: data.map(item => item.aSalesCount)
        },
        {
          name: 'B',
          type: 'bar',
          data: data.map(item => item.bSalesCount)
        },
        {
          name: 'C',
          type: 'bar',
          data: data.map(item => item.cSalesCount)
        },
        {
          name: 'D',
          type: 'bar',
          data: data.map(item => item.dSalesCount)
        }
      ],

      media: [
        {
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        icon: 'circle',
        bottom: '3%'
      },
      color: GRAPH_COLORS
    }
  }
}
