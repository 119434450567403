import template from './_form.pug'

const Fields = [
  'name', 'description', 'type', 'utms', 'assignedTrackingNumbers'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      selectedOptions: [],
      options: [],
      typeOptions: [
        {
          value: this.$t('trackingSources.typeOptions.dynamic.value'),
          label: this.$t('trackingSources.typeOptions.dynamic.label')
        },
        {
          value: this.$t('trackingSources.typeOptions.static.value'),
          label: this.$t('trackingSources.typeOptions.static.label')
        }
      ],
      selected: this.$t('trackingSources.typeOptions.dynamic.value'),
      utms: {
        utmCampaing: '',
        utmId: '',
        utmTerm: '',
        utmSource: '',
        utmMedium: '',
        utmContent: ''
      },
      trackingNumbers: []
    }
  },
  computed: {

  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadTrackingNumbers()
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data

      if (this.data.type !== '') {
        this.selected = this.data.type
      }

      if (this.data.utms) {
        this.utms = this.data.utms
      } else {
        this.data.utms = this.utms
      }

      if (this.data.assignedTrackingNumbers) {
        this.selectedOptions = this.data.assignedTrackingNumbers
        this.selectedOptionsAdded = this.data.assignedTrackingNumbers.map(trackingNumber => ({
          label: `${trackingNumber.number} (${trackingNumber.description})`,
          value: trackingNumber
        }))
      }
    },

    loadTrackingNumbers() {
      API.trackingNumbers.availableToTrackingSource(
        {page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.options = data.map(trackingNumber => ({
          label: `${trackingNumber.number} (${trackingNumber.description})`,
          value: trackingNumber
        }))
        this.selectedOptionsAdded.forEach(el => {
          this.options.push(el)
        })
        this.trackingNumbers = data
      })
    },

    unselectedFilter(options, text) {
      return options.filter(item => item.label.includes(text))
    },

    t(key, options = {}) {
      return this.$t(`trackingSources.form.${key}`, options)
    },

    submit() {
      this.data.assignedTrackingNumbers = this.selectedOptions
      this.data.type = this.selected
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
