export default {
  errorsRootKey: 'leadtrade',
  resource: 'leadtrade',
  actions: ['index', 'show', 'create', 'update', 'destroy'],
  customers(params = {}, options = {}) {
    return Vue.http.get(
      'leadtrade/customers',
      {params, ...options}
    ).then(response => response.data)
  },
  customerCreate(data, params = {}, options = {}) {
    return Vue.http.post(
      'leadtrade/customerByStatusPending', data,
      {params, ...options}
    ).then(response => response.data)
  },
  campaignCreate(data, params = {}, options = {}) {
    return Vue.http.post(
      'leadtrade/campaignByStatusPending', data,
      {params, ...options}
    ).then(response => response.data)
  },
  campaigns(params = {}, options = {}) {
    return Vue.http.get(
      'leadtrade/campaigns',
      {params, ...options}
    ).then(response => response.data)
  },
  currencys(params = {}, options = {}) {
    return Vue.http.get(
      'leadtrade/currencies',
      {params, ...options}
    ).then(response => response.data)
  },
  customerTypology(params = {}, options = {}) {
    return Vue.http.get(
      'leadtrade/customerTypology',
      {params, ...options}
    ).then(response => response.data)
  },
  leadTypology(params = {}, options = {}) {
    return Vue.http.get(
      'leadtrade/leadTypology',
      {params, ...options}
    ).then(response => response.data)
  }
}
