import {isEqual} from 'lodash'
import CallCenters from './_call-centers.js'
import Providers from './_providers.js'
import template from './basic-info.pug'

const DefaultValues = () => Object.freeze({
  name: '',
  customerId: '',
  providerId: '',
  siteIds: '',
  productIds: '',
  investment: '',
  callCenters: [],
  providers: [],
  description: '',
  startAt: '',
  endAt: ''
})

const Fields = Object.keys(DefaultValues())

const Validations = {
  name: {required: true},
  customerId: {required: true},
  productIds: {required: true},
  investment: {required: true},
  startAt: {required: true},
  endAt: {required: true},
  //siteIds: {required: true},
  description: {required: true},
  callCenters: {
    type: 'objectsArray',
    required: true,
    objectValidations: {
      id: {required: true},
      ctiId: {required: true}
    }
  },
  providers: {
    type: 'objectsArray',
    required: true,
    objectValidations: {
      id: {required: true},
      siteId: {required: true}
    }
  }
}

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  components: {
    CallCenters,
    Providers
  },
  props: {
    showTitle: {
      type: Boolean,
      default: () => (true)
    },
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    startDate: {
      type: String,
      default: () => ('today')
    }
  },
  data() {
    return {
      data: {},
      ready: false,
      customersSelectOptions: [],
      productsSelectOptions: [],
      sitesSelectOptions: [],
      callCentersData: [],
      providerLeadsData: [],
      callCentersError: [],
      providers: []
    }
  },
  computed: {
    nameIsEmpty() {
      return !this.data.name
    },

    basicDateConfig() {
      return {
        dateFormat: 'd/m/Y',
        monthSelectorType: 'static',
        minDate: this.startDate,
        enableTime: false,
        initParseDate(date) {
          return date ? moment(date, FORMAT_DATE).toDate() : null
        },
        reverseParseDate(date) {
          return moment(date, FORMAT_DATE_DATEPICKER).format(FORMAT_DATE)
        }
      }
    },

    startDateConfig() {
      return {
        ...this.basicDateConfig,
        maxDate: this.data.endAt
      }
    },

    endDateConfig() {
      return {
        ...this.basicDateConfig,
        minDate: this.data.startAt
      }
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    },

    callCentersData: {
      deep: true,
      handler(_newVal, oldVal) {
        if (oldVal) this.data.callCenters = this.callCentersData
      }
    },
    providerLeadsData: {
      deep: true,
      handler(_newVal, oldVal) {
        if (oldVal) this.data.providers = this.providerLeadsData
      }
    }
  },
  created() {
    this.loadCustomers()
  },
  methods: {
    initializeData() {
      const data = {}
      Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
      this.data = data
      this.callCentersData = this.data.callCenters
      this.providerLeadsData = this.data.providers

      if (this.data.customerId) this.loadCustomerDependentData(this.data.customerId)

      this.ready = true
    },

    loadCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customersSelectOptions = data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
      })
    },

    changeCustomerSelected(customerId) {
      this.data.providers = []
      this.providerLeadsData = []
      this.data.productIds = []
      this.loadCustomerDependentData(customerId)
    },

    loadCustomerDependentData(customerId) {
      this.loadSites(customerId)
      this.loadProducts(customerId)
    },

    loadSites(customerId) {
      API.sites.index({filter: {customerId}, page: {number: 1, size: 1000}}).then(({data}) => {
        this.sitesSelectOptions = data.map(sites => ({
          label: sites.description || sites.url,
          value: sites.id
        }))
      })
    },

    loadProducts(customerId) {
      API.products.index({filter: {customerId}, page: {number: 1, size: 1000}}).then(({data}) => {
        this.productsSelectOptions = data.map(product => ({
          label: product.name,
          value: product.id
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.form.${key}`, options)
    }
  }
})
