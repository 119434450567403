import UserForm from '../_form.js'

import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    UserForm
  },
  data() {
    return {
      ready: false,
      data: {
        customerId: 0
      },
      errors: {},
      customerOptions: []
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('users.agency-suppliers.form.new.title'))
        this.$setBreadcrumbs([
          {
            label: this.$t('breadcrumbs.users.agency-suppliers.index'),
            route: {name: 'agency-suppliers-index'}
          },
          {label: this.$t('breadcrumbs.users.agency-suppliers.new'), route: {}}
        ])
      }
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`users.agency-suppliers.new.${key}`, options)
    },

    create(data) {
      this.errors = {}

      API.users.create(data).then(() => {
        this.$notifications.success(this.$t('users.form.succesfullySave'))
        this.$router.push({name: 'agency-suppliers-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
