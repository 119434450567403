import camelCase from 'lodash/camelCase'
import template from './root.pug'

const TABS = {
  'invoicing-index': 'invoicings',
  'agreement-index': 'agreement',
  'tax-index': 'tax',
  'sector-index': 'sector',
  'family-index': 'family',
  'way-to-pay-index': 'wayToPay',
  'payment-via-index': 'paymentVia',
  'config-index': 'config'
}
export default Vue.extend({
  name: 'invoice-root',
  template: template(),
  data() {
    return {
      data: {},
      ready: false,
      currentTab: 'invoicing-index'
    }
  },
  computed: {
    tabsOptions() {
      const tabs = [
        'invoicing-index',
        'agreement-index',
        'config-index',
        'tax-index',
        'sector-index',
        'family-index',
        'way-to-pay-index',
        'payment-via-index'
      ]
      return tabs.map(item => ({
        value: item,
        label: this.t(`${camelCase(TABS[item])}.tabTitle`)
      }))
    },
    showTabs() {
      return TABS[this.$route.name] || false
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.currentTab = this.$route.name
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    getRouteCurrentTab(route) {
      return {name: route}
    },

    loadData() {
        this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`invoicings.${key}`, options)
    },

    setCurrentTab(tab) {
      return this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
