import AudiosMenuVoiceForm from './_form';
import template from './edit.pug';
import playAudios from './_play-audios';

export default Vue.extend({
  template: template(),
  components: {
    AudiosMenuVoiceForm,
    playAudios,
  },
  data() {
    return {
      ready: false,
      data: {},
      errors: {},
      selectedAudio: null,
    };
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('audiosMenuVoice.edit.title'));
        this.loadData();
      }
    }
  },
  methods: {
    loadData() {
      API.audiosMenuVoice.show(this.$route.params.id)
        .then(response => {
          this.data = response;
          this.ready = true;
          this.$setBreadcrumbs([
            { label: this.$t('breadcrumbs.audiosMenuVoice.index'), route: { name: 'audios-menu-voice-index' } },
            { label: this.$t('breadcrumbs.audiosMenuVoice.edit', { name: this.data.name }), route: {} },
          ]);
        })
        .catch(error => {
          this.errors = error.response.data;
        });
    },
    updateAudiosMenuVoice(formData) {
      API.audiosMenuVoice.update(this.$route.params.id, formData)
        .then(() => {
          this.$notifications.success(this.$t('audiosMenuVoice.edit.succesfullyUpdated'));
          this.$router.push({ name: 'audios-menu-voice-index' });
        })
        .catch(errors => {
          this.errors = errors.response.data;
        });
    },
    openAudioPanel(audio) {
      this.selectedAudio = audio;
    },
    closeAudioPanel() {
      this.selectedAudio = null;
    }
  },
  mounted() {
    this.$on('audio-loaded', this.openAudioPanel);
  },
});
