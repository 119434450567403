/**
 * Carga automaticamente todos los filtros de este directorio y los expone con el nombre del archivo
 * */
const files = require.context('./', false, /\.js$/)

files.keys().forEach(fileName => {
  if (fileName === './index.js') return

  const nameFilter = fileName
  // Remove extension
    .replace(/.*\//, '').replace(/\..*$/, '')
  // snake-case to camelCase
    .replace(/(-\w)/g, m => m[1].toUpperCase())

  Vue.filter(nameFilter, files(fileName).default)
})
