import {openModalConfirmRemoveWithPrompt, openModalAlert} from 'js/services/modals.js'
import template from './_modal-pool-config.pug'

export default Vue.extend({
  template: template(),
  props: {
    providerSelected: {
      type: Object,
      required: true
    },
    campaignId: {
      type: String,
      required: true
    },
    poolNumbers: {
      type: Array,
      required: true,
      default: () => []
    },
    staticNumbers: {
      type: Array,
      required: true,
      default: () => []
    },
    ddiNumbers: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    const constants = {
      dynamic: 'dynamic',
      static: 'static',
      notpool: 'notpool'
    }
    return {
      constants,
      data: {
        poolTypeAssigned: constants.dynamic,
        poolDynamicSetting: {
          poolNumberId: '',
          defaultPhoneId: ''
        },
        poolStaticSetting: {
          staticPhonesAssigned: [],
          ddiNumberId: ''
        }
      },
      optionNumbers: [],
      selectedOptions: [],
      staticNumbersAvailable: [],
      providerStaticNumbersAssigned: [],
      errors: {}
    }
  },
  computed: {
    isDynamic() {
      return true;// this.data.poolTypeAssigned === this.constants.dynamic
    },
    poolNumbersOptions() {
      return this.poolNumbers.map(item => ({label: item.name, value: item.id}))
    },
    staticNumbersOptions() {
      return this.staticNumbers.map(item => ({label: item.number, value: item.id}))
    },
    ddiNumbersOptions() {
      return this.ddiNumbers.map(item => ({label: item.number, value: item.id}))
    },
    disabledSubmit() {
      if (this.isDynamic) {
        return !this.data.poolDynamicSetting.poolNumberId ||
        !this.data.poolDynamicSetting.defaultPhoneId
      }
      return !this.data.poolStaticSetting.ddiNumberId ||
          !this.selectedOptions.length
    }
  },
  mounted() {
    this.initData()
    this.loadStaticNumbersAvailable()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.provider.pool.${key}`, options)
    },

    initData() {
      this.data.poolTypeAssigned = this.constants.dynamic;
      if (this.providerSelected.poolTypeAssigned !== this.constants.notpool) {       
        this.data.poolDynamicSetting = {...this.providerSelected.poolDynamicSetting}
        this.data.poolStaticSetting = {...this.providerSelected.poolStaticSetting}
      }
    },

    loadStaticNumbersAvailable() {
      API.callTrackingStaticNumbers.available.index(
        {page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.staticNumbersAvailable = data
        const assigned = this.providerSelected.poolStaticSetting?.staticPhonesAssigned
          ?.map((item => ({value: {...item}, label: `${item.number} ${item.description}`}))) || []
        const available = this.staticNumbersAvailable
          .map((item => ({value: {...item}, label: `${item.number} ${item.description}`})))

        const concatedOptions = assigned.concat(available)

        const options = concatedOptions
          .filter((item, index, self) => index === self.findIndex(t => (
            t.value.id === item.value.id
          )))

        this.selectedOptions = this.providerSelected.poolStaticSetting?.staticPhonesAssigned || []
        this.optionNumbers = options
      })
    },

    loadProviderStaticNumbersAssigned() {
      API.campaigns.providers.staticNumbersAssigned(
        this.campaignId,
        this.providerSelected.id,
        this.providerSelected.siteId,
        {page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.providerStaticNumbersAssigned = data
      })
    },

    unselectedFilter(options, text) {
      return options.filter(item => (item.label.includes(text)))
    },

    submit() {
      const data = {
        ...this.data,
        poolStaticSetting: {
          ...this.data.poolStaticSetting,
          staticPhonesAssigned: this.selectedOptions
        }
      }
      API.campaigns.providers.staticNumbersAssign(
        this.campaignId,
        this.providerSelected.id,
        this.providerSelected.siteId,
        data
      ).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.reload()
        this.cancel()
      }, errors => {
        this.errors = errors
      })
    },

    remove() {
      API.campaigns.providers.staticNumbersRemove(
        'dynamic',
        this.campaignId,
        this.providerSelected.id,
        this.providerSelected.siteId,
        {}
      ).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.reload()
        this.cancel()
      })
    },

    deleteAssing() {
      openModalConfirmRemoveWithPrompt(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton'),
        this.t('confirmationModal.remove.promptHelp')
      ).then(({data}) => {
        if (data !== 'yes') {
          openModalAlert(
            this.t('confirmationModal.cancel.title'),
            this.t('confirmationModal.cancel.body'),
            this.t('confirmationModal.cancel.okButton')
          )
        } else {
          this.remove()
        }
      })
    },

    cancel() {
      this.$emit('cancel')
    },

    reload() {
      this.$emit('reload')
    }
  }
})
