export default {
  index(campaignId, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/segmentations`,
      {params, ...options}
    ).then(response => response.data)
  },

  create(campaignId, data, options = {}) {
    return Vue.http.post(
      `campaigns/${campaignId}/segmentations`,
      data,
      {errorsRootKey: 'segmentations', ...options}
    ).then(
      response => response.data,
      response => {
        throw response.data.errors
      }
    )
  }
}
