
import template from './modal-campaign.pug'

export default Vue.extend({
  template: template(),
  props: {
    customerId: {
      type: String,
      default: null
    },
    customersOptions: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      data: {
        callCenters: [],
        customerId: '',
        defaultForm: null,
        description: '',
        endAt: '',
        finished: false,
        investment: '',
        name: '',
        productIds: [],
        providerId: '',
        siteIds: [],
        startAt: '',
        userId: ''
      }
    }
  },
  mounted() {
    if (this.customerId) {
      this.data.customerId = this.customerId
    }
  },
  methods: {
    t(key, options = {}) {
        return this.$t(`offerAndDemand.campaign.${key}`, options)
    },
    cancel() {
        this.$emit('input', false)
    },
    submit() {
      this.data.userId = this.$user.id
      API.campaignDrafts.create(this.data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.$emit('refresh-campaign')
        this.cancel()
      })
    }
  }
})
