import {isEqual} from 'lodash'
import template from './lead-destination.pug'

const DefaultValues = () => Object.freeze({
  instance: '',
  campaignCtiId: '',
  tokenaibe: ''
})

const Fields = Object.keys(DefaultValues())

const Validations = {
  instance: {required: true, type: 'string'},
  campaignCtiId: {required: true, type: 'string'},
  tokenaibe: {required: true, type: 'string'}
}

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      campaignOptions: [],
      data: {},
      ready: false
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    }
  },
  methods: {
    initializeData() {
      const data = {}
      Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
      this.data = data
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`customers.aibe.form.${key}`, options)
    },

    loadCampaings() {
      API.customers.aibe.campaing(this.data.instance, {
        page: {number: 1, size: 1000},
        tokenaibe: this.data.tokenaibe
      }).then(({data}) => {
        this.campaignOptions = data.map(item => ({
          value: item.id,
          label: item.name
        }))
      })
    }

  }
})
