import RouteParent from 'js/router/route-parent'

// eslint-disable-next-line max-len
import GoogleAdsLeadFormAuthIndex from 'js/pages/auth-integrations/google-ads-lead-form-auth/auth.js'

export default {
  path: '/google-ads-lead-form/auth',
  component: RouteParent,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: 'google-ads-lead-form-auth-index',
      component: GoogleAdsLeadFormAuthIndex,
      meta: {
        auth: true
      }
    }
  ]
}

