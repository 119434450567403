import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import {openModalAlert} from 'js/services/modals'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './function-node.pug'

const DefaultData = () => ({
  config:{
    functionId:"",
    retryAttempts:""
 }
})

// Nodo que permite dividir el flujo del workflow en dos caminos basado en una condición
export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      FunctionsLambda:[]
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    functionsSelectOptions() {
      return this.FunctionsLambda.map(item => (
        {label: item.name || item.key, value: item.id}
      )).sort((a, b) => (a.label.localeCompare(b.label)))
    },

  },
  watch: {
  },
  methods: {
    getFunctionsLambda(){
      return API.functionsLambda.index(
        {page: {number: 1, size: 1000}}
      ).then(({data}) => {
        // console.log(data)
        this.FunctionsLambda = data

      })
    },
    save() {

      if (this.data.config.retryAttempts <=5) {
        validatePresence(this.data, [
          'config.retryAttempts',
          'config.functionId'
        ])
  
        if (this.data.errors) {
          openModalConfirm().then(() => {
            this.$emit('save', cloneDeep(this.data))
          })
        } else {
          this.$emit('save', cloneDeep(this.data))
        }
      }else{
        openModalAlert(
          this.t('AlertAttemps.title'),
          this.t('AlertAttemps.body'),
          this.t('AlertAttemps.okButton')
        )
      }
      
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.functionNode.${key}`, options)
    }
  },

  created(){
    this.getFunctionsLambda()
  }
})
