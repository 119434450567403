import template from './_play-audios.pug';

export default Vue.extend({
  template: template(),
  props: {
    selectedAudio: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      idCopied: null,
      currentTab: 'audio-url',
      playingAudioUrl: null,
      isPlaying: false,
      audioCurrentTime: 0,
      audioDuration: 0,
    };
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`audiosMenuVoice.playAudios.${key}`, options);
    },
    playPauseAudio() {
      const audio = this.$refs.audio;
      if (audio.paused) {
        audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
    },
    updateCurrentTime() {
      if (this.$refs.audio) {
        this.audioCurrentTime = this.$refs.audio.currentTime;
      }
    },
    updateDuration() {
      this.audioDuration = this.$refs.audio.duration;
    },
    seekAudio(event) {
      const audio = this.$refs.audio;
      audio.currentTime = event.target.value;
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    },
    closeAudioPanel() {
      this.$emit('close');
    },
    cancel() {
      this.$emit('cancel');
    },
    audioEnded() {
      this.audioCurrentTime = this.audioDuration;
      this.isPlaying = false;
      this.$nextTick(() => {
        this.audioCurrentTime = 0;
      });
    }
  },
  watch: {
    audioCurrentTime(newTime) {
      if (newTime >= this.audioDuration) {
        this.audioCurrentTime = this.audioDuration;
      }
    }
  }
});
