import RouteParent from 'js/router/route-parent'

import smsTemplatesIndex from 'js/pages/sms-templates/index.js'
import smsTemplatesNew from 'js/pages/sms-templates/new.js'
import smsTemplatesEdit from 'js/pages/sms-templates/edit.js'

export default [
  {
    path: '/sms-templates',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'sms-templates-index',
        component: smsTemplatesIndex,
        meta: {
          auth: true

        }
      },
      {
        path: 'new',
        name: 'sms-templates-new',
        component: smsTemplatesNew,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit',
        name: 'sms-templates-edit',
        component: smsTemplatesEdit,
        meta: {
          auth: true
        }
      }
    ]
  }
]
