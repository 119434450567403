import template from './_form_modal.pug'
import FormComponent from '../../../../campaigns/wizard/form.js'
import validateForm from '../../../../../services/validate-form'
import { cloneDeep } from 'lodash'

export default Vue.extend({
  template: template(),
  components: {
    FormComponent
  },
  props: {
    initData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      errors: {},
      data: {},
      dataErrors: {},
    }
  },
  created() {
    this.initializeData()
  },
  methods: {
    cancel() {
      this.$emit('input', false)
    },
    initializeData() {
      this.data = cloneDeep(this.initData)
    },
    isValid() {
      this.dataErrors = {}
      const errors = validateForm(
        FormComponent.options.validations, this.data, 'campaigns'
      )
      if (errors) {
        this.dataErrors = errors
        return false
      }
      return true
    },
    createForm() {
      if(!this.isValid()) return
      this.$emit('set-data', this.data)
      this.cancel()
    },

    t(key, options = {}) {
      return this.$t(`customers.aibe.form.formModal.${key}`, options)
    }
  }
})
