import ProductsTable from './_table.js'
import template from './call-center-index.pug'

export default Vue.extend({
  template: template(),
  components: {
    ProductsTable
  },
  data() {
    return {
      products: [],
      meta: {},
      ready: false
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.loadCallCenter()
      }
    }
  },
  methods: {
    loadCallCenter() {
      this.ready = false
      API.callCenters.show(this.$route.params.id).then(callCenter => {
        this.$setPageTitle(this.$t('products.callCenter.title', {name: callCenter.name}))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.callCenters.index'), route: {name: 'call-centers-index'}},
          {
            label: this.$t('breadcrumbs.callCenters.show', {name: callCenter.name}),
            route: {name: 'call-centers-show', params: {id: callCenter.id}}
          },
          {
            label: this.$t('breadcrumbs.callCenters.products', {name: callCenter.name}),
            route: {}
          }
        ])

        this.ready = true
      })
    },

    loadProducts(queryOptions = {}) {
      queryOptions.filter = queryOptions.filter || {}
      queryOptions.filter.callCenterId = [this.$route.params.id]

      API.products.index(queryOptions).then(({data, meta}) => {
        this.products = data
        this.meta = meta
      })
    }
  }
})
