import ProviderForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    ProviderForm
  },
  data() {
    return {
      errors: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('providerLeads.new.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.provider-leads.index'), route: {name: 'provider-leads-index'}},
      {label: this.$t('breadcrumbs.provider-leads.new'), route: {}}
    ])
  },
  methods: {
    createProvider(data) {
      API.providerLeads.create(data).then(() => {
        this.$notifications.success(this.$t('providerLeads.new.succesfullyCreated'))
        this.$router.push({name: 'provider-leads-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
