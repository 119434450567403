import {pick, isEqual} from 'lodash'
import {mapGetters} from 'vuex'
import {validations} from '../../../../../mocks/customers/factory.js'
import template from './info.pug'

const DefaultValues = () => Object.freeze({
  name: '',
  address: '',
  country: '',
  email: '',
  fantasyName: '',
  legalIdentifier: '',
  city: '',
  province: ''
})
const Fields = Object.keys(DefaultValues())
const Validations = pick(validations, Fields)

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    logoUrl: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: {},
      removeAvatar: false,
      ready: false
    }
  },
  computed: {
    ...mapGetters(['countries', 'customerActivities']),

    showInitials() {
      return (!this.logoUrl && !this.data.logo) || this.removeAvatar
    },

    countrySelectOptions() {
      return this.countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    }
  },
  methods: {
    initializeData() {
      const data = {}
      Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
      this.data = data
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`customers-admin.form.${key}`, options)
    }
  }
})
