
import template from './modal-customer.pug'

export default Vue.extend({
  template: template(),
  props: {},
  data() {
    return {
      data: {
        customerName: ''
      }
    }
  },
  methods: {
    t(key, options = {}) {
        return this.$t(`offerAndDemand.customer.${key}`, options)
    },
    cancel() {
        this.$emit('input', false)
    },
    submit() {
      API.leadTrade.customerCreate(this.data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.$emit('refresh-customer')
        this.cancel()
      })
    }
  }
})
