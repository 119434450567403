import orderBy from 'lodash/orderBy'
import {openModalConfirmRemove} from 'js/services/modals.js'
import ProviderForm from './_provider-form.js'
import ModalPixelControl from '../../../_modal-pixel-control'
import ModalPoolConfig from './_modal-pool-config.js'
import ModalSpoolConfig from './_modal-spool-config.js'
import template from './provider-index.pug'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')

export default Vue.extend({
  template: template(),
  components: {
    ModalPixelControl,
    ModalPoolConfig,
    ModalSpoolConfig,
    ProviderForm
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      providerFormData: {},
      providersFormErrors: {},
      showModalPoolConfig: false,
      showModalSpoolConfig: false,
      showForm: false,
      providers: [],
      providerSelected: null,
      pixelControlCampaign: null,
      campaignProviders: [],
      sitesSelectOptions: [],
      providerLeadsOptions: [],
      poolNumbers: [],
      staticNumbers: [],
      ddiNumbers: []
    }
  },
  computed: {
    campaignId() {
      return this.campaign.id
    },
    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    formMode() {
      return this.providerFormData.id ? 'edit' : 'new'
    }
  },
  async created() {
    this.loadSites()
    this.loadProviders()
    this.loadCampaignProviders()
    this.loadPool()
    this.loadStaticNumbers()
    this.loadDdiNumbers()
    this.ready = true
  },
  methods: {
    showPoolConfig(data) {
      this.providerSelected = data
      this.showModalPoolConfig = true
    },
    showStaticPoolConfig(data) {
      this.providerSelected = data
      this.showModalSpoolConfig = true
    },
    reloadCampaign() {
      const currentPath = this.$route.path
      const currentQuery = this.$route.query?.l || 0
      const query = {l: parseInt(currentQuery) + 1}
      this.$router.push({path: currentPath, query})
    },
    poolTypeStatic({poolTypeAssigned}) {
      const options = {
        static: {
          hover: this.t('hover.static'),
          icon: 'done',
          class: 'info'
        },
        dynamic: {
          hover: this.t('hover.notspool'),
          icon: 'block',
          class: 'danger'
        },
        both: {
          hover: this.t('hover.static'),
          icon: 'done',
          class: 'info'
        },
        notpool: {
          hover: this.t('hover.notspool'),
          icon: 'block',
          class: 'danger'
        }
      }
      return options[poolTypeAssigned]
    },
    poolTypeDynamic({poolTypeAssigned}) {
      const options = {
        dynamic: {
          hover: this.t('hover.dynamic'),
          icon: 'done',
          class: 'success'
        },
        static: {
          hover: this.t('hover.notpool'),
          icon: 'block',
          class: 'danger'
        },
        both: {
          hover: this.t('hover.dynamic'),
          icon: 'done',
          class: 'success'
        },
        notpool: {
          hover: this.t('hover.notpool'),
          icon: 'block',
          class: 'danger'
        }
      }
      return options[poolTypeAssigned]
    },
    openConfirmModal(data) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.deleteProvider(data))
    },

    showProviderCpl(data) {
      this.$router.push({
        name: 'campaigns-show-config-provider-cpl',
        params: {providerId: data.id, id: this.campaign.id},
        query: {
          name: data.name,
          providerId: data.id
        }
      })
    },

    changeOrder(field, dir) {
      this.campaignProviders = orderBy(
        this.campaignProviders,
        item => item[field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        dir
      )
    },

    openNewForm() {
      this.providerFormData = {
        campaignId: this.campaignId
      }
      this.showForm = true
    },

    openEditForm(item) {
      this.providerFormData = {
        id: item.id,
        providerId: item.id,
        siteId: item.siteId
      }

      this.showForm = true
    },

    closeModal() {
      this.providerFormData = {}
      this.providersFormErrors = {}
      this.showForm = false
    },

    saveProvider(data) {
      this.providersFormErrors = {}

      if (this.formMode === 'edit') {
        this.updateProvider(data)
      } else {
        this.createProvider(data)
      }
    },

    loadCampaignProviders() {
      this.campaignProviders = [...this.campaign.providers]
    },

    loadSites() {
      API.sites.index(
        {
          filter: {
            customerId: this.campaign.customerId
          },
          page: {
            number: 1, size: 1000
          }
        }
      ).then(({data}) => {
        this.sitesSelectOptions = data.map(sites => ({
          label: sites.description || sites.url,
          value: sites.id
        }))
      })
    },

    showPixelControl(data) {
      const pixelControl = {
        name: this.campaign.name,
        providers: [
          {
            id: data.id,
            pixelControl: data.pixelControl,
            pixelConsent: data.pixelConsent,
            name: data.name,
            url: data.siteUrl
          }
        ]
      }
      this.pixelControlCampaign = JSON.parse(JSON.stringify(pixelControl))
    },

    loadProviders() {
      API.providerLeads.index()
        .then(({data}) => {
          this.providerLeadsOptions = data.map(provider => ({
            label: provider.name,
            value: provider.id
          }))
        })
    },

    loadPool() {
      API.pool.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.poolNumbers = data
      })
    },

    loadStaticNumbers() {
      API.callTrackingStaticNumbers.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.staticNumbers = data
      })
    },

    loadDdiNumbers() {
      API.ddis.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.ddiNumbers = data
      })
    },

    updateProvider(data) {
      API.campaigns.providers.update(this.campaignId, data.id, data)
      .then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.reloadCampaign()
        this.closeModal()
      }, errors => {
        this.providersFormErrors = errors
      })
    },

    createProvider(data) {
      API.campaigns.providers.create(this.campaignId, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.reloadCampaign()
        this.closeModal()
      }, errors => {
        this.providersFormErrors = errors
      })
    },

    deleteProvider(data) {
      API.campaigns.providers
      .destroy(this.campaignId, data.id, data.siteId).then(() => {
        this.$notifications.success(this.t('succesfullyDeleted'))
        this.reloadCampaign()
        this.closeModal()
      }, errors => {
        this.providersFormErrors = errors
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.provider.${key}`, options)
    }
  }
})
