import {cloneDeep, isEqual, isEmpty} from 'lodash'
import {openModalConfirm} from 'js/services/modals.js'
import validateForm from '../../../../services/validate-form'
import CampaignForm from './wizard/campaign-form.js'
import LeadSource from './wizard/lead-source.js'
import LeadDestination from './wizard/lead-destination'
import CompleteForm from './wizard/complete.js'
import template from './wizard.pug'

// Wizard para la creación de una campaña
// Gestiona las validaciones en frontend de las distintas pestañas
// Puede guardar una versión Draft sin cumplir las validaciones
// Avisa al usuario si cambia de pestañas con datos sin validar y si abandona la pagina sin guardar
export default Vue.extend({
  template: template(),
  components: {
    CampaignForm,
    LeadSource,
    LeadDestination,
    CompleteForm
  },
  data() {
    return {
      data: {},
      errors: {},
      campaignFormData: {},
      campaignFormErrors: {},
      leadSourceFormData: {},
      leadSourceFormErrors: {},
      leadDestinationFormData: {},
      leadDestinationFormErrors: {},
      completeForm: {},
      // Valores de WIZARD_STEP_STATE
      // COMPLETED => Todos los datos obligatorios están rellenos de forma correcta
      // EMPTY => No ha recogido ningún dato hasta el momento
      // WIP => No se han completado todos los campos obligatorios o no se ha validado alguno
      campaignFormState: '', // Valores de WIZARD_STEP_STATE
      leadSourceFormState: '', // Valores de WIZARD_STEP_STATE
      leadDestinationFormState: '', // Valores de WIZARD_STEP_STATE
      formData: {messages: {success: '', error: ''}},
      ready: false,
      tabs: ['campaignForm', 'leadSourceForm', 'leadDestinationForm', 'completeForm'],
      currentTab: '',
      saved: false
    }
  },
  // Si el usuario abandona el wizard por el modo que sea se muestra un mensaje dando la opción
  // de guardar el wizard antes de abandonar la página
  // eslint-disable-next-line consistent-return
  beforeRouteLeave(to, _from, next) {
    // Evita el mensaje al abandonar la pagina por falta de permisos o al redirecionar tras guardar
    if (to.name === 'error-403' || to.name === 'error-500' || this.saved) {
      return next()
    }

    if (this.isCompleted) {
      return next()
    }

    openModalConfirm(
      this.t('leaveWizardConfirmationModal.title'),
      this.t('leaveWizardConfirmationModal.body'),
      this.t('leaveWizardConfirmationModal.okButton')
    ).then(() => {
      next()
    }).catch(() => {
      next()
    })
  },
  computed: {
    isCompleted() {
      return this.currentTab === 'completeForm'
    },

    indexOfCurrentTab() {
      return this.tabs.indexOf(this.currentTab)
    },

    previousButtonDisabled() {
      return this.indexOfCurrentTab === 0
    },

    nextButtonDisabled() {
      return this.indexOfCurrentTab === this.tabs.length - 1 || this.nameIsEmpty
    },

    createButtonDisabled() {
      const incomplete = this.tabs.filter(
        tab => this[`${tab}State`] !== WIZARD_STEP_STATE.COMPLETED
      )
      return incomplete.length > 1 || incomplete.length === 1 && incomplete[0] !== this.currentTab
    },

    nameIsEmpty() {
      return !this.campaignFormData.campaign.name
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }

  },
  methods: {
    async initializeData() {
      this.$setPageTitle(this.$t('customers.aibe.new.title'))
      this.$setBreadcrumbs([
        {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
        {label: this.$t('breadcrumbs.customers.aibe'), route: {}}
      ])

      this.initializeNewCampaign()

      this.initializeTabs()
    },

    exitWizard() {
      this.$router.push({name: 'customers-edit', params: {id: this.$route.params.id}})
    },

    initializeNewCampaign() {
      const data = {}
      this.campaignFormData = cloneDeep(CampaignForm.options.defaultValues())
      this.leadSourceFormData = cloneDeep(LeadSource.options.defaultValues())
      this.leadDestinationFormData = cloneDeep(LeadDestination.options.defaultValues())
      this.data = data
    },

    initializeTabs() {
      this.validateTab('campaignForm', CampaignForm, false)

      // Selecciona la primera que no está completa o la ultima si estan todas completas
      this.currentTab = this.tabs.find(
        tab => this[`${tab}State`] !== WIZARD_STEP_STATE.COMPLETED
      ) || this.tabs[this.tabs.length - 1]

      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`customers.aibe.form.${key}`, options)
    },

    getClassByStatus(status) {
      switch (status) {
        case WIZARD_STEP_STATE.EMPTY:
          return 'tab--gray'
        case WIZARD_STEP_STATE.WIP:
          return 'tab--yellow'
        case WIZARD_STEP_STATE.COMPLETED:
          return 'tab--blue'
        default:
          return ''
      }
    },

    // keepErrors por defecto = true, mantiene los errores, para mostrarlos en el formulario
    // Se usa con valor false en la carga inicial del formulario que necesita inicializar el estado
    // de la pestaña pero no queremos que se muestren los errores en el formulario
    validateTab(tabName, tabComponent, keepErrors = true) {
      const errors = validateForm(
        tabComponent.options.validations, this[`${tabName}Data`], 'campaigns'
      )

      if (errors) {
        if (keepErrors) this[`${tabName}Errors`] = errors

        const dataTab = this[`${tabName}Data`]
        const defaultValues = tabComponent.options.defaultValues()

        if (isEmpty(dataTab) || isEqual(dataTab, defaultValues)) {
          this[`${tabName}State`] = WIZARD_STEP_STATE.EMPTY
        } else {
          this[`${tabName}State`] = WIZARD_STEP_STATE.WIP
        }

        this.$nextTick(() => {
          const error = document.getElementsByClassName('error')[0]
          if (error) error.scrollIntoView({block: 'center'})
        })
      } else {
        this[`${tabName}Errors`] = {}
        this[`${tabName}State`] = WIZARD_STEP_STATE.COMPLETED
      }
    },

    saveFinished() {
      if (this.isValidCurrentTab()) {
        this.saveCampaign()
      }
    },

    saveCampaign() {
      // eslint-disable-next-line max-len
      this.data = {customerId: this.$route.params.id, ...this.campaignFormData, ...this.leadSourceFormData, destination: this.leadDestinationFormData}

      API.customers.aibe.wizard(this.data).then(response => {
        this.$notifications.success(this.t('successfullyCreated'))
        this.completeForm = response.data
        this.nextStep()
      }, errors => {
        this.errors = errors

        if (!isEmpty(this.campaignFormErrors)) this.campaignFormState = WIZARD_STEP_STATE.WIP
        if (!isEmpty(this.leadSourceFormErrors)) this.leadSourceFormState = WIZARD_STEP_STATE.WIP
        if (!isEmpty(this.leadDestinationFormErrors)) {
          this.leadDestinationFormState = WIZARD_STEP_STATE.WIP
        }
        this.$notifications.error(this.t('errorCreated'))
        window.scrollTo(0, 0)
      })
    },

    previousStep() {
      this.changeTab(this.tabs[this.indexOfCurrentTab - 1])
    },

    nextStep() {
      this.changeTab(this.tabs[this.indexOfCurrentTab + 1])
    },

    changeTab(tabId) {
      if (this.isValidCurrentTab()) {
        this.currentTab = tabId
        return
      }

      openModalConfirm(
        this.t('changeStepConfirmationModal.title'),
        this.t('changeStepConfirmationModal.body'),
        this.t('changeStepConfirmationModal.okButton'),
        {cancelText: this.t('changeStepConfirmationModal.cancelButton')}
      ).then(() => {
        this.currentTab = tabId
      }).catch(() => {
        const currentTab = this.currentTab
        this.currentTab = tabId
        this.$nextTick(() => { this.currentTab = currentTab })
      })
    },

    // eslint-disable-next-line consistent-return
    isValidCurrentTab() {
      if (this.currentTab === 'campaignForm') {
        this.validateTab(this.currentTab, CampaignForm)
        return isEmpty(this.campaignFormErrors)
      } else if (this.currentTab === 'leadSourceForm') {
        this.validateTab(this.currentTab, LeadSource)
        return isEmpty(this.leadSourceFormErrors)
      } else if (this.currentTab === 'leadDestinationForm') {
        this.validateTab(this.currentTab, LeadDestination)
        return isEmpty(this.leadDestinationFormErrors)
      }
    }
  }
})
