import {mapGetters} from 'vuex'
import template from './_form.pug'

const Fields = [
  'description','ddiNumber','trackingSource'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      optionsDdis: [],
      selectedDdi: {},
      selectedDdiLabel: '',
      selectedTrackingSource: {},
      optionsTrackingSources: [],
      selectedCampaing: '',
      phoneNumberConfig:{
        size:"lg",
        noExample: true,
        error: false,
        colorError: "#EE666A",
        updateData: {},
        translations:{
          countrySelectorLabel: 'Código de país',
          countrySelectorError: '',
          phoneNumberLabel: 'Teléfono',
          example: ''
        },
        noFlags: true,
      },
      campaigns:[]
    }
  },
  computed: {
    optionsCampaings(){
      return this.campaigns.map(campaing =>({
        label: `${campaing.campaignName} - ${campaing.siteName}`,
        value: campaing.campaignSiteId
      }))
    }

  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
        this.loadDdis()
        this.loadTrackingSources()
        this.loadCampaignsInit()
      }
    },
    selectedDdi: {
      handler(newValue) {
        if(newValue) {
          this.loadCampaigns()
          // this.selectedCampaing = this.campaigns[0].campaignSiteId
          console.log(this.optionsDdis)
        }
      }
    }
  },
  created() {
    // this.initializeData()
    // this.loadDdis()
    // this.loadTrackingSources()
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data
      this.selectedDdi = this.initData.ddiNumber
      try {
        this.selectedDdiLabel = `${this.initData.ddiNumber.number} (${this.initData.ddiNumber.description})`
        
      } catch (error) {
        this.selectedDdiLabel = ''
        
      }
      this.selectedTrackingSource = this.initData.trackingSource
      this.selectedCampaing = this.initData.campaignSiteId
      
      
    },

    unselectedFilter(options, text) {
      return options.filter(item => item.label.includes(text))
    },

    loadDdis() {
      API.ddis.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.optionsDdis = data.map(ddiNumber => ({
          label: `${ddiNumber.number} (${ddiNumber.description})`,
          value: ddiNumber
        }))
      })
    },

    loadCampaigns() {
        API.customers.campaigns.index(this.selectedDdi.customerId,{page: {number: 1, size: 1000}}).then(({data}) => {
            console.log(data)
            this.campaigns = data
        })
    },
    loadCampaignsInit() {
      if (this.selectedDdi) {
        API.customers.campaigns.index(this.selectedDdi.customerId,{page: {number: 1, size: 1000}}).then(({data}) => {
            console.log(data)
            this.campaigns = data
        })
      }
    },

    loadTrackingSources() {
      API.trackingSources.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.optionsTrackingSources = data.map(TrackingSource => ({
          label: `${TrackingSource.name}`,
          value: TrackingSource
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`trackingNumbers.form.${key}`, options)
    },

    submit() {

      // console.log('-----------------')
      // console.log('ddis data')
      // console.log(this.selectedDdi)
      // console.log(this.initData.ddi)
      // console.log(this.initData.ddi.number === this.selectedDdi.number)
      // console.log('-----------------')
      // console.log('tracking sources data')
      // console.log(this.initData.trackingSource)
      // console.log(this.selectedTrackingSource)
      // console.log(this.selectedTrackingSource === this.initData.trackingSource)
      // console.log('-----------------')

      this.data.ddiNumber = this.selectedDdi
      this.data.trackingSource = this.selectedTrackingSource
      this.data.campaignSiteId = this.selectedCampaing

      // console.log(this.data)
      // console.log(this.selectedCampaing)

      this.$emit('submit', this.data)

    },

    cancel() {
      this.$emit('cancel')
    },

    errorUpload(errors = []) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`fields.logo.errors.${error}`)
        )
      }
    },
  }
})
