import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './send-lead.pug'

const DefaultData = () => ({
  config: {
    providerSiteId: '',
    campaignId: '',
    action: 'move'
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      campaigns: [],
      providerSiteOptions: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    campaignSelectOptions() {
      return this.campaigns.map(campaign => ({
        label: campaign.name,
        value: campaign.id
      }))
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
        this.getCampaigns()
      }
    }
  },
  methods: {
    getCampaigns() {
      return API.campaigns.index().then(({data}) => {
        this.campaigns = this.$sortByLocale(data, 'name')
        this.loadProviders(this.data.config.campaignId)
      })
    },
    loadProviders(campaignId) {
      this.providerSiteOptions = []
      if (!campaignId) return
      const campaignSelected = this.campaigns.find(campaign => campaign.id === campaignId)
      this.providerSiteOptions = campaignSelected.providers.map(provider => ({
        label: `${provider.name} - ${provider.siteUrl}`,
        value: provider.id
      }))
    },
    loadProviderSiteId(event) {
      this.data.config.providerSiteId = null
      this.loadProviders(event)
    },

    save() {
      validatePresence(this.data, [
        'config.campaignId',
        'config.action'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.sendLead.${key}`, options)
    }
  }
})
