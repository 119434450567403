import Message from './_message'
import template from './accept-invitation.pug'

export default Vue.extend({
  template: template(),
  components: {Message},
  data() {
    return {
      status: null,
      user: {
        name: '',
        lastName: '',
        password: '',
        password_confirmation: ''
      },
      errors: {}
    }
  },
  computed: {
    invitationToken() {
      return this.$route.query.invitation_token
    }
  },
  watch: {
    invitationToken: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.$router.push({name: 'login'})
        } else {
          this.status = null
          this.checkInvitation()
        }
      }
    }
  },
  methods: {
    // Verifica el token contra el backend
    async checkInvitation() {
      this.invitation = await this.$auth.showInvitation(this.invitationToken).then(() => {
        this.status = 'valid'
      }, response => {
        if (response.status === 410) {
          this.status = 'expired'
        } else {
          this.status = 'invalid'
        }
      })
    },

    submit() {
      this.$auth.updateInvitation(this.invitationToken, this.user).then(
        () => { this.status = 'success' },
        errors => { this.errors = errors }
      )
    }
  }
})
