import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    customers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      filters: {name: '', legalIdentifier: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    statusOptions() {
      return [
        {label: this.t('filters.status.all'), value: ''},
        {label: this.t('filters.status.active'), value: true},
        {label: this.t('filters.status.inactive'), value: false}
      ]
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  methods: {
    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    t(key, options = {}) {
      return this.$t(`customers-admin.index.${key}`, options)
    },

    noHaveCampaigns(status) {
      return status === CUSTOMER_STATUSES.NO_CAMPAIGNS
    },

    getClassByStatus(status) {
      switch (status) {
        case true:
          return 'table-tag--success'
        case false:
          return 'table-tag--gray'
        default:
          return ''
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case true:
          return 'check'
        case false:
          return 'close'
        default:
          return ''
      }
    },

    getTextByStatus(status) {
      switch (status) {
        case true:
          return this.$t('customers-admin.statuses.active')
        case false:
          return this.$t('customers-admin.statuses.inactive')
        default:
          return ''
      }
    },

    avatarInitials(customer) {
      return customer.name ? customer.name[0] : '-'
    },

    confirmDelete(customer) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: customer.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(customer))
    },

    destroy(customerId) {
      API.customersAdmin.destroy(customerId.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
