import RouteParent from 'js/router/route-parent'

import cutReportIndex from 'js/pages/cut-report/index.js'

export default [
  {
    path: '/cut-report',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'cut-report-index',
        component: cutReportIndex,
        meta: {
          auth: true

        }
      }
    ]
  }
]
