import pick from 'lodash/pick'

import AnalyticsChart from 'js/components/analytics/chart'
import Filters from 'js/components/analytics/filters.js'
import statusCountFunnel from 'js/components/analytics/leads-report-funnel'
import contactedConvertedBar from 'js/components/analytics/contacted-converted-bar'
import leadsSalesCtaLine from 'js/components/analytics/leads-sales-cta-line'
import salesByTargetPie from 'js/components/analytics/sales-by-target-pie'
import salesByAgentBar from 'js/components/analytics/sales-by-agent-bar'
import crByCallCenterLine from 'js/components/analytics/cr-call-centers-line'
import AnalyticsUtils from 'js/services/analytics-utils'

import template from './transformation-tab.pug'

// Gestiona la pestaña trasformación de la analítica de una campaña
// Está compuesta por un cuadro de filtros y varias gráficas
export default Vue.extend({
  name: 'transformation-tab',
  template: template(),
  components: {
    Filters,
    AnalyticsChart
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      campaignData: null,
      itemGlobalData: null,
      dayData: null,
      salesByProductData: null,
      salesByCallCenterData: null,
      salesByAgentData: null,
      salesByAgentGroupData: null,
      dayCallCentersData: null,
      filters: {},
      filtersInfo: {}
    }
  },
  computed: {
    targetTypeOptions() {
      const targetTypes = ['general', 'agent', 'product', 'callCenter', 'agentGroup']
      return targetTypes.filter(
        target => Vue.account.has(`campaign_transformation_${target}`)
      )
    },
    studyItemCallCenter() {
      return !!this.filters.callCenterId
    },

    studyItemProduct() {
      return !!this.filters.productId
    },

    studyItemAgent() {
      return !!this.filters.agentId
    },

    studyItemAgentGroup() {
      return !!this.filters.agentGroupId
    },

    statusCountChartOptions() {
      if (!this.campaignData) return null

      return statusCountFunnel.options(pick(this.campaignData, [
        'totalCount', // leads Brutos
        'leadsCount', // leads Netos
        'sentCount', // leads enviados
        'contactedCount', // leads contactados
        'convertedCount', // ventas
        'registratedCount' // altas
      ]), 'analytics.charts.transformationFunnel')
    },

    contactedConvertedChartOptions() {
      return contactedConvertedBar.options(this.dayData)
    },

    // cuando se filtra por un canal, el resto de canales se agrupan
    groupedLeadsReportByProductData() {
      if (this.filters.productId) {
        return AnalyticsUtils.groupOtherPie(
          this.salesByProductData,
          'id',
          this.filters.productId
        )
      }

      return this.salesByProductData
    },

    salesByProductChartOptions() {
      if (!this.salesByProductData) return null

      return salesByTargetPie.options(
        this.groupedLeadsReportByProductData,
        'analytics.charts.salesByProductPie'
      )
    },

    // cuando se filtra por un canal, el resto de canales se agrupan
    groupedLeadsReportByCallCenterData() {
      if (this.filters.callCenterId) {
        return AnalyticsUtils.groupOtherPie(
          this.salesByCallCenterData,
          'id',
          this.filters.callCenterId
        )
      }

      return this.salesByCallCenterData
    },

    salesByCallCenterChartOptions() {
      if (!this.salesByCallCenterData) return null

      return salesByTargetPie.options(
        this.groupedLeadsReportByCallCenterData,
        'analytics.charts.salesByCallCenterPie'
      )
    },

    // cuando se filtra por un canal, el resto de canales se agrupan
    groupedLeadsReportByAgentData() {
      if (this.filters.agentId) {
        return AnalyticsUtils.groupOtherPie(
          this.salesByAgentData,
          'key',
          this.filters.agentId
        )
      }

      return this.salesByAgentData
    },

     groupedLeadsReportByAgentGroupData() {
      if (this.filters.agentGroupId) {
        return AnalyticsUtils.groupOtherPie(
          this.salesByAgentGroupData,
          'key',
          this.filters.agentGroupId
        )
      }

      return this.salesByAgentGroupData
    },

    salesByAgentChartOptions() {
      if (!this.salesByAgentData) return null

      return salesByAgentBar.options(
        this.groupedLeadsReportByAgentData,
        'analytics.charts.salesByAgentPie'
      )
    },

    salesByAgentGroupChartOptions() {
      if (!this.salesByAgentGroupData) return null

      return salesByAgentBar.options(
        this.groupedLeadsReportByAgentGroupData,
        'analytics.charts.salesByAgentGroupPie'
      )
    },

    leadsSalesCtaLineChartOptions() {
      if (!this.dayData) return null

      return leadsSalesCtaLine.options(
        this.dayData.map(item => ({
          ...item,
          cpa: ((this.filtersInfo.investment || 0) / item.totalCount).toFixed(
            2
          )
        })),
        'analytics.charts.leadsSalesCtaLine'
      )
    },

    // el cr es % de leads convertidos
    crByCallCenterChartOptions() {
      if (!this.dayCallCentersData) return null

      const {callCenters, chartData} = AnalyticsUtils.getCrByCallCenterChart(
        this.dayCallCentersData,
        this.filters
      )

      return crByCallCenterLine.options(
        callCenters,
        chartData,
        'analytics.charts.crByCallCenterLine'
      )
    },

    isGlobalStudyItem() {
      return !this.studyItemCallCenter &&
        !this.studyItemProduct &&
        !this.studyItemAgent &&
        !this.studyItemAgentGroup
    },

    // cuando aparece el segundo panel del item de estudio, pasamos de 4 a 3 columnas
    generalColumnsClass() {
      return this.isGlobalStudyItem ? 'col-3-lg' : 'col-4-lg'
    },

    campaignHasData() {
      return AnalyticsUtils.checkObjectValues(this.campaignData, 'campaignId')
    },

    itemGlobalHasData() {
      return AnalyticsUtils.checkObjectValues(this.itemGlobalData, 'campaignId')
    }
  },
  methods: {
    exportSales(filters) {
      this.filters = {...filters, campaignId: this.campaign.id}
      setTimeout(
        () => {
          this.loadSalesCsv()
        },
        MOCKS ? 500 : 0
      )
    },
    exportLeads(filters) {
      this.filters = {...filters, campaignId: this.campaign.id}
      setTimeout(
        () => {
          this.loadLeadsCsv()
        },
        MOCKS ? 500 : 0
      )
    },
    applyFilters(filters, info) {
      // los ponemos a null para hacer que aparezca el cargando
      this.campaignData = null
      this.itemGlobalData = null
      this.dayData = null
      this.salesByProductData = null
      this.salesByCallCenterData = null
      this.salesByAgentGroup = null
      this.dayCallCentersData = null

      this.filters = {...filters, campaignId: this.campaign.id}
      this.filtersInfo = info

      // TODO: eliminar cuando se terminen las pruebas
      setTimeout(
        () => {
          this.loadFunnelData()
          if (!this.isGlobalStudyItem) this.loadItemGeneralDataData()
          this.loadDayCountData()
          this.loadSalesByProductData()
          this.loadSalesByCallCenterData()
          this.loadDayCallCentersData()
          this.loadSalesByAgentData()
          this.loadSalesByAgentGroupData()
        },
        MOCKS ? 500 : 0
      )
    },

    loadLeadsCsv() {
      // Este solo se ve afectado por estos filtros
      const filters = pick(this.filters, [
        'from',
        'to',
        'campaignId',
        'agentGroupId',
        'productId',
        'callCenterId',
        'agentId'
      ])
      API.analytics
        .leadsCvs(this.campaign.customerId, filters)
        .then(({data}) => {
          AnalyticsUtils.downloadCsv({
            title: this.$i18n.t('analytics.export.titleLeads'),
            to: filters.to,
            from: filters.from,
            url: data.file
          })
        })
    },

    loadSalesCsv() {
      // Este solo se ve afectado por estos filtros
      const filters = pick(this.filters, [
        'from',
        'to',
        'campaignId',
        'agentGroupId',
        'productId',
        'callCenterId',
        'agentId'
      ])
      API.analytics
        .salesCvs(this.campaign.customerId, filters)
        .then(({data}) => {
          AnalyticsUtils.downloadCsv({
            title: this.$i18n.t('analytics.export.titleSales'),
            to: filters.to,
            from: filters.from,
            url: data.file
          })
        })
    },

    loadFunnelData() {
      // Este solo se ve afectado por estos filtros
      const filters = pick(this.filters, ['from', 'to', 'campaignId'])

      API.analytics
        .statusCount(this.campaign.customerId, filters)
        .then(({data}) => {
          this.campaignData = data
        })
    },

    loadItemGeneralDataData() {
      // Este solo se ve afectado por estos filtros
      const filters = pick(this.filters, [
        'from',
        'to',
        'campaignId',
        'productId',
        'callCenterId',
        'agentGroupId',
        'agentId'
      ])

      API.analytics
        .statusCount(this.campaign.customerId, filters)
        .then(({data}) => {
          this.itemGlobalData = data
        })
    },

    loadDayCountData() {
      API.analytics
        .dayCount(this.campaign.customerId, this.filters)
        .then(({data}) => {
          this.dayData = data
        })
    },

    loadSalesByProductData() {
      // esta gráfica no se filtra por producto
      const params = {...this.filters, productId: undefined}

      API.analytics
        .salesByProduct(this.campaign.customerId, params)
        .then(({data}) => {
          this.salesByProductData = data
        })
    },

    loadSalesByCallCenterData() {
      // esta gráfica no se filtra por callCenter
      const params = {...this.filters, callCenterId: undefined}

      API.analytics
        .salesByCallCenter(this.campaign.customerId, params)
        .then(({data}) => {
          this.salesByCallCenterData = data
        })
    },

    loadDayCallCentersData() {
      if (!this.studyItemProduct) {
        API.analytics
          .dayCallCentersCount(this.campaign.customerId, this.filters)
          .then(({data}) => {
            this.dayCallCentersData = data
          })
      }
    },

    loadSalesByAgentData() {
      // esta gráfica no se filtra por agente
      const params = {...this.filters, agentId: undefined}

      API.analytics
        .salesByAgent(this.campaign.customerId, params)
        .then(({data}) => {
          this.salesByAgentData = data
        })
    },

    loadSalesByAgentGroupData() {
      const filters = {...this.filters, agentGroupId: undefined}
      // esta gráfica no se filtra por grupo de agente
      const params = filters
      API.analytics
        .salesByAgentGroup(this.campaign.customerId, params)
        .then(({data}) => {
          this.salesByAgentGroupData = data
        })
    },

    cpl: AnalyticsUtils.cpl,
    cpa: AnalyticsUtils.cpa,
    cr: AnalyticsUtils.cr,

    t(key, params = {}) {
      return this.$i18n.t(`analytics.charts.${key}`, params)
    },

    getTargetTitle(graphic, specificStudyItem = false) {
      return this.t(
        `${graphic}${
          !this.isGlobalStudyItem && specificStudyItem ? '.targetFilter' : ''
        }.title`,
        {
          target: this.filtersInfo.subtargetName || this.filtersInfo.targetName
        }
      )
    }
  }
})
