/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
export default {
    getSrc: name => {
        let logo
        try {
          try {
            logo = require(`assets/images/logos/${name}.svg`)
          } catch (error) {
            logo = require(`assets/images/logos/${name}.png`)
          }
        } catch (error) {
          logo = require('assets/images/logos/general_integration.svg')
        }
        return logo
      }
}