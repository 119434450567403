import FullTable from 'js/components/full-table/index.js'
import {cloneDeep} from 'lodash'
import template from './_form.pug'
import {months} from '../../../services/months'

const Fields = [
  'id',
  'demandId',
  'demandOverTimes'
]

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    initData: {
      type: Object,
      default: null
    },
    demandData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {},
      auxTable: [],
      months: months(),
      currencyOptions: []
    }
  },
  computed: {
    modeForm() {
      return this.data.id ? 'edit' : 'new'
    },

    saveButtonText() {
      return this.t(`saveButton.${this.modeForm}`)
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadCurrencys()
  },
  methods: {
    initializeData() {
      this.auxTable = []
      this.data = {}
      const data = {}
      const aux = []

      Fields.forEach(key => {
        if (key === 'demandOverTimes') {
          data[key] = this.initData ? this.initData[key] : []
        } else {
          data[key] = this.initData ? this.initData[key] : ''
        }
      })
      if (this.initData) {
        this.initData.demandOverTimes.forEach(item => {
          aux.push({
            id: item.id,
            demandOverTimeId: item.leadDemandDetailId,
            month: this.getMonthText(item.availableMonthOffer),
            cplOffer: item.cplOffer,
            leadOffer: item.leadAmountOffer,
            cpl: item.cplDemand,
            leadAmount: item.leadAmountDemand,
            errorCpl: '',
            errorLead: ''
          })
        })
        data.demandId = this.initData.leadDemandId
      } else {
        this.demandData.demandOverTimes.forEach(item => {
          aux.push({
            ...item,
            id: null,
            demandOverTimeId: item.id,
            month: this.getMonthText(item.availableMonthOffer),
            cplOffer: '',
            leadOffer: '',
            errorCpl: '',
            errorLead: ''
          })
        })
        data.demandId = this.demandData.id
      }
      this.auxTable = aux
      this.data = data
    },
    getMonthText(month) {
      return this.months.find(({value}) => value === month).label
    },

    loadCurrencys() {
      API.leadTrade.currencys({page: {number: 1, size: 1000}}).then(({data}) => {
        this.currencyOptions = data.map(currency => ({
          label: currency.name,
          value: currency.id
        }))
      })
    },

    isEmpty(value) {
      return value === '' || value === null || value === undefined
    },

    isValid() {
      let isValid = true
      const auxTableValidated = this.auxTable.map(item => {
        if (this.isEmpty(item.cplOffer) || this.isEmpty(item.leadOffer)) {
          isValid = false
        }
        return {
          ...item,
          errorCpl: this.isEmpty(item.cplOffer) ? 'no-cpl' : '',
          errorLead: this.isEmpty(item.leadOffer) ? 'no-lead' : ''
        }
      })
      this.auxTable = cloneDeep(auxTableValidated)
      return isValid
    },

    serializeDemandOverTimes() {
      this.data.demandOverTimes = []
      this.data.demandOverTimes = this.auxTable.map(item => ({
        id: item.id,
        demandOverTimeId: item.demandOverTimeId,
        cplOffer: item.cplOffer,
        leadAmountOffer: item.leadOffer
      }))
    },

    t(key, options = {}) {
      return this.$t(`leadtrade.offer.form.${key}`, options)
    },

    submit() {
      if (!this.isValid()) return
      this.serializeDemandOverTimes()
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$router.push({name: 'offer-index'})
    }
  }
})
