import LayoutBus from 'js/layouts/sidebar-menu/layout-bus.js'
import {openModalAlert, openModalConfirmRemove} from 'js/services/modals'
import {max, cloneDeep, pickBy} from 'lodash'
import httpRequestConfig from '../../nodes/http-request'
import splitConfig from '../../nodes/split'
import ctiConfig from '../../nodes/cti'
import conditionConfig from '../../nodes/condition-inbound'
import updateConfig from '../../nodes/update'
import callFowardingConfig from '../../nodes/call-fowarding'
import startConfig from '../../nodes/start'
import voiceMenuConfig from '../../nodes/voice-menu'
import functionNodeConfig from '../../nodes/function-node'
import webhookConfig from '../../nodes/webhook'
import template from './_workflow-editor.pug'

const StepInternalKeys = ['classes', 'x', 'y', 'dropzone', 'errors', 'tags']

// Componente que gestiona el editor de workflow de un Automation
// Recibe el conjunto de nodos (steps) a representar, si no, inicia uno nuevo con un nodo start
//
// Utiliza una tabla para dibujar el diagrama (asegura distancia entre nodos y alineación)
// Esta tabla se compone de varios tipos de casillas: vacias, de nodos, de caminos entre nodos
//
// Al pulsar cada nodo aparece un tooltip con su información ampliada
// Desde el tooltip se puede acceder a su configuración (un modal con un formulario)
// También se puede eliminar el nodo si es el último de su rama (no tiene hijos)
//
// Si un nodo tiene errores se debe poner su borde en rojo
//
// Un nodo puede tener ninguno (end), uno o dos nodos hijos
// Al añadir un nuevo nodo, se dibujan las casillas de sus hijos con un borde naranja (dropzone)
// Los nodos se añaden arrastrándolos desde el panel de la izquierda
//
// Cuando no está en modo edición no se permite arrarstrar ni configurar ni eliminar nodos
export default Vue.extend({
  template: template(),
  components: {
    conditionConfig,
    httpRequestConfig,
    splitConfig,
    ctiConfig,
    updateConfig,
    voiceMenuConfig,
    callFowardingConfig,
    functionNodeConfig,
    startConfig,
    webhookConfig
  },
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    campaign: {
      type: Object,
      required: true
    },
    errors: {
      type: Object
    },
    editing: {
      type: Boolean
    },
    status: {
      type: String
    }
  },
  data() {
    return {
      nodeTypes: [
        'condition',
        'callFowarding',
        'voiceMenu',
        // 'split',
        // 'httpRequest',
        // 'update',
        // 'cti',
        'functionNode',
        'webhook',
        'HangOut',
        'end'
      ],
      nodes: [], // nodos del workflow (copia interna de steps con variables extras)
      graphItems: [], // elementos de la tabla que se pintan (nodos + caminos)
      maxX: 0, // nº de columnas del workflow
      maxY: 0, // nº de filas del workflow
      count: 0, // iterador para evitar bucles infinitos
      reducedView: false, // oculta las descripciones de los nodos
      fullscreen: false, // cierra el sidebar y el acordeón con los campos
      openedNode: null, // nodo del que se está mostrando el tooltip
      configNode: null, // nodo que se está configurando (se abre modal)
      lastId: null, // último id asignado a un nodo (se usa para añadir nuevos)
      ready: false
    }
  },
  watch: {
    errors(newVal) {
      // si llegan errores del backend, se añaden a cada nodo y se recalculan las clases
      if (newVal && newVal.steps) {
        newVal.steps.forEach((stepError, index) => {
          this.nodes[index].errors = stepError
        })

        this.calculateNodeClasses()
      }
    },

    editing(newVal) {
      // siempre que se deja de editar se vuelve a inicializar con los valores de los props
      // en el caso de cancelar la editición, el prop steps no cambia así que usamos esto
      // elimina los posibles errores existentes y deshace los cambios
      if (!newVal) {
        this.initializeNodes()
        this.recalculeGraph()
      }
    }
  },
  async created() {
    await this.loadCampaignTags()
    this.initializeNodes()
    this.recalculeGraph()
  },
  methods: {
    // como son usadas por la mayoría de nodos, las cargamos una sola vez aqui
    loadCampaignTags() {
      const params = {page: {number: 1, size: PAGINATION_ALL}}

      return API.campaigns.tags.index(this.campaign.id, params, {skipLoading: true}).then(
        response => { this.campaignTags = response.data }
      )
    },

    initializeNodes() {
      if (!this.steps?.length) {
        this.nodes.push({id: '0', stepType: 'start'})
      } else {
        this.nodes = this.steps.map(item => {
          const node = cloneDeep(item)

          // Inicializamos las variables internas
          // StepInternalKeys.forEach(key => { node[key] = null })

          return node
        })
      }
    },

    emitInfo() {
      const unfinishedNodes = this.nodes.filter(item => item.dropzone).length > 0
      const nodesWithError = this.nodes.filter(item => item.errors).length > 0
      const startNodeError = this.nodes[0] && this.nodes[0].config && this.nodes[0].config.trackingNumbers.length ? false : true

      this.$emit('info', {
        unfinishedNodes,
        nodesWithError,
        startNodeError,
        steps: this.nodes.map(step => (
          pickBy(step, key => StepInternalKeys.indexOf(key) === -1)
        ))
      })
    },

    // recalcula y dibuja de nuevo el diagrama
    // se llama cada vez que hay una modificación en los nodos
    recalculeGraph() {
      this.ready = false
      this.count = 0
      this.maxX = 0
      this.maxY = 0
      this.lastId = max(this.nodes.map(item => parseInt(item.id))) + 1
      this.graphItems = []

      this.processNode(this.nodes[0], 0, 0)
      this.calculateNodeClasses()
      this.drawBranchPathss()

      this.ready = true
      this.emitInfo()
    },

    // Función que se encarga de crear la matriz con los nodos que dibuja la tabla (diagrama)
    // - node: nodo a representar
    // - xLevel: columna en la que se tiene que colocar el nodo
    // - yLevel: fila en la que se tiene que colocar el nodo
    // Devuelve la fila (Y) en la que se ha situado el nodo
    processNode(node, xLevel, yLevel) {
      this.count += 1

      // TODO: paramos un posible bucle infinito durante las pruebas
      if (this.count > 1000) {
        // eslint-disable-next-line
        throw `node: ${node.id}, x: ${xLevel}, y: ${yLevel}, count: ${this.count}`
      }

      if (MOCKS) {
        // eslint-disable-next-line
        console.log(`node: ${node?.id}, x: ${xLevel}, y: ${yLevel}, count: ${this.count}`)
      }

      if (!this.graphItems[xLevel]) this.graphItems[xLevel] = []
      if (this.maxX < xLevel) this.maxX = xLevel

      // devuelve los nodos hijos que admite o tiene este nodo
      const childs = this.getNodeChilds(node)

      // si no tiene hijos, añade el nodo en el [x,y] pasado y retorna el y
      if (!childs.length) {
        this.addNodeToGraph(node, xLevel, yLevel)
        this.maxY = yLevel

        return yLevel

      }

      // si tiene solo un hijo, procesa primero el hijo y después añade el nodo en el Y del hijo
      // esto es necesario por si el hijo tiene sub-hijos con doble camino, su fila cambia
      if (childs.length === 1) {
        let child = childs[0]

        const yChild = this.processNode(child, xLevel + 1, yLevel)
        this.addNodeToGraph(node, xLevel, yChild)

        return yChild
      }

      // si tiene mas de dos hijos (voiceMenu)
      if (node.stepType === 'voiceMenu') {

        let topChilds = 0
        let downChilds = 0

        if(childs.length % 2 === 0) {
          topChilds = childs.length / 2
          downChilds = childs.length / 2
        }else{
          topChilds = Math.ceil(childs.length / 2)-1
          downChilds = Math.floor(childs.length / 2)
        }


        for (let i = topChilds; i < childs.length; i++) {
          // procesa primera la rama de arriba
          this.processNode(childs[i], xLevel + 1, this.maxY + 1)
        }


        // después añade el nodo en un Y superior al máximo de la rama superior
        this.maxY += 1
        const currentY = this.maxY
        this.addNodeToGraph(node, xLevel, currentY)

        // this.processNode(childs[1], xLevel + 1, this.maxY + 1)
        // // y por último procesa la rama de abajo partiendo un Y superior al nodo actual
        // this.processNode(childs[0], xLevel + 1, this.maxY + 1)

        for (let i = downChilds-1; i >= 0; i--) {
          this.processNode(childs[i], xLevel + 1, this.maxY + 1)
        }

        return currentY
      }


      // si tiene 2 hijos (split o if)
      if (childs.length === 2) {
        const childA = childs[0]
        const childB = childs[1]

        // procesa primera la rama de arriba
        this.processNode(childA, xLevel + 1, yLevel)

        // después añade el nodo en un Y superior al máximo de la rama superior
        this.maxY += 1
        const currentY = this.maxY
        this.addNodeToGraph(node, xLevel, currentY)

        // y por último procesa la rama de abajo partiendo un Y superior al nodo actual
        this.processNode(childB, xLevel + 1, this.maxY + 1)

        return currentY
      }

    },

    // devuelve los nodos hijos del nodo pasado, si no existen los crea como dropzones
    getNodeChilds(node) {
      // nodo sin hijos
      if (node.stepType === 'end' || node.dropzone) return []

      // nodo con varios hijos
      if (this.isBranchNode(node)) {
        if (!node.branchs) node.branchs = []; // este ; es necesario

        // condicional para saber si el tipo de nodo es voice menu
        // y poder crear los hijos de manera dinamica
        if (node.stepType == 'voiceMenu') {

          let arrayBranches = []

          // for para iterar sobre el numero de ramas dinamicas que tenga el nodo y crear un array
          for (let i = 0; i < node.dinamicBranches; i++) {
            arrayBranches.push(i);
          };

          if(arrayBranches.length > 0) {
            arrayBranches.forEach(index => {
              if (!node.branchs[index]) {
                this.lastId += 1
                const child = {dropzone: true, id: this.lastId}
                node.branchs[index] = {
                  nextStepId: child.id,
                  value: node.config ? node.config.itemMenus[index].keyPress : 'voiceMenu'
                }
                node.config.itemMenus[(arrayBranches.length-1)-index].nextStepId = child.id 
                this.nodes.push(child)
              }else{
                node.dinamicBranches = 0
              }
            })
          }

          const childIds = node.branchs.map(item => item.nextStepId).sort((a, b) => a - b)
          //childIds = childIds.sort((a, b) => a - b);
        // Importante devolver los hijos en el orden en el que están los ids
      
        console.log(childIds)
        //console.log(childIds.map(id => this.nodes.find(child => child.id === id)))

        return childIds.map(id => this.nodes.find(child => child.id === id))

        }
        else{
          // si el nodo no es voice menu entonces se crean de manera estaticas los nodos
          [0, 1].forEach(index => {
            if (!node.branchs[index]) {
              this.lastId += 1
              const child = {dropzone: true, id: this.lastId}
              node.branchs[index] = {
                nextStepId: child.id,
                value: node.stepType === 'condition' ? index === 0 : 50
              }
              this.nodes.push(child)
            }
          })

        }

        const childIds = node.branchs.map(item => item.nextStepId)

        // Importante devolver los hijos en el orden en el que están los ids
        return childIds.map(id => this.nodes.find(child => child.id === id))
      }

      // nodo con un solo hijo
      if (!node.nextStepId) {
        this.lastId += 1
        let child = {}
        if (node.stepType === 'callFowarding') {
          child = {onlyHangOut:true,dropzone: true, id: this.lastId}

        }else{
          child = {dropzone: true, id: this.lastId}

        }
        node.nextStepId = child.id
        this.nodes.push(child)
      }

      return [this.nodes.find(item => item.id === node.nextStepId)]
    },

    addNodeToGraph(node, x, y) {
      node.x = x
      node.y = y
      this.graphItems[x][y] = node // importante que sea una referencia al nodo original
    },

    // calcula las clases a aplicar a cada nodo
    calculateNodeClasses() {
      this.nodes.forEach(node => {
        node.classes = []

        if (this.graphItems[node.x + 1] && this.graphItems[node.x + 1][node.y]) {
          node.classes.push('node--path-next')
        }

        if (node === this.openNode) node.classes.push('node--opened')
        if (node.errors) node.classes.push('node--error')
      })
    },

    // añade a la tabla los nodos que dibujan los caminos de los nodos con 2 hijos
    drawBranchPathss() {
      // recorremos todos los nodos que se bifurcan
      const branchNodes = this.nodes.filter(item => (
        ['condition', 'split','voiceMenu'].indexOf(item.stepType) > -1
      ))

      branchNodes.forEach(parent => {
        if (parent.stepType == 'voiceMenu') {
          // buscamos sus hijos y los recorremos (serán dinamicos)
          this.getNodeChilds(parent).forEach((child,i) => {
            if (!child) return;
            
            // var for set length of inverse childs
            let length_childs = this.getNodeChilds(parent).length - 1
  
            if (child.y > parent.y) { // si el hijo está por debajo en el grafo
              for (let y = parent.y + 1; y <= child.y; y++) {
                const isCorner = y === child.y
  
                // añadimos un nodo en el camino con la clase correspondiente
                this.graphItems[parent.x][y] = {
                  type: isCorner ? 'if-false-corner' : 'if-false-path',
                  class: isCorner ? 'path--bottom-corner' : 'path--branch-bottom',
                  text: isCorner ? this.getBranchCornerText(parent,length_childs - i) : null
                }
  
              }
            } else { // si el hijo está por encima en el grafo
              for (let y = parent.y - 1; y >= child.y; y--) {
                const isCorner = y === child.y
  
                // añadimos un nodo en el camino con la clase correspondiente
                this.graphItems[parent.x][y] = {
                  type: isCorner ? 'if-true-corner' : 'if-true-path',
                  class: isCorner ? 'path--top-corner' : 'path--branch-top',
                  text: isCorner ? this.getBranchCornerText(parent,length_childs - i) : null
                }
  
              }
            }
  
          })

        }else{
          this.getNodeChilds(parent).forEach(child => {
            if (!child) return
  
            if (child.y > parent.y) { // si el hijo está por debajo en el grafo
              for (let y = parent.y + 1; y <= child.y; y++) {
                const isCorner = y === child.y
  
                // añadimos un nodo en el camino con la clase correspondiente
                this.graphItems[parent.x][y] = {
                  type: isCorner ? 'if-false-corner' : 'if-false-path',
                  class: isCorner ? 'path--bottom-corner' : 'path--branch-bottom',
                  text: isCorner ? this.getBranchCornerText(parent, 1) : null
                }
              }
            } else { // si el hijo está por encima en el grafo
              for (let y = parent.y - 1; y >= child.y; y--) {
                const isCorner = y === child.y
  
                // añadimos un nodo en el camino con la clase correspondiente
                this.graphItems[parent.x][y] = {
                  type: isCorner ? 'if-true-corner' : 'if-true-path',
                  class: isCorner ? 'path--top-corner' : 'path--branch-top',
                  text: isCorner ? this.getBranchCornerText(parent, 0) : null
                }
              }
            }
          })
        }
      })
    },

    graphItem(x, y) {
      return this.graphItems[x][y] || {}
    },

    getBranchCornerText(node, branchIndex) {
      if (!node.branchs) return '-'

      const branch = node.branchs[branchIndex]
      if (node.stepType === 'condition') return branch.value ? 'TRUE' : 'FALSE'
      if (node.stepType === 'split') return `${branch.value}%`
      if (node.stepType === 'voiceMenu') return `${branch.value}`

      return '-'
    },

    isBranchNode(node) {
      if (!node?.stepType) return false
      return ['condition', 'split','voiceMenu'].indexOf(node.stepType) > -1
    },

    canDeleteNode(node) {
      if (node.stepType === 'start') return false
      if (node.stepType === 'end') return true

      const childs = this.getNodeChilds(node)
      return childs.every(item => item?.dropzone)
    },

    openNode(_event, x, y) {
      // hay que ponerle una clase para subirle el z-index al nodo
      // ya que si no el bubble sale por detrás de otros nodos
      // (un elemento no puede tener un z-index mayor que el de su padre)
      this.openedNode = this.graphItems[x][y]
      this.calculateNodeClasses()
    },

    openNodeConfig(node) {
      this.calculateNodeTags() // aseguramos que están actualizadas
      this.configNode = node
      this.openedNode = null
    },

    closeNode() {
      this.calculateNodeClasses()
      this.openedNode = null
    },

    // permite calcular los tags de un nodo de forma recursiva (son los del padre)
    processNodeTags(node, parentTags = []) {
      const childs = this.getNodeChilds(node)

      childs.forEach(child => {
        // si es un nodo httpRequest sus hijos pueden usar sus variables
        if (node.stepType === 'httpRequest' && node.config?.responseMapping) {
          const workflowTags = Object.keys(node.config.responseMapping).map(item => (
            {type: 'workflow', key: item}
          ))

          this.processNodeTags(child, parentTags.concat(workflowTags))
        } else {
          this.processNodeTags(child, parentTags)
        }
      })

      node.tags = parentTags
    },

    // calcula los tags de todos los nodos del diagrama
    calculateNodeTags() {
      this.processNodeTags(this.nodes[0], this.campaignTags)
    },

    showNoEditingAlert() {
      openModalAlert(
        this.t('noEditingAlert.title'),
        this.t('noEditingAlert.body'),
        this.t('noEditingAlert.okButton')
      )
    },

    getNodeIcon(type) {
      switch (type) {
        case 'condition': return 'pixel-control'
        case 'functionNode': return 'pixel-control'
        case 'webhook': return 'zsync'
        case 'split': return 'zexpand-right'
        case 'httpRequest': return 'wglobe'
        case 'update': return 'zsync'
        case 'voiceMenu': return 'zphone'
        case 'callFowarding': return 'zsync'
        case 'cti': return 'zphone'
        case 'start': return 'arrow-right'
        case 'HangOut': return 'phone'
        case 'end': return 'zstop'
        default: return ''
      }
    },

    drag(event, type) {
      event.dataTransfer.setData('type', type)
    },

    drop(event, graphNode) {
      const node = this.nodes.find(item => item.id === graphNode.id)
      node.stepType = event.dataTransfer.getData('type')


      if(node.onlyHangOut){
        if (node.stepType !== 'HangOut' && node.stepType !== 'end') {
          openModalAlert(
            this.t('callFowardingAlert.title'),
            this.t('callFowardingAlert.body'),
            this.t('callFowardingAlert.okButton')
          )
        }else{
          delete node.dropzone
          delete node.config // hacemos que el componente de su configuración lo inicialize
          delete node.nextStepId // eliminamos el nextStepId para que al eliminar se pueda agregar de nuevo los nodos con un solo hijo 

          // lo marcamos con errores hasta que rellene la información (menos el end)
          node.errors = node.stepType !== 'end' && node.stepType !== 'HangOut'

          if (node.stepType !== 'end' && node.stepType !== 'HangOut') {
            this.recalculeGraph()
            this.$nextTick(() => { this.openNodeConfig(node) })
          }else{
            this.recalculeGraph()
          }
        }
      }else{
        delete node.dropzone
        delete node.config // hacemos que el componente de su configuración lo inicialize
        delete node.nextStepId // eliminamos el nextStepId para que al eliminar se pueda agregar de nuevo los nodos con un solo hijo

        // lo marcamos con errores hasta que rellene la información (menos el end)
        node.errors = node.stepType !== 'end' && node.stepType !== 'HangOut'

        if (node.stepType !== 'end' && node.stepType !== 'HangOut') {
          this.recalculeGraph()
          this.$nextTick(() => { this.openNodeConfig(node) })
        }else{
          this.recalculeGraph()
        }
      }


    },

    addNewNode(type, parent) {
      this.nodes.push({id: this.nodes.length + 1, type, parent})
    },

    removeNode(graphItem) {
      openModalConfirmRemove(
        this.t('removeNodeConfirmationModal.title'),
        this.t('removeNodeConfirmationModal.body'),
        this.t('removeNodeConfirmationModal.okButton')
      ).then(() => {
        // primero eliminamos los hijos si los tiene
        const childs = this.getNodeChilds(graphItem)
        childs.forEach(child => {
          const nodeIndex = this.nodes.findIndex(item => item.id === child.id)
          this.nodes.splice(nodeIndex, 1)
        })

        // despues, lo convertimos en un nodo dropzone
        Object.assign(graphItem, {
          dropzone: true,
          stepType: null,
          branchs: null,
          name: null,
          config: null
        })

        this.recalculeGraph()
      }).catch(() => {})
    },

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen

      // cerramos o abrimos el sidebar del layour
      LayoutBus.$emit(this.fullscreen ? 'close-sidebar' : 'open-sidebar')

      // emitimos el evento para que el padre cierre / abra el acordeón del formulario
      this.$emit('fullscreen', this.fullscreen)
    },

    updateNode(info) {
      Object.assign(this.configNode, info) // para que funcione el binding sin recalcular
      this.recalculeGraph()
      this.configNode = null
      this.calculateNodeClasses()

      // console.log("nodes: ",this.nodes)
      // console.log("last id of nodes: ",this.lastId)

      this.emitInfo()
    },

    nodeHasConfig(node) {
      return ['HangOut','end'].indexOf(node.stepType) === -1
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.${key}`, options)
    }
  }
})
