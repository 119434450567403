/* eslint-disable max-len */
import {isEqual} from 'lodash'
import template from './duplicate-form.pug'

export default Vue.extend({
  template: template(),
  props: {
    showTitle: {
      type: Boolean,
      default: () => (false)
    },
    value: {
      type: [Array, Object],
      default: () => ({})
    },
    keysSelected: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {},
      ready: false,
      keysSelectedForm: {}
    }
  },
  computed: {
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      // immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    },

    leadLandingPage: {
      handler(_newVal) {
        if (!_newVal) this.dataTest[0].fieldsDuplicated = ['phone']
      }
    },

    leadFacebookForm: {
      handler(_newVal) {
        if (!_newVal) this.dataTest[1].fieldsDuplicated = ['phone']
      }
    },

    leadprovider: {
      handler(_newVal) {
        if (!_newVal) this.dataTest[2].fieldsDuplicated = ['phone']
      }
    },

    leadBaseCold: {
      handler(_newVal) {
        if (!_newVal) this.dataTest[3].fieldsDuplicated = ['phone']
      }
    },

    leadInbound: {
      handler(_newVal) {
        if (!_newVal) this.dataTest[4].fieldsDuplicated = ['phone']
      }
    },

  },
  methods: {
    initializeData() {
      this.data = this.value
      this.keysSelectedForm = this.keysSelected
      this.keysSelectedForm.leadBaseColdKey = this.data[0].keys.filter(key => key.selected).map(key => key.key)
      this.keysSelectedForm.leadInboundKey = this.data[1].keys.filter(key => key.selected).map(key => key.key)
      this.keysSelectedForm.leadLandingPageKey = this.data[2].keys.filter(key => key.selected).map(key => key.key)
      this.keysSelectedForm.leadFacebookFormKey = this.data[3].keys.filter(key => key.selected).map(key => key.key)
      this.keysSelectedForm.leadproviderKey = this.data[4].keys.filter(key => key.selected).map(key => key.key)

      this.ready = true
    },

    optionsKeys(keys) {
      return keys.map(key => ({
        label: key.key,
        value: key.key
      }))
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.duplicated.form.${key}`, options)
    }
  }
})
