/* eslint-disable max-len */
const table = data => {
    const total = data.reduce((acc, curr) => {
        acc += curr.value
        return acc
    }, 0)
    const getAverage = value => ((value * 100) / total)
    const list = data.sort((a, b) => a.value - b.value).reverse().reduce((acc, curr) => {
        acc += `
        <tr>
            <td>${curr.name}</td>
            <td class="value">
                <span style="z-index: 1;">${curr.value}</span>
                <span style="z-index: 1;" class="value-average">(${getAverage(curr.value).toFixed(1)}%)</span>
                <div class="meter">
                    <span style="width: ${getAverage(curr.value) > 3 ? getAverage(curr.value) : 0}%; text-align: center;"></span>
                </div>
            </td>
        </tr>\n`
        return acc
    }, '')
    return `
        <table class="table-chart">
            <thead>
                <tr>
                    <th>${Vue.i18n.t('general.description')}</th>        
                    <th class="value-title">${Vue.i18n.t('general.value')}</th>             
                </tr>
            </thead>
            <tbody>
                ${list}
            </tbody>
        </table>
    `
}
export default table
