import flatMap from 'lodash/flatMap'
import VueRouter from 'vue-router'
import checkAuthBeforeEnter from './check-auth'

// Le indicamos a Vue que use VueRouter
Vue.use(VueRouter)

// Importación de modulos
// 1. Recogemos todos los archivos con condiciones
const files = require.context('./modules', false, /^\.\/.*\.js$/)

// 2. Recorremos y concatenamos en uno único
const routes = flatMap(files.keys(), key => files(key).default)

const router = new VueRouter({routes, mode: 'history'})

router.beforeEach(checkAuthBeforeEnter)

// reseteamos el título de la página y sus breadcrumbs
router.beforeEach((_to, _from, next) => {
  Vue.store.commit('setPageTitle', null)
  Vue.store.commit('setBreadcrumbs', null)

  next()
})

export default router
