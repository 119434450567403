import template from './_form.pug'

const Fields = [
  'firstName', 'lastName', 'email', 'targetId', 'targetType', 'roleId', 'avatarUrl', 'avatar',
  'allowManageWorkflows', 'allowCreateCustomers', 'allowCreateCampaigns', 'providerLeadid'
]

export default Vue.extend({
  template: template(),
  props: {
    roleKey: {type: String},
    initData: {
      type: Object
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    customerOptions: {
      type: Array,
      default: () => ([])
    },
    callCenterOptions: {
      type: Array,
      default: () => ([])
    },
    siteOptions: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      data: {
        firstName: '',
        lastName: '',
        email: '',
        targetId: '',
        targetType: '',
        roleId: null,
        providerLeadid: '',
        allowManageWorkflows: false,
        allowCreateCampaigns: false,
        allowCreateCustomers: false
      },
      providerLeadOptions: []
    }
  },
  computed: {
    targetOptions() {
      if (this.data.targetType === 'customer') return this.customerOptions
      if (this.data.targetType === 'call-center') return this.callCenterOptions
      if (this.data.targetType === 'site') return this.siteOptions

      return []
    },

    showInitials() {
      return (!this.data.avatarUrl && !this.data.avatar)
    },

    modeForm() {
      return this.initData && this.initData.id ? 'edit' : 'new'
    },

    roleId() {
      return this.$findRoleByKey(this.roleKey).id
    },

    avatarInitials() {
      if (this.data.firstName && this.data.lastName) {
        return this.data.firstName[0] + this.data.lastName[0]
      } else if (this.data.firstName) {
        return this.data.firstName[0]
      } else if (this.data.lastName) {
        return this.data.lastName[0]
      }

      return this.$t(`users.roles.${this.roleKey}.initials`)
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        if (this.initData) this.initializeData()
      }
    },

    'data.targetType': {
      handler(newValue) {
        if (!newValue) {
          this.data.allowManageWorkflows = false
          this.data.allowCreateCampaigns = false
          this.data.allowCreateCustomers = false
        }

        this.data.targetId = ''
      }
    }
  },
  created() {
    this.data.roleId = this.roleId
    if (this.roleKey === 'agency-supplier') {
      this.loadProviderLeads()
    }
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        if (this.initData[key] !== undefined) data[key] = this.initData[key]
      })

      Object.assign(this.data, data)
    },

    loadProviderLeads() {
      this.providerLeadOptions = []
      API.providerLeads.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.providerLeadOptions = data.map(item => ({
          value: item.id,
          label: item.name
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`users.${this.roleKey}s.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    }
  }
})
