import template from './filters.pug'

const TargetTypes = [
  'general', 'product', 'agent', 'callCenter', 'utmMedium', 'agentGroup', 'provider',
  'utmSource', 'utmCampaign', 'ctaType', 'campaign', 'landing'
]

// Gestiona los filtros del apartado analítica computestos por
// - fecha de inicio
// - fecha de fin
// - target
// - subtarget (solo si el target es campaña)
export default Vue.extend({
  template: template(),
  props: {
    exportLeads: {
      type: Boolean,
      default: false
    },
    exportSales: {
      type: Boolean,
      default: false
    },
    campaignId: {
      // define el scope de los target cargados a la campaña
      type: String
    },
    customerId: {
      // define el scope de los target cargados al cliente
      type: String
    },
    minDate: {
      type: String
    },
    maxDate: {
      type: String
    },
    targetTypes: {
      // target permitidos de los disponibles (declarados arriba)
      type: Array,
      required: true,
      validator: value => value.every(val => TargetTypes.includes(val))
    },
    addAllOption: {
      // Agregar el target "Todos los items" a lo declarado en esta prop
      type: Array,
      default: () => []
    },
    defaultTargetType: {
      type: String
    },
    investment: {
      type: Number
    }
  },
  data() {
    return {
      minDateMoment: moment(this.minDate, 'DD/MM/YYYY'),
      maxDateMoment: moment(this.maxDate, 'DD/MM/YYYY'),

      // Al selecionar el check se usan las fechas de la campaña como filtro
      useCampaignDates: false,

      targetItems: [], // guarda el listado de recursos del Type seleccionado
      subtargetItems: [], // guarda el listado de recursos del SubType seleccionado

      fromDate: moment().subtract(7, 'days').toDate(),
      toDate: moment().toDate(),
      targetType: this.defaultTargetType,
      targetId: null,
      subtargetType: null,
      subtargetId: null,
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      }
    }
  },
  computed: {
    today() {
      return moment()
    },

    todayFormated() {
      return this.today.format('DD/MM/YYYY')
    },

    // cuando el target es Campaña, se puede seleccionar un subitem
    isTargetCampaign() {
      return this.targetType === 'campaign' && this.targetId
    },

    targetTypeSelectOptions() {
      return this.targetTypes.map(item => (
        {label: this.t(`targetTypes.${item}`), value: item}
      )).sort((a, b) => (a.label.localeCompare(b.label)))
    },

    targetItemSelectOptions() {
      const items = this.targetItems.map(item => ({
        label: item.name,
        value: item.id || item.key
      })) // los canales tienen key en lugar de id
      if (this.addAllOption.includes(this.targetType)) {
        items.unshift({
          label: this.t('targetAllOption'),
          value: 'selected-all-values-options'
        })
      }
      return items
    },

    // los subtipos son los mimos que los tipos quitando campaña
    subtargetTypeSelectOptions() {
      if (!this.isTargetCampaign) return []

      return this.targetTypeSelectOptions.filter(
        item => item.value !== 'campaign' && item.value !== 'provider'
      )
    },

    subtargetItemSelectOptions() {
      const items = this.subtargetItems.map(item => ({
        label: item.name,
        value: item.id || item.key
      }))
      if (this.addAllOption.includes(this.subtargetType)) {
        items.unshift({
          label: this.t('targetAllOption'),
          value: 'selected-all-values-options'
        })
      }
      return items
    },

    fromDateConfig() {
      return {
        ...this.dateConfig,
        minDate: this.minDate,
        maxDate: this.toDate ? this.toDate : this.maxDate
      }
    },

    toDateConfig() {
      return {
        ...this.dateConfig,
        minDate: this.fromDate ? this.fromDate : this.minDate,
        maxDate: moment.min([this.maxDateMoment, this.today]).format('DD/MM/YYYY')
      }
    }
  },
  watch: {
    targetId() {
      this.subtargetId = null
      this.subtargetType = 'general'
    },

    targetType(newVal) {
      if (newVal === 'general') this.targetId = null
    }
  },
  mounted() {
    this.applyFilters()
  },
  methods: {
    loadTargetItems() {
      this.subtargetType =
        this.targetType === 'campaign' ? this.defaultTargetType : null

      if (this.targetType === 'general') return

      let filter = this.campaignId ?
        {campaignId: this.campaignId} :
        {customerId: this.customerId}

      if (this.targetType === 'utmSource' ||
        this.targetType === 'utmCampaign' ||
        this.targetType === 'landing' ||
        this.targetType === 'provider' ||
        this.targetType === 'utmMedium') {
        filter = {
          ...filter,
          filter: {
            from: moment(this.fromDate).format(FORMAT_DATE),
            to: moment(this.toDate).format(FORMAT_DATE)
          }
        }
      }

      this.getTargetTypeService(this.targetType)(
        {...filter, order: {name: 'asc'}}, {skipLoading: true}
      ).then(
        response => {
          this.targetItems = []
          this.targetId = null
          // Guardamos los items ordenados alfabeticamente
          this.targetItems = response.data.sort((a, b) => (a.name.localeCompare(b.name)))
          this.targetId = this.targetItems[0].id || this.targetItems[0].key
        }
      )
    },

    loadSubtargetItems() {
      if (this.subtargetType === 'general') return

      this.getTargetTypeService(this.subtargetType)(
        {campaignId: this.targetId,filter:{
          from: moment(this.fromDate).format(FORMAT_DATE),
          to: moment(this.toDate).format(FORMAT_DATE)
        }, order: {name: 'asc'}}, {skipLoading: true}
      ).then(response => {
        // Guardamos los items ordenados alfabeticamente
        this.subtargetItems = response.data.sort((a, b) => (a.name.localeCompare(b.name)))
        this.subtargetId = this.subtargetItems[0].id || this.subtargetItems[0].key
      })
    },

    getTargetTypeService(targetType) {
      switch (targetType) {
        case 'provider': return API.customers.provider
        case 'product': return API.products.index
        case 'agent': return API.campaigns.agents.index
        case 'agentGroup': return API.campaigns.agentGroup.index
        case 'callCenter': return API.callCenters.index
        case 'campaign': return API.campaigns.index
        case 'utmMedium': return API.utm.mediums
        case 'utmSource': return API.utm.sources
        case 'ctaType': return API.campaigns.ctas.index
        case 'landing': return API.campaigns.landings.index
        case 'utmCampaign': return API.utm.campaigns
        default: return null
      }
    },

    changeUseCampaignDates(value) {
      if (value) {
        this.fromDate = this.minDate
        this.toDate = this.maxDateMoment.isBefore(this.today) ?
          this.maxDate :
          this.todayFormated
      }
    },
    configFilters() {
      const filters = {
        from: moment(this.fromDate).format(FORMAT_DATE),
        to: moment(this.toDate).format(FORMAT_DATE),
        campaignId: undefined,
        productId: undefined,
        agentId: undefined,
        provider: undefined,
        agentGroupId: undefined,
        callCenterId: undefined,
        utmMedium: undefined,
        ctaType: undefined,
        landing: undefined,
        utmSource: undefined,
        utmCampaign: undefined
      }

      // información que se le pasa al padre para pintar en la interfaz
      const info = {
        investment: parseFloat(this.investment),
        targetType: this.targetType,
        targetName: this.targetItems?.find(
          item => (item.id || item.key) === this.targetId
        )?.name
      }

      // TODO optimizar lineas
      if (this.targetType === 'provider') filters.provider = this.targetId
      if (this.targetType === 'agent') filters.agentId = this.targetId
      if (this.targetType === 'agentGroup') filters.agentGroupId = this.targetId
      if (this.targetType === 'product') filters.productId = this.targetId
      if (this.targetType === 'callCenter') filters.callCenterId = this.targetId
      if (this.targetType === 'utmMedium') filters.utmMedium = this.targetId
      if (this.targetType === 'ctaType') filters.ctaType = this.targetId
      if (this.targetType === 'landing') filters.landing = this.targetId
      if (this.targetType === 'utmSource') filters.utmSource = this.targetId
      if (this.targetType === 'utmCampaign') filters.utmCampaign = this.targetId

      if (this.targetType === 'campaign') {
        filters.campaignId = this.targetId

        // TODO optimizar lineas
        if (this.targetType === 'provider') filters.provider = this.subtargetId
        if (this.subtargetType === 'agentGroup') filters.agentGroupId = this.subtargetId
        if (this.subtargetType === 'agent') filters.agentId = this.subtargetId
        if (this.subtargetType === 'product') filters.productId = this.subtargetId
        if (this.subtargetType === 'callCenter') filters.callCenterId = this.subtargetId
        if (this.subtargetType === 'utmMedium') filters.utmMedium = this.subtargetId
        if (this.subtargetType === 'ctaType') filters.ctaType = this.subtargetId
        if (this.subtargetType === 'landing') filters.landing = this.subtargetId
        if (this.subtargetType === 'utmSource') filters.utmSource = this.subtargetId
        if (this.subtargetType === 'utmCampaign') filters.utmCampaign = this.subtargetId

        if (this.subtargetId && this.subtargetType !== 'general') {
          info.subtargetType = this.subtargetType
          info.subtargetName = this.subtargetItems?.find(
            item => (item.id || item.key) === this.subtargetId
          )?.name
        }
      }
      return {filters, info}
    },
    applyFilters() {
      const {filters, info} = this.configFilters()
      this.$emit('apply-filters', filters, info)
    },
    exportLeadsCsv() {
      const {filters, info} = this.configFilters()
      this.$emit('export-leads', filters, info)
    },
    exportSalesCsv() {
      const {filters, info} = this.configFilters()
      this.$emit('export-sales', filters, info)
    },

    t(key, options = {}) {
      return this.$t(`analytics.filters.${key}`, options)
    }
  }
})
