import ChartsBuilder from './_charts-builder.js'
import ChartsList from './_charts-list'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    ChartsBuilder,
    ChartsList
  },
  created() {
    this.$setPageTitle('LAIA Analytics')
    this.$setBreadcrumbs([
      {label: 'Generador de gráficas', route: {}}
    ])
  }
})
