import LeadsAccountsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsAccountsTable
  },
  data() {
    return {
      leads: [],
      providerLeads: [],
      campaigns: [],
      meta: {}
    }
  },
  created() {
    this.loadCampaigns()
    this.loadProviders()
    this.$setPageTitle(this.$t('cutReport.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.cutReport.index'), route: {}}
    ])
  },
  methods: {
    loadProviders() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.providerLeads.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.providerLeads = data
        this.providerLeads.unshift(allData)
      })
    },
    loadCampaigns() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.campaigns.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.campaigns = data
        this.campaigns.unshift(allData)
      })
    },
    loadLeadsDetails(queryOptions = {}) {
        API.cutReport.index(queryOptions).then(({data, meta}) => {
          this.leads = data
          this.meta = meta
        })
    }
  }
})
