import RouteParent from 'js/router/route-parent'

import ProviderLeadsIndex from 'js/pages/provider-leads/index.js'
import ProviderLeadsLogsIndex from 'js/pages/provider-leads/logs/index.js'
import ProviderLeadsNew from 'js/pages/provider-leads/new.js'
import ProviderLeadsEdit from 'js/pages/provider-leads/edit.js'

export default [
  {
    path: '/provider-leads',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'provider-leads-index',
        component: ProviderLeadsIndex,
        meta: {
          auth: true,
          permit: ['MANAGE_PROVIDERS_LEADS']
        }
      },
      {
        path: 'new',
        name: 'provider-leads-new',
        component: ProviderLeadsNew,
        meta: {
          auth: true,
          permit: ['MANAGE_PROVIDERS_LEADS']
        }
      },
      {
        path: ':id/edit',
        name: 'provider-leads-edit',
        component: ProviderLeadsEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_PROVIDERS_LEADS']
        }
      },
      {
        path: ':id/invalid-leads-logs',
        name: 'provider-invalid-leads-logs',
        component: ProviderLeadsLogsIndex,
        meta: {
          auth: true,
          permit: ['MANAGE_PROVIDERS_LEADS']
        }
      }
    ]
  }
]
