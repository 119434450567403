import FullTable from 'js/components/full-table/index.js'
import template from './_form-detail.pug'
import {months} from '../../../services/months'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    offerId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      auxTable: [],
      months: months()
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      API.offerPurchase.order.byOffer(this.offerId).then(response => {
        const aux = []
        response.data.leadProposalOfferDetail.forEach(item => {
          aux.push({
            id: item.id,
            month: this.getMonthText(item.availableMonth),
            // Id del detalle de la oferta
            leadProposalOfferDetailId: item.leadProposalOfferId,
            // Cantidad de Leads ofertados por el proveedor
            leadAmountOffer: item.leadAmountOffer,
            // Costo de leads ofertados
            cplOffer: item.cplOffer,
            // Cantidad de Leads a comprar
            leadAmount: item.leadAmount,
            errorLead: ''
          })
        })
        this.auxTable = aux
      })
    },
    getMonthText(month) {
      return this.months.find(({value}) => value === month).label
    },

    t(key, options = {}) {
      return this.$t(`leadtrade.offerOrder.form.${key}`, options)
    },

    cancel() {
      this.$router.push({name: 'offer-order-index'})
    }
  }
})
