import {cloneDeep} from 'lodash'
import {openModalConfirmRemove} from 'js/services/modals.js'
import template from './validation-index.pug'

import RobinsonForm from './_validation-form'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')

export default Vue.extend({
  template: template(),
  components: {
    RobinsonForm
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    campaignTags: {
      type: Array,
      default: () => ([])
    },
    translateKey: String,
    validationService: String
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      validationData: {},
      showForm: false,
      validationList: [],
      originalValidationList: [],
      ready: false,
      hasChange: false,
      tableKey: 1
    }
  },
  computed: {
    defaultOrder() {
      return {currentField: 'order', currentDir: 'asc'}
    },
    formMode() {
      return this.validationData.id ? 'edit' : 'new'
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.validationComponent.${key}`, options)
    },
    tk(key, options = {}) {
      return this.$t(`campaigns.show.validations.tabs.${this.translateKey}.${key}`, options)
    },

    getClassByStatus(status) {
      switch (status) {
        case true:
          return 'table-tag--success'
        case false:
          return 'table-tag--gray'
        default:
          return ''
      }
    },
    getIconByStatus(status) {
      switch (status) {
        case true:
          return 'check'
        case false:
          return 'close'
        default:
          return ''
      }
    },
    cancel() {
      this.validationList = cloneDeep(this.originalValidationList)
      this.hasChange = false
    },

    loadData() {
      this.ready = false
      this.validationList = []
      this.originalValidationList = []
      API.campaigns[this.validationService].index(this.campaign.id, {
        page: {number: 1, size: 1000}
      }).then(({data}) => {
        this.originalValidationList = cloneDeep(data)
        this.validationList = cloneDeep(data)
        this.ready = true
      })
    },
    changeOrder(field, dir) {
      this.validationList = orderBy(
        this.validationList,
        item => item[field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        dir
      )
    },
    openEditForm(item) {
      this.validationData = {
        ...item
      }
      this.showForm = true
    },
    openNewForm() {
      this.validationData = {
        campaignId: this.campaign.id
      }
      this.showForm = true
    },

    rowMoved() {
      this.validationList.forEach((item, i) => {
        item.order = i + 1
      })
      this.tableKey += 1
      this.hasChange = true
    },

    saveForm(data) {
      if (this.formMode === 'new') {
        this.create(data)
      } else {
        this.update(data)
      }
    },

    openConfirmModal(data) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.deleteValidationRobinson(data))
    },

    deleteValidationRobinson(data) {
      API.campaigns[this.validationService].destroy(this.campaign.id, data.id).then(() => {
        this.$notifications.success(this.t('succesfullyDeleted'))
        this.loadData()
      })
    },

    create(data) {
      API.campaigns[this.validationService].create(this.campaign.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.showForm = false
        this.loadData()
      })
    },

    update(data) {
      API.campaigns[this.validationService].update(this.campaign.id, data.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.showForm = false
        this.loadData()
      })
    },

    saveChangePriority() {
      const data = this.validationList.map(item => ({
        id: item.id,
        order: item.order
      }))
      API.campaigns.validation.changePriority(this.campaign.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.loadData()
        this.hasChange = false
      })
    }
  }
})
