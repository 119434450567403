export default {
  show(options = {}) {
    return Vue.http.get('authentication/me', options).then(
      response => response.data.data
    )
  },

  update(data, options = {}) {
    return Vue.http.put('authentication/me', data, options).then(
      response => response.data,
      response => { throw response.data.errors }
    )
  },

  patch(data, options = {}) {
    return Vue.http.patch('authentication/me', data, options).then(
      response => response.data,
      response => { throw response.data.errors }
    )
  },

  updatePassword(data, options = {}) {
    return Vue.http.put(
      'authentication/me/password',
      data,
      {...options, errorsRootKey: 'profile'}
    ).then(
      response => response.data,
      response => { throw response.data.errors }
    )
  },

  updateEmail(data, options = {}) {
    return Vue.http.put(
      'authentication/me/email',
      data,
      {...options, errorsRootKey: 'profile'}
    ).then(
      response => response.data,
      response => { throw response.data.errors }
    )
  }
}
