import { cloneDeep } from 'lodash'
import LeadsAccountsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsAccountsTable
  },
  data() {
    const queryOptions = {
      order: {
        name: 'asc'
      },
      page: {number: 1, size: 1000}
    }
    const allData = {
      value: '0',
      label: this.t('allData')
    }
    return {
      queryOptions,
      allData,
      consumptions: [],
      customers: [],
      accounts: [],
      transactionTypes: [],
      meta: {}
    }
  },
  created() {
    this.loadAccounts()
    this.loadTransactionType()
    this.$setPageTitle(this.t('title'))
    this.$setBreadcrumbs([
      {label: this.t('breadcrumb'), route: {}}
    ])
  },
  methods: {
    loadTransactionType() {
      this.transactionTypes = [
        {label: 'Todos', value: ''},
        {label: 'SMS', value: 'SMS'},
        {label: 'EMAIL', value: 'EMAIL'},
        {label: 'LEADS', value: 'LEADS'},
        {label: 'PHONENUMBERS', value: 'PHONENUMBERS'},
      ]
    },
    loadCustomers(id) {
      API.laiaAccounts.getcustomers(id, this.queryOptions).then(({data}) => {
        this.customers = cloneDeep(data.map(item => ({label: item.name, value: item.id})) || [])
        if (data.length)
          this.customers.unshift({...this.allData})
      })
    },
    loadAccounts() {
      API.laiaAccounts.index(this.queryOptions).then(({data}) => {
        this.accounts = cloneDeep(data.map(item => ({label: item.name, value: item.id})) || [])
        if (data.length)
          this.accounts.unshift({...this.allData})
      })
    },
    loadData(queryOptions = {}) {
      this.consumptions = []
      API.consumption.usages(queryOptions).then(({data}) => {
        this.consumptions = cloneDeep(data)
      })
    },
    t(key, options = {}) {
      return this.$t(`consumption.${key}`, options)
    }
  }
})
