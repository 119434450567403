export default {
  state: {
    comparisonOperators: []
  },
  getters: {
    comparisonOperators: state => state.comparisonOperators,

    findComparisonOperatorById(_state, getters) {
      return id => getters.comparisonOperators.find(
        comparisonOperator => comparisonOperator.id === id
      )
    },

    findComparisonOperatorByKey(_state, getters) {
      return key => getters.comparisonOperators.find(
        comparisonOperator => comparisonOperator.key === key
      )
    }
  },
  mutations: {
    setComparisonOperators(state, comparisonOperators) {
      state.comparisonOperators = comparisonOperators
    }
  },
  actions: {
    loadComparisonOperators({commit}) {
      return API.comparisonOperators.index().then(data => {
        commit('setComparisonOperators', data.data)
        return data
      })
    }
  }
}
