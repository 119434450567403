import {isEqual} from 'lodash'
// import {validations} from '../../../../../mocks/laiaAccounts/factory.js'
import template from './phoneNumberProviders.pug'
import integrationsUtils from '../../../services/integrations-utils'

export default Vue.extend({
  template: template(),
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    errors: {
      type: Array,
      default: () => ([])
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      ready: false,
      dataAux: [],
      phoneNumberProvidersData: []
    }
  },
  watch: {
    // cada vez que se cambia el phoneNumberProvidersData
    // emitimos el valor para mantener el v-model actualizado
    phoneNumberProvidersData: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', JSON.parse(JSON.stringify(this.phoneNumberProvidersData)))
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.phoneNumberProvidersData)) return

        this.initializeData()
      }
    }
  },
  created() {
    this.initializeData()
    this.loadPhoneNumberProviders()
  },
  methods: {
    loadPhoneNumberProviders() {
      const persistent = JSON.parse(JSON.stringify(this.value))
      this.phoneNumberProvidersData = []
      API.phoneNumberProviders.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.phoneNumberProvidersData = data.map(item => {
          const existProvider = persistent.find(({id}) => id === item.id)
          if (existProvider) {
            return {...existProvider, active: true}
          }
          return {...item, active: false, providerAccountId: '', authToken: ''}
        })
      })
    },
    getSrc(name) {
      return integrationsUtils.getSrc(name)
    },
    initializeData() {
      this.dataAux = JSON.parse(JSON.stringify(this.value))
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`laiaAccounts.form.${key}`, options)
    }
  }
})
