import UserEdit from '../_form.js'

import template from './edit.pug'

export default Vue.extend({
  template: template(),
  components: {
    UserEdit
  },
  data() {
    return {
      ready: false,
      data: {
        customerId: 0
      },
      errors: {},
      customerOptions: []
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('users.admins.form.edit.title'))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.users.admins.index'), route: {name: 'admins-index'}},
          {label: this.$t('breadcrumbs.users.admins.edit'), route: {}}
        ])
        this.getData()
      }
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`users.admins.edit.${key}`, options)
    },

    update(data) {
      this.errors = {}

      API.users.update(this.$route.params.id, data).then(() => {
        this.$notifications.success(this.$t('users.form.succesfullyEdit'))
        this.$router.push({name: 'admins-index'})
      }, errors => {
        this.errors = errors
      })
    },

    getData() {
      this.ready = false
      API.users.show(this.$route.params.id).then(response => {
        this.data = response
        this.ready = true
      })
    }
  }
})
