import OfferTable from './_table.js'
import template from './index.pug'

import {generateMonthStructureForOffer} from '../demand-table/helpers'

export default Vue.extend({
  template: template(),
  components: {
    OfferTable
  },
  props: {
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      offers: [],
      monthsUsed: {},
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('leadtrade.offer.index.title'))
    this.$setBreadcrumbs([
      {
        label: this.$t('breadcrumbs.offer-and-demand.index'),
        route: {name: 'offer-and-demand-index'}
      },
      {label: this.$t('breadcrumbs.offer.index'), route: {}}
    ])
  },
  methods: {
    loadOffers(queryOptions = {}) {
      this.offers = []
      API.offerPurchase.index(queryOptions).then(({data, meta}) => {
        const {offers, monthsUsed} = generateMonthStructureForOffer(
          data,
          this.$user.can('CONFIRM_OFFER')
        )
        this.offers = offers
        this.monthsUsed = monthsUsed
        this.meta = meta
      })
    }
  }
})
