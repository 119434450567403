import OfferForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    OfferForm
  },
  props: {
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      errors: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('leadtrade.offer.new.title'))
    this.$setBreadcrumbs([
      {
        label: this.$t('breadcrumbs.offer-and-demand.index'),
        route: {name: 'offer-and-demand-index'}
      },
      {
        label: this.$t('breadcrumbs.offer.index'),
        route: {name: 'offer-index'}
      },
      {label: this.$t('breadcrumbs.offer.new'), route: {}}
    ])
  },
  methods: {
    createOffer(data) {
      API.offerPurchase.create(data).then(() => {
        this.$notifications.success(this.$t('leadtrade.offer.new.succesfullyCreated'))
        this.$router.push({name: 'offer-index', query: {reload: true}})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
