export default {
  mediums(params = {}, options = {}) {
    const url = params.campaignId ?
      `campaigns/${params.campaignId}/utm-mediums` :
      `customers/${params.customerId}/utm-mediums`

    delete params.campaignId
    delete params.customerId

    return Vue.http.get(
      url,
      {params, ...options}
    ).then(response => response.data)
  },

  sources(params = {}, options = {}) {
    const url = params.campaignId ?
      `campaigns/${params.campaignId}/utm-sources` :
      `customers/${params.customerId}/utm-sources`

    delete params.campaignId
    delete params.customerId

    return Vue.http.get(
      url,
      {params, ...options}
    ).then(response => response.data)
  },

  campaigns(params = {}, options = {}) {
    const url = params.campaignId ?
      `campaigns/${params.campaignId}/utm-campaigns` :
      `customers/${params.customerId}/utm-campaigns`

    delete params.campaignId
    delete params.customerId

    return Vue.http.get(
      url,
      {params, ...options}
    ).then(response => response.data)
  }
}
