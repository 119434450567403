const openModalConfirmRemove = (titleText, bodyText, okButtonText) => {
  const title = `<i class='ns-icon-info-circle ns-confirm--delete'></i>${titleText}`
  const body = bodyText
  const options = {
    html: true,
    okText: okButtonText
  }

  return Vue.dialog.confirm({title, body}, options)
}

const openModalConfirmRemoveWithPrompt = (titleText, bodyText, okButtonText, promptHelp) => {
  const title = `<i class='ns-icon-info-circle ns-confirm--delete'></i>${titleText}`
  const body = bodyText
  const options = {
    html: true,
    okText: okButtonText,
    promptHelp: promptHelp
  }

  return Vue.dialog.prompt({title, body}, options)
}

const openModalConfirm = (titleText, bodyText, okButtonText, customOptions = {}) => {
  const title = `<i class='ns-icon-info-circle ns-confirm'></i>${titleText}`
  const body = bodyText
  const defaultOptions = {
    html: true,
    okText: okButtonText,
    customClass: ''
  }

  const options = {...defaultOptions, ...customOptions}

  return Vue.dialog.confirm({title, body}, options)
}

const openModalAlert = (titleText, bodyText, okButtonText, customOptions = {}) => {
  const title = `<i class='ns-icon-info-circle ns-confirm'></i>${titleText}`
  const body = bodyText
  const defaultOptions = {
    html: true,
    okText: okButtonText,
    customClass: ''
  }

  const options = {...defaultOptions, ...customOptions}

  return Vue.dialog.alert({title, body}, options)
}


export {openModalConfirm, openModalConfirmRemoveWithPrompt, openModalConfirmRemove, openModalAlert}
