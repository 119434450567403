import CampaignsTable from './_table.js'
import template from './customer-index.pug'

export default Vue.extend({
  template: template(),
  components: {
    CampaignsTable
  },
  data() {
    return {
      campaigns: [],
      meta: {},
      ready: false
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.loadCustomer()
      }
    }
  },
  methods: {
    loadCustomer() {
      this.ready = false
      API.customers.show(this.$route.params.id).then(customer => {
        this.$setPageTitle(this.$t('campaigns.customer.title', {name: customer.name}))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
          {
            label: this.$t('breadcrumbs.customers.show', {name: customer.name}),
            route: {name: 'customers-show-info', params: {id: customer.id}}
          },
          {
            label: this.$t('breadcrumbs.customers.campaigns', {name: customer.name}),
            route: {}
          }
        ])

        this.ready = true
      })
    },

    loadCampaigns(queryOptions = {}) {
      queryOptions.filter = queryOptions.filter || {}
      queryOptions.filter.customerId = [this.$route.params.id]

      API.campaigns.index(queryOptions).then(({data, meta}) => {
        this.campaigns = data
        this.meta = meta
      })
    }
  }
})
