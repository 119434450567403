import template from './voice-menu.pug';
import { cloneDeep, merge, set } from 'lodash';
import FormModal from 'js/components/form-modal';
import openModalConfirm from './_confirm';
import { openModalAlert } from 'js/services/modals';
import validatePresence from './_validate';
import PlayAudios from '../../../../audios-menu-voice/_play-audios.js';

const DefaultData = () => ({
  name: '',
  config: {
    playMessage: '',
    urlAudioMessage: '',
    urlAudioMessageId: '',
    languageMessage: 'English',
    inputTimeout: '7',
    itemMenus: [],
  },
  branchs: [],
  dinamicBranches: 0,
  errors: {
    name: '',
    config: {
      urlAudioMessage: '',
      urlAudioMessageId: '',
      languageMessage: '',
      inputTimeout: '',
      itemMenus: []
    }
  }
});

export default {
  template: template(),
  components: {
    FormModal,
    PlayAudios
  },
  props: {
    node: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    const initialData = this.node && this.node.config ? this.node : DefaultData();
    return {
      data: merge(DefaultData(), cloneDeep(initialData)),
      isCustomOptionSelected: false,
      isWritingCustomOption: false,
      isValidAudioSelected: false,
      dataFields: [],
      checkDataFields: true,
      leadFieldValues: [],
      audiosUrl: [],
      optionsLanguages: [
        {
          label: this.$t('campaigns.show.automations.workflow.nodes.voiceMenu.fields.optionsLanguage.english'),
          value: this.$t('campaigns.show.automations.workflow.nodes.voiceMenu.fields.optionsLanguage.english'),
        },
        {
          label: this.$t('campaigns.show.automations.workflow.nodes.voiceMenu.fields.optionsLanguage.spanish'),
          value: this.$t('campaigns.show.automations.workflow.nodes.voiceMenu.fields.optionsLanguage.spanish'),
        }
      ],
      selectedAudioName: '',
      showAudioPanel: false,
      selectedAudio: null,
      customOptionValue: (this.node.config && this.node.config.playMessage) || ''
    };
  },

  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors);
    },
    itemMenusErrors() {
      return this.dataFields.filter(item => item.errors).length;
    },
    audioOptions() {
      return this.audiosUrl.map(urlAudioMessageId => ({
        label: urlAudioMessageId.audioFileName,
        value: urlAudioMessageId.id
      }));
    },
    audioOptionsWithCustomOption() {
      const playMessage = this.data.config.playMessage
        ? [{ label: this.data.config.playMessage, value: '' }]
        : [{ label: 'Escribir Mensaje', value: 'custom' }];
      return [...this.audioOptions, ...playMessage];
    },
    audioOptionsWithCustomOptionField() {
      return index => {
        if (!this.dataFields[index]) {
          return [];
        }
        const playMessage = this.dataFields[index].playMessage
          ? [{ label: this.dataFields[index].playMessage, value: '' }]
          : [{ label: 'Escribir Mensaje', value: 'custom' }];
        return [...this.audioOptions, ...playMessage];
      };
    },
    shouldShowAudioControls() {
      return this.isValidAudioSelected && !this.isWritingCustomOption;
    }
  },
  created() {
    if (this.data.config.itemMenus.length) {
      this.data.config.itemMenus.forEach(item => {
        this.dataFields.push({
          keyPress: item.keyPress,
          nextStepId: item.nextStepId,
          playMessage: item.playMessage || '',
          urlAudioMessage: item.urlAudioMessage || '',
          urlAudioMessageId: item.urlAudioMessageId || '',
          errors: null,
          isWritingCustomOption: false,
          customOptionValue: ''
        });
      });
    } else {
      this.addField();
    }

    if (this.node.config) {
      this.validateFields();
      this.setCheckDataFields();
    }

    this.getaudiosUrl();
  },
  methods: {
    getaudiosUrl() {
      const params = {
        page: {
          number: 1,
          size: 100
        },
        order: {
          name: 'asc'
        }
      };
      API.audiosMenuVoice.index(params).then(({ data }) => {
        this.audiosUrl = data;
        this.updateSelectedAudioName();
      }).catch(error => {
        console.error('Error fetching audio URLs:', error);
      });
    },
    findDuplicatesNumbersFromArray(a) {
      var d = [];
      for (let i = 0; i < a.length; i++) {
        var ct = a[i];
        var cmt = 0;
        for (var x = 0; x < a.length; ++x) {
          if (ct === a[x]) {
            cmt++;
            if (cmt > 1) {
              d.push(a[i]);
            }
          }
        }
      }
      return d.filter((i, ix) => d.indexOf(i) === ix);
    },
    setCheckDataFields() {
      return this.dataFields.length > 1 || this.dataFields[0].keyPress != '' ? this.checkDataFields = false : this.checkDataFields = true;
    },
    addField() {
      this.dataFields.push({
        keyPress: '',
        playMessage: '',
        nextStepId: '',
        errors: null,
        isWritingCustomOption: false,
        customOptionValue: ''
      });
      this.checkDataFields = false;
      this.setCheckDataFields();
    },
    deleteField(index) {
      if (this.dataFields.length > 1) {
        this.dataFields.splice(index, 1);
        this.setCheckDataFields();
        this.updateSelectedAudioName();
      } else {
        openModalAlert(
          this.$t('alertModal.title'),
          this.$t('alertModal.body'),
          this.$t('alertModal.okButton')
        );
      }
    },
    save() {
      this.data.config.itemMenus = this.dataFields;
      let arrayOfKeys = this.dataFields.map(el => el.keyPress);

      const duplicates = this.findDuplicatesNumbersFromArray(arrayOfKeys);
      if (duplicates.length) {
        openModalAlert(
          this.t('alertModal.title'),
          this.t('alertModal.body'),
          this.t('alertModal.okButton')
        );
      } else {
        if (this.data.config.urlAudioMessageId) {
          validatePresence(this.data, [
            'name',
            'config.itemMenus',
            'config.urlAudioMessageId',
          ]);
        } else {
          validatePresence(this.data, [
            'name',
            'config.itemMenus',
            'config.playMessage',
          ]);
        }

        this.validateFields();
        if (this.data.errors || this.itemMenusErrors) {
          openModalConfirm().then(() => {
            this.data.dinamicBranches = this.dataFields.length;
            this.$emit('save', cloneDeep(this.data));
            this.updateSelectedAudioName();

            console.log('Datos guardados:', this.data);
          });
        } else {
          this.data.dinamicBranches = this.dataFields.length;
          this.$emit('save', cloneDeep(this.data));
          this.updateSelectedAudioName();

          console.log('Datos guardados:', this.data);
        }
      }
    },

    validateFields() {
      let error = false;
      this.dataFields.forEach(field => {
        field.errors = null;
        validatePresence(field, ['keyPress']);
        const sameNameItems = this.dataFields.filter(item => item.keyPress === field.keyPress).length;
        if (sameNameItems > 1) {
          field.errors = { keyPress: this.$t('campaigns.show.automations.workflow.nodes.voiceMenu.fields.errors.duplicateKeyPress') };
          error = true;
        }
        if (field.errors) error = true;
      });
      if (error || this.itemMenusErrors || this.data.errors) {
        set(this.data, 'errors.config.itemMenus', true);
      }
    },

    onAudioChange() {
      const selectedValue = this.data.config.urlAudioMessageId;
      if (selectedValue === 'custom' || selectedValue === '') {
        this.isCustomOptionSelected = true;
        this.isWritingCustomOption = true;
        this.isValidAudioSelected = false;
        this.customOptionValue = this.data.config.playMessage;
        this.data.config.urlAudioMessage = '';
        this.data.config.urlAudioMessageId = '';
      } else {
        this.isCustomOptionSelected = false;
        this.isWritingCustomOption = false;
        const selectedAudio = this.audiosUrl.find(audio => audio.id === selectedValue);
        if (selectedAudio) {
          this.data.config.urlAudioMessage = selectedAudio.urlFile;
          this.data.config.urlAudioMessageId = selectedAudio.id;
          this.isValidAudioSelected = true;
        } else {
          this.data.config.urlAudioMessage = '';
          this.data.config.urlAudioMessageId = '';
          this.isValidAudioSelected = false;
        }
      }
      this.updateSelectedAudioName();
    },
    handleCustomOptionInput(event) {
      if (event && event.target) {
        this.data.config.playMessage = event.target.value;
      }
    },
    handleCustomOptionInputField(index, event) {
      if (event && event.target) {
        this.$set(this.dataFields[index], 'customOptionValue', event.target.value);
      }
    },

    onAudioChangeField(index) {
      const selectedValue = this.dataFields[index].urlAudioMessageId;
      if (selectedValue === 'custom' || selectedValue === '') {
        this.$set(this.dataFields[index], 'isWritingCustomOption', true);
        this.$set(this.dataFields[index], 'customOptionValue', this.dataFields[index].playMessage);
        this.$set(this.dataFields[index], 'urlAudioMessage', '');
        this.$set(this.dataFields[index], 'urlAudioMessageId', '');
        this.$set(this.dataFields[index], 'isValidAudioSelected', false);
      } else {
        this.$set(this.dataFields[index], 'isWritingCustomOption', false);
        const selectedAudio = this.audiosUrl.find(audio => audio.id === selectedValue);
        if (selectedAudio) {
          this.$set(this.dataFields[index], 'urlAudioMessage', selectedAudio.urlFile);
          this.$set(this.dataFields[index], 'urlAudioMessageId', selectedAudio.id);
          this.$set(this.dataFields[index], 'isValidAudioSelected', true);
        } else {
          this.$set(this.dataFields[index], 'urlAudioMessage', '');
          this.$set(this.dataFields[index], 'urlAudioMessageId', '');
          this.$set(this.dataFields[index], 'isValidAudioSelected', false);
        }
      }
      this.updateSelectedAudioNameField(index);
    },
    saveCustomOptionField(index) {
      if (this.dataFields[index].customOptionValue.trim() !== '') {
        this.$set(this.dataFields[index], 'playMessage', this.dataFields[index].customOptionValue);
        this.$set(this.dataFields[index], 'urlAudioMessage', '');
        this.$set(this.dataFields[index], 'urlAudioMessageId', '');
        this.$set(this.dataFields[index], 'isWritingCustomOption', false);
        this.$set(this.dataFields[index], 'isCustomOptionSelected', true);
        this.$set(this.dataFields[index], 'isValidAudioSelected', true);
      } else {
        this.$set(this.dataFields[index], 'isWritingCustomOption', false);
        this.$set(this.dataFields[index], 'isCustomOptionSelected', false);
        this.$set(this.dataFields[index], 'isValidAudioSelected', false);
      }
      this.updateSelectedAudioNameField(index);
    },

    updateSelectedAudioNameField(index) {
      const selectedAudio = this.audiosUrl.find(audio => audio.id === this.dataFields[index].urlAudioMessageId);
      if (selectedAudio) {
        this.$set(this.dataFields[index], 'selectedAudioName', selectedAudio.audioFileName);
        this.$set(this.dataFields[index], 'isValidAudioSelected', true);
      } else {
        this.$set(this.dataFields[index], 'selectedAudioName', '');
        this.$set(this.dataFields[index], 'isValidAudioSelected', false);
      }
    },
    saveCustomOption() {
      if (this.customOptionValue.trim() !== '') {
        this.data.config.playMessage = this.customOptionValue.trim();
        this.data.config.urlAudioMessage = '';
        this.data.config.urlAudioMessageId = '';
        this.isCustomOptionSelected = true;
        this.isWritingCustomOption = false;
        this.isValidAudioSelected = true;
      } else {
        this.isCustomOptionSelected = false;
        this.isWritingCustomOption = false;
        this.isValidAudioSelected = false;
      }
      this.updateSelectedAudioName();
    },


    updateSelectedAudioName() {
      const selectedAudio = this.audiosUrl.find(audio => audio.id === this.data.config.urlAudioMessageId);
      if (selectedAudio) {
        this.selectedAudioName = selectedAudio.audioFileName;
        this.isValidAudioSelected = true;
      } else {
        this.selectedAudioName = '';
        this.isValidAudioSelected = false;
      }
    },
    playSelectedAudio() {
      const selectedAudio = this.audiosUrl.find(audio => audio.id === this.data.config.urlAudioMessageId);
      this.selectedAudio = selectedAudio;
      this.showAudioPanel = true;
    },
    closeAudioPanel() {
      this.showAudioPanel = false;
    },
    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.voiceMenu.${key}`, options);
    }
  }
};
