import AudiosMenuVoiceTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
    template:template(),
    components:{
        AudiosMenuVoiceTable
    },
    data(){
        return{
            audiosMenuVoice:[],
            meta:{}
        }
       
    },
    created(){
        this.$setPageTitle(this.$t('audiosMenuVoice.index.title'))
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.audiosMenuVoice.index'), route: {}}
        ])
    },
    methods:{
        loadAudiosMenuVoice(queryOptions={}){
            API.audiosMenuVoice.index(queryOptions).then(({data,meta})=>{
                this.audiosMenuVoice=data
                this.meta=meta
            })

        }
    }
})