import {mapGetters, mapMutations} from 'vuex'

const keyByValue = (object, value) => Object.keys(object).find(key => object[key] === value)

Vue.mixin(Vue.extend({
  computed: {
    ...mapGetters({
      $user: 'currentUser',
      $client: 'currentAccount',
      $findRoleById: 'findRoleById',
      $findRoleByKey: 'findRoleByKey'
    })
  },
  methods: {
    ...mapMutations({
      $setPageTitle: 'setPageTitle',
      $setBreadcrumbs: 'setBreadcrumbs',
      $insertBreadcrumb: 'insertBreadcrumb'
    }),

    // si la url no trae el host, usamos el origin del API
    // necesario para las url de los archivos subidos a LAIA desde el front (avatars, logos...)
    $fileURL(path) {
      if (!path) return ''

      return path.indexOf('http') > -1 ? path : `${new URL(API_HOST).origin}${path}`
    },

    $keyByValue: keyByValue,

    $tFieldType(value) {
      return this.$t(`fieldTypes.${keyByValue(FIELD_TYPES, value)}`)
    },

    $tLeadField(value) {
      return this.$t(`leadFields.${keyByValue(LEAD_FIELDS, value)}`)
    },

    $tCdimensionsScope(value) {
      return this.$t(`cdimensionsScopes.${keyByValue(CDIMENSIONS.SCOPE, value)}`)
    },

    $tCdimensionsSourceLocation(value) {
      return this.$t(`cdimensionsSourceLocations.${keyByValue(CDIMENSIONS.SOURCE_LOCATION, value)}`)
    },

    $sortByLocale(objectArray, key) {
      return objectArray.sort((a, b) => (a[key].localeCompare(b[key])))
    }
  }
}))
