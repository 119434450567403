import RouteParent from 'js/router/route-parent'

import trackingSourcesIndex from 'js/pages/tracking-sources/index.js'
import trackingSourcesNew from 'js/pages/tracking-sources/new.js'
import trackingSourcesEdit from 'js/pages/tracking-sources/edit.js'

export default [
  {
    path: '/tracking-sources',
    component: RouteParent,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '',
        name: 'tracking-sources-index',
        component: trackingSourcesIndex,
        meta: {
          auth: true,

        }
      },
      {
        path: 'new',
        name: 'tracking-sources-new',
        component: trackingSourcesNew,
        meta: {
          auth: true,
        }
      },
      {
        path: ':id/edit',
        name: 'tracking-sources-edit',
        component: trackingSourcesEdit,
        meta: {
          auth: true,
        }
      }
    ]
  }
]