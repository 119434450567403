export default {
  options: data => {
    if (!data) return null

    return {
      tooltip: {
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          data: data.map(item => ({
            value: item.convertedCount,
            name: item.name
          }))
        }
      ]
    }
  }
}
