import template from './_menu.pug'

export default Vue.extend({
  template: template(),
  props: {
    // propiedades del componente ns-layout-sidebar
    layoutProps: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showLead: false,
      menu: []
    }
  },
  computed: {
    isSuperAdmin() {
      return this.$user.role === ROLES.SUPER_ADMIN
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        if (this.layoutProps.isMobile && this.layoutProps.showSidebar) this.layoutProps.toggle()
      }
    }
  },
  created() {
    this.menu = [
      {
        separator: this.$t('menu.content')
      },
      // MANAGE_LAIA_ADMIN
      {
        condition: this.$user.can('MANAGE_LAIA_ADMIN'),
        to: {name: 'accounts-laia-index'},
        icon: 'admin',
        title: this.$t('menu.systemAccount')
      },
      {
        condition: this.$user.can('MANAGE_LAIA_ADMIN'),
        to: {name: 'invoicing-index'},
        icon: 'dollar',
        title: this.$t('menu.invoicing')
      },
      {
        condition: this.$user.can('MANAGE_LAIA_ADMIN'),
        to: {name: 'customers-admin-index'},
        icon: 'star-o',
        title: this.$t('menu.customers-admin')
      },
      {
        condition: this.$user.can('MANAGE_LAIA_ADMIN'),
        to: {name: 'notifications-index'},
        icon: 'bell',
        title: this.$t('menu.notifications')
      },
      {
        condition: this.$user.can('MANAGE_LAIA_ADMIN'),
        to: {name: 'consumption-index'},
        icon: 'generate',
        title: this.$t('menu.consumption')
      },
      // CAMPAIGN
      {
        condition: this.$user.can('LIST_CAMPAIGNS'),
        to: {name: 'campaigns-index'},
        icon: 'megaphone',
        title: this.$t('menu.campaigns')
      },
      // ANALYTICS
      {
        condition: this.$user.can('SHOW_ANALYTICS'),
        group: 'report',
        title: this.$t('menu.analitycMenu'),
        icon: 'chart',
        open: false,
        items: [
          // Analytics
          {
            condition: this.$user.can('SHOW_ANALYTICS'),
            to: {name: 'analytics-index'},
            icon: 'transform',
            title: this.$t('menu.analytics')
          },
          // Analytics Bi
          {
            condition: this.$user.can('SHOW_ANALYTICS'),
            to: {name: 'analytics-bi-index'},
            icon: 'generate',
            title: this.$t('menu.biAnalytics')
          },
          // Reports
          {
            condition: this.$user.can('SHOW_ANALYTICS'),
            separator: this.$t('menu.reportMenu')
          },
          {
            condition: this.$user.can('SHOW_ANALYTICS'),
            to: {name: 'operational-report-index'},
            icon: 'check',
            title: this.$t('menu.operationalReport')
          },
          {
            condition: this.$user.can('SHOW_ANALYTICS'),
            to: {name: 'tipification-report-index'},
            icon: 'check',
            title: this.$t('menu.tipificationReport')
          },
          {
            condition: this.$user.can('SHOW_ANALYTICS'),
            to: {name: 'cut-report-index'},
            icon: 'check',
            title: this.$t('menu.cutReport')
          },
          {
            condition: this.$user.can('SHOW_ANALYTICS'),
            to: {name: 'programation-report-index'},
            icon: 'clock',
            title: this.$t('menu.programationReport')
          }
        ]
      },
      // CONFIGURATION
      {
        condition: !this.$user.can('MANAGE_LAIA_ADMIN'),
        group: 'configuration',
        title: this.$t('menu.config'),
        icon: 'settings',
        open: false,
        items: [
          {
            condition: this.$user.can('VIEW_CUSTOMERS'),
            to: {name: 'customers-index'},
            icon: 'star-o',
            title: this.$t('menu.customers')
          },
          {
            condition: this.$user.can('VIEW_CALL_CENTERS'),
            to: {name: 'call-centers-index'},
            icon: 'phone',
            title: this.$t('menu.callCenters')
          },
          {
            condition: this.$user.can('VIEW_PRODUCTS'),
            to: {name: 'products-index'},
            icon: 'product',
            title: this.$t('menu.products')
          },
          {
            condition: this.$user.can('LIST_CAMPAIGNS'),
            to: {name: 'email-marketing-index'},
            icon: 'email',
            title: this.$t('menu.emailMarketing')
          },
          {
            condition: this.$user.can('VIEW_DEMAND'),
            to: {name: 'offer-and-demand-index'},
            icon: 'zbuy',
            title: this.$t('menu.offerAndDemand')
          },
          {
            condition: this.$user.can('LIST_CAMPAIGNS'),
            to: {name: 'sms-templates-index'},
            icon: 'message',
            title: this.$t('menu.smsTemplates')
          },
          {
            condition: this.$user.can('MANAGE_PROVIDERS_LEADS'),
            to: {name: 'provider-leads-index'},
            icon: 'wglobe',
            title: this.$t('menu.providerLeads')
          },
    

          // Call Tracking
          {
            condition:this.isSuperAdmin ,
            separator: this.$t('menu.callTrackings')
          },
          {
            condition:this.isSuperAdmin ,
            to: { name: 'pool-index' },
            icon: 'phone',
            title: this.$t('menu.pool')
          },
          
          {
            condition:this.isSuperAdmin ,
            to: { name: 'ddis-index' },
            icon: 'menu',
            title: this.$t('menu.DDIs')
          },
          {
            condition: this.isSuperAdmin ,
            to: { name: 'static-phone-index' },
            icon: 'wglobe',
            title: this.$t('menu.staticPhones')
          },          
          {
            condition: this.isSuperAdmin ,
            to: { name: 'tracking-numbers-index' },
            icon: 'phone',
            title: this.$t('menu.trackingNumbers')
          },
          {
            condition: this.isSuperAdmin,
            to: { name: 'audios-menu-voice-index' },
            icon: 'voice',
            title: this.$t('menu.audiosMenuVoice')
          },
       





          // Operator
          {
            condition: this.$user.can('MANAGE_OPERATORS') ||
              this.$user.can('MANAGE_ADMINS') ||
              this.$user.can('MANAGE_AGENCIES') ||
              this.$user.can('MANAGE_ADMINS') ||
              this.$user.isSuperAdmin,
            separator: this.$t('menu.users')
          },
          {
            condition: this.$user.can('MANAGE_AGENCIES'),
            to: {name: 'agency-suppliers-index'},
            icon: 'operadores',
            title: this.$t('menu.agencies')
          },
          {
            condition: this.$user.can('MANAGE_OPERATORS'),
            to: {name: 'operators-index'},
            icon: 'operadores',
            title: this.$t('menu.operators')
          },
          {
            condition: this.$user.can('MANAGE_ADMINS'),
            to: {name: 'admins-index'},
            icon: 'user-add',
            title: this.$t('menu.admins')
          },
          // Activity Register
          {
            condition: this.$user.isSuperAdmin || this.$user.can('MANAGE_ADMINS'),
            separator: this.$t('menu.activityRegister')
          },
          {
            condition: this.$user.isSuperAdmin,
            to: {name: 'accounts-index'},
            icon: 'user',
            title: this.$t('menu.account.lead')
          },
          {
            condition: this.$user.can('MANAGE_ADMINS'),
            to: {name: 'log-lead'},
            icon: 'zlink',
            title: this.$t('menu.logs')
          },

          // Help
          {
            condition: true,
            separator: this.$t('menu.help')
          },
          {
            condition: true,
            href: 'https://docs.laia-digital.com/',
            icon: 'help',
            title: this.$t('menu.documentation')
          }
        ]
      }
    ]
  },
  methods: {
    showLeadMenu() {
      this.showLead = !this.showLead
    }
  }
})
