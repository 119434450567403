import staticPhoneTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    staticPhoneTable
  },
  data() {
    return {
      staticPhones: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('staticPhone.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.staticPhone.index'), route: {}}
    ])
  },
  methods: {
    loadStaticPhone(queryOptions = {}) {
      API.callTrackingStaticNumbers.index(queryOptions).then(({data, meta}) => {
        this.staticPhones = data
        this.meta = meta
      })
    }
  }
})
