import {cloneDeep, merge, set} from 'lodash'
import FormModal from 'js/components/form-modal'
import {mapGetters} from 'vuex'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './start-form.pug'

const DefaultData = () => ({
  config: {
    triggers: []
  }
})

const DefaultAux = () => ({
  triggerTypeId: '',
  triggerTypeName: '',
  triggerParameters: []
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      aux: DefaultAux(),
      auxErrors: {},
      auxEditIndex: null,
      auxParameters: [],
      dialogParam: false,
      checkTriggersValue: false,
      checkTriggersButtonValue: false,
      data: merge(DefaultData(), cloneDeep(this.node)),
      callStatus: [],
      dataTriggers: [],
      onlyOnceSetting: new Date(''),
      datetimeObjectConfig: {
        allowInput: true,
        enableTime: true,
        dateFormat: 'Y/m/d H:i',
        time_24hr: true,
        onChange: s => {
          // convert date to string and format date to YYYY-MM-DD HH:mm:
          const date = new Date(s)
          const year = date.getFullYear()
          const month = date.getMonth() + 1 > 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
          const day = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`
          const hour = date.getHours() > 10 ? date.getHours() : `0${date.getHours()}`
          const min = date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`
          const dateStr = `${year}-${month}-${day} ${hour}:${min}`
          this.programationData.onlyOnceSetting.date = dateStr
        }
      },
      monthlySetting: '',
      weeklySetting: '',
      dailySetting: '',
      hourlySetting: '',
      minutesSetting: '',
      programationData: {
        frequency: '',
        monthlySetting: {
              start: '00:00',
              day: ''
        },
        weeklySetting: {
              start: '00:00',
              day: ''
        },
        dailySetting: {
              start: '00:00'
        },
        hourlySetting: {
              mins: '0'
        },
        onlyOnceSetting: {
              date: '1901-01-01 00:00'
        }
      },
      frecuencyOptions: [
        {value: 'monthly', label: this.t('frequencyOptions.monthly')},
        {value: 'weekly', label: this.t('frequencyOptions.weekly')},
        {value: 'daily', label: this.t('frequencyOptions.daily')},
        {value: 'hourly', label: this.t('frequencyOptions.hourly')},
        {value: 'onlyOnce', label: this.t('frequencyOptions.onlyOnce')}
      ]
    }
  },
  computed: {
    ...mapGetters(['triggerTypes']),
    triggerTypeSelectOptions() {
      return this.triggerTypes.map(trigger => ({
        label: trigger.name,
        value: trigger.id
      }))
    },
    trigerName() {
      return this.aux.triggerTypeId
    },
    selectMonthDays() {
      const days = []
      for (let i = 1; i <= 31; i++) {
        days.push({value: `${i}`, label: `${i}`})
      }
      return days
    },
    selectWeekDays() {
      return [
        {value: 'Monday', label: 'Lunes'},
        {value: 'Tuesday', label: 'Martes'},
        {value: 'Wednesday', label: 'Miércoles'},
        {value: 'Thursday', label: 'Jueves'},
        {value: 'Friday', label: 'Viernes'},
        {value: 'Saturday', label: 'Sábado'},
        {value: 'Sunday', label: 'Domingo'}
      ]
    },
    selecteHours() {
      const hours = []
      // for con las horas pero en los numeros menores a 0 se le agrega un 0 delante
      for (let i = 0; i <= 23; i++) {
        if (i < 10) {
          hours.push({value: `0${ i}`, label: `0${ i}`})
        } else {
          hours.push({value: `${ i}`, label: `${ i}`})
        }
      }
      return hours
    },
    selectMinutes() {
      const minutes = []
      // for con los minutos pero en los numeros menores a 0 se le agrega un 0 delante
      for (let i = 0; i <= 59; i++) {
        if (i < 10) {
          minutes.push({value: `0${ i}`, label: `0${ i}`})
        } else {
          minutes.push({value: `${ i}`, label: `${ i}`})
        }
      }
      return minutes
    },
    checkTriggers() {
        const array = this.dataTriggers
        const atributo = 'triggerTypeName'
        const valor = 'Programacion'

        for (let i = 0; i < array.length; i++) {
          const objeto = array[i]
          // eslint-disable-next-line no-prototype-builtins
          if (!objeto.hasOwnProperty(atributo) || objeto[atributo] !== valor) {
            return false
          }
        }
        return true
    },
    errors() {
      return merge(DefaultData(), this.data.errors)
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
        this.getCallStatus()
      }
    },
  monthlySetting(value) {
    this.programationData.monthlySetting.day = value
  },
  weeklySetting(value) {
      this.programationData.weeklySetting.day = value
  },
  minutesSetting(value) {
    if (this.programationData.frequency === 'hourly') {
      this.programationData.hourlySetting.mins = value
    }
    if (this.programationData.frequency === 'monthly') {
      this.programationData.monthlySetting.start = `${this.hourlySetting}:${value}`
    }
    if (this.programationData.frequency === 'weekly') {
      this.programationData.weeklySetting.start = `${this.hourlySetting}:${value}`
    }
    if (this.programationData.frequency === 'daily') {
      this.programationData.dailySetting.start = `${this.hourlySetting}:${value}`
    }
  },
  hourlySetting(value) {
    if (this.programationData.frequency === 'monthly') {
      this.programationData.monthlySetting.start = `${value}:${this.minutesSetting}`
    }
    if (this.programationData.frequency === 'weekly') {
      this.programationData.weeklySetting.start = `${value}:${this.minutesSetting}`
    }
    if (this.programationData.frequency === 'daily') {
      this.programationData.dailySetting.start = `${value}:${this.minutesSetting}`
    }
  }
  },
  created() {
    // Creamos el array de los triggers, con el cual editaremos los mismos
    if (this.data.config.triggers.length) {
      this.data.config.triggers.forEach(trigger => {
        this.dataTriggers.push(trigger)
      })
    }

    if (this.node.config) this.validateTriggers()
  },
  methods: {
    resetFrecuencyValues() {
      this.programationData.monthlySetting = {
        start: '00:00',
        day: ''
      }
      this.programationData.weeklySetting = {
        start: '00:00',
        day: ''
      }
      this.programationData.dailySetting = {
        start: '00:00'
      }
      this.programationData.hourlySetting = {
        mins: '00'
      }
      this.programationData.onlyOnceSetting = {
        date: '1901-01-01 00:00'
      }

      this.monthlySetting = ''
      this.weeklySetting = ''
      this.dailySetting = ''
      this.hourlySetting = ''
      this.minutesSetting = ''
      this.onlyOnceSetting = new Date()

      this.auxParameters = ['data']
    },
    selectTrigger(value) {
      if (!value) {
        this.aux.triggerTypeName = null
      } else {
        const trigger = this.triggerTypeSelectOptions.find(item => item.value === value)
        if (trigger) this.aux.triggerTypeName = trigger.label
        if (this.auxEditIndex !== null) {
          if (this.checkTriggers && this.aux.triggerTypeName === 'Programacion') {
            this.checkTriggersValue = false
          } else {
            this.checkTriggersValue = true
          }
        } else if (this.dataTriggers.length > 0) {
            if (this.checkTriggers && this.aux.triggerTypeName === 'Programacion') {
              this.checkTriggersValue = false
            } else {
              this.checkTriggersValue = true
            }
          }
      }
    },
    getCallStatus() {
      return API.campaigns.callStatus.index(this.campaign.id).then(({data}) => {
        this.callStatus = data.map(item => ({
          label: item.name,
          value: item.key
        }))
      })
    },

    addTrigger() {
      this.dialogParam = true
    },
    addTypes() {
      if (this.aux.triggerTypeName !== 'Programacion') {
        const triggerParameters = this.auxParameters.map(value => {
          const callStatus = this.callStatus.find(item => item.value === value)
          return {
            key: callStatus.value,
            value: callStatus.label
          }
        })
        this.aux.triggerParameters = triggerParameters
        // Si es una edicion de trigger, se ejecuta este codigo
        if (this.auxEditIndex !== null) {
          this.dataTriggers[this.auxEditIndex] = cloneDeep(this.aux)
          this.auxEditIndex = null
        } else {
          this.dataTriggers.push(this.aux)
        }
        this.dialogParam = false
        this.auxParameters = []
        this.aux = DefaultAux()
      } else {
        // Si es una edicion de trigger, se ejecuta este codigo
        if (this.auxEditIndex !== null) {
          this.dataTriggers[this.auxEditIndex] = cloneDeep({
            triggerTypeId: this.aux.triggerTypeId,
            triggerTypeName: this.aux.triggerTypeName,
            triggerParameters: [this.programationData]
          })
          this.auxEditIndex = null
        } else {
          this.dataTriggers.push({
            triggerTypeId: this.aux.triggerTypeId,
            triggerTypeName: this.aux.triggerTypeName,
            triggerParameters: [this.programationData]
          })
        }
        this.dialogParam = false
        this.auxParameters = []
        this.programationData = {}
        this.aux = DefaultAux()
      }
    },

    deleteTrigger(index) {
      this.dataTriggers.splice(index, 1)
      if (!this.dataTriggers.length) {
        this.checkTriggersValue = false
      }
    },

    editTrigger(index, trigger) {
      this.auxEditIndex = index
      this.aux = cloneDeep(trigger)
      this.auxParameters = trigger.triggerParameters.map(({key}) => key)
      if (this.aux.triggerTypeName === 'Programacion') {
        this.programationData = trigger.triggerParameters[0]
      }
      this.dialogParam = true
    },

    save() {
      this.data.errors = null
      this.data.config.triggers = []
      this.dataTriggers.forEach(trigger => {
        this.data.config.triggers.push({
          triggerTypeId: trigger.triggerTypeId,
          triggerTypeName: trigger.triggerTypeName,
          triggerParameters: trigger.triggerParameters
        })
      })
      // Si 'triggers' esta vacio creamos un error para mostrar rojo el nodo'
     /* if (this.data.config.triggers.length === 0) {
        this.data.errors = true
      }*/

      this.validateTriggers()

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    validateTriggers() {
      let error = false

      // validamos cada sección
      this.dataTriggers.forEach(trigger => {
        trigger.errors = null
        validatePresence(trigger, ['triggerTypeId', 'triggerTypeName'])
        if (!trigger.triggerParameters.length) error = true

        // validamos unicidad de las claves
        const sameNameItems = this.dataTriggers.filter(item => item.triggerTypeId).length
        if (sameNameItems > 1) trigger.errors = {key: this.$t('errors.unique')}
        if (trigger.errors) error = true
      })

      // añadimos un error para que se muestre en rojo el nodo en el diagrama
      if (error) set(this.data, 'errors.config.fields', true)
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.startForm.${key}`, options)
    }
  }
})

