export default {
  errorsRootKey: 'callCenters',
  resource: 'call-centers',
  actions: ['index', 'show', 'create', 'update', 'destroy'],

  ctiSetting: {
    index(callCenterId,ctiId,  params = {}, options = {}) {
      return Vue.http.get(
        `call-centers/${callCenterId}/cti/${ctiId}/setting`,
        {params},
        {options}
      ).then(response => response.data)
    },

    update(callCenterId, ctiId, data, options = {}) {
      return Vue.http.put(
        `call-centers/${callCenterId}/cti/${ctiId}/setting`,
        data,
        {errorsRootKey: 'callCenterCtiSetting', ...options}
      ).then(
        response => response.data,
        response => {
          throw response.data.errors
        }
      )
    }
  },
  ctiServices: {
    index(id, ctiId, params = {}, options = {}) {
      return Vue.http.get(
        `call-centers/${id}/cti/${ctiId}/services`,
        {params},
        {options}
      ).then(response => response.data)
    }
  },
}
