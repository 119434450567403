import CampaignsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  name: 'campaigns-page',
  template: template(),
  components: {
    CampaignsTable
  },
  data() {
    return {
      campaigns: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('campaigns.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.campaigns.index'), route: {}}
    ])
  },
  methods: {
    loadCampaigns(queryOptions = {}) {
      API.campaigns.index(queryOptions).then(({data, meta}) => {
        this.campaigns = data
        this.meta = meta
      })
    }
  }
})
