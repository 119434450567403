import template from './index.pug'

Vue.component('ns-input-password', {
  template: template(),
  inheritAttrs: false,
  props: {type: String},
  data() {
    return {
      dinamicType: 'password'
    }
  },
  computed: {
    iconType() {
      return this.dinamicType === 'password' ? 'eye' : 'eye-slash'
    }
  },
  methods: {
    toggleDinamicType() {
      this.dinamicType = this.dinamicType === 'password' ? 'text' : 'password'
    }
  }
})
