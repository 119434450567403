import table from './chart-table.js'

export default {
  options: (data, localeRoot) => {
    if (!data || data.length === 0) return null
    const sanitizedData = []
    data.forEach(item => {
      if (item.convertedCount !== 0) {
        sanitizedData.push(item)
      }
    })
    if (sanitizedData.length === 0) return null
    const seriesData = sanitizedData.map(item => ({
      value: item.convertedCount,
      name: item.name
    }))
    return {
      tooltip: {
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: params => {
          const tooltipData = {
            name: params.data.name,
            value: Vue.filter('floatRepresentation')(params.data.value),
            color: params.color
          }

          return Vue.i18n.t(`${localeRoot}.tooltip`, tooltipData)
        }
      },
      toolbox: {
        right: '20px',
        bottom: '7px',
        feature: {
          saveAsImage: {
            title: Vue.i18n.t('general.download')
          },
          dataView: {
            title: Vue.i18n.t('general.table'),
            readOnly: true,
            optionToContent: () => table(seriesData),
            buttonColor: 'rgba(255, 255, 255, 1)',
            buttonTextColor: '#F00',
            lang: ['', `<strong>${Vue.i18n.t('general.changeChart')}</strong>`]
          }
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          data: seriesData
        }
      ]
    }
  }
}
