
import template from './modal-add-board.pug'

export default Vue.extend({
  template: template(),
  props: {
    chartsAvailable: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedChart: null
    }
  },
  created() {
    this.selectedChart = this.chartsAvailable.map(item => ({
      ...item
    }))
  },
  methods: {
    t(key, options = {}) {
        return this.$t(`analytics.index.addBoard.${key}`, options)
    },
    cancel() {
        this.$emit('input', false)
    },
    save() {
      this.$emit('selected-chart', this.selectedChart)
    }
  }
})
