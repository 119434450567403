import camelCase from 'lodash/camelCase'
import template from './analytic-model.pug'

export default Vue.extend({
  template: template(),
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // eslint-disable-next-line max-len
      currentTab: `${this.$route.name.split('-')[this.$route.name.split('-').length - 2] }-${ this.$route.name.split('-').pop()}`
    }
  },
  computed: {
    tabsOptions() {
      return ['matrix-model', 'rules-model'].map(item => ({
        value: item,
        label: this.t(`tabs.${camelCase(item)}.tabTitle`)
      }))
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.analyticsModel.${key}`, options)
    },

    getRouteCurrentTab(tab) {
      return {name: `campaigns-show-analytics-model-${tab}`, params: {id: this.campaign.id}}
    },

    setCurrentTab(tab) {
      return this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
