import LeadsAccountsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsAccountsTable
  },
  data() {
    return {
      leads: [],
      customers: [],
      campaigns: [],
      providerLeads: [],
      utmSources: [],
      meta: {}
    }
  },
  created() {
    this.loadCustomers()
    this.loadCampaigns()
    this.loadProviders()
    this.loadCamapignsUtms()
    this.$setPageTitle(this.$t('operationalReport.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.operationalReport.index'), route: {}}
    ])
  },
  methods: {
    loadCustomers() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.customers.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.customers = data
        this.customers.unshift(allData)
      })
    },
    loadProviders() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.providerLeads.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.providerLeads = data
        this.providerLeads.unshift(allData)
      })
    },
    loadCamapignsUtms() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.campaignsUtmSources.index(queryOptions).then(({data}) => {
        const allData = {
          key: '0',
          name: 'Todos'
        }
        this.utmSources = data
        this.utmSources.unshift(allData)
      })
    },
    loadCampaigns() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.campaigns.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.campaigns = data
        this.campaigns.unshift(allData)
      })
    },
    loadLeadsDetails(queryOptions = {page: {size: 100}}) {
        API.operativeReport.index(queryOptions).then(({data, meta}) => {
          this.leads = data
          this.meta = meta
        })
    }
  }
})
