import {pick, cloneDeep, isEqual} from 'lodash'
import {validations} from '../../../../../mocks/customers/factory.js'
import template from './sites.pug'

const DefaultValues = () => Object.freeze({
  sites: [{url: '', description: ''}]
})
const Fields = Object.keys(DefaultValues())
const Validations = pick(validations, Fields)

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    initErrors: {
      type: [Array, Object],
      default: () => []
    },
    disableAccordion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {},
      errors: [],
      // Flag para mostrar si es necesario mostrar un error indicando que es obligatorio añadir
      // al menos una web
      siteRequiredError: false,
      ready: false
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    },

    initErrors: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.initErrors.sites) return

        // gestionamos el error: "sites: required"
        if (this.initErrors.sites instanceof Array) {
          this.errors = cloneDeep(this.initErrors)
          this.siteRequiredError = false
        } else {
          this.errors = []
          this.siteRequiredError = true
        }
      }
    }
  },
  methods: {
    initializeData() {
      const data = {}
      Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
      this.data = data
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`customers.form.${key}`, options)
    },

    addSite() {
      this.data.sites.push({url: '', description: ''})
    },

    deleteSite(index) {
      this.data.sites.splice(index, 1)
    }
  }
})
