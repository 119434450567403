import {mapGetters} from 'vuex'
import {isEmpty} from 'lodash'
import LayoutBus from './layout-bus.js'
import UserMenu from './_menu.js'
import TopBar from './_top-bar'
import Notification from './_notification.js'

import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    TopBar,
    UserMenu,
    Notification
  },
  data() {
    return {
      showUserMenu: false,
      showNotification: false
    }
  },
  computed: {
    ...mapGetters(['pageTitle', 'breadcrumbs']),

    notifications: {
      get() {
        return this.$store.getters.notifications
      },
      set(value) {
        this.$store.commit('updateNotifications', value)
      }
    },

    userAvatarInitials() {
      if (!this.$user) return null

      return this.$user.firstName[0] + this.$user.lastName[0]
    },

    clientInitials() {
      if (!this.$client.name) return null

      return this.$client.name.slice(0, 2).toUpperCase()
    }
  },
  created() {
    LayoutBus.$on('close-sidebar', () => {
      // está feo pero no nos queda otra
      this.$refs.sidebar.showSidebar = false
    })

    LayoutBus.$on('open-sidebar', () => {
      this.$refs.sidebar.showSidebar = true
    })
  },
  methods: {
    // Esta función se utiliza para comprobar que el elemento del breadcrumb debe ser un enlace.
    // Las migas que no tengan ruta o las que esten en la ultima posición no funcionan como enlace
    showBreadcrumbRouterLink(route, index) {
      return !isEmpty(route) && index + 1 < this.breadcrumbs.length
    }
  }
})
