import pick from 'lodash/pick'

import template from './_info.pug'

// Pinta el formulario con la información básica del usuario
// El componente padre asegura que no se pinta este hasta tener cargado $user
export default Vue.extend({
  template: template(),
  data() {
    return {
      data: {
        firstName: '',
        lastName: '',
        role: '',
        phone: '',
        secondPhone: '',
        avatarUrl: '',
        avatar: null
      },
      removeAvatar: false,
      errors: {},
      whitelist: ['jpg', 'png', 'jpeg']
    }
  },
  computed: {
    showInitials() {
      return (!this.$user.avatarUrl && !this.data.avatar) || this.removeAvatar
    },

    userAvatarInitials() {
      if (!this.$user.firstName || !this.$user.lastName) return null

      return this.$user.firstName[0] + this.$user.lastName[0]
    }
  },
  created() {
    this.data = pick(
      this.$user, ['firstName', 'lastName', 'phone', 'secondPhone']
    )
  },
  methods: {
    updateProfile() {
      this.errors = {}

      const data = new FormData()

      Object.keys(this.data).forEach(key => {
        data.append(key, this.data[key])
      })

      // para poder eliminar el avatar hay que usar put enviado el campo a null
      const method = this.removeAvatar ? API.profile.update : API.profile.patch
      method(data).then(() => {
        this.$store.dispatch('loadCurrentUser')
        this.$notifications.success(this.$t('profile.infoSettings.succesfullyUpdated'))
      }, errors => {
        this.errors = errors
      })
    },

    avatarChanged(file) {
      this.removeAvatar = !file
    },

    errorUpload(errors = []) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.$t(`profile.infoSettings.errors.${error}`)
        )
      }
    },

    openAvatarInput() {
      this.$refs.avatarInput.$el.children[0].click()
    }
  }
})
