import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    laiaAccounts: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      filters: {name: '', status: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    statusOptions() {
      return [
        {label: this.t('status.1'), value: '1'},
        {label: this.t('status.2'), value: '2'}
      ]
    },

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  methods: {
    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },
    getClassByStatus(status) {
      switch (status) {
        case 1:
          return 'table-tag--success'
        case 2:
          return 'table-tag--yellow-light'
        default:
          return ''
      }
    },
    getIconByStatus(status) {
      switch (status) {
        case 1:
          return 'check'
        case 2:
          return 'close'
        default:
          return ''
      }
    },
    actived(accountId) {
      API.laiaAccounts.enable(accountId).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyActived'))
      })
    },
    deactived(accountId) {
      API.laiaAccounts.disable(accountId).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeactived'))
      })
    },
    confirmActivation(account) {
      if (account.status === 1) {
        openModalConfirmRemove(
          this.t('activateConfirmation.deactive.title'),
          this.t('activateConfirmation.deactive.body'),
          this.t('activateConfirmation.okButton')
        ).then(() => {
          this.deactived(account.id)
        })
      } else {
        openModalConfirmRemove(
          this.t('activateConfirmation.active.title'),
          this.t('activateConfirmation.active.body'),
          this.t('activateConfirmation.okButton')
        ).then(() => {
          this.actived(account.id)
        })
      }
    },

    t(key, options = {}) {
      return this.$t(`laiaAccounts.${key}`, options)
    }
  }
})
