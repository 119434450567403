import FullTable from 'js/components/full-table/index.js'
import template from './table-provider-comparative.pug'

export default Vue.extend({
  name: 'table-provider-comparative',
  template: template(),
  components: {
    FullTable
  },
  props: {
    items: {
        type: Array,
        required: true
    }
  },
  data() {
    return {
      order: {
        orderBy: null,
        orderDir: null
      },
      data: [],
      tfoot: {
        leadsCount: 0,
        contactabilityPercentage: 0,
        contactsUtilPercentage: 0,
        closedPercentage: 0,
        salesCount: 0
      }
    }
  },
  watch: {
    items() {
      this.setData()
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.data = []
      const auxData = []
      this.items.forEach(items => {
        items.data.forEach(subItem => {
          auxData.push({
            provider: items.provider,
            ...subItem
          })
        })
      })

      this.tfoot.leadsCount = 0;
      this.tfoot.contactabilityPercentage = 0;
      this.tfoot.contactsUtilPercentage = 0;
      this.tfoot.closedPercentage = 0;
      this.tfoot.salesCount = 0;

      auxData.forEach(item => {
        this.tfoot.leadsCount += item.leadsCount
        this.tfoot.contactabilityPercentage += item.contactabilityPercentage
        this.tfoot.contactsUtilPercentage += item.contactsUtilPercentage
        this.tfoot.closedPercentage += item.closedPercentage
        this.tfoot.salesCount += item.salesCount
      })
      if (this.tfoot.contactabilityPercentage > 0 ||
        this.tfoot.contactsUtilPercentage > 0 ||
        this.tfoot.closedPercentage > 0
      ) {
       this.tfoot.contactabilityPercentage /= auxData.length
       this.tfoot.contactsUtilPercentage /= auxData.length
       this.tfoot.closedPercentage /= auxData.length
      }
      this.data = auxData
    },
    changeOrder(params) {
      const filter = Object.keys(params.order)[0]
      this.order.orderBy = filter
      this.order.orderDir = params.order[filter]
      this.applyOrder()
    },
    applyOrder() {
      const dir = this.order.orderDir === 'asc' ? 1 : -1
      if (this.order.orderBy && this.order.orderDir) {
        this.data = this.data.sort((a, b) => {
          const x = a[this.order.orderBy]
          const y = b[this.order.orderBy]
          if (x < y) return dir * -1
          if (x > y) return dir
          return 0
        })
      }
    },
    t(key, params = {}) {
      return this.$i18n.t(`analytics.index.providercomparative.${key}`, params)
    }
  }
})
