import camelCase from 'lodash/camelCase'
import ModalPixelControl from '../_modal-pixel-control.js'
import template from './analytics.pug'

const noDataImg = require('assets/images/content/empty-report.svg')

export default Vue.extend({
  name: 'analytics-tab',
  template: template(),
  components: {
    ModalPixelControl
  },
  props: {
    campaign: {
      type: Object
    }
  },
  data() {
    return {
      noDataImg,
      callCenters: [],
      products: [],
      customer: null,
      pixelControlCampaign: null,
      ready: false,
      currentTab: this.$route.name.split('campaigns-show-analytics-').pop() || 'transformation',
      today: moment().format(FORMAT_DATE),
      optionsMobile: ['transformation', 'tracking', 'audience', 'dash-contact-center'],
      embeddedReport: false
    }
  },
  computed: {
    viewTracking() {
      return Vue.account.has('campaign_tracking')
    },
    viewAudience() {
      return Vue.account.has('campaign_audience')
    },
    viewTransformation() {
      return Vue.account.has('campaign_transformation')
    },
    // se usan para el select en versión movil
    tabsOptions() {
      return this.optionsMobile.map(item => ({
        value: item,
        label: this.t(`tabs.${camelCase(item)}.tabTitle`)
      }))
    },

    // invertimos el hash para buscar el key del valor de forma facil
    // esto lo utilizamos para montar la traducción con el key
    statusesKeys() {
      const keys = {}

      Object.keys(CAMPAIGN_STATUSES).forEach(key => {
        keys[CAMPAIGN_STATUSES[key]] = key
      })

      return keys
    },

    isTodayBeforeStart() {
      return moment().isBefore(moment(this.campaign.startAt, FORMAT_DATE))
    },

    isTodayAfterEndStart() {
      return moment().isAfter(moment(this.campaign.endAt, FORMAT_DATE))
    },

    // elementos de la línea temporal en el orden que tienen que aparecer
    timelineInfo() {
      const today = {date: this.today, text: this.t('datesInfo.actualDate')}
      const start = {date: this.campaign.startAt, text: this.t('datesInfo.startAt')}
      const end = {date: this.campaign.endAt, text: this.t('datesInfo.endAt')}

      if (this.isTodayBeforeStart) return [today, start, end]
      if (this.isTodayAfterEndStart) return [start, end, today]
      return [start, today, end]
    }
  },
  async created() {
    await this.loadCallCenters()
    await this.loadProducts()
    await this.loadCustomer()
    await this.loadEmbeddedReport()
    this.ready = true
  },
  methods: {
    async loadCallCenters() {
      const callCentersId = this.campaign.callCenters.map(item => item.id)
      return API.callCenters.index({filter: {id: callCentersId}}).then(({data}) => {
        this.callCenters = data
      })
    },

    async loadProducts() {
      return API.products.index({filter: {id: this.campaign.productIds}}).then(({data}) => {
        this.products = data
      })
    },

    async loadEmbeddedReport() {
      API.campaigns.embeddedreport.index(this.campaign.id).then(({data}) => {
        if (data.url) {
          this.embeddedReport = true
          this.optionsMobile = ['transformation', 'tracking', 'audience', 'dash-contact-center']
        } else {
          this.embeddedReport = false
          this.optionsMobile = ['transformation', 'tracking', 'audience']
        }
      })
    },

    async loadCustomer() {
      return API.customers.show(this.campaign.customerId).then(response => {
        this.customer = response
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.analytics.${key}`, options)
    },

    getMessageClassByStatus(status) {
      switch (status) {
        case CAMPAIGN_STATUSES.ACTIVE:
          return 'status-active'
        case CAMPAIGN_STATUSES.INACTIVE:
          return 'status-inactive'
        case CAMPAIGN_STATUSES.FINISHED:
          return 'status-finished'
        default:
          return ''
      }
    },

    getClassByStatus(status) {
      switch (status) {
        case CAMPAIGN_STATUSES.CREATION:
          return 'table-tag--yellow-light'
        case CAMPAIGN_STATUSES.ACTIVE:
          return 'table-tag--success'
        case CAMPAIGN_STATUSES.INACTIVE:
          return 'table-tag--gray'
        case CAMPAIGN_STATUSES.FINISHED:
          return 'table-tag--blue'
        default:
          return ''
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case CAMPAIGN_STATUSES.CREATION:
          return 'clock'
        case CAMPAIGN_STATUSES.ACTIVE:
          return 'check'
        case CAMPAIGN_STATUSES.INACTIVE:
          return 'close'
        case CAMPAIGN_STATUSES.FINISHED:
          return 'ban'
        default:
          return ''
      }
    },

    getMessageByStatus(status) {
      switch (status) {
        case CAMPAIGN_STATUSES.ACTIVE:
          return this.t(
            'status.active',
            {number: moment(this.campaign.endAt, FORMAT_DATE).diff(moment(), 'days')}
          )
        case CAMPAIGN_STATUSES.INACTIVE:
          return this.t(
            'status.inactive',
            {number: moment(this.campaign.startAt, FORMAT_DATE).diff(moment(), 'days')}
          )
        case CAMPAIGN_STATUSES.FINISHED:
          return this.t('status.finished', {date: this.campaign.endAt})
        default:
          return ''
      }
    },

    isInactiveCampaign() {
      return this.campaign.status === CAMPAIGN_STATUSES.INACTIVE
    },

    // para cambiar de pestaña desde la versión movil
    setCurrentTab(tab) {
      this.$router.push({name: `campaigns-show-analytics-${tab}`, params: {id: this.campaign.id}})
    }
  }
})
