import template from './index.pug'

// Layout de las páginas de inicio de sesión como login, olvidé mi contraseña...
export default {
  template: template(),
  computed: {
    notifications: {
      get() {
        return this.$store.getters.notifications
      },
      set(value) {
        this.$store.commit('updateNotifications', value)
      }
    }
  }
}
