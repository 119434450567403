import Vue from 'vue'
import {openModalConfirm} from 'js/services/modals'
import template from './sort-toggler.pug'

export default Vue.extend({
  template: template(),
  props: {
      value: {
          // currentOptions: [0, 1]
          type: Number,
          required: true
      }
  },
  computed: {
      selectedSort: {
          get() {
              return this.value
          },
          set(newValue) {
              this.$emit('input', newValue)
          }
      }
  },
  methods: {
      showAlertDialog() {
          openModalConfirm(
              'Posibles dificultades en la visualización',
              'Es posible que esta combinación de campos dificulte\n' +
              'la interpretación de los datos representados en las gráficas',
              'OK',
              {
                customClass: 'alert-message-modal'
              }
        )
      },
      toggleSortOption(newVal) {
          this.selectedSort = newVal
      }
  }
})
