import template from './_password.pug'

export default Vue.extend({
  template: template(),
  data() {
    return {
      data: {
        password: '',
        currentPassword: '',
        confirmPassword: ''
      },
      errors: {}
    }
  },
  methods: {
    updatePassword() {
      this.errors = {}

      API.profile.updatePassword(this.data).then(() => {
        this.$notifications.success(this.$i18n.t('profile.passwordSettings.succesfullyUpdated'))
        this.data.password = ''
        this.data.currentPassword = ''
        this.data.confirmPassword = ''
      }, errors => {
        this.errors = errors
      })
    }
  }
})
