export default {
  errorsRootKey: 'analytics/reports/codificationsummary',
  resource: 'analytics/reports/codificationsummary',
  actions: ['show', 'create', 'update', 'destroy'],

  exportTipificationReport(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/reports/codificationsummary/export',
      {params, ...options}
    ).then(response => response.data)
  },
  customIndex(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/reports/codificationsummary',
      {params, ...options}
    ).then(response => response.data)
  }
}
