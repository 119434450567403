import RouteParent from 'js/router/route-parent'

import CustomersCampaignsIndex from 'js/pages/campaigns/customer-index.js'
import CustomersProductsIndex from 'js/pages/products/customer-index.js'
import CustomersCallCentersIndex from 'js/pages/call-centers/customer-index.js'

import CustomersIndex from 'js/pages/customers/index.js'
import CustomersNew from 'js/pages/customers/new.js'
import CustomersEdit from 'js/pages/customers/edit.js'
import CustomersEditFbLeads from 'js/pages/customers/integration/facebook/index.js'
import CustomersAdwords from 'js/pages/customers/integration/adwords/index.js'
import CustomersAds from 'js/pages/customers/integration/ads/index.js'
import CustomersAibe from 'js/pages/customers/integration/aibe/wizard.js'
import CustomersEditTiktokLeads from 'js/pages/customers/integration/tiktok/index.js'
import CustomersEditSMSlinkMobility from 'js/pages/customers/integration/link-mobility/index.js'
// eslint-disable-next-line max-len
import CustomersEditEmaillinkMobility from 'js/pages/customers/integration/email-link-mobility/index.js'
import CustomersShow from 'js/pages/customers/show.js'

import CustomersShowInfo from 'js/pages/customers/show/info-tab.js'
import CustomersShowTracking from 'js/pages/customers/show/tracking-tab.js'
import CustomersShowTransform from 'js/pages/customers/show/transformation-tab.js'
import CustormersLeadsCount from 'js/pages/customers/leads-details/index.js'

export default [
  {
    path: '/customers',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: ':id/campaigns',
        name: 'customer-campaigns',
        component: CustomersCampaignsIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CUSTOMERS', 'LIST_CAMPAIGNS']
        }
      },
      {
        path: ':id/call-centers',
        name: 'customer-call-centers',
        component: CustomersCallCentersIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CUSTOMERS', 'VIEW_CALL_CENTERS']
        }
      },
      {
        path: ':id/products',
        name: 'customer-products',
        component: CustomersProductsIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CUSTOMERS', 'VIEW_PRODUCTS']
        }
      },
      {
        path: '',
        name: 'customers-index',
        component: CustomersIndex,
        meta: {
          auth: true,
          permit: ['VIEW_CUSTOMERS']
        }
      },
      {
        path: 'new',
        name: 'customers-new',
        component: CustomersNew,
        meta: {
          auth: true,
          permit: ['CREATE_CUSTOMERS']
        }
      },
      {
        path: ':id/edit',
        name: 'customers-edit',
        component: CustomersEdit,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id/edit/fbleads',
        name: 'customers-edit-fb-leads',
        component: CustomersEditFbLeads,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id/aibe',
        name: 'customers-aibe',
        component: CustomersAibe,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id/adwords',
        name: 'customers-adwords',
        component: CustomersAdwords,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id/ads-lead-form',
        name: 'customers-ads',
        component: CustomersAds,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id/edit/tiktokleads',
        name: 'customers-edit-tiktok-leads',
        component: CustomersEditTiktokLeads,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id/edit/sms-link-mobility',
        name: 'customers-edit-sms-link-mobility',
        component: CustomersEditSMSlinkMobility,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id/edit/email-link-mobility',
        name: 'customers-edit-email-link-mobility',
        component: CustomersEditEmaillinkMobility,
        meta: {
          auth: true,
          permit: ['EDIT_CUSTOMERS']
        }
      },
      {
        path: ':id',
        component: CustomersShow,
        meta: {
          auth: true,
          permissions: [PERMISSIONS.VIEW_CUSTOMERS]
        },
        children: [
          {
            path: '',
            name: 'customers-show-info',
            component: CustomersShowInfo,
            meta: {
              auth: true,
              permit: ['VIEW_CUSTOMERS']
            }
          },
          {
            path: 'tracking',
            name: 'customers-show-tracking',
            component: CustomersShowTracking,
            meta: {
              auth: true,
              permit: ['VIEW_CUSTOMERS']
            }
          },
          {
            path: 'transform',
            name: 'customers-show-transform',
            component: CustomersShowTransform,
            meta: {
              auth: true,
              permit: ['VIEW_CUSTOMERS']
            }
          }
        ]
      },
      {
        path: ':id/leads',
        name: 'customers-leads-count',
        component: CustormersLeadsCount,
        meta: {
          auth: true,
          permissions: [PERMISSIONS.VIEW_CUSTOMERS]
        }
      }
    ]
  }
]
