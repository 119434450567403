import {mapGetters} from 'vuex'
import template from './_form.pug'

const Fields = [
  'name', 'description', 'template'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      customers: [],
      url: '',
      tagSelected: '',
      tags: [],
      textTest: '',
      showModalTres: false,
      totalChars: 160
    }
  },
  computed: {
    ...mapGetters(['countries']),

    countryOptions() {
      return this.countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    },
    customerOptions() {
      return this.customers.map(customer => ({
        label: customer.name,
        value: customer.id
      }))
    },
    lengthText() {
      return this.data.template.length
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
        this.getTags()
      }
    }
  },
  created() {

  },
  methods: {

    insertLink() {
      this.data.template = `${this.data.template} [url=${this.url}]`
      this.closeTres()
    },

    insertTag() {
      this.data.template = `${this.data.template} $lead.${this.tagSelected}`
    },

    insertTag() {
      this.$refs.textarea1.focus()
      const cursorPosition = this.$refs.textarea1.selectionStart
      this.$refs.textarea1.setSelectionRange(cursorPosition, cursorPosition)
      const textoActual = this.$refs.textarea1.value
      // eslint-disable-next-line max-len
      this.data.template = `${textoActual.substring(0, cursorPosition)}$lead.${this.tagSelected}${textoActual.substring(cursorPosition)}`
    },

    showTres() {
      this.showModalTres = true
    },

    closeTres() {
      this.showModalTres = false
    },

    acceptTres() {
      this.closeTres()
      alert('Modal tres aceptado'); // eslint-disable-line
    },

    getTags() {
      API.tags.all({page: {number: 1, size: 1000}}).then(({data}) => {
        this.tags = data.map(tag => ({
          label: tag.name,
          value: tag.type+'.'+tag.key
        }))
      })
    },

    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data
    },

    t(key, options = {}) {
      return this.$t(`smsTemplates.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    },

    errorUpload(errors = []) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`fields.logo.errors.${error}`)
        )
      }
    }
  }
})
