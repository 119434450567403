import PoolForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    PoolForm
  },
  data() {
    return {
      lastPath: null,
      lastPathParams: {},
      errors: {}
    }
  },

  created() {
    this.$setPageTitle(this.$t('pool.new.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.pool.index'), route: {name: 'pool-index'}},
      {label: this.$t('breadcrumbs.pool.new'), route: {}}
    ])
  },
  methods: {
    createPool(data) {
      API.pool.create(data).then(() => {
        this.$notifications.success(this.$t('pool.new.succesfullyCreated'))

        this.$router.push({
          name: this.lastPath || 'pool-index',
          params: this.lastPathParams
        })
      }, errors => {
        this.errors = errors
      })
    }
  }
})
