import template from './_template.pug'

export default Vue.extend({
  template: template(),
  computed: {
    validated() {
      return this.$store.getters.hasCurrentUser
    }
  }
})
