import RouteParent from 'js/router/route-parent'

import staticPhoneIndex from 'js/pages/static-phone/index.js'
import staticPhoneNew from 'js/pages/static-phone/new.js'
import staticPhoneEdit from 'js/pages/static-phone/edit.js'

export default [
  {
    path: '/static-phone',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'static-phone-index',
        component: staticPhoneIndex,
        meta: {
          auth: true

        }
      },
      {
        path: 'new',
        name: 'static-phone-new',
        component: staticPhoneNew,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit',
        name: 'static-phone-edit',
        component: staticPhoneEdit,
        meta: {
          auth: true
        }
      }
    ]
  }
]
