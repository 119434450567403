import {mapGetters} from 'vuex'
import template from './_form.pug'

const Fields = [
  'name', 'address', 'postalCode', 'city', 'country', 'email', 'phone', 'ctiIds', 'logo'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      removeAvatar: false,
      whitelist: ['jpg', 'png', 'jpeg'],
      ctisSelectOptions: []
    }
  },
  computed: {
    ...mapGetters(['countries']),

    countryOptions() {
      return this.countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    },

    showInitials() {
      return (!this.initData?.logoUrl && !this.data.logo) || this.removeAvatar
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadCtis()
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data
    },

    loadCtis() {
      API.ctis.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.ctisSelectOptions = data.map(product => ({
          label: product.name,
          value: product.id
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`callCenters.form.${key}`, options)
    },

    submit() {
      const data = new FormData()

      Object.keys(this.data).forEach(key => {
        if (Array.isArray(this.data[key])) {
          this.data[key].forEach(element => data.append(`${key}[]`, element))
        } else {
          data.append(key, this.data[key])
        }
      })

      this.$emit('submit', data)
    },

    cancel() {
      this.$emit('cancel')
    },

    logoChanged(file) {
      this.removeAvatar = !file
    },

    errorUpload(errors = []) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`fields.logo.errors.${error}`)
        )
      }
    },

    openLogoInput() {
      this.$refs.logoInput.$el.children[0].click()
    }
  }
})
