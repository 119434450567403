// Todos los usuarios pertenecen a una cuenta
export default {
  state: {
    currentAccount: null
  },
  getters: {
    currentAccount: state => state.currentAccount || {}
  },
  mutations: {
    setCurrentAccount(state, client) {
      state.currentAccount = client
    }
  },
  actions: {
    loadCurrentAccount({commit}, id) {
      return API.accounts.show(id, {representation: 'basic'}).then(data => {
        commit('setCurrentAccount', data)
        return data
      })
    }
  }
}
