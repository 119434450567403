import template from './logout.pug'

/**
 * Este componente es necesario porque anteriormente haciamos this.$auth.logout desde el layout,
 * sin embargo si esa vista requeria de algo del usuario o tenia que desuscribirse de algun canal de
 * ws, el beforeDestroy del componente se ejecutaba DESPUES de haber hecho logout resultando en
 * errores, para minimizar futuros errores se ha decidido crear este componente que permita
 * respetar el beforeDestroy de cada componente
 * */
export default Vue.extend({
  template: template(),
  created() {
    this.$auth.logout()
  }
})
