export default {
  errorsRootKey: 'tiktok',
  resource: 'tiktok',
  actions: ['create', 'index', 'destroy','patch'],
  pages(authCode, params = {}, options = {}) {
    return Vue.http.get(
      `tiktok/${authCode}/pages`,
      {params, ...options}
    ).then(response => response.data)
  },
}
