import {simulateValidateBackend} from '../../../mocks/helpers'
import translateErrors from './translate-errors'

const validateForm = (validations, data, errorsRootKey) => {
  const result = simulateValidateBackend(validations, data)

  if (!result.body?.errors) return null

  const {errors} = translateErrors(result.body.errors, errorsRootKey)
  return errors
}

export default validateForm
