import {mapGetters} from 'vuex'
import template from './modal-tracking-number.pug'

export default Vue.extend({
  template: template(),

  props: {},
  data() {
    return {
      data: {
        name: '',
        phone: '',
        email: '',
        requestNumberCount: '',
        country: '',
        description: ''
      },
      errors: {}
    }
  },
  computed: {
    ...mapGetters(['countries']),

    countriesOptions() {
      return this.countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`pool.modalTracking.${key}`, options)
    },
    submit() {
      this.errors = {}
      API.trackingNumbers.request(this.data).then(() => {
          this.$notifications.success(this.t('succesfullyRequest'))
          this.cancel()
      }, errors => {
          this.errors = errors
      })
    },
    cancel() {
      this.$emit('input', false)
    }
  }
})
