const copyTextFromElementById = elementId => {
  const copyText = document.getElementById(elementId)
  let isCopiedText = false

  if (!copyText) return isCopiedText

  if (copyText.tagName.toLowerCase() === 'input') {
    // Se realiza cuando el elemento es un input
    copyText.select() // Selecciona el file
    copyText.setSelectionRange(0, 99999) // Selecciona el rango completo (movil)
    isCopiedText = document.execCommand('copy') // Copia el texto seleccionado
  } else {
    const selection = window.getSelection()
    const range = document.createRange()

    range.selectNode(copyText) // Creamos el rango de lo que seleccionaremos
    selection.removeAllRanges() // Borra selecciones antiguas
    selection.addRange(range) // Selecciona el nuevo texto
    isCopiedText = document.execCommand('copy') // Copia el texto seleccionado
    selection.removeAllRanges() // Borra la seleccion
  }

  return isCopiedText // Copy the text inside the text field
}

export default copyTextFromElementById
