import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    trackingSources: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      showFilters: true,
      filters: { 
        name: '',
        type: ''
      },
      typeOptions:[
        {value: '', label: this.$t("trackingSources.typeOptions.all.label")},
        {value: this.$t("trackingSources.typeOptions.dynamic.value"), label: this.$t("trackingSources.typeOptions.dynamic.label")},
        {value: this.$t("trackingSources.typeOptions.static.value"), label: this.$t("trackingSources.typeOptions.static.label")},
      ],
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_CALL_CENTERS')
    },
    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
    this.ready = true
  },
  methods: {

    openConfirmModal(customer) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: customer.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(customer))
    },

    t(key, options = {}) {
      return this.$t(`trackingSources.index.${key}`, options)
    },
    t_g(key, options = {}) {
      return this.$t(`trackingSources.${key}`, options)
    },

    destroy(object) {
      API.trackingSources.destroy(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },
  }
})
