
// import {pickBy, cloneDeep} from 'lodash'
// import Pagination from 'js/components/pagination-default/index.js'
import template from './filter-wrapper.pug'

// Gestiona el objeto con los parámetros de la consulta y lo envía mediante el evento 'load'
// Es utilizado por ejemplo en el listado de usuarios
export default Vue.extend({
  template: template(),
  components: {
    // Pagination
  },
  data() {
    return {
      appliedFilters: false,
      filterData: {
        initialDate: {
          label: 'Fecha Inicial',
          placeholder: 'Seleccionar',
          component: 'ns-datepicker',
          value: null
        },
        endDate: {
          label: 'Fecha Final',
          placeholder: 'Seleccionar',
          component: 'ns-datepicker',
          value: null
        },
        sortToggler: {
          label: '',
          component: 'sort-toggler',
          value: 0
        },
        // useCases: {
        //   label: 'Casos de uso',
        //   placeholder: 'Casos de uso',
        //   component: 'ns-select',
        //   value: null,
        //   options: [
        //     {label: 'Cero', value: 0},
        //     {label: 'Uno', value: 1},
        //     {label: 'Dos', value: 2},
        //     {label: 'Tres', value: 3},
        //     {label: 'Cuatro', value: 4}
        //   ]
        // }
      },
      orderData: {},
      showFilters: true
    }
  },
  computed: {
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  methods: {

    cleanFilters() {
      const filterEntries = Object.entries(this.filterData)

      filterEntries.forEach(([key, _]) => {
        this.filterData[key].value = null
      })

      console.log(this.filterData)
    },
    
    applyFilters() {
      console.log('apply filters')
      console.log(this.filterData)
    },

    createChart() {
      console.log('create chart')
    }
  }
})

