import {openModalConfirmRemove} from 'js/services/modals.js'
// import FormForm from './_form-form.js'
import draggable from 'vuedraggable'
import template from './index.pug'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')
const arrowImage = require('assets/images/logos/arrow.svg')

export default Vue.extend({
  template: template(),
  components: {
    openModalConfirmRemove,
    draggable
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      arrow: arrowImage,
      titleFormData: '',
      selectOperators: [
        {
          label: 'Igual',
          value: 'equal'
        },
        {
          label: 'Mayor que',
          value: 'greaterThan'
        },
        {
          label: 'Menor que',
          value: 'lessThan'
        },
        {
          label: 'Entre',
          value: 'between'
        },
        {
          label: 'No está en',
          value: 'notIn'
        },
        {
          label: 'Mayor o igual que',
          value: 'greaterEqualThan'
        },
        {
          label: 'Menor o igual que',
          value: 'lessEqualThan'
        }
      ],
      forms: [],
      tagSelected: '',
      tags: [],
      rulesData: {},
      formData: [],
      formErrors: {},
      showForm: false
    }
  },
  computed: {
    tagsOptions() {
      return this.tags.map(el => ({
        label: el.key,
        value: el.key
      }))
    }
  },
  watch: {
  },
  created() {
    this.loadData()
    this.loadTags()
  },
  methods: {
    saveRulesData(onDelete = false) {
      API.campaigns.rulesModule.create(this.campaign.id, this.rulesData).then(() => {
        if (!onDelete) {
          this.$notifications.success(this.t('succesfullyCreated'))
        } else {
          this.$notifications.success(this.t('succesfullyDeleted'))
        }
        this.closeForm()
      }, errors => {
        this.formErrors = errors
      })
    },
    dragEvent() {
      this.rulesData.structure.forEach((el, i) => {
        this.rulesData.structure[i].order = i + 1
      })
    },
    addRule(keyName = '') {
      this.formData.push({
        key: keyName,
        operator: '',
        value: '',
        mappingValue: '',
        valueMin: 0,
        valueMax: 0
      })
    },
    deleteRule(index) {
      if (this.formData.length > 1) {
        this.formData.splice(index, 1)
      }
    },
    deleteKey(index) {
      openModalConfirmRemove(
        this.t('deleteConfirmationModal.title'),
        this.t('deleteConfirmationModal.body'),
        this.t('deleteConfirmationModal.okButton')
      ).then(() => {
          // eslint-disable-next-line max-len
          this.rulesData.rules = this.rulesData.rules.filter(el => el.key !== this.rulesData.structure[index].key)
          this.rulesData.structure.splice(index, 1)
          this.rulesData.structure.forEach((el, i) => {
            this.rulesData.structure[i].order = i + 1
          })
          this.saveRulesData(true)
      })
    },
    loadData() {
      API.campaigns.rulesModule.index(
        this.campaign.id,
        {order: {title: 'asc'}, page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.rulesData = data
      })
    },
    loadTags() {
      API.campaigns.tags.index(
        this.campaign.id,
        {order: {title: 'asc'}, page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.tags = data
      })
    },

    openNewForm() {
      // eslint-disable-next-line max-len
      if (!this.rulesData.structure.find(el => el.key === this.tagSelected) && this.tagSelected !== '') {
        this.rulesData.structure.push({
          key: this.tagSelected,
          order: this.rulesData.structure.length + 1
        })
        const setKey = this.rulesData.structure.find(el => el.key === this.tagSelected)
        this.addRule(setKey)
        this.titleFormData = this.tagSelected
        this.showForm = true
      } else {
        this.$notifications.error(this.t('errorNewForm'))
      }
    },

    openEditForm(item) {
      this.formData = this.rulesData.rules.filter(el => el.key === item.key)
      this.titleFormData = item.key
      this.showForm = true
    },

    closeForm() {
      if (this.formData.find(el => !el.key || !el.mappingValue || !el.operator)) {
        // eslint-disable-next-line max-len
        this.rulesData.structure = this.rulesData.structure.filter(el => el.key !== this.titleFormData)
      }
      this.formData = []
      this.formErrors = []
      this.tagSelected = ''
      this.showForm = false
    },

    saveForm() {
      if (this.formData.find(el => !el.mappingValue || !el.operator)) {
        this.$notifications.error(this.t('errorEditForm'))
      } else {
        this.formData.forEach((el, i) => {
          this.formData[i].key = this.titleFormData
        })

        const keysToDelete = this.formData.map(el => (el.key))
        this.rulesData.rules = this.rulesData.rules.filter(el => !keysToDelete.includes(el.key))

        this.formData.forEach(el => {
          this.rulesData.rules.push(el)
        })

        this.formData = []
        this.tagSelected = ''
        this.showForm = false
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.analyticsModel.tabs.rulesModel.index.${key}`, options)
    }
  }
})
