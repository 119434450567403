import RouteParent from 'js/router/route-parent'

import poolIndex from 'js/pages/pool/index.js'
import poolNew from 'js/pages/pool/new.js'
import poolEdit from 'js/pages/pool/edit.js'
import poolEditTrackingNumber from 'js/pages/pool/tracking-number.js'

export default [
  {
    path: '/pool',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'pool-index',
        component: poolIndex,
        meta: {
          auth: true

        }
      },
      {
        path: 'new',
        name: 'pool-new',
        component: poolNew,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit',
        name: 'pool-edit',
        component: poolEdit,
        meta: {
          auth: true
        }
      },
      {
        path: ':id/edit/tracking-number',
        name: 'pool-edit-tracking-number',
        component: poolEditTrackingNumber,
        meta: {
          auth: true
        }
      }
    ]
  }
]
