import camelCase from 'lodash/camelCase'
import template from './root.pug'

const TABS = {
  'log-lead': 'log-lead',
  'log-deleted': 'log-deleted',
  'log-accepted-policy': 'log-accepted-policy'
}
export default Vue.extend({
  name: 'invoice-root',
  template: template(),
  data() {
    return {
      data: {},
      ready: false,
      currentTab: 'invoicing-index'
    }
  },
  computed: {
    tabsOptions() {
      return Object.entries(TABS).map(([_item, value]) => ({
        value: camelCase(value),
        label: this.t(`${value}.title`)
      }))
    },
    showTabs() {
      return TABS[this.$route.name] || false
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler() {
        this.currentTab = this.$route.name
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    getRouteCurrentTab(route) {
      return {name: route}
    },

    loadData() {
        this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`logs.${key}`, options)
    },

    setCurrentTab(tab) {
      return this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
