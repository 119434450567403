import {isEqual} from 'lodash'
import ProviderFormModal from './_provider_form_modal.js'
import template from './campaign-form.pug'

const DefaultValues = () => Object.freeze({
  campaign: {
    name: '',
    description: ''
  },
  providerId: [],
})

const Fields = Object.keys(DefaultValues())

const Validations = {
  campaign: {
    type: 'object',
    required: true,
    objectValidations: {
      name: {type: 'string', required: true},
      description: {type: 'string', required: true}
    }
  },
  providerId: {required: true, type: 'array'}
}

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  components: {
    ProviderFormModal
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {},
      ready: false,
      providerLeadOptions: [],
      showProviderFormModal: false
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    }
  },
  created() {
    this.loadProviderLeads()
  },
  methods: {
    initializeData() {
      const data = {}
      Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
      this.data = data
      this.ready = true
    },

    openFormProviderModal() {
      this.showProviderFormModal = true
    },

    closeFormProviderModal() {
      this.showProviderFormModal = false
    },

    t(key, options = {}) {
      return this.$t(`customers.aibe.form.${key}`, options)
    },

    loadProviderLeads() {
      this.providerLeadOptions = []
      API.providerLeads.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.providerLeadOptions = data.map(item => ({
          value: item.id,
          label: item.name
        }))
      })
    }
  }
})
