import orderBy from 'lodash/orderBy'
import CdimensionForm from './_cdimension-form.js'
import template from './cdimensions-index.pug'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')

export default Vue.extend({
  template: template(),
  components: {
    CdimensionForm
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      cdimensions: [],
      cdimensionFormData: {},
      cdimensionFormErrors: {},
      showForm: false
    }
  },
  computed: {
    campaignId() {
      return this.campaign.id
    },

    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },

    formMode() {
      return this.cdimensionFormData.id ? 'edit' : 'new'
    }
  },
  async created() {
    await this.loadCdimensions()
    this.ready = true
  },
  methods: {
    async loadCdimensions() {
      API.campaigns.cdimensions.index(
        this.campaignId,
        {order: {name: 'asc'}, page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.cdimensions = data
      })
    },

    changeOrder(field, dir) {
      this.cdimensions = orderBy(
        this.cdimensions,
        item => item[field].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
        dir
      )
    },

    openNewForm() {
      this.cdimensionFormData = {
        campaignId: this.campaignId
      }
      this.showForm = true
    },

    openEditForm(item) {
      this.cdimensionFormData = {
        id: item.id,
        name: item.name,
        sourceLocation: item.sourceLocation,
        sourceKey: item.sourceKey,
        fieldType: item.fieldType,
        leadField: item.leadField,
        campaignId: item.campaignId
      }

      this.showForm = true
    },

    closeModal() {
      this.cdimensionFormData = {}
      this.cdimensionFormErrors = {}
      this.showForm = false
    },

    saveCdimension(data) {
      this.cdimensionFormErrors = {}

      if (this.formMode === 'edit') {
        this.updateCdimension(data)
      } else {
        this.createCdimension(data)
      }
    },

    updateCdimension(data) {
      API.campaigns.cdimensions.update(data.campaignId, data.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.loadCdimensions()
        this.closeModal()
      }, errors => {
        this.cdimensionFormErrors = errors
      })
    },

    createCdimension(data) {
      API.campaigns.cdimensions.create(data.campaignId, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.loadCdimensions()
        this.closeModal()
      }, errors => {
        this.cdimensionFormErrors = errors
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.config.tabs.cdimensions.${key}`, options)
    }
  }
})
