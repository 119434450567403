import RouteParent from 'js/router/route-parent'

import NotificationIndex from 'js/pages/notifications/index.js'
import NotificationNew from 'js/pages/notifications/new.js'
import NotificationEdit from 'js/pages/notifications/edit.js'

export default [
  {
    path: '/notifications',
    component: RouteParent,
    meta: {
      auth: true,
      permit: ['MANAGE_LAIA_ADMIN']
    },
    children: [
      {
        path: '',
        name: 'notifications-index',
        component: NotificationIndex,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: 'new',
        name: 'notifications-new',
        component: NotificationNew,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: ':id/edit',
        name: 'notifications-edit',
        component: NotificationEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      }
    ]
  }
]
