import {cloneDeep} from 'lodash'
import template from './complete.pug'
import copyTextFromElementById from 'js/services/copy-text-from-html.js'

export default Vue.extend({
  template: template(),
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {},
      ready: true,
      idCopied: [],
    }
  },
  created() {
    this.initializeData()
  },

  methods: {
    initializeData() {
      this.data = cloneDeep(this.value)
    },

    t(key, options = {}) {
      return this.$t(`customers.aibe.form.${key}`, options)
    },

    existIdCopied(domId) {
      return this.idCopied.includes(domId)
    },

    copyPixelControl(domId) {
      this.idCopied.push(domId)
      if (copyTextFromElementById(domId)) {
        this.$notifications.success(this.t('succesfullyCopied'))
      } else {
        this.$notifications.error(this.t('errorOnCopy'))
      }
    },

  }
})
