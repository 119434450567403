import template from './_modal-save-template.pug'

// componente para crear o editar un template
// si el templateId no esta vacio salen 3 botones para comprobar si se quiere
// editar, crear o cancelar. Si le damos a editar nos sale el "form", si no emite lo elegido
export default Vue.extend({
  template: template(),
  props: {
    templateId: {
      type: String
    }
  },
  data() {
    return {
      mode: this.templateId ? 'update' : 'create',
      data: {
        name: null
      },
      errors: {}
    }
  },
  computed: {
    isCreating() {
      return this.mode === 'create'
    }
  },
  methods: {
    create() {
      if (this.data.name) {
        this.$emit('create', this.data.name)
      } else {
        this.errors = {name: this.$t('errors.blank')}
      }
    }
  }
})
