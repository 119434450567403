import template from './_form.pug'

const Fields = [
  'description',
  'trackingNumberId',
  'utmId',
  'utmCampaign',
  'utmSource',
  'utmMedium',
  'utmContent',
  'utmTerm'
]

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    }
  },
  data() {
    return {
      data: {},
      trackingNumberOptions: []
    }
  },
  computed: {
    isEditMode() {
      return this.initData !== null
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadTrackingNumbers()
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })

      this.data = data
    },

    loadTrackingNumbers() {
      API.trackingNumbers.available({page: {number: 1, size: 1000}}).then(({data}) => {
        this.trackingNumberOptions = data.map(tracking => ({
          label: tracking.number,
          value: tracking.id
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`staticPhone.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$emit('cancel')
    }
  }
})
