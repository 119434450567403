import Login from 'js/pages/auth/login.js'
import Logout from 'js/pages/auth/logout.js'
import ResetPassword from 'js/pages/auth/reset-password.js'
import ForgotPassword from 'js/pages/auth/forgot-password.js'
import AcceptInvitation from 'js/pages/auth/accept-invitation.js'
import ConfirmEmail from 'js/pages/auth/confirm-email.js'

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      layout: 'auth',
      auth: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'auth',
      auth: false
    }
  },
  {
    path: '/accept-invitation',
    name: 'accept-invitation',
    component: AcceptInvitation,
    meta: {
      layout: 'auth'
    }
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: ConfirmEmail,
    meta: {
      layout: 'auth'
    }
  }
]
