import CtiConfigForm from './_form'
import template from './edit.pug'

export default Vue.extend({
  template: template(),
  components: {
    CtiConfigForm
  },
  data() {
    return {
      ready: false,
      data: {},
      errors: {},
      ctisOptions: []
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('callCenters.show.ctiConfigs.title'))
        this.loadCtis()
        this.loadData()
      }
    }

  },

  methods: {
    loadCtis() {
      return API.ctis.index({page: {number: 1, size: 1000}}).then(({data}) => {
        data.forEach(cti => { this.ctisOptions[cti.id] = cti })
      })
    },

    loadData() {
      this.ready = false

      // eslint-disable-next-line max-len
      API.callCenters.ctiSetting.index(this.$route.params.id_call_center, this.$route.params.id).then(({data}) => {
        this.data = data
        this.ready = true

        this.$setBreadcrumbs([
          // eslint-disable-next-line max-len
          {label: this.$t('breadcrumbs.callCenters.show'), route: {name: 'call-centers-show', params: {id: this.$route.params.id_call_center}}},
          // eslint-disable-next-line max-len
          {label: this.$t('breadcrumbs.callCenters.ctiConfig', {name: this.ctisOptions[this.$route.params.id].name}), route: {}}
        ])
      })
    },

    updateCtiConfig(data) {
      // eslint-disable-next-line max-len
      API.callCenters.ctiSetting.update(this.$route.params.id_call_center, this.$route.params.id, data).then(() => {
        this.$notifications.success(this.$t('callCenters.edit.succesfullyUpdated'))
        // eslint-disable-next-line max-len
        this.$router.push({name: 'call-centers-show', params: {id: this.$route.params.id_call_center}})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
