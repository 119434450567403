
import template from './modal-laia-token.pug'

export default Vue.extend({
    template: template(),
    props: {
        formSelected: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            campaigns: []
        }
    },
    mounted() {
        this.loadCampaigns()
    },
    methods: {
        t(key, options = {}) {
            return this.$t(`customers.facebook.modal-laia.${key}`, options)
        },
        loadCampaigns() {
            API.customers.campaigns.index(this.$route.params.id).then(response => {
                this.campaigns = response.data
            })
        },
        selectCampaign(campaign) {
            const data = {
                id: this.formSelected.id,
                customerId: this.formSelected.customerId,
                campaignSiteId: campaign.campaignSiteId
            }
            API.facebook.patch(this.formSelected.id, data).then(() => {
                this.$notifications.success(this.t('succesfullyUpdated'))
                this.$emit('load-data')
                this.cancel()
              }, errors => {
                console.log(errors)
              })
        },
        cancel() {
            this.$emit('input', false)
        }
    }
})
