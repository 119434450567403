import FacebookTable from './_table.js'
import NewIntegration from './new.js'
import template from './index.pug'
import { cloneDeep } from 'lodash'

export default Vue.extend({
  template: template(),
  components: {
    NewIntegration,
    FacebookTable
  },
  props: {
    createMode: {
      type: Boolean,
      required: false,
      default: false
    },
    initData: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      customer: {},
      pages: [],
      meta: {},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {},
  mounted() {
    if(!this.createMode) {
      this.loadCustomer()
    } else {
      this.pages = cloneDeep(this.initData || [])
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.facebook.${key}`, options)
    },
    deleteEvent(event) {
      this.pages.splice(this.pages.indexOf(event), 1)
    },
    createEvent(event) {
      // Buscamos si ya existe el elemento
      if (this.pages.find(page => page.facebookForm.id === event.facebookForm.id)) {
        return this.$notifications.warning(this.t('new.errorAlreadyExists'))
      }

      this.pages.push(event)
      this.$notifications.success(this.t('new.succesfullyCreated'))
      this.saveCreateMode()
    },
    saveCreateMode() {
      this.$emit('create-event', this.pages)
    },
    loadCustomer() {
      this.$setPageTitle(this.t('title'))
      API.customers.show(this.$route.params.id).then(response => {
        this.customer = response
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
          {
            label: this.$t('breadcrumbs.customers.edit', {
            name: response.name
          }),
            route: {
              name: 'customers-edit',
              params: {id: this.$route.params.id}
            }
          },
          {label: this.$t('breadcrumbs.customers.fbLeads'), route: {}}
        ])
      })
    },
    loadData(queryOptions = {}) {
      if(!this.createMode){
        const options = {
          ...(queryOptions || this.lastQueryOptions),
          filter: {customerId: this.$route.params.id}
        }
        API.facebook.index(options).then(({data, meta}) => {
          this.pages = JSON.parse(JSON.stringify(data))
          this.meta = JSON.parse(JSON.stringify(meta))
          if (queryOptions) this.lastQueryOptions = queryOptions
        })
      }
    }
  }
})
