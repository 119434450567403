import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './_index.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    roleKey: {type: String}
  },
  data() {
    return {
      users: null,
      meta: {},
      showFilters: true,
      initFilters: {fullName: '', email: '', phone: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    defaultOrder() {
      return {currentField: 'fullName', currentDir: 'asc'}
    },

    roleId() {
      return this.$findRoleByKey(this.roleKey).id
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
    this.$setPageTitle(this.t('title'))
    this.$setBreadcrumbs([
      {label: this.$t(`breadcrumbs.users.${this.roleKey}s.index`), route: {}}
    ])
  },
  methods: {
    openConfirmModal(user) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {fullName: user.fullName}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroyUser(user.id))
    },

    t(key, options = {}) {
      return this.$t(`users.${this.roleKey}s.index.${key}`, options)
    },

    redirectToUserPage() {
      // Fuera de alcance
    },

    toogleShowFilters() {
      this.showFilters = !this.showFilters
    },

    destroyUser(id) {
      API.users.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDelete'))
      })
    },

    avatarInitials(user) {
      return user.firstName && user.lastName ? user.firstName[0] + user.lastName[0] : '-'
    },

    getData(queryOptions) {
      API.users.indexByRole(this.roleId, queryOptions || this.lastQueryOptions)
        .then(({data, meta}) => {
          this.meta = meta

          this.users = data.map(item => {
            item.fullName = `${item.firstName} ${item.lastName}`.trim()
            return item
          })
        })

      if (queryOptions) this.lastQueryOptions = queryOptions
    }
  }
})
