import moment from 'moment'
import template from './_top-bar.pug'

export default Vue.extend({
  template: template(),
  props: {},
  data() {
    // Date range config
    const today = moment().toDate()
    const yesterday = moment().subtract(1, 'days').toDate()
    const weekFirstDay = moment().startOf('week').toDate()
    const lastSevenDay = moment().subtract(7, 'days').toDate()
    const lastThirtyDay = moment().subtract(30, 'days').toDate()
    const lastNinetyDay = moment().subtract(90, 'days').toDate()
    const lastTwelveMonth = moment().subtract(12, 'months').toDate()
    const yearFirstDay = moment().startOf('year').toDate()
    return {
      customers: [],
      campaigns: [],
      showNotification: false,
      dateRange: {
        startDate: lastSevenDay,
        endDate: today
      },
      ranges: {
        Hoy: [today, today],
        Ayer: [yesterday, yesterday],
        'Esta semana': [weekFirstDay, today],
        'Los ultimos 7 dias': [lastSevenDay, today],
        'Los ultimos 30 dias': [lastThirtyDay, today],
        'Los ultimos 90 dias': [lastNinetyDay, today],
        'Los ultimos 12 meses': [lastTwelveMonth, today],
        'Este año (de enero a hoy)': [yearFirstDay, today]
      },
      localeData: {
        daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab'],
        monthNames: [
          'Ene',
          'Feb',
          'Mar',
          'Abr',
          'May',
          'Jun',
          'Jul',
          'Ago',
          'Sep',
          'Oct',
          'Nov',
          'Dic'
        ],
        applyLabel: 'Aplicar',
        cancelLabel: 'Cancelar'
      },
      notifications: [
        {
          icon: 'info',
          color: '',
          title: 'Recordatorio',
          description: 'La campaña ABC esta a punto de finalizar'
        },
        {
          icon: 'info',
          color: '',
          title: 'Actualización',
          description: 'Se agrego la herramienta Automations en LAIA'
        },
        {
          icon: 'arrow-up',
          color: 'icon--green',
          title: 'Ventas',
          description: 'El mes se cerro con un 10% mas de ventas'
        },
        {
          icon: 'arrow-down',
          color: 'icon--red',
          title: 'Leads',
          description: 'El mes se cerro con un 2% menos de leads'
        },
        {
          icon: 'arrow-down',
          color: 'icon--red',
          title: 'Visitas',
          description: 'El mes se cerro con un 5% menos de visitas'
        }
      ],
      notificationsCustomer: [
        {
          icon: 'exclamation-triangle',
          color: 'icon--warning',
          title: 'Atención',
          description: 'CTI fuera de linea'
        }
      ]
  }
  },
  mounted() {
    this.loadCustomers()
    this.loadCampaigns()
  },
  methods: {
    dateFormated({startDate, endDate}) {
      // eslint-disable-next-line max-len
      return `${startDate ? moment(startDate).format(FORMAT_DATE) : ''} - ${endDate ? moment(endDate).format(FORMAT_DATE) : ''}`
    },
    loadCustomers(queryOptions = {}) {
      API.customers.index(queryOptions).then(({data}) => {
        this.customers = data.map(item => ({
          label: item.name,
          value: item.id
        }))
      })
    },
    loadCampaigns(queryOptions = {}) {
      API.campaigns.index(queryOptions).then(({data}) => {
        this.campaigns = data.map(item => ({
          label: item.name,
          value: item.id,
          customerId: item.customerId
        }))
      })
    },
    getDateTime() {
      return moment().format(FORMAT_DATETIME)
    },
    deleteNotification(i) {
      this.notifications.splice(i, 1)
    }
  }
})
