import {months} from '../../../services/months'

const getMonthStructure = () => {
  let monthsObject = {}
  months().forEach(month => {
    monthsObject = {
      ...monthsObject,
      [month.value]: {}
    }
  })
  return monthsObject
}

const generateMonthStructureForOffer = (data, canConfirm = false) => {
  const monthsObject = getMonthStructure()
  const monthsUsed = {}
  const offers = data.map(offer => {
    offer.demandOverTimes.forEach(item => {
      let tdClass = ''
      if (canConfirm) {
        tdClass = item.cplOffer <= item.cplDemand ? 'success-td' : 'danger-td'
      }
      monthsUsed[item.availableMonthOffer] = true
      monthsObject[item.availableMonthOffer] = JSON.parse(JSON.stringify({
        ...item,
        tdClass
      }))
    })
    return {...offer, month: JSON.parse(JSON.stringify(monthsObject))}
  })
  return {offers, monthsUsed}
}

const generateMonthStructureForOfferOrder = data => {
  const monthsObject = getMonthStructure()
  const monthsUsed = {}

  const orders = data.map(order => {
    order.leadProposalOfferDetail.forEach(item => {
      monthsUsed[item.availableMonth] = true
      monthsObject[item.availableMonth] = JSON.parse(JSON.stringify({
        ...item
      }))
    })
    return {...order, month: JSON.parse(JSON.stringify(monthsObject))}
  })
  return JSON.parse(JSON.stringify({orders, monthsUsed}))
}

const generateMonthStructureForDemand = data => {
  const monthsObject = getMonthStructure()
  const monthsUsed = {}

  data.forEach(item => {
    monthsUsed[item.availableMonthOffer] = true
    monthsObject[item.availableMonthOffer] = JSON.parse(JSON.stringify(item))
  })
  return {demands: monthsObject, monthsUsed}
}

export {
  generateMonthStructureForOffer,
  generateMonthStructureForDemand,
  generateMonthStructureForOfferOrder
}
