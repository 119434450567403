import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import AnalyticsUtils from 'js/services/analytics-utils'
import ModalGeneratePdf from './modal-generate-pdf'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    ModalGeneratePdf,
    FullTable
  },
  props: {
    orders: {
      type: Array,
      required: true
    },
    monthsUsed: {
      type: Object,
      required: true
    },
    meta: {
      type: Object,
      required: true
    },
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showModalGeneratePdf: false,
      orderData: null,
      showFilters: true,
      filters: {agencyName: '', leadDemandId: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    defaultOrder() {
      return {currentField: 'createdAt', currentDir: 'desc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
    this.filters.leadDemandId = this.demandData.id
  },
  methods: {

    t(key, options = {}) {
      return this.$t(`leadtrade.offerOrder.index.${key}`, options)
    },

    confirmDelete(product) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: product.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(product.id))
    },

    confirmGeneratePdf(product) {
      this.orderData = null
      this.orderData = product
      this.showModalGeneratePdf = true
    },

    getData(queryOptions) {
      const params = queryOptions || this.lastQueryOptions

      const payload = {
        ...params,
        filter: {
          ...params.filter,
          leadDemandId: this.demandData.id
        }
      }
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', payload)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    destroy(id) {
      API.offerPurchase.order.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    downloadPdf(file) {
      AnalyticsUtils.downloadCsv({
        title: 'order',
        url: file
      })
    }
  }
})
