import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemoveWithPrompt, openModalConfirmRemove} from 'js/services/modals.js' 



import template from './_table.pug'
import { number } from 'echarts'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    trackingNumbers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      showFilters: true,
      filters: { number: '',campaignId:''},
      lastQueryOptions: null, // guarda los últimos filtros aplicados
      campaigns:[],
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_CALL_CENTERS')
    },
    defaultOrder() {
      return {currentField: 'number', currentDir: 'asc'}
    },
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },
    optionsCampaigns(){
      
      let campaigns = this.campaigns.map(campaign =>({
        label: `${campaign.name}`,
        value: campaign.id
      }))

      let campaignAll = {
        label:"Todos",
        value:""
      }

      campaigns.push(campaignAll)

      return campaigns
    }
  },
  created() {
    
  },

  watch: {
    trackingNumbers: {
      immediate: true,
      handler() {
        this.loadCampaigns()
        this.ready = true
      }
    }
  },
  methods: {

    loadCampaigns() {
      API.campaigns.index({page: {number: 1, size: 1000}, simplequery:'1'}).then(({data}) => {
          this.campaigns = data.map((campaign)=>({
            id: campaign.id,
            name: campaign.name
          }))
      })
    },

    setNameCampaign(id){
      let campaignName = this.campaigns.filter((campaign)=> id == campaign.id)
      // catch if campaign id not exist
      try {
        return campaignName[0].name
      } catch (error) {
        return ' '
      }
    },

  /* 
   openConfirmModal(data) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: data.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(data))
    },
    */

    openConfirmModal(trackingNumbers) {
      openModalConfirmRemoveWithPrompt(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', { name: trackingNumbers.name }),
        this.t('confirmationModal.remove.okButton'),
        this.t('confirmationModal.remove.promptHelp')
      ).then(({ data }) => {
        if (data !== 'yes') {
          openModalAlert(
            this.t('confirmationModal.cancel.title'),
            this.t('confirmationModal.cancel.body'),
            this.t('confirmationModal.cancel.okButton')
          );
        } else {
          this.destroy(trackingNumbers);
        }
      });
    },
    
    t(key, options = {}) {
      return this.$t(`trackingNumbers.index.${key}`, options)
    },

    destroy(object) {
      API.trackingNumbers.destroy(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    getClassByStatus(status) {
      switch (status) {
        case CUSTOMER_STATUSES.ACTIVE:
          return 'table-tag--success'
        case CUSTOMER_STATUSES.INACTIVE:
          return 'table-tag--gray'
        case CUSTOMER_STATUSES.NO_CAMPAIGNS:
          return 'table-tag--yellow-light'
        default:
          return ''
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case CUSTOMER_STATUSES.ACTIVE:
          return 'check'
        case CUSTOMER_STATUSES.INACTIVE:
          return 'close'
        case CUSTOMER_STATUSES.NO_CAMPAIGNS:
          return 'ban'
        default:
          return ''
      }
    },

    noHaveCampaigns(status) {
      return status === CUSTOMER_STATUSES.NO_CAMPAIGNS
    }
  }
})
