import RouteParent from 'js/router/route-parent'

import GoogleAdsAuthIndex from 'js/pages/auth-integrations/google-ads-auth/auth.js'

export default {
  path: '/google-ads/auth',
  component: RouteParent,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: 'google-ads-auth-index',
      component: GoogleAdsAuthIndex,
      meta: {
        auth: true
      }
    }
  ]
}

