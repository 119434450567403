import template from './index.pug'

import InfoForm from './_info.js'
import EmailForm from './_email.js'
import PasswordForm from './_password.js'
import NotificationForm from './_notification.js'

export default Vue.extend({
  template: template(),
  components: {
    InfoForm,
    EmailForm,
    PasswordForm,
    NotificationForm
  },
  data() {
    return {
      currentTab: 'info'
    }
  },
  beforeRouteEnter(_to, from, next) {
    next(vm => { vm.loadCurrentUser(from.name) })
  },
  computed: {
    tabsOptions() {
      return ['info', 'email', 'password', 'notification'].map(key => ({
        value: key,
        label: this.t(`${key}Settings.title`)
      }))
    }
  },
  created() {
    this.$setPageTitle(this.t('title'))
  },
  methods: {
    async loadCurrentUser(fetch = true) {
      if (fetch) await Vue.store.dispatch('loadCurrentUser')
    },

    t(key) {
      return this.$t(`profile.${key}`)
    }
  }
})
