import { mapGetters } from 'vuex'
import template from './_form.pug'

const Fields = ['name', 'audioFileName', 'urlFile']

export default {
  template: template(),
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    saveButtonText: {
      type: String,
      default: () => Vue.i18n.t('general.save')
    },
    isEditMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {},
      fileList: [],
      localErrors: {},
      whitelist: [
        'audio/mpeg',
        'audio/wav',
        'audio/wave',
        'audio/x-wav',
        'audio/aiff',
        'audio/x-aifc',
        'audio/x-aiff',
        'audio/x-gsm',
        'audio/gsm',
        'audio/ulaw'
      ]
    }
  },
  computed: {
    ...mapGetters(['countries']),
    
    countryOptions() {
      return this.countries.map(country => ({
        label: country.name,
        value: country.code
      }))
    },
    allErrors() {
      return { ...this.localErrors, ...this.errors }
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.initializeData()
  },
  methods: {
    initializeData() {
      const data = {}
      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] || '' : ''
      })
      this.data = data
    },
    t(key, options = {}) {
      return this.$t(`audiosMenuVoice.${key}`, options)
    },
    submit() {
      this.localErrors = {}

      if (!this.data.name) {
        this.localErrors.name = this.t('form.errorMessages.nameRequired')
      } else if (this.data.name.length > 100) {
        this.localErrors.name = this.t('form.errorMessages.nameTooLong')
      }
      
      if (this.fileList.length === 0 && !this.data.audioFileUrl) {
        this.localErrors.audioFile = this.t('form.errorMessages.fileRequired')
      }

      if (Object.keys(this.localErrors).length > 0) {
        return
      }
      const formData = new FormData()
      formData.append('name', this.data.name)
      if (this.fileList.length > 0) {
        formData.append('audioFile', this.fileList[0])
      }
      this.$emit('submit', formData)
    },
    cancel() {
      this.$emit('cancel')
    },
    dragOver(event) {
      event.preventDefault()
    },
    dropFile(event) {
      event.preventDefault()
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleFileUpload(event) {
      const files = event.target.files
      this.handleFiles(files)
    },
    handleFiles(files) {
      const validFiles = Array.from(files).filter(file => this.whitelist.includes(file.type))
      if (validFiles.length > 0) {
        const file = validFiles[0]
        if (this.isEditMode && file.size > 1 * 1024 * 1024) { 
          const errorMessage = this.t('form.errorMessages.fileTooLarge')
          this.$notifications.error(errorMessage)
        } else {
          this.fileList = [file]
          this.localErrors.audioFile = ''
        }
      } else {
        const errorMessage = this.t('form.errorMessages.invalidFileFormat')
        this.$notifications.error(errorMessage)
      }
    },
    selectFile() {
      this.$refs.fileInput.click()
    },
    handleNameChange() {
      if (this.data.name) {
        this.localErrors.name = ''
      }
    },
    onAudioSelected(audioData) {
      const cleanAudioData = {
        name: audioData.name,
        audioFileName: audioData.audioFileName,
        urlFile: audioData.urlFile  
      }
      this.$emit('audio-loaded', cleanAudioData)
    }
  }
}
