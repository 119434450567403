import AnalyticsChart from 'js/components/analytics/chart'
import AudienceChartSunburst from 'js/components/analytics/audience-chart-sunburst'

import template from './audience-tab.pug'

export default Vue.extend({
  template: template(),
  components: {
    AnalyticsChart
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      fromDate: moment().subtract(7, 'days').toDate(),
      toDate: moment().toDate(),
      groupTags: false,
      minDate: this.campaign.startAt,
      maxDate: this.campaign.endAt,
      sunburstData: null,
      filters: {},
      tags: [],
      tagsData: [],
      tagsSelectOptions: [],
      tagsSelectedObject: []
    }
  },
  computed: {
    minDateMoment() {
      return moment(this.minDate, 'DD/MM/YYYY')
    },
    maxDateMoment() {
      return moment(this.maxDate, 'DD/MM/YYYY')
    },
    today() {
      return moment()
    },
    todayFormated() {
      return this.today.format('DD/MM/YYYY')
    },
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }
      return {class: '', icon: 'angle-down'}
    },
    fromDateConfig() {
      return {
        ...this.dateConfig,
        minDate: this.minDate,
        maxDate: this.toDate ? this.toDate : this.maxDate
      }
    },
    toDateConfig() {
      return {
        ...this.dateConfig,
        minDate: this.fromDate ? this.fromDate : this.minDate,
        maxDate: moment.min([this.maxDateMoment, this.today]).format('DD/MM/YYYY')
      }
    }
  },
  async mounted() {
    await this.loadTags()
    this.applyFilters()
  },
  methods: {
    onEnd() {
      this.tagsSelectedObject.forEach((item, i) => {
        item.order = i + 1
      })
    },
    addTag(v) {
      const tagObject = {
        ...this.tagsData.find(({key}) => key === v),
        order: this.tagsSelectedObject.length + 1
      }
      this.tagsSelectedObject.push(tagObject)
      this.orderTags()
    },
    orderTags() {
      this.tagsSelectedObject.forEach((item, i) => {
        item.order = i + 1
      })
    },
    removeTag(v) {
      const index = this.tagsSelectedObject.findIndex(({key}) => key === v)
      this.tagsSelectedObject.splice(index, 1)
      const indexT = this.tags.findIndex(value => value === v)
      if (indexT > -1) this.tags.splice(indexT, 1)
      this.orderTags()
    },
    changeUseCampaignDates(value) {
      if (value) {
        this.fromDate = this.minDate
        this.toDate = this.maxDateMoment.isBefore(this.today) ?
        this.maxDate : this.todayFormated
      }
    },
    applyFilters() {
      // los ponemos a null para hacer que aparezca el cargando
      this.sunburstData = null
      let filter = {}
      this.tagsSelectedObject.forEach(tag => {
        filter = {...filter, [tag.key]: tag.order}
      })
      this.filters = {
        from: moment(this.fromDate).format(FORMAT_DATE),
        to: moment(this.toDate).format(FORMAT_DATE),
        filter
      }
      // TODO: eliminar cuando se terminen las pruebas
      setTimeout(
        () => {
          this.loadAudienceData()
        },
        MOCKS ? 500 : 0
      )
    },
    loadAudienceData() {
      API.analytics.audiencesReport(this.campaign.id, this.filters)
      .then(({data}) => { this.sunburstData = AudienceChartSunburst.options(data, this.groupTags) })
    },
    loadTags() {
      API.tags.tagsByCampaign(this.campaign.id, {}).then(({data}) => {
        this.tagsData = []
        this.tagsSelectedObject = []
        this.tagsSelectOptions = []
        this.tag = []

        this.tagsData = data

        this.tagsSelectOptions = data.map(tag => ({
          label: tag.name,
          value: tag.key
        }))
      })
    },
    cleanFilters() {
      this.tags = []
      this.tagsSelectedObject = []
      this.fromDate = moment().subtract(7, 'days').toDate()
      this.toDate = moment().toDate()
    },

    t(key, params = {}) {
      return this.$i18n.t(`analytics.${key}`, params)
    }
  }
})

