import ErrorTemplate from './_template.js'
import template from './500.pug'

export default Vue.extend({
  template: template(),
  components: {ErrorTemplate},
  beforeRouteLeave(_to, _from, next) {
    // limpiamos cualquier notificación residual
    this.$store.commit('updateNotifications', [])
    return next()
  }
})
