import {openModalAlert, openModalConfirmRemove, openModalConfirm} from 'js/services/modals.js'
import {pick, cloneDeep} from 'lodash'
import GeneralDataForm from './_general-data-form.js'
import WorkflowEditorForm from './work-flow-editors/form/_workflow-editor.js'
import WorkflowEditorInbound from './work-flow-editors/inbound/_workflow-editor.js'

import template from './_form.pug'

export default Vue.extend({
  template: template(),
  components: {
    GeneralDataForm,
    WorkflowEditorInbound,
    WorkflowEditorForm
  },
  props: {
    initData: {
      type: Object
    },
    campaign: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: null,
      generalData: {},
      workflowData: {},
      workflowDataForm: {},
      workflowDataInbound: {},
      showSegmentation: false,
      workFlowType: this.t('workFlowType.form.value'),
      disabled: false,
      initPage: false,
    }
  },
  computed: {
    workflowErrors() {
      return this.errors?.workflow
    },

    generalErrors() {
      return this.errors ? pick(this.errors, GeneralDataForm.options.fields) : null
    }
  },
  watch: {
    editing() {
      // cuando se cancela la edición, se pone a false e inicializamos
      if (!this.editing) this.initializeData()
    },

    workflowErrors() {
      this.workflowNodesWithError = !!this.workflowErrors
    },

    generalErrors() {
      if (this.generalErrors) this.toggleFullscreen(false)
    },
    workFlowType(newValue){
      if(newValue) this.initPage = true 
    }
  },
  created() {
    this.initializeData()
  },
  methods: {
    initializeData() {
      this.generalData = pick(this.initData, GeneralDataForm.options.fields)
      this.status = this.initData?.status
      this.workFlowType = this.initData.type ? this.initData.type : this.t('workFlowType.form.value')
      this.workflowData = this.initData ? cloneDeep(this.initData.workflow) : {steps: []}

      if (this.workFlowType == this.t('workFlowType.form.value')) {
        this.workflowDataForm = this.workflowData
      }else{
        this.workflowDataInbound = this.workflowData
      }
    },

    setWorkflowInfo(info) {
      if (this.workFlowType == this.t('workFlowType.form.value')) {
        this.workflowDataForm.steps = info.steps
      }else{
        this.workflowDataInbound.steps = info.steps
      }

      this.workflowUnfinishedNodes = info.unfinishedNodes
      this.workflowNodesWithError = info.nodesWithError
      this.workflowStartNodeWithError = info.startNodeError

      if(this.workflowUnfinishedNodes){
        this.disabled = true
      }else{
        this.disabled = false
      }

    },

    saveAutomation() {
      if (this.workflowNodesWithError) {
        openModalAlert(
          this.t('workflowErrorAlert.title'),
          this.t('workflowErrorAlert.nodesWithErrors'),
          this.t('workflowErrorAlert.okButton')
        )
      } else if (this.workflowUnfinishedNodes) {
        openModalAlert(
          this.t('workflowErrorAlert.title'),
          this.t('workflowErrorAlert.noFinishedWays'),
          this.t('workflowErrorAlert.okButton')
        )
      } else if (this.workflowStartNodeWithError) {
        openModalAlert(
          this.t('workflowErrorAlert.title'),
          this.t('workflowErrorAlert.nodeStartWithErrors'),
          this.t('workflowErrorAlert.okButton')
        )
      } else {
        openModalConfirmRemove(
          this.t('saveConfirmationModal.body'),
          '',
          this.t('saveConfirmationModal.okButton')
        ).then(() => {

          if (this.workFlowType == this.t('workFlowType.form.value')) {
            this.workflowData = this.workflowDataForm
          }else{
            this.workflowData = this.workflowDataInbound
          }


          this.$emit('save', {...this.generalData, workflow: this.workflowData, type:this.workFlowType})
          // console.log({...this.generalData, workflow: this.workflowData, type:this.workFlowType})
        }).catch(() => {})
      }
    },

    toogleShowSegmentation() {
      this.showSegmentation = !this.showSegmentation
    },

    toggleFullscreen(value) {
      // muy feo pero no queda otra para controlar el estado del accordion desde fuera
      this.$refs.form.$refs.accordion.opened = !value
    },

    cancel() {
      openModalConfirmRemove(
        this.t('cancelEditConfirmationModal.title'),
        this.t('cancelEditConfirmationModal.body'),
        this.t('cancelEditConfirmationModal.okButton')
      ).then(() => {
        this.$emit('cancel')
        this.$nextTick(() => this.initializeData())
      }).catch(() => {})
    },

    start() {
      openModalConfirm(
        this.t('startConfirmationModal.title'),
        this.t('startConfirmationModal.body'),
        this.t('startConfirmationModal.okButton'),
        {cancelText: this.t('startConfirmationModal.cancelButton')}
      ).then(() => {
        API.automations.start(this.campaign.id, this.initData.id).then(() => {
          this.status = 'started'
        })
      }).catch(() => {})
    },

    stop() {
      openModalConfirm(
        this.t('stopConfirmationModal.title'),
        this.t('stopConfirmationModal.body'),
        this.t('stopConfirmationModal.okButton')
      ).then(() => {
        API.automations.stop(this.campaign.id, this.initData.id).then(() => {
          this.status = 'stopped'
        })
      }).catch(() => {})
    },

    edit(){
      $emit('edit')
    },

    scrollToSelectSegmentationId(open) {
      if (open) {
        // Se necesita usar nextTick para esperar a que el componente se muestre
        Vue.nextTick(() => (document.getElementById('segmentation_id').scrollIntoView()))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.form.${key}`, options)
    }
  }
})
