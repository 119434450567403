import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import typeNotificationValues from '../../components/notification/type-notificationvalues'
import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    notifications: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showFilters: true,
      typeNotification: typeNotificationValues(),
      filters: {title: '', notificationId: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    statusOptions() {
      return [
        {label: this.t('status.1'), value: '1'},
        {label: this.t('status.2'), value: '2'}
      ]
    },

    defaultOrder() {
      return {currentField: 'title', currentDir: 'asc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  methods: {
    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },
    getClassByStatus(status) {
      switch (status) {
        case true:
          return 'table-tag--success'
        case false:
          return 'table-tag--yellow-light'
        default:
          return ''
      }
    },
    getIconByStatus(status) {
      switch (status) {
        case true:
          return 'check'
        case false:
          return 'close'
        default:
          return ''
      }
    },
    actived(id) {
      API.notifications.enable(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyActived'))
      })
    },
    notificationPush(id) {
      API.notifications.pushManual(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyActived'))
      })
    },
    confirmActivation(notification) {
      openModalConfirmRemove(
        this.t('activateConfirmation.active.title'),
        this.t('activateConfirmation.active.body'),
        this.t('activateConfirmation.okButton')
      ).then(() => {
        this.actived(notification.id)
      })
    },
    confirmActivationPush(notification) {
      openModalConfirmRemove(
        this.t('activateConfirmation.active.title'),
        this.t('activateConfirmation.active.body'),
        this.t('activateConfirmation.okButton')
      ).then(() => {
        this.notificationPush(notification.id)
      })
    },

    t(key, options = {}) {
      return this.$t(`notifications.${key}`, options)
    },

    confirmDelete(notification) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body'),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(notification))
    },

    destroy(notification) {
      API.notifications.destroy(notification.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
