import FullTable from 'js/components/full-table/index.js'

import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    campaign: {
      type: Object
    }
  },
  data() {
    return {
      showFilters: true,
      leads: [],
      meta: {},
      filters: {phone: ''}
    }
  },
  computed: {
    canManage() {
        return this.$user.can('MANAGE_LEADS')
    },

    defaultOrder() {
      return {currentField: 'date', currentDir: 'desc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  methods: {
    getData(queryOptions = {}) {
      API.campaigns.leads.index(this.$route.params.id, queryOptions).then(({data, meta}) => {
        this.leads = data
        this.meta = meta
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.leads.${key}`, options)
    }
  }
})
