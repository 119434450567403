import DemandForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    DemandForm
  },
  data() {
    return {
      errors: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('offerAndDemand.new.title'))
    this.$setBreadcrumbs([
      {
        label: this.$t('breadcrumbs.offer-and-demand.index'),
        route: {name: 'offer-and-demand-index'}
      },
      {label: this.$t('breadcrumbs.offer-and-demand.new'), route: {}}
    ])
  },
  methods: {
    createDemand(data) {
      API.leadTrade.create(data).then(() => {
        this.$notifications.success(this.$t('offerAndDemand.new.succesfullyCreated'))
        this.$router.push({name: 'offer-and-demand-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
