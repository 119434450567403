import template from './_message.pug'

export default Vue.extend({
  template: template(),
  props: {
    title: String,
    text: String,
    image: String,
    buttonRoute: Object,
    buttonText: String
  },
  computed: {
    imageSrc() {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return this.image ? require(`assets/images/${this.image}`) : null
    }
  }
})
