/* eslint-disable no-underscore-dangle */
import * as echarts from 'echarts'

import template from './chart.pug'

export default Vue.extend({
  template: template(),
  props: {
    title: {type: String},
    options: {type: Object, default: () => {}}
  },
  data() {
    return {
      showSqueleton: true,
      chart: null,
      resizeListener: null
    }
  },
  watch: {
    options: {
      handler(val) {
        if (val) {
          this.showSqueleton = false
          this.$nextTick(this.draw)
        }
      }
    }
  },
  mounted() {
    if (this.options) {
      this.showSqueleton = false
      this.$nextTick(this.draw)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    draw() {
      const domId = this.$refs.chart

      // Solo se llama a echarts.init la primera vez
      // Si la gráfica ya esta inicializada se reutiliza
      this.chart = echarts.getInstanceByDom(domId)
      if (!this.chart) this.chart = echarts.init(domId)

      const defaultOption = {
        title: {
          text: this.title,
          textStyle: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 'normal',
            overflow: 'break',
            fontFamily: 'Montserrat'
          },
          padding: [17, 17, 27, 17]
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: '#212529',
          borderColor: '#212529',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)',
            fontFamily: 'Montserrat'
          }
        },
        toolbox: {
          right: '20px',
          feature: {
            saveAsImage: {
              title: this.$t('general.download')
            }
          }
        },
        textStyle: {
          fontFamily: 'Montserrat'
      },
        color: GRAPH_COLORS
      }
      const options = {...this.options}

      // Cuando el grafico tiene configurado DataView se le agrega un titulo
      if (options?.toolbox?.feature?.dataView?.lang?.length > 0) {
        options.toolbox.feature.dataView.lang[0] = `<span class="table-title">${this.title}</span>`
      }

      this.chart.setOption(Object.assign(defaultOption, options), {notMerge: true})

      // Cuando hay demasiados datos en el grafico, renderizamos una tabla para visualizar mejor
      if (options.series[0].data.length > 9) {
         const dataViewInstance = this.chart._componentsViews
          .find(c => c._features && c._features.dataView)
          // Se renderiza la tabla en vez del grafico
          dataViewInstance?._features?.dataView.model.iconPaths.dataView.trigger('click')
      }

      if (!this.resizeListener) {
        this.resizeListener = window.addEventListener('resize', this.chart.resize)
      }
    }
  }
})
