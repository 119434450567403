export default {
  state: {
    notifications: [],
    backendNotifications: [],
    backendNotificationsIndividual: []
  },
  getters: {
    notifications(state) {
      return state.notifications
    },
    backendNotifications(state) {
      return state.backendNotifications
    },
    backendNotificationsIndividual(state) {
      return state.backendNotificationsIndividual
    }
  },
  mutations: {
    updateNotifications(state, notifications) {
      state.notifications = notifications
    },
    addNotification(state, notification) {
      state.notifications.push(notification)
    },
    setBackendNotification(state, backendNotifications) {
      state.backendNotifications = backendNotifications
    },
    setBackendNotificationIndividual(state, backendNotificationsIndividual) {
      state.backendNotificationsIndividual = backendNotificationsIndividual
    }
  }
}
