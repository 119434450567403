import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import formMatrix from './_form'
import ListFileForm from './_list-file-form.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    formMatrix,
    ListFileForm
  },
  props: {
    matrixModel: {
      type: Array,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      showFilters: true,
      showForm: false,
      formMatrixErrors: {},
      showRobinsonForm: false,
      matrixFormData: {},
      filters: {keyModel: '', category: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados,
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_CALL_CENTERS')
    },
    defaultOrder() {
      return {currentField: 'id', currentDir: 'asc'}
    },
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    },

    formMode() {
      return this.matrixFormData.id ? 'edit' : 'new'
    }
  },

  watch: {
    MatrixModel: {
      immediate: true,
      handler() {
        this.ready = true
      }
    }
  },

  methods: {

    openNewFileForm() {
      this.showRobinsonForm = true
    },

    deleteMatrix(campaignId) {
      API.campaigns.matrixModule.deleteMatrix(campaignId).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    saveMatrix(data) {
      this.formMatrixErrors = {}

      if (this.formMode === 'edit') {
        this.updateMatrix(data)
      } else {
        this.createMatrix(data)
      }
    },

    updateMatrix(data) {
      API.campaigns.matrixModule.update(this.campaign.id, data.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.getData()
        this.closeModal()
      }, errors => {
        this.formMatrixErrors = errors
      })
    },

    createMatrix(data) {
      API.campaigns.matrixModule.create(this.campaign.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.getData()
        this.closeModal()
      }, errors => {
        this.formMatrixErrors = errors
      })
    },

    closeModal() {
      this.matrixFormData = {}
      this.formMatrixErrors = {}
      this.showForm = false
    },

    closeModalFile() {
      this.showRobinsonForm = false
    },

    openNewForm() {
      this.matrixFormData = {
        keyModel: '',
        category: '',
        probability: 0.0
      }
      this.showForm = true
    },

    openEditForm(item = {}) {
      this.matrixFormData = item

      this.showForm = true
    },

    openConfirmModal(customer) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: customer.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(this.campaign.id, customer))
    },

    openConfirmModalMatrix(customer) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: customer.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.deleteMatrix(this.campaign.id))
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.analyticsModel.tabs.matrixModel.index.${key}`, options)
    },

    destroy(campaignId, object) {
      API.campaigns.matrixModule.destroy(campaignId, object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    }
  }
})
