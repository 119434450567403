export default {
  deletedLogs(params = {}, options = {}) {
    return Vue.http.get(
      `log/leadremoval`,
      { params, ...options }
    ).then(response => response.data)
  },
  exportDeletedLogs(params = {}, options = {}) {
    return Vue.http.get(
      `log/leadremoval-exportfile`,
      { params, ...options }
    ).then(response => response.data)
  },
  acceptedPolicyLogs(params = {}, options = {}) {
    return Vue.http.get(
      `log/termsandconditions`,
      { params, ...options }
    ).then(response => response.data)
  },
  exportAcceptedPolicyLogs(params = {}, options = {}) {
    return Vue.http.get(
      `log/termsandconditions-exportfile`,
      { params, ...options }
    ).then(response => response.data)
  }
}