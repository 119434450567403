export default {
  errorsRootKey: 'provider-leads',
  resource: 'provider-leads',
  actions: ['index', 'show', 'create', 'update', 'patch', 'destroy'],

  getLogsError(providerId, params = {}, options = {}) {
    return Vue.http.get(
      `provider-leads/${providerId}/leadsErrorLog`,
      {params, ...options}
    ).then(response => response.data)
  },
  exportLogsError(providerId, params = {}, options = {}) {
    return Vue.http.get(
      `provider-leads/${providerId}/leadsErrorLog/export`,
      {params, ...options}
    ).then(response => response.data)
  },
  exportProviderLeads(params = {}, options = {}) {
    return Vue.http.get(
      'provider-leads/leads-detail-report-export',
      {params, ...options}
    ).then(response => response.data)
  }
}
