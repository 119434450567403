import {cloneDeep, merge, set} from 'lodash'
import template from './_field-mapping-modal.pug'
import validatePresence from '../_validate'

const DefaultData = () => ({
  // Esto es para la lista
  active: false,
  mappings: {}
})

export default Vue.extend({
  template: template(),
  props: {
    campaignTags: {
      type: Array,
      default: () => ([])
    },
    initData: {
      type: Object,
      default: null
    },
    translateKey: String
  },
  data() {
    return {
      data: DefaultData(),
      dataMappings: [],
      errorsMapping: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },
    valueSelectOptions() {
      const options = {}

      // agrupamos por tipo
      this.campaignTags.forEach(item => {
        if (!options[item.type]) options[item.type] = []

        options[item.type].push(
          {label: item.name || item.key, value: item.key}
        )
      })

      let finalOptions = []

      // cada tipo debe tener su grupo
      Object.keys(options).sort().forEach(type => {
        finalOptions.push({title: this.$t(`campaigns.show.tags.types.${type}`)})
        finalOptions = finalOptions.concat(this.$sortByLocale(options[type], 'label'))
      })

      return finalOptions
    },
    mappingsErrors() {
      return this.dataMappings.filter(item => item.errors).length
    }
  },
  watch: {
    dataMappings(arr) {
      if (!arr.length) this.data.active = false
    }
  },
  created() {
    if (this.initData) {
      this.data = cloneDeep({...DefaultData(), ...this.initData})
    } else {
      this.data.method = 'post'
    }
    // Convertimos a un array para poder manejar de forma mas facil en Vue los valores
    // De esta forma es más sencillo añadir o eliminar elementos
    this.transformObjectToArrayFields('Mappings', this.data.mappings)
  },
  methods: {
    // valida los parámetros de cada sección
    validateSections() {
      let error = false;
      [
        this.dataMappings
      ].forEach(sectionFields => {
        sectionFields.forEach(field => {
          field.errors = null
          validatePresence(field, ['name', 'value'])
          // validamos unicidad de las claves
          const sameNameItems = sectionFields.filter(item => item.name === field.name).length
          if (sameNameItems > 1) field.errors = {name: this.$t('errors.unique')}
          if (field.errors) error = true
        })
      })
      // añadimos un error para que se muestre en rojo el nodo en el diagrama
      if (error) set(this.data, 'errors.config.fields', true)
    },
      // Función que transforma el objeto de config en el array de data
    transformObjectToArrayFields(arrayName, object) {
      Object.keys(object).forEach(key => {
        let manual = true
        if (object[key].match(/\$lead/) && key !== 'body') {
          manual = false
        } else if (object[key].match(/\$lead/) && key === 'body') {
          manual = true
        } else {
          manual = true
        }

        this[`data${arrayName}`].push({
          name: key,
          value: object[key],
          manualUpdate: manual,
          errors: null // guarda los errores si los tiene
        })
      })
    },
    removeElementTo(index, arrayName) {
      this[`data${arrayName}`].splice(index, 1)
    },
    addElementTo(arrayName) {
      this[`data${arrayName}`].push({name: '', value: '', manualUpdate: true, errors: null})
    },
    transformArrayFieldsToObject(array) {
      const object = {}

      array.forEach(item => {
        // el campo errors es interno y no se envía al backend
        if (item.name !== 'errors') object[item.name] = item.value
      })

      return object
    },
    save() {
      this.data = {
        ...this.data,
        mappings: this.transformArrayFieldsToObject(this.dataMappings)
      }

      this.validateSections()
      if (
        !this.mappingsErrors
      ) {
        this.$emit('save', cloneDeep(this.data))
      }
    },
    close() {
      this.$emit('close')
    },
    t(key, options = {}) {
      return this.$t(
        `campaigns.show.validations.tabs.phoneListComponent.fieldMappingModal.${key}`, options
      )
    },
    tk(key, options = {}) {
      // eslint-disable-next-line max-len
      return this.$t(`campaigns.show.validations.tabs.${this.translateKey}.fieldMappingModal.${key}`, options)
    }
  }
})
