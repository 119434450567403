import {pick, isEqual} from 'lodash'
import template from './info.pug'

const DefaultValues = () => Object.freeze({
  name: '',
  description: '',
  subject: ''
})
const Fields = Object.keys(DefaultValues())
const validations = {
  name: {type: 'string', required: true},
  subject: {type: 'string', required: true}
}
const Validations = pick(validations, Fields)

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    logoUrl: {
      type: String,
      default: ''
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: {},
      tagSelected: '',
      tags: [],
      tagSelectActivate: false,
      ready: false
    }
  },
  computed: {
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    }
  },
  mounted() {
    this.loadTags()
  },
  methods: {
    loadTags(queryOptions = {}) {
      API.tags.all(queryOptions).then(({data}) => {
        this.tags = data
      })
    },
    selectOption(value,type) {
      console.log(value);
      this.data.subject += ` $lead.${type}.${value}`
      this.tagSelectActivate = false
    },

    activateSelect() {
      if (this.tagSelectActivate) {
        this.tagSelectActivate = false
      } else {
        this.tagSelectActivate = true
      }
    },

    initializeData() {
      const data = {}
      Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
      this.data = data
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`emailMarketing.form.${key}`, options)
    }
  }
})
