import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    targetNumbers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      showFilters: true,
      filters: { number: '',description:''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_CALL_CENTERS')
    },
    defaultOrder() {
      return {currentField: 'number', currentDir: 'asc'}
    },
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
    this.ready = true
  },
  methods: {

    openConfirmModal(data) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: data.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(data))
    },

    t(key, options = {}) {
      return this.$t(`targetNumbers.index.${key}`, options)
    },

    destroy(object) {
      API.targetNumbers.destroy(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    getClassByStatus(status) {
      switch (status) {
        case CUSTOMER_STATUSES.ACTIVE:
          return 'table-tag--success'
        case CUSTOMER_STATUSES.INACTIVE:
          return 'table-tag--gray'
        case CUSTOMER_STATUSES.NO_CAMPAIGNS:
          return 'table-tag--yellow-light'
        default:
          return ''
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case CUSTOMER_STATUSES.ACTIVE:
          return 'check'
        case CUSTOMER_STATUSES.INACTIVE:
          return 'close'
        case CUSTOMER_STATUSES.NO_CAMPAIGNS:
          return 'ban'
        default:
          return ''
      }
    },

    noHaveCampaigns(status) {
      return status === CUSTOMER_STATUSES.NO_CAMPAIGNS
    }
  }
})
