import template from './index.pug'

export default Vue.extend({
    template: template(),
    props: {
      demand: {
        type: Array,
        default: () => ([])
      },
      monthsUsed: {
        type: Object,
        default: () => ({})
      }
    }
})
