import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import ModalConfig from './modal-config.js'
import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable,
    ModalConfig
  },
  props: {
    pages: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogLaiaToken: false,
      formSelected: null,
      filters: {id: [], customerId: []},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    defaultOrder() {
      return {currentField: 'pageName', currentDir: 'asc'}
    }
  },
  mounted() {
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.tiktok.${key}`, options)
    },
    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },
    configCampaign(form) {
      this.formSelected = JSON.parse(JSON.stringify(form))
      this.dialogLaiaToken = true
    },
    confirmDelete(form) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: form.tiktokSetting.pageName}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(form.id))
    },
    destroy(id) {
      API.tiktok.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
