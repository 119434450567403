import LaiaAccountForm from './_form'
import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    LaiaAccountForm
  },
  data() {
    return {
      lastPath: null,
      lastPathParams: {},
      errors: {}
    }
  },
  beforeRouteEnter(_to, from, next) {
    // se añade el nombre de la ultima dirección, ya que, el boton cancelar debe redirigir
    // a la ultima dirección si esta es el listado de operadores o la creación de operadores
    next(vm => {
      if (['accounts-laia-index', 'accounts-laia-new', 'accounts-laia-edit'].includes(from?.name)) {
        vm.lastPathParams = from.params
        vm.lastPath = from.name
      }
    })
  },
  created() {
    this.$setPageTitle(this.$t('laiaAccounts.new.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.laiaAccounts.index'), route: {name: 'accounts-laia-index'}},
      {label: this.$t('breadcrumbs.laiaAccounts.new'), route: {}}
    ])
  },
  methods: {
    createAccount(data) {
      API.laiaAccounts.create(data).then(() => {
        this.$notifications.success(this.$t('laiaAccounts.new.succesfullyCreated'))

        this.$router.push({
          name: this.lastPath || 'accounts-laia-index',
          params: this.lastPathParams
        })
      }, errors => {
        this.errors = errors
      })
    }
  }
})
