export default {
  errorsRootKey: 'static-numbers',
  resource: 'calltrackings/static-numbers',
  actions: ['index', 'show', 'create', 'update', 'destroy'],
  available: {
    index(params = {}, options = {}) {
      return Vue.http.get(
        'calltrackings/static-numbers/available',
        {params, ...options}
      ).then(response => response.data)
    }
  }
}
