import template from './index.pug'

export default Vue.extend({
  template: template(),
  props: {
    pagination: {
      type: Object,
      required: true,
      default: () => (
        {
          currentPage: null,
          pageSize: null,
          totalElements: null,
          totalPages: null
        }
      )
    },
    selectorOptions: {
      type: Array,
      default: () => [20, 40, 60]
    }
  },
  computed: {
    lastElementInPage() {
      const max = this.pagination.currentPage * this.pagination.pageSize

      return max > this.pagination.totalElements ? this.pagination.totalElements : max
    },

    firstElementInPage() {
      return 1 + ((this.pagination.currentPage - 1) * this.pagination.pageSize)
    },

    dataText() {
      return {
        totalElements: this.pagination.totalElements,
        startPageElement: this.firstElementInPage,
        finishPageElement: this.lastElementInPage
      }
    }
  },
  methods: {
    changePage(page) {
      this.$emit('change-page', page)
    },

    changePageSize(event) {
      const value = parseInt(event.target.value)

      this.$emit('change-size', value)
    }
  }
})
