
import template from './show.pug'
import integrationsUtils from '../../services/integrations-utils'

export default Vue.extend({
  template: template(),
  data() {
    return {
      ready: false,
      data: {}
    }
  },
  computed: {
    countryFlagClass() {
      return this.data.country ? `flag-icon-${this.data.country.toLowerCase()}` : null
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      async handler() {
        this.$setPageTitle(this.$t('laiaAccounts.show.title'))
        await this.loadData()
        this.ready = true
      }
    }
  },
  methods: {
    getIcon(name) {
      return integrationsUtils.getSrc(name)
    },
    loadData() {
      return API.laiaAccounts.show(this.$route.params.id).then(response => {
        this.data = response

        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.laiaAccounts.index'), route: {name: 'accounts-laia-index'}},
          {label: this.$t('breadcrumbs.laiaAccounts.show', {name: this.data.name}), route: {}}
        ])
      })
    },

    t(key, options = {}) {
      return this.$t(`laiaAccounts.show.${key}`, options)
    }
  }
})
