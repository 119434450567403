import {openModalConfirmRemove} from 'js/services/modals.js'
import template from './_providers-form.pug'

const Fields = ['id', 'siteId']

const DefaultValues = () => Object.freeze({
  id: '',
  siteId: ''
})

// Permite definir un call center con su CTI asociado
export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  template: template(),
  components: {
    openModalConfirmRemove
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    customerId: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    sitesSelectOptions: {
      type: Array,
      required: true
    },
    providerLeadsOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      data: {
        id: '',
        siteId: ''
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        // Solo se debe de ejecutar cuando se produce un cambio
        if (JSON.stringify(newValue) === JSON.stringify(this.data)) return

        const data = {}
        Fields.forEach(key => { data[key] = this.value ? this.value[key] : '' })
        this.data = data
      }
    },

    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.form.providers.${key}`, options)
    },

    confirmDelete() {
      openModalConfirmRemove(
        this.t('deleteConfirmationModal.title'),
        this.t('deleteConfirmationModal.body'),
        this.t('deleteConfirmationModal.okButton')
      ).then(() => {
        this.$emit('remove')
      })
    }
  }
})
