export default {
  errorsRootKey: 'smstemplates',
  resource: 'smstemplates',
  actions: ['index', 'show', 'create', 'update', 'destroy'],

  getShortURL(data = {}) {
    return Vue.http.post(
      'shorturls',
      data
    ).then(response => response.data)
  }
}
