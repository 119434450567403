import trackingSourcesTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    trackingSourcesTable
  },
  data() {
    return {
      trackingSources: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('trackingSources.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.trackingSources.index'), route: {}}
    ])
  },
  methods: {
    loadTrackingSources(queryOptions = {}) {
      API.trackingSources.index(queryOptions).then(({data, meta}) => {
        this.trackingSources = data
        this.meta = meta
        console.log(this.trackingSources)
      })
    }
  }
})
