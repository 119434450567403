import RouteParent from 'js/router/route-parent'

import TikTokAuthIndex from 'js/pages/auth-integrations/tiktok-auth/auth.js'

export default {
  path: '/tiktok/auth',
  component: RouteParent,
  meta: {
    auth: true
  },
  children: [
    {
      path: '',
      name: 'tik-tok-auth-index',
      component: TikTokAuthIndex,
      meta: {
        auth: true
      }
    }
  ]
}

