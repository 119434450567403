const send = options => {
  const notification = {
    duration: 5000,
    id: `notification_${new Date().getTime()}`,
    close: true
  }

  if (options.type === 'error') {
    notification.duration = 8000
  }

  Object.assign(notification, options)

  Vue.store.commit('addNotification', notification)
}

export default {
  install(Vue) {
    Vue.notifications = {
      error(message, options = {}) {
        const defaultOptions = {text: message, type: 'error', icon: 'ns-icon-info'}

        send(Object.assign(defaultOptions, options))
      },

      success(message, options = {}) {
        const defaultOptions = {text: message, type: 'success', icon: 'ns-icon-check'}

        send(Object.assign(defaultOptions, options))
      },

      info(message, options = {}) {
        const defaultOptions = {text: message, type: 'info', icon: 'ns-icon-info'}

        send(Object.assign(defaultOptions, options))
      },

      warning(message, options = {}) {
        const defaultOptions = {
          text: message,
          type: 'warning',
          icon: 'ns-icon-exclamation-triangle'
        }

        send(Object.assign(defaultOptions, options))
      },

      dynamic(message, typeNotification, options = {}) {
        let icon = ''

        if(typeNotification === 'success'){
          icon = 'ns-icon-check'
        }

        if(typeNotification === 'warning'){
          icon = 'ns-icon-exclamation-triangle'
        }

        if(typeNotification === 'error' || typeNotification === 'info'){
          icon = 'ns-icon-info'
        }

        const defaultOptions = {
          text: message,
          type: typeNotification,
          icon: icon
        }

        send(Object.assign(defaultOptions, options))
      }

      
    }

    Vue.prototype.$notifications = Vue.notifications
  }
}
