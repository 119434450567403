import Profile from 'js/pages/profile/index.js'

export default [
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      auth: true
    }
  }
]
