const localeRoot = 'analytics.charts.contactedConvertedBar'

export default {
  options: params => {
    if (!params) return null
    // Se excluye el agente / grupo "SIN AGENTES"
    const data = params.filter(({key}) => key !== '99999')
    if (data.length === 0) return null
    // Se calcula CR-Media
    const {convertedCount, totalCount} = (data.reduce((acc, curr) => {
      acc.convertedCount += curr.convertedCount
      acc.totalCount += curr.totalCount
      return acc
    }, {
      convertedCount: 0,
      totalCount: 0
    }))
    const crMedia = ((convertedCount * 100) / (totalCount || 1)).toFixed(2)

    return {
      grid: {
        left: '4%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: '{b0}<br />{a1}: {c1}<br />{a0}: {c0}<br />{a2}: {c2}%<br />{a3}: {c3}%'
      },
      xAxis: [
        {
          type: 'category',
          data: data.map(item => item.name),
          axisLabel: {
            rotate: 30,
            margin: 10,
            // align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `               ${value.substring(0, 7).replace(/\/\d{4}$/, '')}...`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: Vue.i18n.t(`${localeRoot}.series.convertedCount`),
          type: 'bar',
          stack: 'Ad',
          barGap: 0,
          data: data.map(item => item.convertedCount)
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.contactedCount`),
          type: 'bar',
          stack: 'Ad',
          data: data.map(item => item.totalCount)
        },
        {
          name: 'Media',
          type: 'line',
          data: data.map(() => crMedia)
        },
        {
          name: 'CR',
          type: 'line',
          data: data.map(item => parseFloat(item.convertedCount * 100 / item.totalCount).toFixed(2))
        }
      ],
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'empty',
          brushSelect: false,
          showDetail: false
        }
      ],
      media: [
        {
          query: {
            maxWidth: 500
          },
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        top: '3%',
        icon: 'circle',
        left: 'middle'
      },
      color: GRAPH_COLORS
    }
  }
}
