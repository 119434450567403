import LeadsAccountsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsAccountsTable
  },
  data() {
    return {
      leads: [],
      campaigns: [],
      providerLeads: [],
      dataCountTotal: [],
      totalPercentage: 0,
      totalQuantity: 0,
      head: [],
      meta: {}
    }
  },
  created() {
    this.loadCampaigns()
    this.loadProviders()
    this.$setPageTitle(this.$t('tipificationReport.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.tipificationReport.index'), route: {}}
    ])
  },
  methods: {
    loadCampaigns() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.campaigns.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.campaigns = data
        this.campaigns.unshift(allData)
      })
    },
    loadProviders() {
      const queryOptions = {
        order: {
          name: 'asc'
        },
        page: {number: 1, size: 1000}
      }
      API.providerLeads.index(queryOptions).then(({data}) => {
        const allData = {
          id: '0',
          name: 'Todos'
        }
        this.providerLeads = data
        this.providerLeads.unshift(allData)
      })
    },
    loadLeadsDetails(queryOptions = {}) {
      API.tipificationReport.customIndex(queryOptions).then(({data, meta}) => {
        this.leads = data[0].dataReport
        this.dataCountTotal = data[0].dataCountReport
        this.meta = meta
        // eslint-disable-next-line no-shadow
        const datahead = this.dataCountTotal.map(data => data.campaignName+' '+ data.providerLeadName)
        console.log(this.leads);
        this.head = [...new Set(datahead)]

        // eslint-disable-next-line max-len
        this.totalPercentage = 100 //this.leads.reduce((a, b) => a + b.weightPercentage, 0)
        this.totalQuantity = this.leads.reduce((a, b) => a + b.countTotal, 0)
      })
    }
  }
})
