import camelCase from 'lodash/camelCase'
import template from './show.pug'

export default Vue.extend({
  name: 'campaigns-show',
  template: template(),
  data() {
    return {
      data: {},
      ready: false,
      currentTab: this.$route.name.split('-')[2]
    }
  },
  computed: {
    canManageCampaign() {
      return this.$user.permissions.includes('MANAGE_CAMPAIGNS')
    },

    canManageWorkflow() {
      return this.$user.permissions.includes('MANAGE_AUTOMATIONS')
    },

    tabsOptions() {
      let tabs = ['analytics']
      if (this.$user.can('VIEW_LEADS')) tabs.push('leads')
      if (this.$user.can('VIEW_AUTOMATIONS')) tabs.push('automations')
      if (this.$user.can('MANAGE_CONFIG')) tabs.push('config')
      if (this.$user.can('MANAGE_VALIDATIONS')) tabs.push('validations')
      if (this.$user.can('MANAGE_CONFIG')) tabs.push('analytics-model-matrix-model')

      // Si es embebido en artimisa, se ocultan los demas tabs y se deja solo "automations"
      if (this.$store.getters.embeddedArtemisa) tabs = ['automations']

      return tabs.map(item => ({
        value: item,
        // eslint-disable-next-line max-len
        label: item === 'analytics-model-matrix-model' ? this.t('analyticsModel.tabTitle') : this.t(`${camelCase(item)}.tabTitle`)
      }))
    }
  },
  watch: {
    '$route.fullPath': {
      immediate: true,
      handler() {
        this.loadData()
        // eslint-disable-next-line max-len
        this.currentTab = `${this.$route.name.split('-')[2] }-${ this.$route.name.split('-').pop()}`
        if (this.currentTab === 'analytics-model') {
          // eslint-disable-next-line max-len
          this.currentTab = `${this.$route.name.split('-')[2] }-${ this.$route.name.split('-').pop()}-matrix-model`
        } else {
          this.currentTab = this.$route.name.split('-')[2]
        }
      }
    }
  },
  methods: {
    getRouteCurrentTab(route) {
      switch (route) {
        case 'analytics':
          return {name: 'campaigns-show-analytics-transformation'}
        case 'config':
          return {name: 'campaigns-show-config-cdimensions'}
        case 'validations':
          return {name: 'campaigns-show-validations-summary'}
        default:
          return {name: `campaigns-show-${route}`}
      }
    },

    loadData() {
      this.ready = false

      API.campaigns.show(this.$route.params.id).then(response => {
        this.data = response
        this.$setPageTitle(this.data.name)
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.campaigns.index'), route: {name: 'campaigns-index'}},
          {label: this.data.name, route: this.getRouteCurrentTab(this.currentTab)}
        ])
        this.ready = true
      })
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.${key}`, options)
    },

    setCurrentTab(tab) {
      return this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
