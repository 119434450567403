const collectionAccounts = [
  {
    id: 'account1',
    name: 'Votafone',
    logoUrl: 'https://logoipsum.com/logo/logo-14.svg'
  },
  {
    id: 'account2',
    name: 'Emerlia',
    logoUrl: 'https://logoipsum.com/logo/logo-13.svg'
  },
  {
    id: 'account99',
    name: 'Nss',
    logoUrl: 'https://logoipsum.com/logo/logo-15.svg'
  },
  {
    id: 'account100',
    name: 'Agency',
    logoUrl: 'https://logoipsum.com/logo/logo-15.svg'
  }
]

const collectionLeadsByAccounts = [
  {
    id: 'lead1',
    customerId: '1',
    campaignId: 'campaign1',
    fullname: 'Firstname 1 Lastname 1',
    firstName: 'Firstname 1',
    lastName: 'Lastname 1',
    email: 'lead1@email.com',
    phone: '666 555 444',
    hour: '11:14',
    sourceUTM: 'google',
    ctaType: 'inbound',
    date: '31/05/2021',
    from: '22/11/2021',
    to: '25/11/2021',
    customerName: 'Alterna'
  },
  {
    id: 'lead2',
    customerId: '1',
    campaignId: 'campaign1',
    fullname: 'Firstname 2 Lastname 2',
    firstName: 'Firstname 2',
    lastName: 'Lastname 2',
    email: 'lead2@email.com',
    phone: '666 555 443',
    hour: '10:14',
    sourceUTM: 'google',
    ctaType: 'inbound',
    date: '27/05/2021',
    from: '22/11/2021',
    to: '25/11/2021',
    customerName: 'Finetwork'
  },
  {
    id: 'lead3',
    customerId: '2',
    campaignId: 'campaign1',
    fullname: 'Firstname 3 Lastname 3',
    firstName: 'Firstname 3',
    lastName: 'Lastname 3',
    email: 'lead3@email.com',
    phone: '777 222 000',
    hour: '10:14',
    sourceUTM: 'facebook',
    ctaType: 'inbound',
    date: '27/05/2021',
    from: '22/11/2021',
    to: '25/11/2021',
    customerName: 'Finetwork'
  }
]

const showAccounts = collectionAccounts
const indexAccounts = collectionAccounts
const leadsAccounts = collectionLeadsByAccounts

export {indexAccounts, showAccounts, leadsAccounts}
