import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    offers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    },
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showFilters: true,
      filters: {agencyName: '', leadDemandId: ''},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_PRODUCTS')
    },

    defaultOrder() {
      return {currentField: 'createdAt', currentDir: 'desc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
    this.filters.leadDemandId = this.demandData.id
  },
  methods: {

    t(key, options = {}) {
      return this.$t(`leadtrade.order.index.${key}`, options)
    },

    confirmDelete(product) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: product.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(product.id))
    },

    getData(queryOptions) {
      const params = queryOptions || this.lastQueryOptions

      const payload = {
        ...params,
        filter: {
          ...params.filter,
          leadDemandId: this.demandData.id,
          statusCode: 1 // Filtrar solo los aprobados
        }
      }
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', payload)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    destroy(id) {
      API.offerPurchase.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
