import {openModalConfirmRemove} from 'js/services/modals.js'
import FormForm from './_form-form.js'
import template from './forms-index.pug'

const defaultNoDataImg = require('assets/images/content/empty-state.svg')

export default Vue.extend({
  template: template(),
  components: {
    FormForm,
    openModalConfirmRemove
  },
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      noDataImg: defaultNoDataImg,
      cdimensions: null,

      forms: [],
      campaignErrors: {},
      defaultFormId: null,
      formData: {},
      formErrors: {},
      showForm: false,

      formToCreateCondition: null,
      conditionData: {
        name: null,
        sourceLocation: null,
        sourceKey: null,
        fieldType: null,
        comparisonOperator: null,
        comparisonValue: null
      },
      conditionErrors: {}
    }
  },
  computed: {
    campaignId() {
      return this.campaign.id
    },

    formMode() {
      return this.formData.id ? 'edit' : 'new'
    },

    sourceLocationsSelectOptions() {
      return Object.keys(CDIMENSIONS.SOURCE_LOCATION).map(key => ({
        label: this.$t(`cdimensionsSourceLocations.${key}`),
        value: CDIMENSIONS.SOURCE_LOCATION[key]
      }))
    },

    fieldTypesSelectOptions() {
      return Object.keys(FIELD_TYPES).map(key => ({
        label: this.$t(`fieldTypes.${key}`),
        value: FIELD_TYPES[key]
      }))
    },

    comparisonOperatorSelectOptions() {
      const fieldType = this.conditionData.fieldType
      let keys = []

      if (!fieldType) return []

      if (fieldType === FIELD_TYPES.TEXT) {
        // operadores disponibles para un campo de tipo texto
        keys = [
          COMPARISON_OPERATORS.EQUAL,
          COMPARISON_OPERATORS.DISTINCT,
          COMPARISON_OPERATORS.CONTAINS
        ]
      } else {
        // operadores disponibles para un campo de tipo número
        keys = [
          COMPARISON_OPERATORS.EQUAL,
          COMPARISON_OPERATORS.LESS_THAN,
          COMPARISON_OPERATORS.GREATER_THAN
        ]
      }

      return keys.map(item => ({
        label: this.$t(`comparisonOperators.${fieldType}.${item}`),
        value: item
      }))
    },

    defaultFormsSelectedOptions() {
      return this.forms.map(item => ({
        label: item.title,
        value: item.id
      }))
    }
  },
  watch: {
    comparisonOperatorSelectOptions() {
      this.conditionData.comparisonOperator = this.comparisonOperatorSelectOptions[0]?.value
    },

    campaign: {
      handler: function handler(newValue) {
        this.campaignErrors = {}
        this.defaultFormId = newValue?.defaultFormId
      },
      immediate: true
    },

    defaultFormId() {
      this.updateCampaignDefaultForm()
    }
  },
  created() {
    this.loadForms()
  },
  methods: {
    loadForms() {
      this.forms = []
      API.campaigns.forms.index(
        this.campaignId,
        {order: {title: 'asc'}, page: {number: 1, size: 1000}}
      ).then(({data}) => {
        this.forms = data
      })
    },

    updateCampaignDefaultForm() {
      API.campaigns.patch(
        this.campaignId,
        {defaultFormId: this.defaultFormId}
      ).then(
        () => {},
        errors => {
          this.campaignErrors = errors
        }
      )
    },

    openNewForm() {
      this.formData = {
        campaignId: this.campaignId,
        launcher: 'phone',
        title: '',
        fields: [],
        submit: this.$t('forms.form.fields.submit.defaultValue'),
        messages: {
          success: this.$t('forms.form.fields.messages.success.defaultValue'),
          error: this.$t('forms.form.fields.messages.error.defaultValue')
        },
        callTracking: {
          ctmID: '',
          phone: '',
          maskPhone: '',
          textTop: '',
          textBottom: ''
        },
        formPolicy: false
      }
      this.showForm = true
    },

    openEditForm(item) {
      this.formData = {
        id: item.id,
        title: item.title,
        launcher: item.launcher,
        color: item.color,
        submit: item.submit,
        messages: item.messages,
        campaignId: item.campaignId,
        fields: item.fields,
        callTracking: item.callTracking,
        formPolicy: item.formPolicy
      }

      this.showForm = true
    },

    closeForm() {
      this.formData = {}
      this.formErrors = {}
      this.showForm = false
    },

    saveForm(data) {
      this.formErrors = {}

      if (this.formMode === 'edit') {
        this.updateForm(data)
      } else {
        this.createForm(data)
      }
    },

    confirmDelete(form) {
      openModalConfirmRemove(
        this.t('deleteConfirmationModal.title'),
        this.t('deleteConfirmationModal.body'),
        this.t('deleteConfirmationModal.okButton')
      ).then(() => {
        this.destroyForm(form)
      })
    },

    destroyForm(form) {
      API.campaigns.forms.destroy(form.campaignId, form.id).then(() => {
        this.loadForms()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    createForm(data) {
      API.campaigns.forms.create(data.campaignId, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.loadForms()
        this.closeForm()
      }, errors => {
        this.formErrors = errors
      })
    },

    updateForm(data) {
      API.campaigns.forms.update(data.campaignId, data.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyUpdated'))
        this.loadForms()
        this.closeForm()
      }, errors => {
        this.formErrors = errors
      })
    },

    openConditionForm(form) {
      Object.keys(this.conditionData).forEach(key => {
        this.conditionData[key] = null
      })
      this.conditionData.fieldType = FIELD_TYPES.NUMERIC
      this.formToCreateCondition = form
    },

    saveCondition(formData) {
      this.conditionErrors = {}

      const data = {
        ...formData,
        formId: this.formToCreateCondition.id
      }

      API.campaigns.conditions.create(this.campaignId, data).then(() => {
        this.formToCreateCondition.conditions.push(data)
        this.loadForms()
        this.$notifications.success(this.$t('conditions.succesfullyCreated'))
        this.closeConditionForm()
      }, errors => {
        this.conditionErrors = errors
      })
    },

    // Dependiendo del tipo de campo, las traducciones cambian
    conditionOperatorText(condition) {
      return this.$t(`comparisonOperators.${condition.fieldType}.${condition.comparisonOperator}`)
    },

    closeConditionForm() {
      this.formToCreateCondition = null
      this.conditionErrors = {}
    },

    removeCondition(form, condition) {
      openModalConfirmRemove(
        this.t('card.deleteConditionConfirmationModal.title'),
        this.t('card.deleteConditionConfirmationModal.body'),
        this.t('card.deleteConditionConfirmationModal.okButton')
      ).then(() => {
        API.campaigns.conditions.destroy(this.campaignId, condition.id).then(() => {
          form.conditions = form.conditions.filter(item => item.id !== condition.id)
          this.$notifications.success(this.$t('conditions.succesfullyDeleted'))
        })
      })
    },

    t(key, options = {}) {
      return this.$t(`forms.index.${key}`, options)
    }
  }
})
