import ddisTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    ddisTable
  },
  data() {
    return {
      ddis: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('smsTemplates.index.title'))
    this.$setBreadcrumbs([
      {label: this.$t('breadcrumbs.smsTemplates.index'), route: {}}
    ])
  },
  methods: {
    loadSmsTemplates(queryOptions = {}) {
      API.smsTemplates.index(queryOptions).then(({data, meta}) => {
        this.ddis = data
        this.meta = meta
      })
    }
  }
})
