import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './start.pug'

const DefaultData = () => ({
  config: {
    trackingNumbers: []
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      selectedOptions: [],
      options: []
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
        this.getTrackingNumbers()
      }
    }

  },
  methods: {
    getTrackingNumbers() {
      console.log(this.$route)
        // eslint-disable-next-line max-len
        var automationId = this.$route.params.automationId;
        if(this.$route.params.automationId == undefined)
          {
            automationId = '00000000-0000-0000-0000-000000000000';
          }

        return API.campaigns.trackingNumbers.availableAutomation(this.$route.params.id,automationId, {page: {number: 1, size: 1000}}).then(({data}) => {
          this.options = data.map(trackingNumber => ({
            label: trackingNumber.trackingNumberId,
            value: trackingNumber.trackingNumberId
          }))

          if (this.data.config.trackingNumbers) {
            this.selectedOptions = this.data.config.trackingNumbers.map(el => el.trackingNumberId)
          }
        })
    },

    unselectedFilter(options, text) {
      return options.filter(item => item.label.includes(text))
    },

    save() {
      validatePresence(this.data, [
        'config.trackingNumbers'
      ])

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.data.config.trackingNumbers = this.selectedOptions.map(id => ({
          trackingNumberId: id
        }))

        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.start.${key}`, options)
    }
  }
})
