import RouteParent from 'js/router/route-parent'

import ProductsIndex from 'js/pages/products/index.js'
import ProductsNew from 'js/pages/products/new.js'
import ProductsEdit from 'js/pages/products/edit.js'
import ProductsShow from 'js/pages/products/show.js'

import ProductsCampaignsIndex from 'js/pages/campaigns/product-index.js'

export default [
  {
    path: '/products',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'products-index',
        component: ProductsIndex,
        meta: {
          auth: true,
          permit: ['VIEW_PRODUCTS']
        }
      },
      {
        path: 'new',
        name: 'products-new',
        component: ProductsNew,
        meta: {
          auth: true,
          permit: ['MANAGE_PRODUCTS']
        }
      },
      {
        path: ':id/edit',
        name: 'products-edit',
        component: ProductsEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_PRODUCTS']
        }
      },
      {
        path: ':id/campaigns',
        name: 'products-campaigns',
        component: ProductsCampaignsIndex,
        meta: {
          auth: true,
          permit: ['VIEW_PRODUCTS', 'LIST_CAMPAIGNS']
        }
      },
      {
        path: ':id',
        name: 'products-show',
        component: ProductsShow,
        meta: {
          auth: true,
          permit: ['VIEW_PRODUCTS']
        }
      }
    ]
  }
]
