const localeRoot = 'analytics.charts.modelCategories.attemptsByCategoryLine'

export default {
  options: data => {
    if (!data || data.length === 0) return null
    const leadsCount = {
      a: data.reduce((acc, item) => acc + item.aLeadsCount, 0),
      b: data.reduce((acc, item) => acc + item.bLeadsCount, 0),
      c: data.reduce((acc, item) => acc + item.cLeadsCount, 0),
      d: data.reduce((acc, item) => acc + item.dLeadsCount, 0)
    }
    const turnsCount = {
      a: data.reduce((acc, item) => acc + item.anTurnsCount, 0),
      b: data.reduce((acc, item) => acc + item.bnTurnsCount, 0),
      c: data.reduce((acc, item) => acc + item.cnTurnsCount, 0),
      d: data.reduce((acc, item) => acc + item.dnTurnsCount, 0)
    }
    const averageSales = {
      a: (data.reduce((acc, item) => acc + item.aSalesCount, 0) * 100) / leadsCount.a,
      b: (data.reduce((acc, item) => acc + item.bSalesCount, 0) * 100) / leadsCount.b,
      c: (data.reduce((acc, item) => acc + item.cSalesCount, 0) * 100) / leadsCount.c,
      d: (data.reduce((acc, item) => acc + item.dSalesCount, 0) * 100) / leadsCount.d
    }
    return {
      grid: {
        top: '13%',
        left: '3%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: v => v.reduce(
          (acc, item) => {
            if (item.seriesName === Vue.i18n.t(`${localeRoot}.series.nTurns`)) {
              return `${acc}${item.seriesName}: ${item.value}<br />`
            }
            // eslint-disable-next-line max-len
            return `${acc}${item.seriesName}: ${parseFloat(item.value).toFixed(1)}%<br />`
          }, `${v[0].name}<br />`
          )
      },
      xAxis: [
        {
          type: 'category',
          data: ['A', 'B', 'C', 'D'],
          axisLabel: {
            margin: 10,
            align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: Vue.i18n.t(`${localeRoot}.series.averageSales`),
          type: 'line',
          data: Object.entries(averageSales).map(([_key, value]) => (value)),
          smooth: true
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.nTurns`),
          type: 'line',
          data: Object.entries(turnsCount).map(([_key, value]) => (value)),
          smooth: true
        }
      ],
      media: [
        {
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        data: [
          Vue.i18n.t(`${localeRoot}.series.averageSales`),
          Vue.i18n.t(`${localeRoot}.series.nTurns`)
        ],
        icon: 'circle',
        bottom: '3%'
      },
      color: GRAPH_COLORS
    }
  }
}
