export default {
  install(Vue) {
    Vue.auth = {
      token() {
        return localStorage.getItem('_rails-vue-session-token')
      },

      setToken(newToken) {
        localStorage.setItem('_rails-vue-session-token', newToken)
      },

      login(data, options = {errorsRootKey: 'auth.login'}) {
        return Vue.http.post('authentication/login', data, options)
      },

      logout() {
        Vue.http.post('authentication/logout', {skipErrors: true}).finally(
          () => {
            this.setToken('')
            Vue.store.commit('setCurrentUser', null)
            Vue.router.push({name: 'login'})
          }
        )

        // Cerrar conexión de WS y vaciar variable
        if (Vue.$cableConnection) {
          Vue.$cableConnection.disconnect()
          Vue.$cableConnection = null
        }
      },

      showPasswordToken(token, options = {}) {
        const optionsDefault = {skipErrors: true}
        Object.assign(options, optionsDefault)

        return Vue.http.get(`authentication/reset-password/${token}`, options)
      },

      createPasswordToken(data, options = {}) {
        const optionsDefault = {skipErrors: true, errorsRootKey: 'auth.forgotPassword'}
        Object.assign(options, optionsDefault)

        return Vue.http.post('authentication/forgot-password', data, options)
      },

      updatePasswordToken(data, options = {}) {
        const optionsDefault = {skipErrors: true, errorsRootKey: 'auth.resetPassword'}
        Object.assign(options, optionsDefault)

        return Vue.http.post('authentication/reset-password', data, options)
      },

      async updateInvitation(id, data, options = {}) {
        Object.assign(options, {errorsRootKey: 'auth.acceptInvitation'})

        return Vue.http.put(`invitations/${id}`, {data}, options).then(
          response => response.data.data,
          response => { throw response.data.errors }
        )
      },

      async showInvitation(id, options = {}) {
        const defaultParams = {skipErrors: true, errorsRootKey: 'auth.acceptInvitation'}
        Object.assign(options, defaultParams)

        const response = await Vue.http.get(`invitations/${id}`, options)

        return response.data.data
      },

      confirmation(token, options = {}) {
        const optionsDefault = {skipErrors: true, errorsRootKey: 'auth.confirmationEmail'}
        Object.assign(options, optionsDefault)

        return Vue.http.post('authentication/confirm-email', {token}, options)
      }
    }

    Vue.prototype.$auth = Vue.auth
  }
}
