import template from './_list-file-form.pug'

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: () => ({})
    },
    campaignId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      data: {},
      errors: {},
      whitelist: ['xlsx', 'xls', 'csv'],
      previousImportRequest: null,
      uploadingFile: false,
      uploadedFile: false,
      file: null
    }
  },
  computed: {
    disabledAdd() {
      return this.file === null
    }
  },
  methods: {
    abortAddFile() {
      if (this.previousImportRequest && !MOCKS) {
        this.previousImportRequest.abort()
      }
    },
    changeFile() {
      this.uploadingFile = false
      this.uploadedFile = false
      this.fileHeaders = []
      this.fileRows = []
      this.previewHeaders = []
      this.previewRows = []
      this.fileId = null
    },

    addErrorFile(errors) {
      const error = errors.includes('invalidFileType') ? 'invalidFileType' : errors[0]

      if (error) {
        this.$notifications.error(
          this.t(`file.errors.${error}`)
        )
      }
    },

    uploadFile() {
      this.fileAdded()
    },

    cancelUploadFile() {
      this.abortAddFile()
      this.file = null
      this.uploadingFile = false
    },

    async fileAdded() {
      this.errors.file = null
      this.uploadingFile = true
      this.abortAddFile()

      const data = new FormData()
      data.append('file', this.file)

      // Añadimos esta espera para que cuando se ejecuta con MOCKS
      // se vea el loading
      if (MOCKS) await new Promise(res => setTimeout(res, 3000))

      await this.$http.post(
        `campaigns/${this.campaignId}/leadModels/upload-file`,
        data,
        {
          before: request => { this.previousImportRequest = request },
          skipLoading: true
        }
      ).then(() => {
        this.uploadedFile = true
        this.$notifications.success(this.t('succesfullyCreated'))
        this.$emit('load')
        this.close()
      }).catch(e => {
          console.log(e)
      })
    },

    close() {
      this.$emit('close')
    },

    submit() {
      this.$emit('submit', this.data)
    },

    t(key, options = {}) {
      // eslint-disable-next-line max-len
      return this.$t(`campaigns.show.analyticsModel.tabs.matrixModel.phoneListComponent.${key}`, options)
    }
  }
})
