import pick from 'lodash/pick'
import {showConditions} from '../campaign-conditions/factory.js'

const [condition1, condition2, condition3] = showConditions

const collection = [
  {
    id: 'form1',
    title: 'Formulario de inicio',
    color: '#ff0000',
    launcher: 'phone',
    campaignId: 'campaign1',
    submit: 'Enviar',
    default: true,
    messages: {
      success: 'Información enviada con éxito',
      error: 'Información errónea'
    },
    fields: [
      {
        id: '1',
        name: 'name',
        label: 'Nombre',
        fieldType: FIELD_TYPES.TEXT,
        fieldValidation: FIELD_VALIDATIONS.TEXT,
        leadField: LEAD_FIELDS.FIRST_NAME,
        required: true
      },
      {
        id: '2',
        name: 'email',
        label: 'Email',
        fieldType: FIELD_TYPES.TEXT,
        fieldValidation: FIELD_VALIDATIONS.TEXT,
        leadField: LEAD_FIELDS.EMAIL,
        required: true
      }
    ],
    conditions: [
      condition1 // definida en mocks/campaign-conditions/factory.js
    ],
    callTracking: {
      ctmID: '456789',
      phone: '678987678',
      maskPhone: '(###) ### ###',
      textTop: 'Texto de la parte superior',
      textBottom: 'Texto de la parte inferior'
    },
    formPolicy: false
  },
  {
    id: 'form2',
    title: 'Formulario de satisfacion',
    color: '#ee0000',
    launcher: 'phone',
    campaignId: 'campaign1',
    submit: 'Enviar',
    messages: {
      success: 'Información enviada con éxito',
      error: 'Información errónea'
    },
    fields: [
      {
        id: '3',
        name: 'name',
        label: 'Nombre',
        fieldType: FIELD_TYPES.TEXT,
        fieldValidation: FIELD_VALIDATIONS.TEXT,
        leadField: LEAD_FIELDS.FIRST_NAME,
        required: true
      },
      {
        id: '4',
        name: 'email',
        label: 'Email',
        fieldType: FIELD_TYPES.TEXT,
        fieldValidation: FIELD_VALIDATIONS.TEXT,
        leadField: LEAD_FIELDS.EMAIL,
        required: true
      }
    ],
    conditions: [
      condition2 // definida en mocks/campaign-conditions/factory.js
    ],
    callTracking: {
      ctmID: '456789',
      phone: '678987678',
      maskPhone: '(###) ### ###',
      textTop: 'Texto de la parte superior',
      textBottom: 'Texto de la parte inferior'
    },
    formPolicy: {
      label: 'Acepto la politica de privacidad',
      link: ''
    }
  },
  {
    id: 'form3',
    title: 'Formulario de recomendación',
    color: '#000000',
    launcher: 'phone',
    campaignId: 'campaign1',
    submit: 'Te llamamos',
    messages: {
      success: 'En un momento recibirás nuestra llamada',
      error: 'Ha ocurrido algún error'
    },
    fields: [],
    conditions: [],
    callTracking: {
      ctmID: '456789',
      phone: '678987678',
      maskPhone: '(###) ### ###',
      textTop: 'Texto de la parte superior',
      textBottom: 'Texto de la parte inferior'
    },
    formPolicy: {
      label: 'Acepto la politica de privacidad',
      link: ''
    }
  },
  {
    id: 'form4',
    title: 'Formulario de inicioo',
    campaignId: 'campaign2',
    default: true,
    color: 'red',
    launcher: 'phone',
    submit: 'Te llamamos',
    messages: {
      success: 'En un momento recibirás nuestra llamada',
      error: 'Ha ocurrido algún error'
    },
    fields: [
      {
        id: '5',
        name: 'name',
        fieldType: FIELD_TYPES.TEXT,
        fieldValidation: FIELD_VALIDATIONS.TEXT,
        leadField: LEAD_FIELDS.FIRST_NAME,
        label: 'Nombre',
        validation: 'string',
        required: true
      }
    ],
    conditions: [
      condition3 // definida en mocks/campaign-conditions/factory.js
    ],
    callTracking: {
      ctmID: '456789',
      phone: '678987678',
      maskPhone: '(###) ### ###',
      textTop: 'Texto de la parte superior',
      textBottom: 'Texto de la parte inferior'
    },
    formPolicy: false
  },
  {
    id: 'form5',
    title: 'Formulario de satisfacion',
    campaignId: 'campaign2',
    color: 'red',
    launcher: 'phone',
    submit: 'Te llamamos',
    messages: {
      success: 'En un momento recibirás nuestra llamada',
      error: 'Ha ocurrido algún error'
    },
    fields: [
      {
        id: '6',
        name: 'name',
        fieldType: FIELD_TYPES.TEXT,
        fieldValidation: FIELD_VALIDATIONS.TEXT,
        leadField: LEAD_FIELDS.FIRST_NAME,
        label: 'Nombre',
        validation: 'string',
        required: true
      }
    ],
    callTracking: {
      ctmID: '456789',
      phone: '678987678',
      maskPhone: '(###) ### ###',
      textTop: 'Texto de la parte superior',
      textBottom: 'Texto de la parte inferior'
    },
    formPolicy: {
      label: 'Acepto la politica de privacidad',
      link: ''
    }
  },
  {
    id: 'form6',
    title: 'Formulario de recomendación',
    campaignId: 'campaign2',
    color: 'red',
    launcher: 'phone',
    submit: 'Te llamamos',
    messages: {
      success: 'En un momento recibirás nuestra llamada',
      error: 'Ha ocurrido algún error'
    },
    fields: [],
    callTracking: {
      ctmID: '456789',
      phone: '678987678',
      maskPhone: '(###) ### ###',
      textTop: 'Texto de la parte superior',
      textBottom: 'Texto de la parte inferior'
    },
    formPolicy: {
      label: 'Acepto la politica de privacidad',
      link: ''
    }
  }
]

// el show incluye todos los campos
const showCampaignForms = collection

// seleccionamos solo aquellos campos del index
const indexCampaignForms = collection.map(item => pick(item, [
  'id',
  'default',
  'title',
  'color',
  'launcher',
  'campaignId',
  'fields',
  'conditions',
  'submit',
  'messages',
  'callTracking',
  'formPolicy'
]))

const validations = {
  title: {type: 'string', required: true, maxLength: 254},
  submit: {type: 'string', required: true, maxLength: 64},
  messages: {
    type: 'object',
    required: true,
    objectValidations: {
      success: {type: 'string', required: true, maxLength: 254},
      error: {type: 'string', required: true, maxLength: 254}
    }
  },
  fields: {
    type: 'objectArray',
    required: true,
    objectValidations: {
      name: {type: 'string', required: true, maxLength: 64},
      label: {type: 'string', required: true, maxLength: 64},
      fieldType: {type: 'string', required: true, inclusion: Object.values(FIELD_TYPES)},
      validation: {type: 'string', inclusion: Object.values(FIELD_VALIDATIONS)},
      required: {type: 'boolean'},
      leadField: {type: 'string', required: true, inclusion: Object.values(LEAD_FIELDS)}
    }
  },
  callTracking: {
    type: 'object',
    required: true,
    objectValidations: {
      ctmID: {type: 'string', required: true, maxLength: 10},
      phone: {type: 'string', required: true, maxLength: 9},
      maskPhone: {type: 'string'},
      textTop: {type: 'string', maxLength: 100},
      textBottom: {type: 'string', maxLength: 100}
    }
  }
}

export {showCampaignForms, indexCampaignForms, validations}
