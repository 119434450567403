import camelCase from 'lodash/camelCase'
import template from './root.pug'
import demandTable from './demand-table'
import {generateMonthStructureForDemand} from './demand-table/helpers'

const TABS = {
  'offer-index': 'offer',
  'order-index': 'order',
  'provisioned-index': 'provisioned',
  'offer-order-index': 'offer-order'
}
export default Vue.extend({
  template: template(),
  components: {
    demandTable
  },
  data() {
    return {
      data: {},
      demand: [],
      monthsUsed: {},
      ready: false,
      currentTab: 'offer-index'
    }
  },
  computed: {
    tabsOptions() {
      const tabs = []
      if (this.$user.can('VIEW_OFFER')) {
        tabs.push('offer-index')
      }
      if (this.$user.can('VIEW_PURCHASE_ORDER')) {
        // tabs.push('order-index')
        tabs.push('offer-order-index')
      }
      return tabs.map(item => ({
        value: item,
        label: this.t(`${camelCase(TABS[item])}.tabTitle`)
      }))
    },
    showTabs() {
      return TABS[this.$route.name] || false
    }
  },
  watch: {
    '$route.query': {
      handler() {
       if (this.$route.query.reload) {
        this.loadData()
       }
      }
    },
    '$route.path': {
      immediate: true,
      handler() {
        this.currentTab = this.$route.name
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    getRouteCurrentTab(route) {
      return {name: route}
    },

    loadData() {

        API.leadTrade.show(this.$route.params.id).then(response => {
          this.demand = []
          this.monthsUsed = {}
          this.data = {}

          this.data = response
          const {demands, monthsUsed} = generateMonthStructureForDemand(response.demandOverTimes)
          const demand = demands
          this.monthsUsed = monthsUsed
          this.demand.push(demand)
          this.ready = true
        })
    },

    t(key, options = {}) {
      return this.$t(`leadtrade.${key}`, options)
    },

    setCurrentTab(tab) {
      return this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
