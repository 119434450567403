import pickBy from 'lodash/pickBy'
import {mapGetters} from 'vuex'

import template from './info-tab.pug'

export default Vue.extend({
  template: template(),
  props: {
    customer: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(['customerActivities']),

    showBilling() {
      // TODO: eliminar cuando se implemente la facturación
      return MOCKS
    },

    canManage() {
      return this.$user.permissions.includes(PERMISSIONS.MANAGE_CUSTOMERS)
    },

    canShowCampaigns() {
      return this.$user.permissions.includes('LIST_CAMPAIGNS')
    },

    canShowProducts() {
      return this.$user.permissions.includes('VIEW_PRODUCTS')
    },

    canShowCallCenters() {
      return this.$user.permissions.includes('VIEW_CALL_CENTERS')
    },

    countryFlagClass() {
      return this.customer.country ? `flag-icon-${this.customer.country.toLowerCase()}` : null
    },

    servicesIntegrated() {
      return Object.keys(
        pickBy(this.customer.integrations, integration => (!!integration))
      ).map(key => ({
        label: this.t(`integrations.${key}.label`, {count: this.customer.integrations[key]}),

        icon: (() => {
          try {
            // eslint-disable-next-line import/no-dynamic-require, global-require
            return require(`assets/images/logos/${this.t(`integrations.${key}.icon`)}.svg`)
          } catch (e) {
            // eslint-disable-next-line import/no-dynamic-require, global-require
            return require(`assets/images/logos/${this.t(`integrations.${key}.icon`)}.png`)
          }
        })()

      }))
    },

    servicesNotIntegrated() {
      return Object.keys(
        pickBy(this.customer.integrations, integration => (!integration))
      ).map(key => ({
        label: this.t(`integrations.${key}.label`),

        icon: (() => {
          try {
            // eslint-disable-next-line import/no-dynamic-require, global-require
            return require(`assets/images/logos/${this.t(`integrations.${key}.icon`)}.svg`)
          } catch (e) {
            // eslint-disable-next-line import/no-dynamic-require, global-require
            return require(`assets/images/logos/${this.t(`integrations.${key}.icon`)}.png`)
          }
        })()

      }))
    },

    activityTypes() {
      const activityTypes = {}

      this.customerActivities.forEach(activity => {
        activityTypes[activity.id] = this.$t(`customerActivities.${activity.key}`)
      })

      return activityTypes
    },

    ready() {
      return !!this.customer
    }
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.show.info.${key}`, options)
    },

    downloadPdf() {
      // Fuera de alcance
    }
  }
})
