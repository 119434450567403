import template from './_form.pug'

const Fields = ['id', 'name', 'customerId', 'description']

export default Vue.extend({
  template: template(),
  props: {
    initData: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: {},
      customersOptions: []
    }
  },
  computed: {
    modeForm() {
      return this.data.id ? 'edit' : 'new'
    },

    isEditing() {
      return this.modeForm === 'edit'
    },

    saveButtonText() {
      return this.t(`saveButton.${this.modeForm}`)
    },

    customerInfoText() {
      return this.t(`fields.customerId.infoCustomer.${this.modeForm}`)
    }
  },
  watch: {
    initData: {
      immediate: true,
      handler() {
        this.initializeData()
      }
    }
  },
  created() {
    this.loadCustomers()
  },
  methods: {
    initializeData() {
      const data = {}

      Fields.forEach(key => {
        data[key] = this.initData ? this.initData[key] : ''
      })

      this.data = data
    },

    loadCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customersOptions = data.map(customer => ({
          label: customer.name,
          value: customer.id
        }))
      })
    },

    t(key, options = {}) {
      return this.$t(`products.form.${key}`, options)
    },

    submit() {
      this.$emit('submit', this.data)
    },

    cancel() {
      this.$router.push({name: 'products-index'})
    }
  }
})
