import camelCase from 'lodash/camelCase'
import template from './validations.pug'

export default Vue.extend({
  template: template(),
  props: {
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentTab: this.$route.name.split('-').pop(),
      campaignTags: []
    }
  },
  computed: {
    tabsOptions() {
      return [
        'summary',
        'duplicated',
        'validationRobinson',
        'listRobinson',
        'validationBlackList',
        'blackList',
        'validationClientPortfolio',
        'clientPortfolioList',
        'treatment'
      ].map(item => ({
        value: item,
        label: this.t(`tabs.${camelCase(item)}.tabTitle`)
      }))
    }
  },
  created() {
    this.loadCampaignTags()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`campaigns.show.validations.${key}`, options)
    },

    getRouteCurrentTab(tab) {
      return {name: `campaigns-show-validations-${tab}`, params: {id: this.campaign.id}}
    },

    loadCampaignTags() {
      const params = {page: {number: 1, size: PAGINATION_ALL}}

      return API.campaigns.tags.index(this.campaign.id, params, {skipLoading: true}).then(
        response => { this.campaignTags = response.data }
      )
    },

    setCurrentTab(tab) {
      return this.$router.push(this.getRouteCurrentTab(tab))
    }
  }
})
