import RouteParentIndex from 'js/router/route-parent'

import RouteParent from 'js/pages/invoicing/root.js'

const InvoicingIndex = () => import('js/pages/invoicing/index.js')
const InvoicingNew = () => import('js/pages/invoicing/new.js')
const InvoicingEdit = () => import('js/pages/invoicing/edit.js')
const InvoicingShow = () => import('js/pages/invoicing/show.js')

const AgreementIndex = () => import('js/pages/invoicing/agreement/index.js')
const AgreementNew = () => import('js/pages/invoicing/agreement/new.js')
const AgreementtEdit = () => import('js/pages/invoicing/agreement/edit.js')
const AgreementtShow = () => import('js/pages/invoicing/agreement/show.js')

const TaxIndex = () => import('js/pages/invoicing/tax/index.js')
const TaxNew = () => import('js/pages/invoicing/tax/new.js')
const TaxtEdit = () => import('js/pages/invoicing/tax/edit.js')

const SectorIndex = () => import('js/pages/invoicing/sector/index.js')
const SectorNew = () => import('js/pages/invoicing/sector/new.js')
const SectorEdit = () => import('js/pages/invoicing/sector/edit.js')

const FamilyIndex = () => import('js/pages/invoicing/family/index.js')
const FamilyNew = () => import('js/pages/invoicing/family/new.js')
const FamilyEdit = () => import('js/pages/invoicing/family/edit.js')

const WayToPayIndex = () => import('js/pages/invoicing/way-to-pay/index.js')
const WayToPayNew = () => import('js/pages/invoicing/way-to-pay/new.js')
const WayToPaytEdit = () => import('js/pages/invoicing/way-to-pay/edit.js')

const PaymentViaIndex = () => import('js/pages/invoicing/payment-via/index.js')
const PaymentViaNew = () => import('js/pages/invoicing/payment-via/new.js')
const PaymentViaEdit = () => import('js/pages/invoicing/payment-via/edit.js')

const ConfigIndex = () => import('js/pages/invoicing/config/index.js')
const ConfigNew = () => import('js/pages/invoicing/config/new.js')
const ConfigEdit = () => import('js/pages/invoicing/config/edit.js')
const ConfigShow = () => import('js/pages/invoicing/config/show.js')

const MANAGE_LAIA_ADMIN = 'MANAGE_LAIA_ADMIN'
export default [
  {
    path: '/invoicing',
    component: RouteParent,
    meta: {
      auth: true,
      permit: ['']
    },
    children: [
      {
        path: '',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'invoicing-index',
            component: InvoicingIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'invoicing-new',
            component: InvoicingNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'invoicing-edit',
            component: InvoicingEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/show',
            name: 'invoicing-show',
            component: InvoicingShow,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      },
      {
        path: 'agreement',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'agreement-index',
            component: AgreementIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'agreement-new',
            component: AgreementNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'agreement-edit',
            component: AgreementtEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/show',
            name: 'agreement-show',
            component: AgreementtShow,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      },
      {
        path: 'tax',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'tax-index',
            component: TaxIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'tax-new',
            component: TaxNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'tax-edit',
            component: TaxtEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      },
      {
        path: 'sector',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'sector-index',
            component: SectorIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'sector-new',
            component: SectorNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'sector-edit',
            component: SectorEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      },
      {
        path: 'family',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'family-index',
            component: FamilyIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'family-new',
            component: FamilyNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'family-edit',
            component: FamilyEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      },
      {
        path: 'way-to-pay',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'way-to-pay-index',
            component: WayToPayIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'way-to-pay-new',
            component: WayToPayNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'way-to-pay-edit',
            component: WayToPaytEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      },
      {
        path: 'payment-via',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'payment-via-index',
            component: PaymentViaIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'payment-via-new',
            component: PaymentViaNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'payment-via-edit',
            component: PaymentViaEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      },
      {
        path: 'config',
        component: RouteParentIndex,
        children: [
          {
            path: '',
            name: 'config-index',
            component: ConfigIndex,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: 'new',
            name: 'config-new',
            component: ConfigNew,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/edit',
            name: 'config-edit',
            component: ConfigEdit,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          },
          {
            path: ':id/show',
            name: 'config-show',
            component: ConfigShow,
            meta: {
              auth: true,
              permit: [MANAGE_LAIA_ADMIN]
            }
          }
        ]
      }
    ]
  }
]
