import Vue from 'vue'

const localeRoot = 'analytics.charts.modelCategories.effectivenessByCategoryBar'

export default {
  options: data => {
    if (!data) return null
    if (data.length === 0) return null

    const loadedLeads = {
      a: data.reduce((acc, item) => acc + item.aLeadsCount, 0),
      b: data.reduce((acc, item) => acc + item.bLeadsCount, 0),
      c: data.reduce((acc, item) => acc + item.cLeadsCount, 0),
      d: data.reduce((acc, item) => acc + item.dLeadsCount, 0)
    }

    const contactability = {
      a: data.reduce((acc, item) => acc + item.aContactsCount, 0),
      b: data.reduce((acc, item) => acc + item.bContactsCount, 0),
      c: data.reduce((acc, item) => acc + item.cContactsCount, 0),
      d: data.reduce((acc, item) => acc + item.dContactsCount, 0)
    }

    const salesCount = {
      a: data.reduce((acc, item) => acc + item.aSalesCount, 0),
      b: data.reduce((acc, item) => acc + item.bSalesCount, 0),
      c: data.reduce((acc, item) => acc + item.cSalesCount, 0),
      d: data.reduce((acc, item) => acc + item.dSalesCount, 0)
    }

    return {
      grid: {
        top: '13%',
        left: '4%',
        right: '4%',
        bottom: '10%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: v => v.reduce(
            (acc, item) => {
              if (item.seriesName === Vue.i18n.t(`${localeRoot}.series.loadedLeads`)) {
                return `${acc}${item.seriesName}: ${item.value}<br />`
              }
              // eslint-disable-next-line max-len
              return `${acc}${item.seriesName}: ${parseFloat((Number(item.value) * 100) / v[0].value).toFixed(1)}%<br />`
            }, `${v[0].name}<br />`
            )
      },
      xAxis: [
        {
          type: 'category',
          data: ['A', 'B', 'C', 'D'],
          axisLabel: {
            margin: 10,
            align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: Vue.i18n.t(`${localeRoot}.series.loadedLeads`),
          type: 'bar',
          stack: 'Ad',
          barGap: 0,
          data: Object.entries(loadedLeads).map(([_key, value]) => value)
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.contactability`),
          type: 'line',
          data: Object.entries(contactability).map(([_key, value]) => value)
        },
        {
          name: Vue.i18n.t(`${localeRoot}.series.uniqueSales`),
          type: 'line',
          data: Object.entries(salesCount).map(([_key, value]) => value)
        }
      ],
      media: [
        {
          query: {
            maxWidth: 500
          },
          option: {
            title: {
              textStyle: {
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 'normal',
                overflow: 'break',
                width: 270
              }
            }
          }
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        icon: 'circle',
        bottom: '3%'
      },
      color: GRAPH_COLORS
    }
  }
}
