import AutomationForm from './_form.js'

import template from './new.pug'

export default Vue.extend({
  template: template(),
  components: {
    AutomationForm
  },
  props: {
    campaign: {type: Object, default: () => ({})}
  },
  data() {
    return {
      errors: {}
    }
  },
  created() {
    this.$insertBreadcrumb({
      label: this.$t('breadcrumbs.automations.new'), route: {}
    })
  },
  methods: {
    save(data) {
      this.errors = {}

      API.automations.create(this.campaign.id, data).then(() => {
        this.$notifications.success(this.t('succesfullyCreated'))
        this.$router.push({name: 'campaigns-show-automations'})
      }, errors => {
        this.errors = errors
      })
    },

    cancel() {
      this.$router.push({name: 'campaigns-show-automations'})
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.new.${key}`, options)
    }
  }
})
