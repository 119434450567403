import OfferForm from './_form'
import template from './edit.pug'

export default Vue.extend({
  template: template(),
  components: {
    OfferForm
  },
  props: {
    demandData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      ready: false,
      data: {},
      errors: {}
    }
  },
  watch: {
    '$route.params.offerid': {
      immediate: true,
      handler() {
        this.$setPageTitle(this.$t('leadtrade.offer.edit.title'))
        this.loadData()
      }
    }
  },

  methods: {
    loadData() {
      this.ready = false

      API.offerPurchase.show(this.$route.params.offerid).then(response => {
        this.data = response

        this.$setBreadcrumbs([
          {
            label: this.$t('breadcrumbs.offer-and-demand.index'),
            route: {name: 'offer-and-demand-index'}
          },
          {
            label: this.$t('breadcrumbs.offer.index'),
            route: {name: 'offer-index'}
          },
          {
            label: this.$t('breadcrumbs.offer.edit',
              {
                name: ''
              }),
            route: {}
          }
        ])

        this.ready = true
      })
    },

    updateOffer(data) {
      API.offerPurchase.update(this.$route.params.offerid, data).then(() => {
        this.$notifications.success(this.$t('leadtrade.offer.edit.succesfullyUpdated'))
        this.$router.push({name: 'offer-index'})
      }, errors => {
        this.errors = errors
      })
    }
  }
})
