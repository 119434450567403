import dataTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    dataTable
  },
  data() {
    return {
      data: [],
      meta: {}
    }
  },
  created() {
    this.$setPageTitle(this.$t('programationReport.index.title'))
    this.$setBreadcrumbs([
      // eslint-disable-next-line max-len
      {label: this.$t('breadcrumbs.programationReport.index'), route: {name: 'programation-report-index'}}
    ])
  },
  methods: {
    loadDdis(queryOptions = {}) {
      API.programationReport.index(queryOptions).then(({data, meta}) => {
        this.data = data
        this.meta = meta
      })
    }
  }
})
