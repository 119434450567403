import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'
import AnalyticsUtils from 'js/services/analytics-utils'


import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    providers: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      nsFieldTo: '',
      nsFieldFrom: '',
      dateConfig: {
        dateFormat: 'd/m/Y',
        allowInvalidPreload: true,
        enableTime: false
      },
      dateFrom: '',
      dateTo: '',
      modalExport: false,
      showFilters: true,
      filters: {id: [], customerId: []},
      customersOptions: [],
      providerId: '',
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {
    fromDateConfig() {
      return {
        ...this.dateConfig
      }
    },
    toDateConfig() {
      return {
        ...this.dateConfig
      }
    },
    defaultOrder() {
      return {currentField: 'name', currentDir: 'desc'}
    },

    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },
  created() {
  },
  methods: {
    switchModal(id) {
        this.modalExport = true
        this.providerId = id
    },
    closeModal() {
        this.modalExport = false
    },
    exportProviderLeads() {
      if (this.dateTo === '' || this.dateFrom === '') {
        this.nsFieldFrom = this.t('errorRequiredFromDate')
        this.nsFieldTo = this.t('errorRequiredToDate')
      } else {
        this.nsFieldFrom = ''
        this.nsFieldTo = ''

        const queryOptions = {}
         queryOptions.filter = {
          from: moment(this.dateFrom).format(FORMAT_DATE),
          to: moment(this.dateTo).format(FORMAT_DATE),
          providerLeadId: this.providerId
        }

        API.providerLeads
          .exportProviderLeads(queryOptions)
          .then(({data}) => {
            AnalyticsUtils.downloadCsv({
              title: this.t('exportCsv.title'),
              to: queryOptions.to,
              from: queryOptions.from,
              url: data.file
            })
            this.modalExport = false
          })
      }
    },

    t(key, options = {}) {
      return this.$t(`providerLeads.index.${key}`, options)
    },

    confirmDelete(product) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: product.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(product.id))
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    destroy(id) {
      API.providerLeads.destroy(id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    }
  }
})
