import RouteParent from 'js/router/route-parent'

import AccountsIndex from 'js/pages/laia-accounts/index.js'
import AccountsNew from 'js/pages/laia-accounts/new.js'
import AccountsEdit from 'js/pages/laia-accounts/edit.js'
import AccounAssignment from 'js/pages/laia-accounts/assignment.js'
import AccounPermission from 'js/pages/laia-accounts/permission.js'
import AccounNotification from 'js/pages/laia-accounts/notification.js'
import AccounLogsNotification from 'js/pages/laia-accounts/logs/index.js'
import AccountsShow from 'js/pages/laia-accounts/show.js'

export default [
  {
    path: '/admin-laia-accounts',
    component: RouteParent,
    meta: {
      auth: true,
      permit: ['MANAGE_LAIA_ADMIN']
    },
    children: [
      {
        path: '',
        name: 'accounts-laia-index',
        component: AccountsIndex,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: 'new',
        name: 'accounts-laia-new',
        component: AccountsNew,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: ':id/edit',
        name: 'accounts-laia-edit',
        component: AccountsEdit,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: ':id',
        name: 'accounts-laia-show',
        component: AccountsShow,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: ':id/assignment',
        name: 'accounts-laia-assignment',
        component: AccounAssignment,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: ':id/permission',
        name: 'accounts-laia-permission',
        component: AccounPermission,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: ':id/notification',
        name: 'accounts-laia-notification',
        component: AccounNotification,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      },
      {
        path: ':id/logs-notification',
        name: 'accounts-laia-logs-notification',
        component: AccounLogsNotification,
        meta: {
          auth: true,
          permit: ['MANAGE_LAIA_ADMIN']
        }
      }
    ]
  }
]
