import LeadsAccountsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    LeadsAccountsTable
  },
  data() {
    return {
      laiaAccounts: [],
      meta: {}
    }
  },
  created() {
    this.loadAccounts()
    this.$setPageTitle(this.t('title'))
    this.$setBreadcrumbs([
      {label: this.t('breadcrumb'), route: {}}
    ])
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`laiaAccounts.${key}`, options)
    },
    loadAccounts(queryOptions = {}) {
      this.laiaAccounts = []
      this.meta = {}
      API.laiaAccounts.index(queryOptions).then(({data, meta}) => {
        this.laiaAccounts = data
        this.meta = meta
      })
    }
  }
})
