export default {
  errorsRootKey: 'accounts',
  resource: 'accounts',
  actions: ['index', 'create', 'show', 'update'],
  enable(id, params = {}, options = {}) {
    return Vue.http.post(
      `accounts/${id}/enable`,
      {params, ...options}
    ).then(response => response.data)
  },
  disable(id, params = {}, options = {}) {
    return Vue.http.post(
      `accounts/${id}/disable`,
      {params, ...options}
    ).then(response => response.data)
  },
  listNumbers(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/availableTrackingNumbers`,
      {params, ...options}
    ).then(response => response.data)
  },
  assignTrackingNumber(id, data, params = {}, options = {}) {
    return Vue.http.post(
      `accounts/${id}/assignTrackingNumbers`,
      data,
      {params, ...options}
    ).then(response => response.data)
  },
  getAssignTrackingNumber(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/assignedTrackingNumbers`,
      {params, ...options}
    ).then(response => response.data)
  },
  setPermission(id, data, params = {}, options = {}) {
    return Vue.http.post(
      `accounts/${id}/permission`,
      data,
      {params, ...options}
    ).then(response => response.data)
  },
  getPermission(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/permission`,
      {params, ...options}
    ).then(response => response.data)
  },
  users(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/users`,
      {params, ...options}
    ).then(response => response.data)
  },
  getNotificationSettings(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/notification`,
      {params, ...options}
    ).then(response => response.data)
  },
  customers(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/customers`,
      {params, ...options}
    ).then(response => response.data)
  },
  getcustomers(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/getcustomers`,
      {params, ...options}
    ).then(response => response.data)
  },
  setNotificationSettings(id, data, params = {}, options = {}) {
    return Vue.http.post(
      `accounts/${id}/notification`,
      data,
      {params, ...options}
    ).then(response => response.data)
  },
  getNotificationLogs(id, params = {}, options = {}) {
    return Vue.http.get(
      `accounts/${id}/notification/logs`,
      {params, ...options}
    ).then(response => response.data)
  }
}
