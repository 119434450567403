export default {
  // callCenters: {id1: 'NameA', 'id2': 'NameB'}
  // data: [
  //   {date: 'xx1', callCenters: {id1: {totalCount: 10, convertedCount: 5}, id2: ...}},
  //   {date: 'xx2', callCenters: {id1: {totalCount: 15, convertedCount: 8}, id2: ...}},
  //   ...
  // ]
  options: (callCenters, data, localeRoot) => {
    if (!data || data.length === 0) return null

    const series = Object.keys(callCenters).map(callCenterId => ({
      name: callCenters[callCenterId],
      type: 'line',
      data: data.map(item => {
        const dayData = item.callCenters[callCenterId]
        if (!dayData) return 0
        return parseFloat((dayData.convertedCount * 100 / dayData.totalCount).toFixed(2))
      }),
      smooth: true
    }))

    return {
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#212529',
        borderColor: '#212529',
        textStyle: {
          color: 'rgba(255, 255, 255, 1)',
          fontFamily: 'Montserrat'
        },
        formatter: params => {
          let text = `${params[0].axisValue}<br/>`

          params.forEach(serie => {
            text += Vue.i18n.t(
              `${localeRoot}.tooltip`,
              {
                color: serie.color,
                name: serie.seriesName,
                data: Vue.filter('floatRepresentation')(serie.data || '-')
              }
            )
          })

          return text
        }
      },
      xAxis: [
        {
          type: 'category',
          data: data.map(item => item.date),
          axisLabel: {
            rotate: 30,
            margin: 28.5,
            align: 'center',
            // Se añaden espacios al principio para que el ZoomSlider no se superponga
            // No encuentro forma de hacerlo con la configuración
            formatter: value => `        ${value.replace(/\/\d{4}$/, '')}`
          },
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series,
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'empty',
          showDetail: false,
          brushSelect: false
        }
      ],
      legend: {
        textStyle: {
          fontFamily: 'Montserrat'
        },
        data: Object.values(callCenters),
        top: '3%'
      },
      color: GRAPH_COLORS
    }
  }
}
