import CallCenterForm from './_form'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    CallCenterForm
  },
  data() {
    return {
      lastPath: null,
      lastPathParams: {},
      configData: {},
      errors: {}
    }
  },
  beforeRouteEnter(_to, from, next) {
    // se añade el nombre de la ultima dirección, ya que, el boton cancelar debe redirigir
    // a la ultima dirección si esta es el listado de operadores o la creación de operadores
    next(vm => {
      if (['operators-index', 'operators-new', 'operators-edit'].includes(from?.name)) {
        vm.lastPathParams = from.params
        vm.lastPath = from.name
      }
    })
  },
  created() {
    this.$setPageTitle(this.t('title'))
    API.customers.show(this.$route.params.id).then(response => {
      this.customer = response
      this.$setBreadcrumbs([
        {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
        {
          label: this.$t('breadcrumbs.customers.edit', {
          name: response.name
        }),
          route: {
            name: 'customers-edit',
            params: {id: this.$route.params.id}
          }
        },
        {label: this.$t('breadcrumbs.customers.linkMobility'), route: {}}
      ])
    })
    API.smsLinkMobility.configByAccount(this.$route.params.id).then(({data}) => {
      this.configData = data
      this.edit = true
    }).catch(() => {
      this.configData = {
        customerId: '',
        setting: {
          username: '',
          password: '',
          platformId: '',
          platformPartnerId: '',
          linkAPI: ''
        }
      }
      this.edit = false
    })
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.link-mobility.index.${key}`, options)
    },
    createCallCenter(data) {
      if (this.edit) {
        API.smsLinkMobility.update(data.id, data).then(() => {
          this.$notifications.success(this.$t('customers.link-mobility.new.succesfullyCreated'))

          this.$router.push({
            name: 'customers-edit',
            params: {id: this.$route.params.id}
          })
        })
      } else {
        API.smsLinkMobility.create(data).then(() => {
          this.$notifications.success(this.$t('customers.link-mobility.new.succesfullyCreated'))

          this.$router.push({
            name: 'customers-edit',
            params: {id: this.$route.params.id}
          })
        })
      }
    }
  }
})
