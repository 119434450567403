import template from './_modal-save-field.pug'

export default Vue.extend({
  template: template(),
  data() {
    return {
      mode: 'create',
      data: {
        name: null,
        value: null
      },
      errors: {}
    }
  },
  methods: {
    create() {
      this.errors = {}
      if (this.data.name && this.data.value) {
        this.$emit('create', this.data)
      } else {
        if (!this.data.name) {
          this.errors = {name: this.$t('errors.blank')}
        }
        if (!this.data.value) {
          this.errors = {
            ...this.errors,
            value: this.$t('errors.blank')
          }
        }
      }
    },
    t(key, options = {}) {
      return this.$t(`leads.show.field.${key}`, options)
    }
  }
})
