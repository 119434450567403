import Error403 from 'js/pages/errors/403.js'
import Error404 from 'js/pages/errors/404.js'
import Error500 from 'js/pages/errors/500.js'

export default [
  {
    path: '/errors/403',
    name: 'error-403',
    component: Error403,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/errors/404',
    name: 'error-404',
    component: Error404,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/errors/500',
    name: 'error-500',
    component: Error500,
    meta: {
      layout: 'blank'
    }
  }
]
