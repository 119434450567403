import RouteParent from 'js/router/route-parent'
import RouteParentRoot from 'js/pages/offer-and-demand/root.js'

import OfferAndDemandIndex from 'js/pages/offer-and-demand/demand/index.js'
import OfferAndDemandNew from 'js/pages/offer-and-demand/demand/new.js'
import OfferAndDemandEdit from 'js/pages/offer-and-demand/demand/edit.js'

import OfferIndex from 'js/pages/offer-and-demand/offer-proposal/index.js'
import OfferNew from 'js/pages/offer-and-demand/offer-proposal/new.js'
import OfferEdit from 'js/pages/offer-and-demand/offer-proposal/edit.js'

import OfferOrderIndex from 'js/pages/offer-and-demand/offer-order/index.js'
import OfferOrderNew from 'js/pages/offer-and-demand/offer-order/new.js'
import OfferOrderEdit from 'js/pages/offer-and-demand/offer-order/edit.js'

import PurchaseOrder from 'js/pages/offer-and-demand/order/index.js'

export default [
  {
    path: '/offer-and-demand',
    component: RouteParent,
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        name: 'offer-and-demand-index',
        component: OfferAndDemandIndex,
        meta: {
          auth: true,
          permit: ['VIEW_DEMAND']
        }
      },
      {
        path: 'new',
        name: 'offer-and-demand-new',
        component: OfferAndDemandNew,
        meta: {
          auth: true,
          permit: ['CREATE_DEMAND']
        }
      },
      {
        path: ':id/edit',
        name: 'offer-and-demand-edit',
        component: OfferAndDemandEdit,
        meta: {
          auth: true,
          permit: ['EDIT_DEMAND']
        }
      },
      {
        path: ':id',
        component: RouteParentRoot,
        meta: {
          auth: true,
          permit: ['VIEW_OFFER']
        },
        children: [
          {
            path: '',
            name: 'offer-index',
            component: OfferIndex,
            meta: {
              auth: true,
              permit: ['VIEW_OFFER']
            }
          },
          {
            path: 'offer/new',
            name: 'offer-new',
            component: OfferNew,
            meta: {
              auth: true,
              permit: ['CREATE_OFFER']
            }
          },
          {
            path: ':offerid/offer/edit',
            name: 'offer-edit',
            component: OfferEdit,
            meta: {
              auth: true,
              permit: ['EDIT_OFFER']
            }
          },
          {
            path: 'offer/order',
            name: 'offer-order-index',
            component: OfferOrderIndex,
            meta: {
              auth: true,
              permit: ['VIEW_PURCHASE_ORDER']
            }
          },
          {
            path: ':orderid/order-edit',
            name: 'offer-order-edit',
            component: OfferOrderEdit,
            meta: {
              auth: true,
              permit: ['CONFIRM_OFFER']
            }
          },
          {
            path: ':offerid/offer/order-new',
            name: 'offer-order-new',
            component: OfferOrderNew,
            meta: {
              auth: true,
              permit: ['CONFIRM_OFFER']
            }
          },
          {
            path: 'purchase-order',
            name: 'order-index',
            component: PurchaseOrder,
            meta: {
              auth: true,
              permit: ['VIEW_PURCHASE_ORDER']
            }
          }
        ]
      }
    ]
  }
]
