import NotificationsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    NotificationsTable
  },
  data() {
    return {
      notifications: [],
      meta: {}
    }
  },
  created() {   
    this.$setPageTitle(this.t('title'))
    this.$setBreadcrumbs([
      {label: this.t('breadcrumb'), route: {}}
    ])
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`notifications.${key}`, options)
    },
    loadNotifications(queryOptions = {}) {    
      API.notifications.index(queryOptions).then(({data,meta}) => {
        this.notifications = data
        this.meta = meta
      })
    }
  }
})
