import FullTable from 'js/components/full-table/index.js'
import {openModalConfirmRemove} from 'js/services/modals.js'

import template from './_table.pug'

export default Vue.extend({
  template: template(),
  components: {
    FullTable
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    meta: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      showFilters: true,
      filters: {name: '', frequency: '', status: ''},
      frecuencyOptions: [
        {label: 'Mensual', value: 'monthly'},
        {label: 'Semanal', value: 'weekly'},
        {label: 'Diario', value: 'daily'},
        {label: 'Cada hora', value: 'hourly'},
        {label: 'Única', value: 'onlyOnce'}
      ],
      statusOptions: [
        {label: 'Activo', value: 'active'},
        {label: 'Inactivo', value: 'inactive'}
      ],
      lastQueryOptions: null, // guarda los últimos filtros aplicados,
      customers: []
    }
  },
  computed: {
    canManage() {
      return this.$user.permissions.includes('MANAGE_CALL_CENTERS')
    },
    defaultOrder() {
      return {currentField: 'name', currentDir: 'asc'}
    },
    filterButtonParams() {
      if (this.showFilters) {
        return {class: 'active', icon: 'angle-up'}
      }

      return {class: '', icon: 'angle-down'}
    }
  },

  watch: {
    data: {
      immediate: true,
      handler() {
        this.getCustomers()
        this.ready = true
      }
    }
  },
  created() {

  },

  methods: {

    getClassByStatus(status) {
      switch (status) {
        case 'active':
          return 'table-tag--success'
        case 'inactive':
          return 'table-tag--gray'
        default:
          return ''
      }
    },

    getCustomers() {
      API.customers.index({page: {number: 1, size: 1000}}).then(({data}) => {
        this.customers = data
      })
    },

    setCustomerName(id) {
      let customerData = ''
      // eslint-disable-next-line eqeqeq
      customerData = this.customers.filter(el => el.id == id)

      return customerData[0].name
    },

    openConfirmModal(customer) {
      openModalConfirmRemove(
        this.t('confirmationModal.remove.title'),
        this.t('confirmationModal.remove.body', {name: customer.name}),
        this.t('confirmationModal.remove.okButton')
      ).then(() => this.destroy(customer))
    },

    t(key, options = {}) {
      return this.$t(`programationReport.index.${key}`, options)
    },

    destroy(object) {
      API.programationReport.destroy(object.id).then(() => {
        this.getData()
        this.$notifications.success(this.t('succesfullyDeleted'))
      })
    },

    getData(queryOptions) {
      // si borramos un elemento y recargamos, usamos los últimos filtros aplicados
      this.$emit('load-data', queryOptions || this.lastQueryOptions)

      if (queryOptions) this.lastQueryOptions = queryOptions
    },

    getIconByStatus(status) {
      switch (status) {
        case 'active':
          return 'check'
        case 'inactive':
          return 'close'
        default:
          return ''
      }
    },

    getIconByStatusReverse(status) {
      switch (status) {
        case 'active':
          return 'close'
        case 'inactive':
          return 'check'
        default:
          return ''
      }
    },

    getLocaleByStatus(status) {
      switch (status) {
        case 'active':
          return 'disable'
        case 'inactive':
          return 'enable'
        default:
          return ''
      }
    },

    sendPostByStatus(status, id) {
      switch (status) {
        case 'active':
          openModalConfirmRemove(
            this.t('modalConfirm.disable.title'),
            this.t('modalConfirm.disable.body'),
            this.t('modalConfirm.disable.okButton')
          ).then(() => {
            API.programationReport.disable(id).then(() => {
              this.getData()
              this.$notifications.success(this.t('succesfullyDisabled'))
            })
          })
          return ''
          case 'inactive':
            openModalConfirmRemove(
              this.t('modalConfirm.enable.title'),
              this.t('modalConfirm.enable.body'),
              this.t('modalConfirm.enable.okButton')
            ).then(() => {
              API.programationReport.enable(id).then(() => {
                this.getData()
                this.$notifications.success(this.t('succesfullyEnabled'))
              })
            })
            return 'enable'
        default:
          return ''
      }
    },

    noHaveCampaigns(status) {
      return status === CUSTOMER_STATUSES.NO_CAMPAIGNS
    }
  }
})
