import {openModalConfirm} from 'js/services/modals.js'
import Message from './_message'
import template from './reset-password.pug'

export default Vue.extend({
  template: template(),
  components: {Message},
  data() {
    return {
      data: {
        password: {
          token: '',
          password: '',
          confirmPassword: ''
        }
      },
      errors: {},
      success: false,
      invalid: false,
      expired: false,
      showForm: false
    }
  },
  computed: {
    isSendEnabled() {
      return this.data.password.password !== '' &&
        this.data.password.confirmPassword !== ''
    },

    currentEmail() {
      return this.$route.query.email
    },

    resetPasswordToken() {
      return this.$route.query.token
    }
  },
  watch: {
    resetPasswordToken: {
      immediate: true,
      handler(newValue) {
        if (!newValue) {
          this.$router.push({name: 'login'})
        } else {
          this.checkResetToken()
        }
      }
    }
  },
  methods: {
    checkResetToken() {
      this.success = false
      this.expired = false
      this.invalid = false
      this.showForm = true
    },

    openConfirmModal() {
      openModalConfirm(
        this.$t('auth.resetPassword.confirmationModal.title'),
        this.$t('auth.resetPassword.confirmationModal.body'),
        this.$t('auth.resetPassword.confirmationModal.okButton')
      ).then(() => this.submit())
    },

    submit() {
      this.errors = {}
      this.data.password.token = this.resetPasswordToken

      this.$auth.updatePasswordToken(this.data.password).then(() => {
        this.success = true
      }, response => {
        if (response.status === 422) {
          const tokenError = response.data.tokenErrors.token

          if (tokenError && ['invalid', 'expired'].includes(tokenError.error)) {
            this.showForm = false
            this[tokenError.error] = true
          } else {
            this.errors = response.data.errors
          }
        }
      })
    }
  }
})
