export default {
  leadsCvs(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-detail-report-export`,
      {params, ...options}
    ).then(response => response.data)
  },
  salesCvs(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/sales-detail-report-export`,
      {params, ...options}
    ).then(response => response.data)
  },
  statusCount(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  dayCount(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/day-leads-report`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  dayCallCentersCount(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/day-leads-report-by-call-center`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  salesByProduct(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-product`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  salesByAgent(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-agent`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  salesByAgentGroup(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-agent-group`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  salesByCallCenter(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-call-center`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  sitesReport(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/sites-report`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  leadsReportByUtmMedium(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-utm-medium`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  leadsReportByUtmSource(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-utm-source`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  leadsReportByUtmCampaign(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-utm-campaign`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  leadsReportByCtaType(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-cta`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  leadsReportByLanding(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-landing`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  moreEfficientTags(campaignId, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/analytics/more-efficient-tags`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  lessEfficientTags(campaignId, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaignId}/analytics/less-efficient-tags`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },

  dynamicReport(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/dynamic-report`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  leadsReportByAgent(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/leads-report-by-agent`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  audiencesReport(campaingId, params = {}, options = {}) {
    return Vue.http.get(
      `campaigns/${campaingId}/analytics/audiences`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  globalReport(customerId, params = {}, options = {}) {
    return Vue.http.get(
      `customers/${customerId}/analytics/global-report`,
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  effectivenessContactability(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/kpi/EffectivenessContactability',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  modelCategories(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/kpi/modelcategories',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  providerIndicator(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/kpi/providervariables',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  timeAtentionLead(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/kpi/attentionTimeLead',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  boardData(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/kpi/boardsData',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  },
  providercomparative(params = {}, options = {}) {
    return Vue.http.get(
      'analytics/kpi/providercomparative',
      {params, skipLoading: true, ...options}
    ).then(response => response.data)
  }
}
