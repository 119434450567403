import Vue from 'vue'
import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import {EmailEditor} from 'vue-email-editor'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './sms.pug'

const DefaultData = () => ({
  name: '',
  config: {
    smsBody: '',
    origin: '',
    smsTemplateId: '',
    to: '',
    customerId: '',
    nameProvider: ''
  }
})

export default Vue.extend({
  template: template(),
  components: {
    FormModal,
    EmailEditor
  },
  props: {
    node: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node),
      ctis: [],
      totalChars: 160,
      tags: [],
      phoneInput: '',
      shortURL: '',
      tagSelected: '',
      emailEditorReady: false,
      templatesSelect: [],
      smsProviders: [],
      smsProvidersData: [],
      providerId: [],
      templates: [],
      selectedTemplate: false,
      originOptions: [
        {
          value: 'empty',
          label: 'Desde cero'
        },
        {
          value: 'template',
          label: 'Desde plantilla'
        }
      ]
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    },

    originType() {
      return this.data.config.origin
    },

    emailTemplateIdSelected() {
      return this.data.config.smsTemplateId
    },

    lengthText() {
      return this.data.config.smsBody.length
    }
  },
  watch: {
    campaign: {
      immediate: true,
      handler() {
      }
    },

    originType: {
      handler(newValue) {
        if (newValue === 'template') {
          this.selectedTemplate = true
        } else {
          this.selectedTemplate = false
          this.data.config.smsBody = ''
        }
      }
    },

    providerId: {
      handler(newValue) {
        if (this.smsProvidersData.find(el => el.id === newValue)) {
          // eslint-disable-next-line max-len
          this.data.config.nameProvider = this.smsProvidersData.find(el => el.id === newValue).nameProvider
          // eslint-disable-next-line max-len
          this.data.config.customerId = this.smsProvidersData.find(el => el.id === newValue).customerId
        }
      }

    },

    emailTemplateIdSelected: {
      handler(newValue) {
        const templateData = this.templates.find(el => el.id === newValue)
        this.data.config.smsBody = templateData.template
      }
    }
  },
  async created() {
    await this.getTemplates()
    await this.getSmsProviders()
    await this.getTags()
    if (this.data.config.smsTemplateId) {
      this.selectedTemplate = true
    }
    if (this.data.config.customerId !== '') {
      // eslint-disable-next-line max-len
      this.providerId = this.smsProvidersData.find(el => el.nameProvider === this.data.config.nameProvider).id
    }
  },
  methods: {

    deletePhone(phone) {
      this.data.config.to = this.data.config.to.filter(el => el !== phone)
    },

    addPhone() {
      if (!this.data.config.to) {
        this.data.config.to = []
      }
      if (!this.data.config.to.find(el => el === this.phoneInput)) {
        this.data.config.to.push(this.phoneInput)
        this.phoneInput = ''
      }
    },

    addShortUrl() {
      const dataURL = {
        longUrl: this.shortURL
      }

      if (this.shortURL) {
        API.smsTemplates.getShortURL(dataURL).then(({data}) => {
          this.data.config.smsBody += ` ${ data.shortUrl }`
        })
      }
    },

    addTagPhone() {
      if (!this.data.config.to) {
        this.data.config.to = []
      }
      if (!this.data.config.to.find(el => el === '$lead.form.phone')) {
        this.data.config.to.push('$lead.form.phone')
      }
    },

    getTags() {
      API.tags.all({page: {number: 1, size: 1000}}).then(({data}) => {
        this.tags = data.map(tag => ({
          label: tag.name,
          value: `${tag.type }.${tag.key}`
        }))
      })
    },

    getTemplates() {
      return API.smsTemplates.index({page: {number: 1, size: 1000}}).then(({data}) => {
        // eslint-disable-next-line no-shadow, max-len
        this.templatesSelect = data.map(template => ({value: template.id, label: `${template.name} - ${template.description}`}))
        this.templates = data
      })
    },

    insertTag() {
      this.$refs.textarea1.focus()
      const cursorPosition = this.$refs.textarea1.selectionStart
      this.$refs.textarea1.setSelectionRange(cursorPosition, cursorPosition)
      const textoActual = this.$refs.textarea1.value
      // eslint-disable-next-line max-len
      this.data.config.smsBody = `${textoActual.substring(0, cursorPosition)}$lead.${this.tagSelected}${textoActual.substring(cursorPosition)}`
    },

    getSmsProviders() {
      return API.campaigns.smsProviders.index(this.campaign.id).then(({data}) => {
        // eslint-disable-next-line no-shadow, max-len
        this.smsProviders = data.map(provider => ({value: provider.id, label: `${provider.nameProvider}`}))
        // eslint-disable-next-line max-len
        this.smsProvidersData = data
      })
    },

    save() {
      if (!this.data.config.to.length) {
        this.data.config.to = ''
      }
      validatePresence(this.data, [
        'name',
        'config.origin',
        'config.smsTemplateId',
        'config.customerId',
        'config.nameProvider',
        'config.smsBody',
        'config.to'
      ])
      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.sendSms.${key}`, options)
    }
  }
})
