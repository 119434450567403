import AdwordsTable from './_table.js'
import template from './index.pug'

export default Vue.extend({
  template: template(),
  components: {
    AdwordsTable
  },
  data() {
    return {
      customer: {},
      data: [],
      meta: {},
      lastQueryOptions: null // guarda los últimos filtros aplicados
    }
  },
  computed: {},
  mounted() {
    this.loadCustomer()
  },
  methods: {
    t(key, options = {}) {
      return this.$t(`customers.adwords.${key}`, options)
    },
    loadCustomer() {
      this.$setPageTitle(this.t('title'))
      API.customers.show(this.$route.params.id).then(response => {
        this.customer = response
        this.$setBreadcrumbs([
          {label: this.$t('breadcrumbs.customers.index'), route: {name: 'customers-index'}},
          {
            label: this.$t('breadcrumbs.customers.edit', {
            name: response.name
          }),
            route: {
              name: 'customers-edit',
              params: {id: this.$route.params.id}
            }
          },
          {label: this.$t('breadcrumbs.customers.adwords'), route: {}}
        ])
      })
    },
    loadData(queryOptions = {}) {
      const options = {
        ...(queryOptions || this.lastQueryOptions),
        filter: {customerId: this.$route.params.id}
      }
      API.adwords.index(options).then(({data, meta}) => {
        this.data = JSON.parse(JSON.stringify(data))
        this.meta = JSON.parse(JSON.stringify(meta))
        if (queryOptions) this.lastQueryOptions = queryOptions
      })
    }
  }
})
