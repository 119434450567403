import {cloneDeep, merge} from 'lodash'
import FormModal from 'js/components/form-modal'
import openModalConfirm from './_confirm'
import validatePresence from './_validate'
import template from './split.pug'

const DefaultData = () => ({
  name: '',
  branchs: [
    {value: '', nextStepId: ''},
    {value: '', nextStepId: ''}
  ]
})

// Nodo que permite dividir el flujo del workflow en dos caminos basado en porcentajes
export default Vue.extend({
  template: template(),
  components: {
    FormModal
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: merge(DefaultData(), this.node)
    }
  },
  computed: {
    errors() {
      return merge(DefaultData(), this.data.errors)
    }
  },
  methods: {
    save() {
      validatePresence(this.data, [
        'name',
        'branchs[0].value',
        'branchs[1].value'
      ])

      // Añadimos un error si los porcentajes estan rellenos y la suma de ambos no es 100
      if (
        parseFloat(this.data.branchs[0].value) + parseFloat(this.data.branchs[1].value) !== 100 &&
        // Si no tienen errores de presencia significa que estan rellenos
        !this.data?.errors?.branchs
      ) {
        this.data.errors = {
          ...this.data.errors,
          branchs: [
            {value: this.t('fields.branches1Value.errors.invalid')},
            {value: this.t('fields.branches2Value.errors.invalid')}
          ]
        }
      }

      if (this.data.errors) {
        openModalConfirm().then(() => {
          this.$emit('save', cloneDeep(this.data))
        })
      } else {
        this.$emit('save', cloneDeep(this.data))
      }
    },

    t(key, options = {}) {
      return this.$t(`campaigns.show.automations.workflow.nodes.split.${key}`, options)
    }
  }
})
