// En este objeto se debe de definir una propiedad para cada uno de los modelos. El contenido de
// estas popiedades será otro objeto donde se definira una propiedad para cada unos de los campos.
// Ejemplo: tenemos un modelo user con un campo email que tiene un minimo de 3 y un maximo de 100
//   user: {email: {maximum: 100, minimum: 3}}
// De esta forma desde los pug cuando tengamos que escribir maxlength/minlength podremos escribir:
//   :maxlenth="$lenths.user.email.maximum",
//   :minlenth="$lenths.user.email.minimum"
const lengths = Object.freeze({
  user: {
    email: {maximum: 254},
    password: {maximum: 72, minimum: 8},
    firstName: {maximum: 32},
    lastName: {maximum: 64},
    phone: {maximum: 32},
    secondPhone: {maximum: 32}
  },
  customer: {
    name: {maximum: 16},
    socialReason: {maximum: 16},
    legalIdentifier: {maximum: 10},
    address: {maximum: 254},
    city: {maximum: 32},
    province: {maximum: 32},
    phone: {maximum: 32},
    email: {maximum: 254},
    postalCode: {maximum: 10},
    sites: {
      url: {maximum: 256},
      description: {maximum: 256}
    }
  },
  laiaAccounts: {
    name: {maximum: 250},
    legalName: {maximum: 250},
    address: {maximum: 254},
    postalCode: {maximum: 10},
    country: {maximum: 250},
    city: {maximum: 250},
    province: {maximum: 250},
    phone: {maximum: 32},
    email: {maximum: 254},
    phoneNumberProviders: {maximum: 20},
    calltrackingProviders: {maximum: 20}
  },
  invoicings: {
    name: {maximum: 120}
  },
  callCenter: {
    name: {maximum: 64},
    address: {maximum: 254},
    email: {maximum: 254},
    phone: {maximum: 32},
    city: {maximum: 254},
    postalCode: {maximum: 10},
    ctiIds: {maximum: 5}
  },
  trackingSource: {
    name: {maximum: 64},
    description: {maximum: 254},
    utms: {
      utmCampaing: {maximum: 64},
      utmId: {maximum: 64},
      utmTerm: {maximum: 64},
      utmSource: {maximum: 64},
      utmMedium: {maximum: 64},
      utmContent: {maximum: 64}
    }
  },
  providerLeads: {
    name: {maximum: 32},
    description: {maximum: 255}
  },
  product: {
    name: {maximum: 64},
    description: {maximum: 1024}
  },
  campaign: {
    name: {maximum: 32},
    description: {maximum: 512},
    investment: {maximum: 16}
  },
  cdimension: {
    name: {maximum: 254},
    sourceKey: {maximum: 254}
  },
  forms: {
    title: {maximum: 254},
    submit: {maximum: 64},
    messages: {
      success: {maximum: 254},
      error: {maximum: 254}
    },
    ctmID: {maximum: 10},
    phone: {maximum: 9},
    textTop: {maximum: 100},
    textBottom: {maximum: 100},
    label: {maximum: 64},
    link: {maximum: 256}
  },
  automation: {
    name: {maximum: 254},
    description: {maximum: 512}
  },
  request: {
    name: {maximum: 1500},
    description: {maximum: 512}
  },
  workflowNode: {
    name: {maximum: 80},
    retryAttemps: {maximum: 80}
  },
  condition: {
    name: {maximum: 512},
    sourceKey: {maximum: 512},
    comparisonValue: {maximum: 512}
  },
  fields: {
    name: {maximum: 64},
    label: {maximum: 64}
  },
  segmentation: {
    name: {maximum: 32},
    rules: {
      value: {maximum: 128}
    }
  },
  provider: {
    name: {maximum: 32}
  },
  template: {
    name: {maximum: 50},
    column: {maximum: 3}
  },
  ddis: {
    description: {maximum: 254},
    number: {maximum: 32}
  },
  targetNumbers: {
    description: {maximum: 254},
    number: {maximum: 32}
  },
  trackingNumbers: {
    name: {maximum: 100},
    description: {maximum: 254},
    phone: {maximum: 16}
  },
  pool: {
    name: {maximum: 100},
    description: {maximum: 255}
  },
  audiosMenuVoice:{
    name: {maximum: 100},
  },
  staticPhone: {
    description: {maximum: 100},
    utmId: {maximum: 255},
    utmCampaign: {maximum: 255},
    utmSource: {maximum: 255},
    utmMedium: {maximum: 255},
    utmContent: {maximum: 255},
    utmTerm: {maximum: 255}
  }
})

export default {
  install(Vue) {
    Vue.lengths = lengths

    Vue.prototype.$lengths = Vue.lengths
  }
}
