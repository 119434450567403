import {pick, isEqual} from 'lodash'
import {validations} from '../../../../../mocks/customers/factory.js'
import template from './integrations.pug'

const DefaultValues = () => Object.freeze({
  integrations: {
    GoogleLeadAds: false,
    googleAdwords: false,
    googleCampaignManager: false,
    RTB: false,
    DMP: false,
    CRM: false,
    ERP: false,
    facebookLeadAds: false,
    SMSLaia: false,
    SMSLinkMobility: false,
    AIBEConnection: false,
    TikTokLeadAds: false
  }
})
const Fields = Object.keys(DefaultValues())
const Validations = pick(validations, Fields)

export default Vue.extend({
  fields: Fields,
  defaultValues: DefaultValues,
  validations: Validations,
  template: template(),
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: {},
      ready: false,
      config: {
        facebookLeads: false
      }
    }
  },
  watch: {
    // cada vez que se cambia el data emitimos el valor para mantener el v-model actualizado
    data: {
      deep: true,
      handler(_newVal, oldVal) {
        // con el if evitamos que se emita la primera vez cuando se inicializa
        if (oldVal) this.$emit('input', {...this.data})
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue) {
        console.log(this.$route.params)
        // Solo se debe de ejecutar cuando se produce un cambio
        if (isEqual(newValue, this.data)) return

        this.initializeData()
      }
    }
  },
  methods: {
    initializeData() {
      this.data = this.value
      this.ready = true
    },

    t(key, options = {}) {
      return this.$t(`customers.form.${key}`, options)
    }
  }
})
