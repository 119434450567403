import template from './auth.pug'

export default Vue.extend({
  name: 'tik-tok-auth-index',
  template: template(),
  created() {
    const customerId = this.$route.query?.state
    if (customerId) {
      const {auth_code, code} = this.$route.query
      this.$router.push({
        name: 'customers-edit-tiktok-leads',
        params: {id: customerId},
        query: {auth_code, code}
      })
    } else {
      window.close()
    }
  }
})
