import {pickBy, cloneDeep} from 'lodash'
import Pagination from 'js/components/pagination-default/index.js'

import template from './index.pug'

const defaultNoResultsImg = require('assets/images/content/no-results.svg')
const defaultNoDataImg = require('assets/images/content/empty-state.svg')

// Componente tabla completo con filtrado, mensajes de no hay datos/resultados y paginación
// Gestiona el objeto con los parámetros de la consulta y lo envía mediante el evento 'load'
// Es utilizado por ejemplo en el listado de usuarios
export default Vue.extend({
  template: template(),
  components: {
    Pagination
  },
  props: {
    applyFiltersText: {type: String},
    loadWithApplyFilters: {type: Boolean, default: true},
    value: {type: Array},
    noResultsTitle: {type: String},
    noResultsImg: {type: String, default: defaultNoResultsImg},
    noResultsDescription: {type: String},
    noDataTitle: {type: String},
    noDataImg: {type: String, default: defaultNoDataImg},
    noDataDescription: {type: String},
    meta: {type: Object},
    defaultOrder: {type: Object},
    showFilters: {type: Boolean, default: false},
    showPagination: {type: Boolean, default: true},
    initFilters: {type: Object, default: () => ({})},
    hideTableIfNotHasData: {type: Boolean, default: true},
    onlyFilters: {type: Boolean, default: false}
  },
  data() {
    return {
      data: this.value || [],
      appliedFilters: false,
      paginationData: {
        totalElements: null,
        currentPage: 1,
        pageSize: PAGINATION_SIZE
      },
      filterValueOnLoad: '',
      filterData: {},
      orderData: {}
    }
  },
  computed: {
    noElements() {
      // mientras está pendiente la respuesta del backend es undefined
      return this.paginationData.totalElements === 0
    },

    noData() {
      // no existen recursos creados (el nº de elementos es 0 cuando no hay filtros)
      return this.noElements && !this.appliedFilters
    },

    perOptions() {
      return [1, 2, 3].map(n => n * PAGINATION_SIZE)
    },

    queryData() {
      const query = {
        page: {
          number: this.paginationData.currentPage,
          size: this.paginationData.pageSize
        },
        order: this.orderData
      }

      if (this.appliedFilters) {
        // Elimina los filtros vacios para no enviarlos
        const filter = pickBy(this.filterData, this.validFilter)
        if (Object.keys(filter).length) {
          query.filter = filter
        }
      }

      return query
    }
  },
  watch: {
    value(newValue) {
      this.data = newValue
    },

    meta(newValue) {
      this.paginationData = newValue
    }
  },
  created() {
    if (this.defaultOrder) {
      this.orderData[this.defaultOrder.currentField] = this.defaultOrder.currentDir
    }
    this.filterData = cloneDeep(this.initFilters)

    this.load()
  },
  methods: {
    validFilter(value) {
      // no se pude usar _.identity porque elimina el valor 0
      return value !== null && value !== undefined && value !== ''
    },

    load() {
      this.$emit('load', this.queryData)
    },

    applyFiltersEvent() {
      this.$emit('apply-filters', this.queryData)
    },

    changePageSize(newPageSize) {
      this.paginationData.currentPage = 1
      this.paginationData.pageSize = newPageSize
      this.load()
    },

    changePage(newPage) {
      this.paginationData.currentPage = newPage
      this.load()
    },

    changeOrder(field, dir) {
      this.orderData = {}
      this.orderData[field] = dir
      this.load()
    },

    cleanFilters() {
      this.appliedFilters = false
      this.filterData = cloneDeep(this.initFilters)
      this.$emit('clean-filters')
      this.load()
    },

    applyFilters() {
      this.appliedFilters = !!Object.keys(pickBy(this.filterData, this.validFilter)).length
      this.filterValueOnLoad = this.filterData
      this.paginationData.currentPage = 1
      this.applyFiltersEvent()
      if (this.loadWithApplyFilters) {
        this.load()
      }
    }
  }
})
